import { createContext } from 'react';
import { MatomoInstance } from './types';

interface MatomoContextType {
  tracker: MatomoInstance | null;
  visitorIdSearchParam: string;
}

const MatomoContext = createContext<MatomoContextType | null>(null);

export default MatomoContext;
