import React, { useRef, useState } from 'react';
import { AdminInputEmail } from 'components/Admin';
import { Button } from 'primereact/button';
import { MessagesMessageType } from 'primereact/messages';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { oidcSettings } from 'utils/authConfig';

interface ForgotPasswordForm {
  email: string;
  hideForgotPassword: (message?: MessagesMessageType) => void;
}

const ForgotPassword = ({ email, hideForgotPassword }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const { control, handleSubmit } = useForm<ForgotPasswordForm>({
    defaultValues: { email: email || '' },
  });

  const onRequestPasswordReset = async (
    requestPasswordResetInput: ForgotPasswordForm,
  ) => {
    setLoading(true);
    const url = `${oidcSettings.authority}/change-password/request`;

    await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({
        ...requestPasswordResetInput,
        clientId: oidcSettings.client_id,
      }),
    })
      .then(async result => {
        if (!result.ok) {
          const body = await result.json().catch(() => ({}));
          toastRef.current?.show({
            severity: 'error',
            summary: 'Error Message',
            detail: body.error || 'Something went wrong',
          });
        } else {
          hideForgotPassword({
            severity: 'success',
            summary:
              'Success! Reset password email sent. Please check your email.',
            sticky: true,
          });
        }
      })
      .catch(error => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error Message',
          detail: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex align-items-center justify-content-center w-full">
      <Toast ref={toastRef} />
      <form onSubmit={handleSubmit(onRequestPasswordReset)}>
        <div className="flex flex-column" style={{ gap: '14px' }}>
          <h4 className="m-0 p-0 text-lg">Forgot Password?</h4>
          <span className="text-base">
            Please enter your email, you will receive an email to reset your
            password
          </span>

          <div className="w-full">
            <AdminInputEmail
              name="email"
              label="Email"
              autoComplete="email"
              required
              control={control}
            />
          </div>

          <div>
            <div className="w-full flex justify-content-end p-0 ">
              <span>Already have an account?</span>
              <Button
                className="text-800 w-3rem m-0 p-0 md:ml-2 text-color-secondary w-full hover:underline"
                style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '.7rem',
                }}
                onClick={() => hideForgotPassword()}
                type="button"
                label="Sign in"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-content-center w-full mt-2">
          <Button
            className="w-20rem p-2"
            style={{ backgroundColor: 'black', borderColor: 'black' }}
            loadingIcon="pi pi-spin pi-spinner"
            label="Request Password Reset"
            loading={loading}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
