import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon, LatLngExpression } from 'leaflet';
import { getMarketContent } from 'utils/marketContent';
import 'leaflet/dist/leaflet.css';
import { Address } from 'types';

export interface AddressMapProps {
  validatedAddress?: Address;
  height?: number;
}

const defaultHeight = 200;

export default function AddressMap({
  validatedAddress,
  height,
}: AddressMapProps) {
  const calcuatedHeight = height !== undefined ? height : defaultHeight;
  const { meta } = getMarketContent();
  const [coordinates, setCoordinates] = useState<LatLngExpression | null>();
  const customIcon = new Icon({
    iconUrl: meta.faviconLight,
    iconSize: [20, 20],
    iconAnchor: [1, 1],
  });

  useEffect(() => {
    if (validatedAddress?.latitude && validatedAddress?.longitude) {
      setCoordinates([validatedAddress.latitude, validatedAddress.longitude]);
    }
  }, [validatedAddress]);

  return (
    <section className="map-component">
      {!!coordinates && (
        <div>
          <p className="text-sm my-1 ml-1">{`Approx lat,lon ${coordinates}`}</p>
          <div className="map" id="map">
            <MapContainer
              center={coordinates}
              zoom={17}
              scrollWheelZoom={false}
              zoomControl={false}
              style={{ height: `${calcuatedHeight}px` }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              <Marker position={coordinates} icon={customIcon}>
                <Popup position={coordinates}>
                  <div
                    className="leaflet-popup__content"
                    style={{ width: '9rem' }}
                  >
                    <strong> Validated Address </strong>
                    <br />
                    {validatedAddress ? validatedAddress.street1 : ''}
                  </div>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      )}
    </section>
  );
}
