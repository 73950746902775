import { ReactNode } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import useLocalStorage from 'utils/useLocalStorage';
import { getMarketContent } from 'utils/marketContent';
// import { useSearchParams } from 'react-router-dom';
import crwdmarket_logo from '../../assets/crwd/brand_market/crwdmarket-default-logo.png';

type HelmetPropTypes = {
  title?: string;
  description?: string;
  link: string;
  keywords?: string | null;
  imageCard?: string;
  largeTwitterCard?: boolean;
  addPostfixTitle?: boolean;
  noIndex?: boolean;
  children?: ReactNode | null;
};

const DEFAULT_DESCRIPTION =
  'A blockchain marketplace platform for people passionate about health.';
let DEFAULT_TITLE = 'crwdmarket';

/**
 * @param title specific webpage title replaces default 'crwdmarket'
 * @param description custom description to replace default
 * @param link page path ex: /product
 * @param keywords postfix specific meta keywords to the mainKeyWords
 * @param imageCard image to replace defaultImageCard for og:image
 * @param largeTwitterCard
 * @param addPostfixTitle If true, adds POSTFIX_TITLE to the end of the title. Ex/ "Vitamins - Advanced Medicine Marketplace "
 * @param noIndex If true: Website page can be indexed, but never shown in any Google search results. ex/ Used on Page Not Found
 * @param children add extra meta tags
 * @param HelmetPropTypes test
 */

export const Head = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  link,
  keywords = '',
  imageCard,
  largeTwitterCard = false,
  addPostfixTitle = false,
  noIndex = false,
  children = null,
}: HelmetPropTypes) => {
  const { meta, styleSheet } = getMarketContent();

  // TODO: Remove this once tailwind styling is updated * For DarkMode:
  const [isDarkFromLocalStorage] = useLocalStorage<boolean>(
    'isDark',
    localStorage.getItem('isDark') === 'true',
  );

  // default values:
  const DOMAIN = meta.protocolAndDomain
    ? meta.protocolAndDomain
    : 'https://cart.crwd.market/';

  const MAIN_KEYWORDS = meta.mainKeyWords
    ? meta.mainKeyWords
    : 'blockchain ecosystem';

  const DEFAULT_IMAGE_CARD = meta.defaultImageCard
    ? meta.defaultImageCard
    : crwdmarket_logo;

  DEFAULT_TITLE = meta.title || 'crwdmarket';
  const POSTFIX_TITLE = ` - ${meta.title}` || ' - crwdmarket';

  let metaTitle: string | undefined;

  if (addPostfixTitle) {
    metaTitle = title + POSTFIX_TITLE;
  } else {
    metaTitle = title;
  }

  const metaDescription = description ?? DEFAULT_DESCRIPTION;
  const metaLink = DOMAIN + link;

  const metaKeywords = keywords?.length
    ? `${MAIN_KEYWORDS}, ${keywords}`
    : MAIN_KEYWORDS;

  let metaImageCard: string;

  if (imageCard) {
    if (imageCard.startsWith('https' || 'http')) {
      metaImageCard = imageCard;
    } else {
      metaImageCard = DOMAIN + imageCard;
    }
  } else {
    metaImageCard = DOMAIN + DEFAULT_IMAGE_CARD;
  }

  const metaRobots = noIndex ? 'noindex, nofollow' : 'index, follow';
  const twitterCardType = largeTwitterCard ? 'summary_large_image' : 'summary';

  // const [searchParams] = useSearchParams();
  // const isEdgeSite = searchParams.get('isEmbedded') === '1';

  return (
    <ReactHelmet>
      <html lang="en" />
      {/* {meta.hubSpotChatId && !isEdgeSite ? (
        // ---- hubspot ---
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={`//js.hs-scripts.com/${meta.hubSpotChatId}.js`}
        />
      ) : null} */}

      <link
        rel="icon"
        type="image/png"
        href={isDarkFromLocalStorage ? meta.faviconLight : meta.faviconDark}
        sizes="16x16"
      />

      {meta?.appleTouchIcon ? (
        <link rel="apple-touch-icon" href={meta?.appleTouchIcon} />
      ) : null}

      {/* OG Tags */}
      {metaImageCard ? (
        <meta property="og:image" content={metaImageCard} />
      ) : null}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={metaLink} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />

      {/* //Basic Meta Tags:  */}

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      {/* // * Twitter tags *\\ */}
      <meta
        property="twitter:site"
        title={meta.twitterUsername ? meta.twitterUsername : ''}
      />
      <meta property="twitter:description" title={metaDescription} />
      <meta property="twitter:title" title={metaTitle} />
      <meta
        property="twitter:creator"
        content={meta.twitterUsername ? meta.twitterUsername : ''}
      />
      <meta property="twitter:card" content={twitterCardType} />
      <meta property="twitter:image" content={metaImageCard} />
      {/* // Facebook id: app ID in facebook  App Dashboard. */}
      {/* <meta property="fb:app_id" content="add crwd fb id here" /> */}

      <meta property="referrer" content="origin-when-crossorigin" />
      {/* // TODO: Remove once tailwind darkmode is updated */}
      <link
        rel="stylesheet"
        href={
          isDarkFromLocalStorage
            ? '/assets/default-dark/theme.css'
            : '/assets/default-light/theme.css'
        }
      />
      {styleSheet.light && styleSheet.dark ? (
        <link
          rel="stylesheet"
          href={isDarkFromLocalStorage ? styleSheet.dark : styleSheet.light}
        />
      ) : (
        <link
          rel="stylesheet"
          href={
            isDarkFromLocalStorage
              ? '/assets/default-dark/colors-fonts.css'
              : '/assets/default-light/colors-fonts.css'
          }
        />
      )}
      {/* //add customized meta methods via children if needed */}
      {children}
    </ReactHelmet>
  );
};
