import React from 'react';
import { Card } from 'primereact/card';
import parse from 'html-react-parser';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';
import ProductFeatureBtn, { FeatureButtonType } from './ProductFeatureBtn';
import './ProductFeatureBottom.scss';

export type RealtedProducts = {
  title: string;
  image: string;
  orderButton: FeatureButtonType;
};

interface FeatureHeaderProps {
  title: string;
  logoDivider?: string;
  realatedProducts: RealtedProducts[] | [];
  headerSection?: any;
  showBottomHeader: boolean;
  video?: {
    showVideo: boolean;
    header: string;
    accountId: string;
    videoId: string;
  };
  htmlContent: string | '';
}

export default function ProductFeatureBottom({
  logoDivider,
  title,
  realatedProducts,
  headerSection,
  showBottomHeader = false,
  video,
  htmlContent,
}: FeatureHeaderProps) {
  return (
    <div className="grid mt-2 mb-6 product-features__bottom">
      {showBottomHeader && (
        <div className="grid col-12">
          <h2
            className={`col-12 ${
              headerSection.styles.flexOrder
                ? headerSection.styles.flexOrder[1]
                : 'px-4'
            }`}
          >
            {headerSection.title}
          </h2>
          <p
            className={`col-12 px-4 ${
              headerSection.styles.flexOrder
                ? headerSection.styles.flexOrder[3]
                : ''
            }`}
          >
            {headerSection.text}
          </p>
          <div
            className={` ${
              headerSection.styles.flexOrder
                ? headerSection.styles.flexOrder[2]
                : ''
            }`}
          >
            {htmlContent && parse(htmlContent)}
          </div>
          {headerSection.orderButton.showButton && (
            <div className="py-4 md:px-0 col-12 flex justify-content-center ">
              <ProductFeatureBtn button={headerSection.orderButton} />
            </div>
          )}
          {headerSection.image && (
            <div
              className={`col-12 mx-auto ${
                headerSection.styles.flexOrder
                  ? headerSection.styles.flexOrder[0]
                  : ''
              }`}
            >
              <img
                className="w-full"
                src={headerSection.image}
                alt={headerSection.title}
              />
            </div>
          )}
          {video?.videoId && video.showVideo && (
            <div className="col-12">
              <h2 className="col-12 text-center uppercase">{video.header}</h2>
              <div className="video-wrap mt-5">
                <h2 className="col-12 px-4">{video.header}</h2>
                <VimeoPlayer url={`https://vimeo.com/${video?.videoId}`} />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`${
          headerSection.styles.logoDividerStyle
            ? headerSection.styles.logoDividerStyle
            : 'col-12 py-6'
        }`}
      >
        <img className="w-full" src={logoDivider} alt="" />
      </div>
      <h2 className="uppercase steps__title col-12  m-auto text-center">
        {title}
      </h2>
      <div className="col-12">
        {!!realatedProducts.length && (
          <div className="grid justify-content-center mt-2">
            {realatedProducts.map(product => (
              <Card
                key={product.image}
                className="border-1 border--color col-12 md:col-3 md:mx-2 product-feature-related-item-card"
                header={
                  <div className="col-12 mx-auto">
                    <img
                      style={{ maxHeight: '100px', width: 'auto' }}
                      className="mx-auto"
                      src={product.image}
                      alt={product.title}
                    />
                  </div>
                }
                footer={<ProductFeatureBtn button={product.orderButton} />}
              >
                <h2 className="col-12 uppercase related-product-title">
                  {product.title}
                </h2>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
