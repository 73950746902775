import { useCartContext } from 'CartContext';
import { Head } from 'components/head/Head';
import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function ShoppingCartQuantity(): ReactElement {
  const [searchParams] = useSearchParams();
  const { quantity } = useCartContext();
  const [cartQty, setCartQty] = useState<string | null>(quantity);
  const style = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    const handleStorageCartQty = () => {
      setCartQty(localStorage.getItem('cart-quantity'));
    };
    window.addEventListener('storage', handleStorageCartQty);
    return () => window.removeEventListener('storage', handleStorageCartQty);
  }, [cartQty, quantity]);

  return (
    <>
      <Head
        title={`Cart-Quantity: ${quantity}`}
        link="/cart-quantity"
        keywords="cart-quantity"
      />
      <div id="cart-quantity" style={style}>
        {cartQty}
      </div>
    </>
  );
}
