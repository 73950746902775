import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ReactElement, useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import Product from 'components/Product';

export default function SkuExample(): ReactElement {
  const [displayBasic, setDisplayBasic] = useState<boolean>(false);
  const [skuValue, setSkuValue] = useState('2021-08-20-HSX-AMM-ve-002');
  const [affiliateValue, setAffiliateValue] = useState('123456');
  const toast = useRef<Toast>(null);

  function modalToggle() {
    setDisplayBasic(true);
  }
  function copyLink() {
    navigator.clipboard.writeText(
      `https://storefront.blockchainecosystem.exchange/product/${skuValue}${
        affiliateValue ? `?afmc=${affiliateValue}` : ''
      }`,
    );
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied link to clipboard',
      contentClassName: '',
    });
  }
  function copyEmbed() {
    navigator.clipboard.writeText(
      `<iframe src="https://storefront.blockchainecosystem.exchange/product/${skuValue}${
        affiliateValue ? `?afmc=${affiliateValue}` : ''
      }" title="crowdpoint" width="100%" height="750px" />`,
    );
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied embed code to clipboard',
      contentClassName: '',
    });
  }

  return (
    <>
      <section className="p-4 flex flex-column justify-content-center align-items-center">
        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-9 xl:w-6 mb-3">
          <h2>SKU/Affiliate Form</h2>
          <div className="p-inputgroup flex flex-column">
            <label htmlFor="skuCode">SKU</label>
            <InputText
              id="skuCode"
              value={skuValue}
              onChange={e => setSkuValue(e.target.value)}
              className="w-full mb-3"
            />

            <label htmlFor="affiliateCode">Affiliate Code</label>
            <InputText
              id="affiliateCode"
              value={affiliateValue}
              onChange={e => setAffiliateValue(e.target.value)}
              className="w-full mb-3"
            />
          </div>
          <Button
            label="Buy Now"
            icon="pi pi-shopping-cart"
            onClick={() => modalToggle()}
          />
        </div>

        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-9 xl:w-6 mb-3">
          <h2>Link</h2>
          <InputTextarea
            rows={3}
            cols={30}
            value={`https://storefront.blockchainecosystem.exchange/product/${skuValue}${
              affiliateValue ? `?afmc=${affiliateValue}` : ''
            }`}
            className="mb-2 w-full"
            style={{ color: 'gold', backgroundColor: '#1c1c1c' }}
          />
          <Button
            label="Copy Link"
            icon="pi pi-copy"
            onClick={() => copyLink()}
          />
        </div>

        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-9 xl:w-6 mb-3 flex flex-column ">
          <h2>Embed Code</h2>
          <p>Add embed code to your site</p>

          <InputTextarea
            rows={5}
            cols={30}
            value={`<iframe src="https://storefront.blockchainecosystem.exchange/product/${skuValue}${
              affiliateValue ? `?afmc=${affiliateValue}` : ''
            }" title="crowdpoint" width="100%" height="750px" />`}
            className="mb-2"
            style={{ color: 'gold', backgroundColor: '#1c1c1c' }}
          />
          <Button
            label="Copy Embed Code"
            icon="pi pi-copy"
            onClick={() => copyEmbed()}
            className="max-w-max"
          />
        </div>
      </section>
      <Dialog
        visible={displayBasic}
        breakpoints={{ '960px': '80vw', '640px': '90vw' }}
        style={{ height: '80vh', width: '60vw' }}
        position="center"
        header=""
        dismissableMask
        draggable={false}
        onHide={() => {
          setDisplayBasic(false);
        }}
      >
        <Product sku={skuValue} />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
}
