import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import Product from 'components/Product';
import { getMarketContent } from 'utils/marketContent';
import Footer from 'components/footer-crwd/Footer';
import ShopNavBar from './shop-home-page/shop-nav-bar';

export default function ProductPage(): ReactElement {
  const { sku } = useParams();
  const resolvedSku = sku || '2021-08-20-HSX-AMM-sb-001';
  const { isEmbedded } = useCartContext();
  const { displaySimpleNav } = getMarketContent();
  return (
    <>
      <ShopNavBar displaySimpleNav={displaySimpleNav || isEmbedded} />
      <Product sku={resolvedSku} isEmbedded={isEmbedded} />
      <Footer />
    </>
  );
}
