import advancedmedicine from 'utils/market-content/advancedmedicine.json';
import communicationssector from 'utils/market-content/communicationssector.json';
import infotechsector from 'utils/market-content/infotechsector.json';
import crwdmarket from 'utils/market-content/crwdmarket.json';
import token from 'utils/market-content/token.json';
import accountant10x from 'utils/market-content/accountant10x.json';
import business10x from 'utils/market-content/business10x.json';
import cyber10x from 'utils/market-content/cyber10x.json';
import health10x from 'utils/market-content/health10x.json';
import signup10xProvider from 'utils/market-content/signup10xProvider.json';
import signup10xAffiliate from 'utils/market-content/signup10xAffiliate.json';
import signup10xMemm from 'utils/market-content/signup10xMemm.json';
import social10x from 'utils/market-content/social10x.json';

export type Vendor = {
  image: string;
  name: string;
};

export type Collection = {
  collection_url: string;
  collection_image: string;
  collection_name: string;
  collection_vendors: Record<string, any>[];
  [key: string]: any;
};

export interface MarketContent {
  market: string;
  supportEmailAddress: string;
  rootCategory: string;
  style: string;
  breadcrumbHome?: string;
  pageHasVideoBanner?: boolean;
  redirectProductDetails: boolean;
  redirectAddToCartVendorSite: boolean;
  redirectVisitWebsiteBtn: boolean;
  displayProductVisitWebsite?: boolean;
  displaySimpleNav: boolean;
  displayOrderPageReturnMarket?: boolean;
  defaultDarkMode?: boolean;
  hideSecondaryLogoNav?: boolean;
  meta: {
    title: string;
    [key: string]: any;
  };
  styleSheet: Record<string, any>;
  navigation: Record<string, any>;
  homePage: Record<string, any>;
  privacy: Record<string, any>;
  footer: Record<string, any>;
  copyright: Record<string, any>;
  redirects?: Record<string, string>;
  matomoSiteIdMappings?: Record<string, number>;
  contentPages: Record<string, any>[];
  ProductFeaturesPages?: Record<string, any>[];
  collections: Collection[];
  marketVendors: Vendor[];
  blogID: string;
  blogIDSMB: string;
  blogPage: Record<string, any>;
  teamPage?: Record<string, any>;
  signupEmail?: Record<string, any>;
}

export const markets: MarketContent[] = [
  advancedmedicine,
  communicationssector,
  infotechsector,
  crwdmarket,
  token,
  accountant10x,
  business10x,
  cyber10x,
  health10x,
  signup10xProvider,
  signup10xAffiliate,
  signup10xMemm,
  social10x,
];

export function getMarketContent(): MarketContent {
  if (!process.env.REACT_APP_MARKET_NAME) {
    throw new Error(`no market name set in env`);
  }
  const marketContent = markets.find(
    item => item?.market === process.env.REACT_APP_MARKET_NAME,
  );

  if (!marketContent) {
    throw new Error(
      `no market content for ${process.env.REACT_APP_MARKET_NAME}`,
    );
  }
  // TODO: remove once categories are setup
  // marketContent.rootCategory = 'shopping';
  return marketContent;
}
