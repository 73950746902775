import { HashLink } from 'react-router-hash-link';
import { ReactElement } from 'react';
import { rightSlideMobile } from 'components/imedia/components/Animations';
import { useContent } from 'utils/useContent';
import MessageVideos from 'components/imedia/components/MessageVideos';
import Reveal from 'react-awesome-reveal';
import ReactGA from 'react-ga4';
import './scss/MyMessage.scss';
import { useMatomo } from 'matomoReact';

export default function MyMessage(): ReactElement {
  const data = useContent();
  const { trackEvent } = useMatomo();

  return (
    <section
      id="my-message"
      style={{
        backgroundColor: '#EFEFEF',
      }}
      className={
        data?.message.videos[0].id
          ? `my-message flex flex-column justify-content-center align-items-center px-4 p-m80-d100`
          : 'hidden'
      }
    >
      <Reveal
        keyframes={rightSlideMobile}
        triggerOnce
        className="w-full flex flex-column justify-content-center"
      >
        <h3 className="text-5xl font-normal mb-6 capitalize mt-0 text-center text-900">
          {data.participate.plural ? 'Our Message' : 'My Message'}
        </h3>
        {/* {data.message.subtitle && (
          <p className="text-xl mt-0 mb-5 text-center">
            {parse(data.message.subtitle)}
          </p>
        )} */}
        <MessageVideos />
        <HashLink
          to="#products"
          id="MyMessage-primary-action"
          className="w-full md:w-auto bg-transparent border-1 px-6 py-3 cursor-pointer no-underline button--blue--filled text-center"
          style={{
            borderRadius: '165px',
            letterSpacing: '0.2em',
            color: 'var(--c-blue)',
            margin: '0 auto',
          }}
          smooth
          onClick={() => {
            ReactGA.event({
              category: 'landing-page',
              action: 'Landing Page - MyMessage-primary-action',
              label: 'Landing Page - MyMessage-primary-action',
            });
            trackEvent({
              category: 'landing-page',
              action: 'Landing Page - MyMessage-primary-action',
              name: 'Landing Page - MyMessage-primary-action',
            });
          }}
        >
          {data?.message.videoFooter}
        </HashLink>
      </Reveal>
    </section>
  );
}
