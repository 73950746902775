export function formatDateString(date: string): string {
  const dateStringOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
  };
  return new Date(date).toLocaleDateString(undefined, dateStringOptions);
}
export const moneyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
