import { Avatar } from 'primereact/avatar';
import parse from 'html-react-parser';

interface BlogAuthorDetailsProps {
  authorDetails?: {
    blog_author_avatar?: { href: string; type: string };
    display_name: string;
    blog_author_bio: string;
  };
}

export default function BlogAuthorDetails({
  authorDetails,
}: BlogAuthorDetailsProps) {
  return (
    <>
      {authorDetails?.display_name && (
        <div className="blog-author-details my-auto">
          <h3 className="uppercase author-header">About the author</h3>
          {authorDetails?.blog_author_avatar?.href && (
            <Avatar
              className="mx-auto my-3 avatar"
              imageAlt={authorDetails?.display_name}
              image={authorDetails.blog_author_avatar.href}
              shape="circle"
              size="xlarge"
            />
          )}
          {authorDetails?.display_name && (
            <h3 className="mb-2 text-base">{authorDetails.display_name}</h3>
          )}
          <section className="max-w-screen-sm author-bio">
            {authorDetails?.blog_author_bio &&
              parse(authorDetails.blog_author_bio)}
          </section>
        </div>
      )}
    </>
  );
}
