import {
  DataView,
  DataViewLayoutOptions,
  DataViewLayoutType,
  DataViewSortOrderType,
} from 'primereact/dataview';
import { DigitalProduct, OrderType } from 'types';
import { Dropdown } from 'primereact/dropdown';
import { ReactElement, useState } from 'react';
import { useGetProduct } from 'hooks/magentoHooks';
import { useNavigate } from 'react-router-dom';
import buttonize from 'utils/buttonize';
import './DigitalProductList.scss';
import { ProductFieldsFragment } from 'graphql/generated/magentoApi';

type DigitalProductListProps = {
  orders: OrderType[];
};

const productTemplate = (product: DigitalProduct, layout: string) => {
  if (!product) {
    return null;
  }
  if (layout === 'list')
    return <ListProduct digitalProduct={product} layout={layout} />;
  if (layout === 'grid')
    return <GridProduct digitalProduct={product} layout={layout} />;
  return null;
};

function OrderImage(props: {
  product?: ProductFieldsFragment;
  layout: string;
}): ReactElement {
  const { product, layout } = props;
  const images = product?.media_gallery?.map(image => image?.url) || [];

  return layout === 'grid' ? (
    <img
      alt={product?.name || ''}
      src={images[0] || ''}
      className="w-full mt-2"
      style={{ height: '150px', objectFit: 'contain', borderRadius: '4px' }}
    />
  ) : (
    <img
      alt={product?.name || ''}
      src={images[0] || ''}
      className="max-w-full"
      style={{ aspectRatio: '1', objectFit: 'contain', borderRadius: '4px' }}
    />
  );
}

const ListProduct = (props: {
  digitalProduct: DigitalProduct;
  layout: string;
}): ReactElement => {
  const { digitalProduct, layout } = props;
  const { product } = useGetProduct({ sku: digitalProduct.sku });
  const navigate = useNavigate();

  return (
    <div className="digital-product col-12">
      <div
        className="w-full cursor-pointer flex"
        {...buttonize(() =>
          navigate(
            `/digital-content/${digitalProduct.sku}?orderNumber=${digitalProduct.order_number}`,
          ),
        )}
      >
        <div className="flex align-items-center col-2">
          <OrderImage product={product} layout={layout} />
        </div>
        <div className="flex flex-column justify-content-center align-items-center col-4 my-0">
          <p
            className="text-center font-bold my-2"
            style={{ color: '#027F7D' }}
          >
            {product?.name || digitalProduct.name}
          </p>
          <p className="text-center font-italic my-2">
            {product?.vendor_company}
          </p>
        </div>
      </div>
    </div>
  );
};

const GridProduct = (props: {
  digitalProduct: DigitalProduct;
  layout: string;
}): ReactElement => {
  const { digitalProduct, layout } = props;
  const { product } = useGetProduct({ sku: digitalProduct.sku });
  const navigate = useNavigate();

  return (
    <div className="digital-product col-12 md:col-4">
      <div
        className="w-full h-full cursor-pointer"
        {...buttonize(() =>
          navigate(
            `/digital-content/${digitalProduct.sku}?orderNumber=${digitalProduct.order_number}`,
          ),
        )}
      >
        <OrderImage product={product} layout={layout} />
        <p
          className="text-center font-bold my-2 text-sm md:text-base "
          style={{ color: '#027F7D' }}
        >
          {product?.name || digitalProduct.name}
        </p>
        <p className="text-center font-italic my-2 text-sm md:text-base">
          {product?.vendor_company}
        </p>
      </div>
    </div>
  );
};

export default function DigitalProductList({
  orders,
}: DigitalProductListProps): ReactElement {
  const [layout, setLayout] = useState<DataViewLayoutType>('grid');
  const [sortKey, setSortKey] = useState<string>();
  const [sortOrder, setSortOrder] = useState<DataViewSortOrderType>(null);
  const [sortField, setSortField] = useState(undefined);
  const sortOptions = [
    { label: 'Ascending', value: 'name' },
    { label: 'Descending', value: '!name' },
  ];

  const products =
    orders?.flatMap(order => {
      const items = order?.items.filter(item => item.is_virtual === 1);
      if (items) {
        return items.map(item => ({
          ...item,
          order_number: order.increment_id,
        }));
      }
      return [];
    }) || [];

  const onSortChange = (event: any) => {
    const { value } = event;
    if (value.indexOf('!') === 0) {
      setSortOrder(-1);
      setSortField(value.substring(1, value.length));
      setSortKey(value);
    } else {
      setSortOrder(1);
      setSortField(value);
      setSortKey(value);
    }
  };

  return (
    <DataView
      className="digital-products-list"
      value={products}
      layout={layout}
      header={
        <div className="grid">
          <div className="col-6" style={{ textAlign: 'left' }}>
            <Dropdown
              options={sortOptions}
              value={sortKey}
              optionLabel="label"
              placeholder="Sort By Name"
              onChange={onSortChange}
            />
          </div>
          <div className="col-6" style={{ textAlign: 'right' }}>
            <DataViewLayoutOptions
              layout={layout}
              onChange={e => setLayout(e.value)}
            />
          </div>
        </div>
      }
      itemTemplate={productTemplate}
      paginator
      rows={9}
      sortOrder={sortOrder}
      sortField={sortField}
    />
  );
}
