import { ReactElement, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Panel, PanelHeaderTemplateOptions } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useParams } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import './CheckoutSummary.scss';
import { moneyFormat } from 'utils/formatHelper';
import { Toast } from 'primereact/toast';
import useViewport from 'hooks/useViewport';
import CartItemList from './CartItemList';

export default function CheckoutSummary(): ReactElement {
  const { shipping, subTotal, total, taxes, updateCart, loading } =
    useCartContext();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { skuToAdd } = useParams();
  const toast = useRef<Toast>(null);
  const { isMobile } = useViewport();

  useEffect(() => {
    if (skuToAdd && isFirstRender) {
      updateCart({
        sku: skuToAdd,
        quantity: 1,
        onlyAddOneIfNotPresent: true,
        debug: 'CheckoutSummary',
      });
    }
    setIsFirstRender(false);
  }, [skuToAdd, updateCart, isFirstRender]);

  const panelTemplate = (options: PanelHeaderTemplateOptions) => {
    const { collapsed, onTogglerClick, className, titleClassName } = options;

    if (isMobile) {
      const toggleIcon = collapsed
        ? 'ml-1 pi pi-chevron-up'
        : 'ml-1 pi pi-chevron-down';
      const header = collapsed ? 'Show Order Summary' : 'Hide Order Summary';
      return (
        <Button
          className="w-full order--sumary--toggle"
          onClick={onTogglerClick}
        >
          <span className="flex align-items-center text-sm">
            {header}
            <span
              className={`ml-1 text-sm md:mx-2 md:text-base  ${toggleIcon}`}
            />
          </span>
          {isMobile && (
            <span className="raleway-price-format text-sm">${total}</span>
          )}
        </Button>
      );
    }

    return (
      <div className={className}>
        <span className={`${titleClassName} ml-2 text-sm`}>Order Summary</span>
      </div>
    );
  };

  return (
    <>
      <div className="checkout__summary z-5 w-full sticky sm:block">
        <Panel
          className="m-0 w-full"
          header="Order Summary"
          headerTemplate={panelTemplate}
          toggleable={isMobile}
          collapsed={isMobile}
        >
          <CartItemList showCheckoutSummary />
          <p className="flex justify-content-between text-700 font-medium mb-2 text-sm">
            Subtotal
            <span className="text-800 raleway-price-format">
              {moneyFormat.format(Number(subTotal))}
            </span>
          </p>
          <p className="flex justify-content-between text-700 font-medium my-2 text-sm">
            Shipping
            <span className="text-800 raleway-price-format">
              {Number.isNaN(Number(shipping))
                ? shipping
                : `${moneyFormat.format(Number(shipping))}`}
            </span>
          </p>
          <p className="flex justify-content-between text-700 font-medium my-2 text-sm">
            Taxes
            <span className="text-800 raleway-price-format">
              {moneyFormat.format(Number(taxes))}
            </span>
          </p>
          <hr className="w-full border-none border-bottom-1 border-300 my-3" />
          {loading ? (
            <ProgressSpinner className="flex align-right" />
          ) : (
            <p className="flex justify-content-between text-800 font-medium mt-2 mb-0 text-sm">
              Total
              <span className="text-900 font-bold raleway-price-format">
                <span className="mr-2 text-800 font-medium">USD</span>
                {moneyFormat.format(Number(total))}
              </span>
            </p>
          )}
        </Panel>
      </div>
      <Toast
        ref={toast}
        position="top-right"
        className="cart-page__toast fixed"
      />
    </>
  );
}
