import VisitWebsiteCards from 'pages/shop-home-page/website-cards';
import React from 'react';

export type FeatureButtonType = {
  showButton: boolean;
  label: string;
  class: string;
  link: string;
  sku: string;
};

interface ProductFeatureBtnProps {
  button: FeatureButtonType;
}

const ProductFeatureBtn = ({ button }: ProductFeatureBtnProps) => (
  <>
    {button && (
      <VisitWebsiteCards
        sku={button.sku}
        link={button.link}
        header={button.label}
        isButton
        classList={`order-btn align-self-start ${button.class}`}
      />
    )}
  </>
);
export default ProductFeatureBtn;
