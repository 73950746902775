import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import { useIsMarketplace, useIsMarketplaceDomain } from 'hooks/useMarketplace';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import './Footer.scss';
import { getMarketContent } from 'utils/marketContent';

export default function Footer(): ReactElement {
  const urlParams = window.location.search;
  const isParamOpenSKU = urlParams.match(/\b(\w*openSku\w*)\b/g);
  const foundParam = isParamOpenSKU
    ?.filter(param => param.toLowerCase().includes('opensku'))
    .toString();
  const [searchParams] = useSearchParams();
  const isMarketplace = useIsMarketplace();
  const isMarketplaceDomain = useIsMarketplaceDomain();
  const market = getMarketContent();
  const { displaySimpleNav } = getMarketContent();
  const marketName = market.market;
  const isCRWDMarket = marketName === 'crwdmarket';
  const isEdgeSite = searchParams.get('isEmbedded') === '1';

  const { hideNav } = useCartContext();

  if (
    (isMarketplace || isMarketplaceDomain) &&
    !hideNav &&
    !foundParam &&
    !isCRWDMarket &&
    !displaySimpleNav &&
    !isEdgeSite
  ) {
    return <MarketplaceFooter />;
  }

  return <></>;
}
