import './index.scss';

export interface BreadCrumbProps {
  label: string;
  text?: string;
  bg: string;
  className?: string;
}

export const Breadcrumbs = ({
  label,
  text,
  bg,
  className = '',
}: BreadCrumbProps) => (
  <section
    id="breadcrumbs"
    className={className}
    style={{ backgroundImage: `url(${bg})` }}
  >
    <div className="container px-4">
      <h2>{label}</h2>
      {text && <h4>{text}</h4>}
    </div>
  </section>
);
