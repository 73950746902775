import { Fade, Reveal } from 'react-awesome-reveal';
import { HashLink } from 'react-router-hash-link';
import {
  leftSlideDesktop,
  leftSlideMobile,
  rightSlideDesktop,
  rightSlideMobile,
} from 'components/imedia/components/Animations';
import { ReactElement, useEffect, useState } from 'react';
import { useContent } from 'utils/useContent';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import './scss/MyVision.scss';
import { useMatomo } from 'matomoReact';

export default function MyVision(): ReactElement {
  const data = useContent();
  const { trackEvent } = useMatomo();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <>
      <section
        id="my-vision"
        style={{
          minHeight: '100vh',
        }}
        className="flex my-vision relative text-white"
      >
        <div
          style={{
            background:
              'linear-gradient(90.34deg, rgba(0, 0, 0, 0.5) 0.5%, #3c3c3c 100%)',
          }}
          className="my-vision__gradient z-1 flex px-4"
        >
          <div
            className="flex flex-column justify-content-center align-items-center p-m80-d100 md:px-0"
            style={{ maxWidth: '1200px', margin: '0 auto' }}
          >
            <Fade triggerOnce direction="up">
              <h3 className="text-5xl font-normal mb-6 capitalize section-padding-top text-left">
                {data.participate.plural ? 'Our Vision' : 'My Vision'}
              </h3>
              {/* {data.vision.subtitle && (
                <p className="text-xl mt-0 mb-6 text-center">
                  {parse(data.vision.subtitle)}
                </p>
              )} */}
            </Fade>
            {data.vision.text && (
              <div
                className="vision-text-container"
                style={{ maxWidth: '527px' }}
              >
                <Fade direction="up" triggerOnce>
                  {parse(data.vision.text)}
                </Fade>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${data.message.backgroundImage})`,
          }}
          className={`my-vision__bg-image min-w-full min-h-full absolute ${data?.participate.exchangeName}`}
        />
      </section>

      <div
        className="w-full px-4 p-80 flex align-items-center"
        style={{
          background:
            'linear-gradient(90.34deg, #2E4FC6 0.27%, #8886FF 96.01%)',
          minHeight: '200px',
        }}
      >
        <div
          className="w-full text-center flex flex-column md:flex-row justify-content-center align-items-center md:justify-content-evenly"
          style={{ maxWidth: '1200px', margin: '0 auto' }}
        >
          <Reveal
            keyframes={isMobile ? leftSlideMobile : leftSlideDesktop}
            triggerOnce
          >
            {data.vision.ctaText && (
              <p className="text-white text-4xl mt-0 mb-44 line-height-2">
                {parse(data.vision.ctaText)}
              </p>
            )}
          </Reveal>
          <Reveal
            keyframes={isMobile ? rightSlideMobile : rightSlideDesktop}
            triggerOnce
            className="w-full md:w-auto flex"
          >
            <HashLink
              to="#products"
              id="Middle-CTA-primary-action"
              smooth
              className="bg-transparent text-white border-gray-50 border-1 px-6 py-3 cursor-pointer no-underline flex flex-1 justify-content-center md:inline-block button--white--filled"
              style={{ borderRadius: '165px', letterSpacing: '0.2em' }}
              onClick={() => {
                ReactGA.event({
                  category: 'landing-page',
                  action: 'Middle-CTA-action',
                  label: 'Landing Page - Middle-CTA-action',
                });
                trackEvent({
                  category: 'landing-page',
                  action: 'Middle-CTA-action',
                  name: 'Landing Page - Middle-CTA-action',
                });
              }}
            >
              SEE PRODUCTS
            </HashLink>
          </Reveal>
        </div>
      </div>
    </>
  );
}
