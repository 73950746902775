import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './NewsletterSubscription.scss';

interface NewsletterProps {
  disableForm: boolean;
  columnStyle: string;
}

const NewsletterSubscription: React.ElementType<NewsletterProps> = ({
  disableForm,
  columnStyle,
}) => (
  <div className={columnStyle}>
    <p className="title uppercase ">
      Get updates about new products and sales!
    </p>

    <form className={`newsletter-form ${disableForm ? 'disabled' : ''} `}>
      <span className="p-input-icon-left">
        <i className="pi pi-envelope" />
        <InputText
          type="email"
          placeholder="Enter your email"
          className={`${disableForm ? 'disabled' : ''} `}
        />
      </span>
      <Button
        type="submit"
        label="Subscribe"
        className={`p-button-secondary ${disableForm ? 'disabled' : ''} w-full`}
      />
    </form>
  </div>
);

export default NewsletterSubscription;
