import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Link, useNavigate } from 'react-router-dom';
import { ReactElement, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useCartContext } from 'CartContext';
import { useGetProduct } from 'hooks/magentoHooks';
import buttonize from 'utils/buttonize';
import Stars from 'components/Stars';
import './ProductLinked.scss';
import { moneyFormat } from 'utils/formatHelper';

type Props = {
  sku: string;
  type: string;
  isGrid?: boolean;
};

export default function ProductLinked({
  sku,
  type,
  isGrid = false,
}: Props): ReactElement {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const { updateCart } = useCartContext();
  const { product } = useGetProduct({ sku });

  const onClickAddBtn = (sku: string) => {
    updateCart({
      sku,
      quantity: 1,
      debug: 'ProductLinked',
    });
  };

  if (product) {
    if (type === 'upsell') {
      return (
        <>
          <div
            className={`linked-product__card--upsell ${
              isGrid ? 'col-6 sm:col-4 md:col-3 lg:col-4' : 'mr-auto px-1'
            }`}
          >
            <div className="cursor-pointer h-full">
              <Card
                header={
                  <picture
                    className="flex justify-content-center p-0 h-full w-full"
                    {...buttonize(() => navigate(`/product/${product.sku}`))}
                  >
                    <img
                      alt={product.name || ''}
                      src={product.image?.url || ''}
                      style={{ objectFit: 'cover' }}
                    />
                  </picture>
                }
                className="h-full"
              >
                <p
                  className="uppercase mt-0 mb-0 text-xs line-clamp-2 text-center"
                  {...buttonize(() => navigate(`/product/${product.sku}`))}
                >
                  {product.name}
                </p>
                <div
                  className="flex justify-content-between text-cente m-0r"
                  {...buttonize(() => navigate(`/product/${product.sku}`))}
                >
                  <span className="font-semibold text-sm text-center w-full raleway-price-format">
                    {moneyFormat.format(
                      Number(
                        product.price_range.maximum_price?.final_price.value,
                      ),
                    )}
                  </span>
                </div>
                <Button
                  className="upsell-button"
                  label="Add to cart"
                  onClick={() => onClickAddBtn(product.sku as string)}
                  id={`product-upsell-to-cart-${product.sku}`}
                />
              </Card>
            </div>
          </div>
          <Toast
            ref={toast}
            position="top-right"
            className="product-description__toast fixed"
          />
        </>
      );
    }
    if (type === 'related') {
      return (
        <>
          <div
            className={`linked-product__card--related mb-0  ${
              isGrid ? 'col-12 md:col-6 lg:col-3 mr-auto' : 'mx-1'
            }`}
            style={{ height: 'max-content' }}
          >
            <Card
              header={
                <Link to={`/product/${product.sku}`}>
                  <picture className="flex justify-content-center p-0 w-full">
                    <img
                      alt={product.name || ''}
                      src={product.image?.url || ''}
                      style={{ objectFit: 'cover' }}
                    />
                  </picture>
                </Link>
              }
              className="border-1 flex flex-column p-0 align-items-start"
            >
              <div className="w-full">
                <p className="uppercase mt-0 mb-1 text-xs line-clamp-1">
                  {product.name}
                </p>
                <div className="flex justify-content-between flex-column">
                  <span className="font-semibold text-sm raleway-price-format">
                    {moneyFormat.format(
                      Number(
                        product.price_range.maximum_price?.final_price.value,
                      ),
                    )}
                  </span>
                  {!!product.rating_summary && (
                    <Stars rating={(product.rating_summary * 5) / 100} />
                  )}
                </div>
              </div>
              <Button
                className="uppercase text-xs font-bold mt-3 border-noround c-button-primary"
                label="Add to cart"
                onClick={() => onClickAddBtn(product.sku)}
                id={`product-related-to-cart-${product.sku}`}
              />
            </Card>
          </div>
          <Toast
            ref={toast}
            position="top-right"
            className="product-description__toast fixed"
          />
        </>
      );
    }
  }
  return <></>;
}
