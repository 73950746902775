import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './index.scss';
import { getMarketContent } from 'utils/marketContent';

export default function PromoParallax(): ReactElement {
  const market = getMarketContent();
  const { promoSection } = market.homePage;
  return (
    <section
      id="parralax-bg"
      style={{
        backgroundImage: `url(${promoSection?.backgroundImg})`,
      }}
      className={promoSection?.cssWrapperClass}
    >
      <div id="testing" className="content">
        <h3>{parse(promoSection?.header)}</h3>
        {promoSection.subHeader && (
          <p className="font-bold text-lg text-center">
            {parse(promoSection?.subHeader)}
          </p>
        )}
        {promoSection?.link && (
          <Link to={promoSection?.link} className={promoSection?.linkClassName}>
            {promoSection?.linkText}
          </Link>
        )}
      </div>
      {promoSection?.videoBg && (
        <div className="main">
          <div className="overlay" />
          <video src={promoSection.videoBg} autoPlay loop muted />
        </div>
      )}
    </section>
  );
}
