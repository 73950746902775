import { OidcClientSettings } from 'oidc-client-ts';

export const oidcSettings: OidcClientSettings = {
  authority: process.env.REACT_APP_AUTH_URL as string,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  redirect_uri: `${window.location.origin}/auth-redirect`,
  post_logout_redirect_uri: `${window.location.origin}/auth-redirect`,
  response_type: 'code',
  scope: 'crwdid openid email',
};
