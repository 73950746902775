import { MouseEventHandler, ReactElement, useState } from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import buttonize from 'utils/buttonize';
import ProviderForm from './forms/ProviderForm';
import SimpleSignupForm from './forms/SimpleSignupForm';
import './signupHome.scss';

export type SimpleFormType = {
  firsName?: string | null;
  lastName?: string | null;
  primaryEmail?: string | null;
};

export default function ProviderSignupHome(): ReactElement {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [formData, setFormData] = useState<SimpleFormType | null>(null);
  const market = getMarketContent();
  const data = market.meta;

  const benefits = [
    'Contracts, malpractice coverage and compensation',
    'IV recommended formulations and supplies',
    'Powerhouse marketing squad',
    'Your own website & business card options',
    'Certified medical and service training',
    'Advanced support around the clock',
    'Align yourself with the leaders in the industry',
  ];

  const showForm = (
    formData?: SimpleFormType | MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (formData) {
      setFormData(formData as SimpleFormType);
      setFormIsVisible(true);
    }
  };

  return (
    <>
      <Dialog
        header={
          <h1 className="text-xl md:text-4xl text-black">
            Join 10X Health - IV Drip Therapy Providers
          </h1>
        }
        className="w-11 md:w-10"
        visible={formIsVisible}
        onHide={() => setFormIsVisible(false)}
        dismissableMask
        draggable={false}
      >
        <ProviderForm initialFormData={formData} />
      </Dialog>
      <Head
        title={data.title}
        link="/"
        keywords={data.title}
        description={data.title}
      />

      <div className="signup-hero-light relative">
        <div
          className="bg-cover bg-center absolute w-full h-full"
          style={{
            zIndex: -100,
            backgroundAttachment: 'fixed',
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2FuxgSOZyfkT?alt=media&token=a1115264-c493-4a05-9142-e261c0da9a07")`,
          }}
        />
        <div className="pt-5 pl-3 md:pl-5 flex">
          <Image
            className="flex logo-image"
            src="/images/10xhealth/10xhealth-dark.png"
          />
          <div className="flex-grow-1" />
          <a
            className="no-underline text-black text-sm md:text-xl font-bold mr-3   md:mr-5 flex align-items-center"
            href="#about"
          >
            About
          </a>
          <a
            className="no-underline text-black text-sm md:text-xl font-bold mr-5 md:mr-6 flex align-items-center"
            href="#features"
          >
            Features
          </a>
        </div>
        <div className="signup-hero-content w-full flex flex justify-content-end px-4">
          <div className="md:w-6 md:pl-7">
            <Badge size="normal" value="IV Infusion Provider Opportunities" />
            <h1 className="text-5xl lg:text-6xl text-black">
              10<span className="text-primary">X</span> Health
              <br />
              IV Drip Therapy Providers
            </h1>
            <p className="text-black text-base text-justify">
              10X Health IV Therapy is offering lucrative opportunities for
              enterprising Medical Doctors, Advanced Practice Providers and
              Registered Nurses who desire the flexibility of part-time or
              full-time independent contract work.
            </p>
            <Button onClick={showForm as MouseEventHandler<HTMLButtonElement>}>
              Join Us
            </Button>
          </div>
        </div>
      </div>

      <section className="bg-white">
        <div className="signup-content text-xl p-4">
          <div className="mt-5">
            <h1 className="mb-2 text-center text-5xl lg:text-6xl text-black">
              10<span className="text-primary">X</span> Health IV Therapy is one
              of the{' '}
              <span className="text-primary">
                fastest growing opportunities
              </span>{' '}
              in the USA.
            </h1>
            <p className="text-center text-black text-base mb-4 md:w-9 mx-auto">
              Interested in learning how you can be part of 10X Health’s IV
              Mobile Therapy services by becoming a contracted provider with us?
              Are you looking to turn your passion for health and wellness into
              a business with the support of a great team?
            </p>
            <p className="text-center text-black text-base mb-3 md:w-9 mx-auto">
              We’ll provide the materials, resources, IV supplies and scheduling
              so you can focus on what you are good at – providing exceptional
              patient care before, during and after mobile infusion
              appointments.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="signup-content text-xl px-4 py-4">
          <div className="mb-6 grid">
            <div className="col-12 lg:col-6">
              <h1 className="mb-2 text-5xl lg:text-6xl text-black">
                <span className="text-primary">Take the next step.</span> Start
                with our team today.
              </h1>
              <p className="text-black mt-0 pt-0 text-base text-justify">
                Through our high-interest marketing strategies, artificial
                intelligence programs, and our latest customer portal, 10X
                Health has a competitive advantage that allows you to provide
                affordable, convenient, and professional Vitamin & Nutrition IV
                drip infusions to your community. Here are a few more power
                tools that you get when you join 10X Health:
              </p>
              <ul className="signup-checks list-none px-3 text-black">
                {benefits?.map(benefit => (
                  <li key={benefit}>
                    <i className="pi pi-check" />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 px-6 lg:col-6">
              <SimpleSignupForm onSubmitSimpleForm={data => showForm(data)} />
            </div>
          </div>
        </div>
      </section>

      <section
        id="about"
        className="bg-cover"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2FWVzDMsjXZM?alt=media&token=30167a0e-bf48-4196-a3aa-c8cad00842a7")`,
        }}
      >
        <div className="signup-content px-4 py-4">
          <div className="my-6 text-center">
            <h1 className="mb-2 text-6xl text-white">
              ONE TEAM. <span className="text-primary">ONE GOAL</span>
            </h1>
            <p className="text-white text-base text-justify md:w-9 mx-auto">
              The 10X Health Team is made up of a group of remarkable humans
              that share one single goal and vision… to redefine the health of
              millions through our 10X Health Systems and mobile IV Therapy. We
              are looking for enterprising licensed healthcare professionals who
              can start and administer IVs to help us accommodate and expand our
              rapidly growing client base.
            </p>
            <p className="text-white text-base text-justify md:w-9 mx-auto">
              We will teach, guide and support you in all the aspects of how to
              be a successful independent contractor, grow your own Mobile IV
              Therapy business giving you the time freedom and flexibility with
              optimal income.
            </p>
            <Button
              className="m-auto"
              onClick={showForm as MouseEventHandler<HTMLButtonElement>}
            >
              SIGN UP NOW
            </Button>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white">
        <div className="signup-content text-xl px-4 py-4">
          <div className="my-6 grid">
            <div className="col-12 lg:col-5 text-center mb-3 mx-auto">
              <Image
                className="img-border"
                src="https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2Fbl79xaomzx?alt=media&token=138c4579-ae90-4bbb-a4e9-96bb1c2fd40b"
                alt="Image"
                width="100%"
              />
              <Button
                className="m-auto mt-3 px-6 py-3"
                onClick={showForm as MouseEventHandler<HTMLButtonElement>}
              >
                SIGN UP NOW
              </Button>
            </div>
            <div className="col-12 lg:col-6">
              <h1 className="text-6xl text-black text-center">
                WORK INDEPENDENT
              </h1>
              <p className="text-base text-black pt-3 px-3 text-justify">
                Looking for a non-hospital, non-clinic job? Ready for a change?
                Want to control your own time? Like to make more money?
                Passionate about really making a difference for patients? This
                is the opportunity for you!
              </p>
              <p className="text-base text-black px-3 text-justify pb-0 mb-0">
                10X Health Mobile IV isn’t your average IV therapy company,
                which is why we’re not looking for average providers. As an
                independently working 10X Health IV Provider or Nurse, you can
                start helping people feel better from the comfort of their own
                homes on day one.
              </p>
            </div>
          </div>
          <Image
            className="my-6 block"
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2FTtz9Ab3vyV?alt=media&token=05a912fa-97c6-4d8a-bfe1-b1649a7a29c2"
          />
          <div className="my-6 grid">
            <div className="col-12 lg:col-6">
              <h1 className="text-6xl text-black text-center">
                WHY IV THERAPY
              </h1>
              <p className="text-base text-black pt-3 pl-3 text-justify">
                IV Fluid and Vitamin Infusions can be a great tool when included
                in your wellness regimen to help your body heal, strengthen your
                immune system, aid in athletic performance, help with mental
                clarity and much more.
              </p>
              <p className="text-base text-black pl-3 text-justify pb-0 mb-0">
                As a 24/7, anytime, anywhere concierge service, you can go
                directly to clients so you can maximize your day. The increasing
                market demand combined with the simple cash-pay based logistics
                of a mobile IV therapy business make it the perfect business to{' '}
                <span
                  className="text-primary cursor-pointer"
                  {...buttonize(() => showForm())}
                >
                  start today.
                </span>
              </p>
            </div>
            <div className="col-12 lg:col-5 text-center mb-3 mx-auto">
              <Image
                className="img-border pl-3"
                src="/images/10xprovider/doctor-sitting.jpg"
                alt="Image"
                width="100%"
              />
              <Button
                className="m-auto mt-3 px-6 py-3"
                onClick={showForm as MouseEventHandler<HTMLButtonElement>}
              >
                SIGN UP NOW
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-cover"
        style={{
          backgroundAttachment: 'fixed',
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2FWVzDMsjXZM?alt=media&token=30167a0e-bf48-4196-a3aa-c8cad00842a7")`,
        }}
      >
        <div className="signup-content px-4 py-4">
          <div className="my-6 text-center md:w-9 mx-auto">
            <h1 className="mb-2 text-6xl text-white">
              Support is important, so we build a power team around you
            </h1>
            <p className="text-white text-base text-justfy">
              10X Health provides a team-driven performance program from the
              moment you sign on. We are a team and it’s our responsibility to
              give you the resources and support so you can turn your dream into
              a successful reality.
            </p>
            <Button
              className="m-auto"
              onClick={showForm as MouseEventHandler<HTMLButtonElement>}
            >
              SIGN UP NOW
            </Button>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="signup-content text-xl px-4 py-4 grid m-auto">
          <div className="my-6 grid">
            <div className="col-12 md:col-6">
              <h1 className="mb-2 text-2xl text-black">
                Marketing, Sales, & Technology
              </h1>
              <p className="text-base text-justify text-black mb-2">
                10X Health’s IV Therapy Program offers strong support through
                pre-launching, post-starting and ongoing marketing guidance. In
                addition, we are proactive with helping you establish your
                business within your local area through social media & email
                marketing campaign guidance.
              </p>
            </div>
            <div className="col-12 md:col-6">
              <h1 className="mb-2 text-2xl text-black">
                Certified Onboarding & Operations
              </h1>
              <p className="text-base text-justify text-black mb-2">
                10X Health provides a thorough onboarding program to prepare you
                for operating successfully as an independent contractor. Our
                onboarding guidance includes how to make your business official,
                product & therapy combination training, product sales support,
                mitigating liability and transacting the sale after the
                treatment.
              </p>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <h1 className="mb-2 text-2xl text-black">
                Logistics & Contracts
              </h1>
              <p className="text-base text-justify text-black mb-2">
                The 10X Health Team comprises experienced entrepreneurs,
                enterprise level business negotiators and health care clinicians
                who have pre-prepared all logistics and legalities for you,
                including medical malpractice options, inventory management,
                supply order portal and mobile-friendly Electronic Medical
                Record access for documentation on the go.
              </p>
            </div>
            <div className="col-12 md:col-6">
              <h1 className="mb-2 text-2xl text-black">Scalability Support</h1>

              <p className="text-base text-justify text-black mb-2">
                10X Health Mobile IV provides the opportunity, support and
                tracking systems, not only for you to have your independent
                contracting business with us, but also allows you to leverage
                our decentralized distribution model. Bring on a caravan of your
                own affiliates to promote your services and/or providers to
                service their own patients and you quickly will have a scalable
                mobile IV company with various providers.
              </p>
            </div>
          </div>
          <div className="my-auto col-12 md:col-offset-3 md:col-6">
            <SimpleSignupForm onSubmitSimpleForm={data => showForm(data)} />
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: 'black' }}>
        <div className="px-8 py-5 grid">
          <p className="text-white col-12 md:col-5 md:col-offset-1 text-center md:text-left my-auto">
            © Copyright 2023 | All Rights Reserved.
          </p>
          <Button
            label="BACK TO 10X HEALTH MARKETPLACE"
            className="my-3 col-12 md:col-4 md:col-offset-1"
            onClick={() => window.open('https://10xhealth.market/', '_blank')}
          />
        </div>
      </section>
    </>
  );
}
