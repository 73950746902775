import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';

interface MarketPlaceBtnDialogProps {
  display: boolean;
  setDisplay;
  image?: string;
  header: string;
  vendorName?: string;
}

export default function MarketPlaceBtnDialog({
  display,
  setDisplay,
  image,
  header,
  vendorName,
}: MarketPlaceBtnDialogProps) {
  return (
    <Dialog
      visible={display}
      header="Thank you for your Support!"
      onHide={() => setDisplay(false)}
      breakpoints={{ '1200px': '40vw', '960px': '75vw', '640px': '90vw' }}
      style={{ width: '40vw' }}
      className="visit-website-modal"
      dismissableMask
      draggable={false}
    >
      <div className="flex flex-column md:flex-row align-items-center justify-content-start mb-4">
        {image && (
          <div
            className="background-image-square mb-4 md:mb-0 mr-0 md:mr-4 "
            style={{ background: `url(${image})` }}
          />
        )}
        <div className="mb-4">
          <p className="py-0 font-bold mb-0 text-sm">{header}</p>
          <h3 className="py-0 font-bold mb-2">{`Redirecting to ${
            vendorName || ''
          } crwdmarket`}</h3>
          <ProgressBar mode="indeterminate" />
        </div>
      </div>
    </Dialog>
  );
}
