import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { GraphQLClient } from 'graphql-request';
import { useForm } from 'react-hook-form';
import { Messages } from 'primereact/messages';
import { AdminInputBoolean } from 'components/Admin';
import { useUserContext } from 'UserContext';
import { FormTriggerRef } from './AffiliateSteps';
import '../SignUpForm.scss';
import { ContactFormFields } from './Step2ContactForm';
import { ProductHandleFormFields } from './Step3ProductHandleForm';
import { TokenFormFields } from './Step1TokenForm';

export interface DisclaimerFormFields {
  disclaimer1: boolean;
  disclaimer2: boolean;
  disclaimer3: boolean;
  disclaimer4: boolean;
  disclaimer5: boolean;
  disclaimer6: boolean;
  hasEmailedConfirmation: boolean;
}

interface UserSignupInput {
  email: string;
  personFirstName: string;
  personMiddleName?: string;
  personLastName: string;
  personFamilyName?: string;
  personAddress1: string;
  personAddress2?: string;
  personCountryCode: string;
  personProvinceState: string;
  personCity: string;
  personRegionCounty?: string;
  personPostal: string;
  personPostal4?: string;
  personPrimaryMobile: string;
  productId?: string;
  personHandleName: string;
  referrerEmail?: string;
  personSocalLink?: string;
  personFollowingSize?: string;
  data?: Record<string, any>;
}

export interface Step4DisclaimerFormProps {
  tokenData: TokenFormFields | undefined;
  contactData: ContactFormFields | undefined;
  productHandleData: ProductHandleFormFields | undefined;
  setError: (error: { message: string; stepIndex: number }) => void;
  setLoading: (loading: boolean) => void;
  setIsFormComplete: (isComplete: boolean) => void;
}

export default React.forwardRef<
  FormTriggerRef<DisclaimerFormFields | undefined>,
  Step4DisclaimerFormProps
>((props, ref) => {
  const {
    tokenData,
    contactData,
    productHandleData,
    setError,
    setLoading,
    setIsFormComplete,
  } = props;
  const messages = useRef<Messages>(null);
  const { control, setValue, trigger, watch } = useForm<DisclaimerFormFields>({
    defaultValues: {},
  });

  const values = watch();

  const { sdkClient } = useUserContext();

  const submitData = useCallback(async (): Promise<boolean> => {
    setLoading(true);
    let isSuccess = false;
    try {
      const graphqlClient = new GraphQLClient(
        'https://crwd-wallet-dev.hasura.app/v1/graphql',
      );
      console.log(graphqlClient);
      const mutation = `mutation userSignup($userInput: UserSignupInput!) {
        userSignup(userInput: $userInput) {
          id
        }
      }`;

      if (tokenData && contactData && productHandleData && values) {
        const {
          personSocialLink,
          personFollowingSize,
          ...remainingContactData
        } = contactData;
        const userInput: UserSignupInput = {
          email: tokenData.email,
          ...remainingContactData,

          productId: productHandleData.productId,
          personHandleName: productHandleData.handle,

          referrerEmail: productHandleData.referrerEmail,
          data: {
            personSocialLink,
            personFollowingSize,
          },
        };
        // TODO: Submit Later on backend
        console.log(personSocialLink, personFollowingSize);
        // const result = await graphqlClient.rawRequest(mutation, {
        //   userInput,
        // });

        // if (result.data.userSignup.id) {
        //   isSuccess = true;
        // }
        isSuccess = true;
        await sdkClient
          .submitEmail({
            input: {
              email: tokenData.email,
              data: {
                name: contactData.personFirstName,
              },
              submitType: '10xAffiliateConfirmation',
            },
          })
          .then(res => {
            if (res.submitEmail?.data) {
              setValue('hasEmailedConfirmation', true);
            }
          })
          .catch(err => {
            messages.current?.show({
              severity: 'error',
              content: err.message,
              sticky: true,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error: any) {
      const handleTakenMessage = 'handle already taken';
      const { message } = error;
      if (error.message.includes(handleTakenMessage)) {
        setError({
          message: handleTakenMessage.toUpperCase(),
          stepIndex: 2,
        });
      } else {
        messages.current?.show({
          closable: true,
          severity: 'error',
          content: message,
          sticky: true,
        });
      }
    } finally {
      setLoading(false);
    }
    return isSuccess;
  }, [
    setLoading,
    tokenData,
    contactData,
    productHandleData,
    values,
    sdkClient,
    setValue,
    setError,
  ]);

  useEffect(() => {
    if (values.hasEmailedConfirmation) {
      trigger();
    }
  }, [values.hasEmailedConfirmation, trigger]);

  useImperativeHandle(ref, () => ({
    trigger: async () => {
      const isValid = await trigger();
      if (isValid) {
        const isSuccess = await submitData();
        if (isSuccess) {
          return values;
        }
      }

      return undefined;
    },
  }));

  useEffect(() => {
    if (
      Object.keys(values).length &&
      Object.values(values).every(value => value === true)
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [setIsFormComplete, values]);

  return (
    <div className="mt-6 mb-4">
      {!!messages && <Messages ref={messages} />}
      <h1 className="mb-4 text-2xl text-black font-montserrat uppercase">
        TERMS & CONDITIONS:
      </h1>
      <form>
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer1"
          control={control}
          secondaryLabel="I Agree"
          label='I understand that this disclaimer must be on any pages that I use to
          promote 10X Health "I am an independent 10X Health Ventures,
          LLC affiliate, not an employee. I receive referral payments from 10X
          Health Ventures, LLC. The opinions expressed here are my own and are
          not official statements of 10X Health Ventures, LLC or its parent
          company, 10X Health Ventures, LLC"'
          required
        />
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer2"
          control={control}
          secondaryLabel="I Agree"
          label="I agree that I&lsquo;m only allowed to use the content, videos,
          marketing collateral provided through 10X Health Ventures, LLC team
          unless there&lsquo;s written approval prior."
          required
        />
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer3"
          control={control}
          secondaryLabel="I Agree"
          label='I understand that I cannot use the "voice&rdquo; of, or purport to speak on behalf of 10X Health Ventures, LLC. (I can&apos;t say I "work for 10X Health Ventures, LLC" &mdash; If you would like to discuss your affiliate relationship you can say "I have formed a strategic partnership with 10X Health to help get their message out to the masses"'
          required
        />
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer4"
          control={control}
          secondaryLabel="I Agree"
          label="  I understand all marketing has to be TRUTHFUL & COMPLIANT. Anything
          I communicate in marketing or advertising any 10X Health Ventures,
          LLC service or product must be true and accurate and follow all
          federal and state laws, rules and regulations of any governmental
          authorities. Claims that relate to any 10X Health Ventures, LLC
          service or opportunity that are untrue or fraudulent are strictly
          prohibited and you may not claim that any government, person, or
          entity endorses or supports 10X Health Ventures, LLC."
          required
        />
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer5"
          control={control}
          secondaryLabel="I Agree"
          label="Before you can be paid any Commission or Bonuses, You must provide
          10X Health Ventures, LLC a completed W-8 or W-9, as instructed by
          10X Health Ventures, LLC. You will be deemed to have permanently
          waived all rights to Commissions or Bonuses that were earned more
          than 120 days before submitting a completed W-8 or W-9 to 10X Health
          Ventures, LLC."
          required
        />
        <AdminInputBoolean
          containerClassName="toggle-card-button disclaimer-layout mb-4 p-4 flex align-items-center justify-content-center md:justify-content-start"
          className="max-w-max"
          name="disclaimer6"
          control={control}
          secondaryLabel="I Agree"
          label="THE COMMISSION AMOUNT PAID WILL BE 20% ON GENE TESTS AND 2% ON SUPERHUMAN PROTOCOL THROUGH 10X HEALTH VENTURES, LLC. COMMISSION PAYMENTS WILL BE MADE EVERY 30 DAYS AT THE END OF THE MONTH BASED ON CASH COLLECTED."
          required
        />
      </form>
    </div>
  );
});
