import { ProductFieldsFragment } from 'graphql/generated/magentoApi';
import { ReactElement } from 'react';
import './ProductInformation.scss';

type Props = {
  product: ProductFieldsFragment;
  displayCategories: string;
};

export default function ProductInformation({
  product,
  displayCategories,
}: Props): ReactElement {
  const { sku, __typename, vendor_company } = product;

  return (
    <div className="product-info__container relative">
      <div className="product-info-row">
        <span className="font-bold">Categories: </span>
        <span className="product-info-data">{displayCategories}</span>
      </div>
      <div className="product-info-row">
        <span className="font-bold">Vendor: </span>
        <span className="product-info-data">{vendor_company}</span>
      </div>
      <div className="product-info-row">
        <span className="font-bold">Sku: </span>
        <span className="product-info-data">{sku}</span>
      </div>
      <div className="product-info-row">
        <span className="font-bold">Product Type: </span>
        <span className="product-info-data">
          {__typename === 'VirtualProduct'
            ? 'Digital Content'
            : 'Physical Product'}
        </span>
      </div>
    </div>
  );
}
