import { ReactElement, useState } from 'react';
import { useContent } from 'utils/useContent';
import { Video } from 'types';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';

export default function MessageVideos(): ReactElement {
  const data = useContent();
  const [currentVideo, setCurrentVideo] = useState<Video>(
    data?.message?.videos[0],
  );
  const [selectedBtn, setSelectedBtn] = useState(
    data?.message?.videos[0].title,
  );

  const updateVideoUrl = (video: Video) => {
    setCurrentVideo(video);
    setSelectedBtn(video.title);
  };

  return (
    <>
      <div
        className="w-full react-media-player__wrapper flex flex-column justify-content-center mb-4"
        style={{ margin: '0 auto' }}
      >
        <VimeoPlayer url={`https://vimeo.com/${currentVideo?.id}`} />
      </div>
      <div
        className={`react-media-player__buttons video-total-${
          data.message.videos.length >= 3 ? 'over-3' : 'under-3'
        }`}
      >
        {data.message.videos.length > 1 &&
          data?.message.videos.map(video => (
            <button
              key={video.id}
              type="button"
              className={selectedBtn === video.title ? 'active' : ''}
              onClick={() => updateVideoUrl(video)}
            >
              {video.title}
            </button>
          ))}
      </div>
    </>
  );
}
