import { MouseEventHandler, ReactElement, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import ProviderForm from './ProviderForm';
import SimpleSignupForm from './SimpleSignupForm';
import '../signupHome.scss';

export type SimpleFormType = {
  firsName?: string | null;
  lastName?: string | null;
  primaryEmail?: string | null;
};

export default function MemmSectionForm(): ReactElement {
  const [formIsVisible, setFormIsVisible] = useState(false);
  const [formData, setFormData] = useState<SimpleFormType | null>(null);

  const benefits = [
    'Contracts, malpractice coverage and compensation',
    'IV recommended formulations and supplies',
    'Powerhouse marketing squad',
    'Your own website & business card options',
    'Certified medical and service training',
    'Advanced support around the clock',
    'Align yourself with the leaders in the industry',
  ];

  const showForm = (
    formData?: SimpleFormType | MouseEventHandler<HTMLButtonElement>,
  ) => {
    if (formData) {
      setFormData(formData as SimpleFormType);
      setFormIsVisible(true);
    }
  };

  return (
    <>
      <Dialog
        header={
          <h1 className="text-xl md:text-4xl text-black">
            Join 10X Health - IV Drip Therapy Providers
          </h1>
        }
        className="w-11 md:w-10"
        visible={formIsVisible}
        onHide={() => setFormIsVisible(false)}
        dismissableMask
        draggable={false}
      >
        <ProviderForm initialFormData={formData} />
      </Dialog>

      <section className="bg-white">
        <div className="signup-content text-xl px-4 pt-4 pb-2">
          <div className="mb-0 grid">
            <div className="col-12 lg:col-6">
              <h1 className="mb-2 text-3xl lg:text-4xl text-black">
                <span className="text-primary">
                  QUALITY PRODUCTS. GREAT COMMISSIONS.
                </span>{' '}
                BEST IN CLASS RESULTS.
              </h1>
              <p className="text-black mt-0 pt-0 text-base text-justify">
                Through our high-interest marketing strategies, artificial
                intelligence programs, and our latest customer portal, 10X
                Health has a competitive advantage that allows you to provide
                affordable, convenient, and professional Vitamin & Nutrition IV
                drip infusions to your community. Here are a few more power
                tools that you get when you join 10X Health:
              </p>
              <ul className="signup-checks list-none px-3 text-black">
                {benefits?.map(benefit => (
                  <li key={benefit}>
                    <i className="pi pi-check" />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 px-2 md:px-6 lg:col-6">
              <SimpleSignupForm onSubmitSimpleForm={data => showForm(data)} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
