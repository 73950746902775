/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from 'UserContext';
import { useCartContext } from 'CartContext';
import { StoreOrderGuestFieldsFragment } from 'graphql/generated/graphqlRequest';
import { useGetProductMapBySkus } from 'hooks/magentoHooks';
import { moneyFormat } from 'utils/formatHelper';
import { Image, Button } from 'primereact';
import { getMarketContent } from 'utils/marketContent';
import { Head } from './head/Head';
import SignupForm from './forms/SignupForm';
import Spinner from './Spinner';
import './Checkout.scss';

export enum CustomerOption {
  guestCheckout,
  login,
  registerAccount,
}

export default function CheckoutComplete(): ReactElement {
  const { userId, userEmail, sdkClient, login } = useUserContext();
  const { lastOrders, lastCheckout, setLastOrders } = useCartContext();

  const { supportEmailAddress } = getMarketContent();
  const navigate = useNavigate();
  const [orderSKUS, setOrderSKUS] = useState<string[]>([]);
  const [_loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<
    StoreOrderGuestFieldsFragment[] | undefined
  >(lastOrders);
  const [searchParams] = useSearchParams();
  const urlOrderNumber = searchParams.get('orderNumber') || undefined;

  const email = userEmail || lastCheckout?.email;

  useEffect(() => {
    (async () => {
      if (!lastOrders && !orders && urlOrderNumber) {
        setLoading(true);
        const orderNumber = [...urlOrderNumber.split(',')][0];
        const result = await sdkClient.orderAndAssociatedOrders({
          id: orderNumber,
        });
        setOrders(result.orderAndAssociatedOrders?.associatedOrders);
        setLoading(false);
      }
    })();
  }, [lastOrders, urlOrderNumber]);

  useEffect(() => {
    orders?.forEach(order =>
      order.items.forEach(item =>
        setOrderSKUS(orderSKUS => [...orderSKUS, item.sku]),
      ),
    );
  }, [orders]);

  const { products: skuProducts } = useGetProductMapBySkus({
    skus: orderSKUS,
  });

  return (
    <>
      <Head
        title="Checkout Complete"
        link="/checkout-complete"
        addPostfixTitle
        keywords="purchase complete, complete purchase, checkout complete"
      />
      {!_loading ? (
        <div
          className="grid flex mx-auto mt-0 my-4 px-4 md:px-2"
          style={{ maxWidth: '1480px' }}
        >
          <div className="col-12 sm:col-8 mx-auto">
            <h1 className="mb-2">
              Thank you for your order
              {lastCheckout
                ? `${' '}${lastCheckout?.shipping.firstName}`
                : null}
              .
            </h1>
            <p className="my-2">
              Please feel free to{' '}
              {supportEmailAddress ? (
                <a
                  className="p-link font-bold text-base text-900 capitalize no-underline"
                  href={`mailto:${supportEmailAddress}?subject=Question about order: ${urlOrderNumber}`}
                  style={{ letterSpacing: '1px' }}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Contact us"
                >
                  contact us
                </a>
              ) : (
                <span className="">contact us</span>
              )}{' '}
              if you have any questions.
            </p>
            {email && (
              <>
                <p className="my-2">
                  You will receive an email confirmation shortly at{' '}
                  <span className="font-medium text-900">{email}</span>
                </p>
              </>
            )}
            {!userId ? (
              <div>
                <div className="border-bottom-1 border--color border w-9 my-3 opacity-50" />
                <p className="md:w-9">
                  <span className="font-bold">Create an account</span> and enjoy
                  faster checkout, purchase history, order tracking, 24/7
                  customer support, special rewards and promotions only
                  available to registered users.
                </p>
                <SignupForm
                  initialData={{
                    email: lastCheckout?.email || '',
                    firstName: lastCheckout?.shipping.firstName || '',
                    lastName: lastCheckout?.shipping.lastName || '',
                    phone: lastCheckout?.shipping.telephone || '',
                  }}
                  onComplete={() => {
                    console.log('todo');
                  }}
                />

                <div className="flex justify-content-center my-2">
                  <Button
                    className="ml-2 p-1"
                    style={{
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      color: 'var(--text-color)',
                    }}
                    label="Already have an account? Log in"
                    onClick={login}
                  />
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <Button
                  className="max-w-max p-2"
                  style={{ backgroundColor: 'black', borderColor: 'black' }}
                  loadingIcon="pi pi-spin pi-spinner"
                  label="Orders"
                  type="button"
                  onClick={() => {
                    setLastOrders(undefined);
                    navigate('/orders');
                  }}
                />
              </div>
            )}
          </div>
          <div className="col-12 sm:col-4">
            {orders?.map(order => (
              <div className="w-full" key={order.id}>
                <h4 className="raleway-price-format">{`Order Number: ${order.id}`}</h4>
                {order.items.map(item => {
                  const product = skuProducts[item.product.sku];

                  return (
                    <div
                      className="flex flex-row grid align-items-center mb-2"
                      key={item.product.sku}
                    >
                      <div className="flex flex-column col-9">
                        <p className="font-bold p-0 m-0">{item.product.name}</p>
                        <p className="p-0 m-0 text-sm raleway-price-format">{`Quantity: ${item.quantity}`}</p>
                        <p className="p-0 m-0 text-sm raleway-price-format">
                          {item.product.sku}
                        </p>
                        {product && (
                          <Image
                            width="80"
                            height="80"
                            src={product.image.url}
                            alt={`${item.product.name} product image`}
                            preview
                            className="image-object-fit-cover"
                          />
                        )}
                        <p className="p-0 m-0 text-sm raleway-price-format">
                          {moneyFormat.format(Number(item.price))}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
