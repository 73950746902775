import { ReactElement } from 'react';
import { getMarketContent } from 'utils/marketContent';
import './index.scss';
import useLocalStorage from 'utils/useLocalStorage';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

export default function VendorLogoSlider(): ReactElement {
  const market = getMarketContent();
  const dataVendorLogos = market.homePage.vendorLogos;
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);

  const settings = {
    arrow: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    rtl: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view previous item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  return (
    <>
      {dataVendorLogos && (
        <section id="vendor-logos" className="mx-auto w-10">
          <div className="container px-6 md:px-0 py-4">
            <Slider {...settings}>
              {dataVendorLogos.map(item => (
                <a
                  href={item.link}
                  target="_blank"
                  className="partner flex bg-primary-color max-w-max mx-auto cursor-pointer"
                  rel="noreferrer"
                  key={item.lenth * Math.random()}
                >
                  <img
                    src={isDarkFromLocalStroage ? item.imgDark : item.imgLight}
                    alt="partner"
                    className="my-auto block mx-auto max-w-max"
                    height="100%"
                    width="100%"
                    style={{ transform: 'scale(0.75)' }}
                    key={`${item.img}${Math.random()}`}
                  />
                </a>
              ))}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
}
