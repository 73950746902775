import { Button } from 'primereact/button';
import React, { ReactElement } from 'react';
import { useCartContext } from 'CartContext';
import { Link, useNavigate } from 'react-router-dom';
import './RightSidebarCart.scss';
import FocusLock from 'react-focus-lock';
import { getCartDropdownOptions } from 'utils/helper';
import { Dropdown } from 'primereact/dropdown';
import { moneyFormat } from 'utils/formatHelper';
import Spinner from 'components/Spinner';
import { CartItemFieldsFragment } from 'graphql/generated/magentoApi';

interface RightSidebarCartProps {
  setVisibleRight: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RightSidebarCart({
  setVisibleRight,
}: RightSidebarCartProps): ReactElement {
  const {
    items: cartItems,
    taxes,
    subTotal,
    total,
    updateCart,
    loading,
  } = useCartContext();
  const navigate = useNavigate();

  const cartItemTemplate = (item: CartItemFieldsFragment) => {
    let subscriptionPlan = 'monthly';
    if (item.product.is_subscription === 1) {
      if (item.product?.name?.toLowerCase().includes('yearly')) {
        subscriptionPlan = 'yearly';
      }
    }
    const subscriptionText =
      item.product.is_subscription === 1
        ? `${item.product.name}\n Subscription`
        : `${item.product.name}`;
    const subscriptionPricing =
      item.product.is_subscription === 1
        ? `${moneyFormat.format(
            item.quantity *
              Number(item.product.price_range.maximum_price.final_price.value),
          )} / ${subscriptionPlan}`
        : `${moneyFormat.format(
            item.quantity *
              Number(item.product.price_range.maximum_price.final_price.value),
          )}`;
    return (
      <div key={item.uid} className="grid m-0 cart-summary-item w-full">
        <Link to={`/product/${item.product.sku}`} className="col-2 mx-0 px-0">
          <picture className="cart-item__image max-w-max mr-auto">
            <img
              alt={String(item.product.name) || ''}
              src={String(item.product.image?.url) || ''}
            />
          </picture>
        </Link>
        <div className="grid col-10 justify-content-between my-0 py-0 mx-0">
          <div className="grid col-12 mt-auto mb-0 justify-content-between align-items-start">
            <p
              className="col-8 m-0 p-0 text-sm"
              style={{ whiteSpace: 'pre-line' }}
            >
              {subscriptionText}
            </p>
            <p className="col-4 m-0 p-0 raleway-price-format font-bold text-right ml-auto text-sm">
              {subscriptionPricing}
            </p>
          </div>
          <div className="flex col-12 mt-0 mb-2 p-0">
            <div
              className="m-0 p-0 max-h-max max-w-max inline-flex ml-auto align-items-center justify-content-center"
              style={{ transform: 'scale(0.8)' }}
            >
              <Dropdown
                aria-label="Adjust product quantity"
                className="p-0 m-0 dropdown-product-quantity"
                value={item.quantity}
                options={getCartDropdownOptions(
                  item.product.sku,
                  item.__typename,
                )}
                onChange={e =>
                  updateCart({
                    sku: item.product.sku,
                    quantity: e.value,
                    debug: 'Right SidebarCart',
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FocusLock>
      <div className="cart-summary-sidebar">
        <h2 className="title ">Cart Summary</h2>
        {loading ? (
          <Spinner />
        ) : (
          <div className="cart-summary-card">
            <>
              {!cartItems.length ? (
                <div
                  className="flex w-full align-items-center justify-content-center"
                  style={{ minHeight: '200px' }}
                >
                  <p className="font-bold text-900">Empty Cart</p>
                </div>
              ) : (
                <div className="p-2">
                  <div className="cart-summary__list">
                    {cartItems.map(item => cartItemTemplate(item))}
                  </div>
                  <div className="border-none border-bottom-1 border-300 mb-2">
                    <p className="flex justify-content-between text-700 mb-2 text-xs">
                      Subtotal
                      <span className="raleway-price-format">
                        {moneyFormat.format(Number(subTotal))}
                      </span>
                    </p>
                    <p className="flex justify-content-between text-700 mt-0 mb-2 text-xs">
                      Taxes
                      <span>
                        {taxes !== '0.00'
                          ? `${moneyFormat.format(Number(taxes))}`
                          : 'Calculated at checkout'}
                      </span>
                    </p>
                  </div>

                  <p className="flex justify-content-between text-700 font-bold font-medium my-4">
                    Total
                    <span className="text-700 raleway-price-format">
                      <span className="mr-2 text-xs font-medium">USD</span>
                      {moneyFormat.format(Number(total))}
                    </span>
                  </p>
                  <div className="flex flex-column w-full p-0 align-items-center">
                    <Button
                      className="shop-button black solid mx-0 max-w-max px-6 px-4"
                      type="button"
                      label="Checkout"
                      aria-label="close"
                      loading={loading}
                      onClick={() => {
                        setVisibleRight(false);
                        navigate('/checkout');
                      }}
                    />

                    <img
                      src="/assets/svg/credit-card-group.svg"
                      alt="Credit Cards"
                      className="w-4 mt-2 mb-0 mx-auto block p-0"
                      style={{ filter: 'saturate(1.10)' }}
                    />
                    <Button
                      className="p-sidebar-close p-link my-2 text-center text-underline max-w-max"
                      type="button"
                      label="Continue Shopping"
                      aria-label="close"
                      onClick={() => setVisibleRight(false)}
                    />
                  </div>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </FocusLock>
  );
}
