import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button as PrimeButton } from 'primereact/button';
import {
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import UserFormDetailsSection from './UserFormDetailsSection';
import AddressFormSection from './AddressFormSection';
import ProviderFormSection from './ProviderFormSection';
import './SignUpForm.scss';

const steps = ['Personal', 'Address', 'Provider'];

export interface FormProps {
  initialFormData?: Record<string, any> | null;
  isAffiliate?: boolean;
}

export function ProviderForm({ initialFormData }: FormProps) {
  const toast = useRef<Toast>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<boolean[]>([]);
  const [incomplete] = React.useState<boolean[]>([]);
  const isLastStep = activeStep === steps.length - 1;

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const formMethods = useForm<Record<string, any>>({
    mode: 'all',
    defaultValues: {
      firstName: initialFormData?.firstName,
      lastName: initialFormData?.lastName,
      primaryEmail: initialFormData?.primaryEmail,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleStep = (step: number) => {
    if (
      step === 0 &&
      !errors.firstName &&
      !errors.middleName &&
      !errors.lastName &&
      !errors.mobilePhone &&
      !errors.primaryEmail
    ) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      handleComplete();
    }
    if (
      step === 1 &&
      !errors.address1 &&
      !errors.city &&
      !errors.country &&
      !errors.state &&
      !errors.zipCode
    ) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      handleComplete();
    }
    if (
      step === 2 &&
      !errors.medicalLicenseType &&
      !errors.medicalLicenseNumber &&
      !errors.issuingState
    ) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      handleComplete();
    }
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <UserFormDetailsSection />;
      case 1:
        return <AddressFormSection allowInternationalAddress={false} />;
      case 2:
        return <ProviderFormSection />;
      default:
        return 'Unknown step';
    }
  }
  const onSubmit = (_data: Record<string, unknown>) => {
    if (!isLastStep) handleStep(activeStep);
  };

  const handleStepsButton = (step: number) => {
    if (completed[step]) {
      setActiveStep(step);
    }
  };

  const handleIncompleteSection = () => {
    toast?.current?.show({
      severity: 'info',
      summary: 'Incomplete Section',
      detail: ` Please complete before ${
        isLastStep ? 'submitting' : 'moving on'
      } `,
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        className="provider-form mx-auto mt-6 px-1 md:px-3"
        style={{ maxWidth: '1000px' }}
        onSubmit={handleSubmit(onSubmit, handleIncompleteSection)}
      >
        <Toast ref={toast} />
        <Stepper activeStep={activeStep} orientation="horizontal">
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {
              completed: completed[index],
            };
            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {
              error: incomplete[index],
            };

            if (incomplete[index]) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Incomplete{' '}
                </Typography>
              );
              labelProps.error = true;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  {...labelProps}
                  color="transparent"
                  onClick={() => handleStepsButton(index)}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep)}
        <div className="flex align-items-center justify-content-between">
          {activeStep !== 0 && (
            <Button
              className="provider-form__basic-btn font-oswald"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          {isLastStep ? (
            <PrimeButton
              className="text-center font-oswald"
              label="Submit"
              type="submit"
            />
          ) : (
            <Button
              className="provider-form__basic-btn ml-auto font-oswald"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                handleSubmit(onSubmit);
              }}
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

export default ProviderForm;
