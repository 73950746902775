import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import {
  DataView,
  DataViewLayoutOptions,
  DataViewLayoutType,
} from 'primereact/dataview';
import { PackageDetails } from 'types';
import { useNavigate } from 'react-router-dom';
import getStoreContent, { getStoreArray } from 'utils/content';
import parse from 'html-react-parser';
import { ReactElement, useEffect, useState } from 'react';
import './scss/StoreList.scss';

export default function StoreList({
  currentExchange,
}: {
  currentExchange: string;
}) {
  const [data, setData] = useState<PackageDetails[]>([]);
  const [layout, setLayout] = useState<DataViewLayoutType>('grid');
  const allStores = getStoreArray();
  const navigate = useNavigate();

  useEffect(() => {
    const currentStores: PackageDetails[] = [];
    const filteredStores = allStores.filter(
      store => store.exchange === currentExchange,
    );
    (currentExchange ? filteredStores : allStores).forEach(store =>
      currentStores.push(getStoreContent(store.name, store.exchange)),
    );
    setData(currentStores);
  }, [allStores, currentExchange]);

  const renderListItem = (store: PackageDetails): ReactElement => (
    <div className="flex py-3">
      <picture className="col-4 md:col-3">
        <img
          src={`${store.participate.backgroundImage}`}
          alt={store.metadata?.title.slice(0, -8)}
          className=""
        />
      </picture>
      <div className="col-8 md:col-9">
        <div className="font-bold text-lg mb-2">
          {store.metadata?.title.slice(0, -8)}
        </div>
        <div className="mb-3">{parse(store.participate.text)}</div>
        <Button
          label="View Store"
          onClick={() => navigate(`/${store.participate.exchangeName}`)}
        />
      </div>
    </div>
  );

  const renderGridItem = (store: PackageDetails): ReactElement => (
    <div className="col-12 sm:col-6 lg:col-4 p-3">
      <Card
        header={
          <img
            alt={store.metadata?.title.slice(0, -8)}
            src={`${store.participate.backgroundImage}`}
          />
        }
        className="exchange-card flex-column justify-content-space-between"
        style={{
          display: 'inline-block',
        }}
      >
        <div className="w-full">
          <p className="p-0 m-0 text-lg font-bold exchange-name mx-auto mt-0 mb-2">
            {store.metadata?.title.slice(0, -8)}
          </p>
        </div>
        <div className="exchange-description mb-4">
          <p>{parse(store.participate.text)}</p>
        </div>
        <a
          className="font-bold text-primary mx-auto no-underline"
          href={`/store/${store.participate.exchangeName}`}
        >
          View Store
        </a>
      </Card>
    </div>
  );

  const itemTemplate = (store: PackageDetails): ReactElement => {
    if (!store) {
      return <></>;
    }
    return layout === 'list' ? renderListItem(store) : renderGridItem(store);
  };

  const renderHeader = () => (
    <div className="flex justify-content-between">
      <h1 className="text-5xl">Stores</h1>
      <DataViewLayoutOptions
        layout={layout}
        onChange={e => setLayout(e.value)}
        className="flex align-self-center"
      />
    </div>
  );

  const header = renderHeader();
  return (
    <DataView
      value={data}
      layout={layout}
      header={header}
      itemTemplate={itemTemplate}
      paginator
      rows={9}
    />
  );
}
