import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import {
  faStar as fullStar,
  faStarHalfAlt as halfStar,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function Stars({
  rating,
  classNames = '',
}: {
  rating: number;
  classNames?: string;
}): ReactElement {
  const getStarIcon = (position: number): IconDefinition => {
    if (Math.floor(rating) >= position) {
      return fullStar;
    }
    if (Math.floor(rating) < position && Math.ceil(rating) === position) {
      return halfStar;
    }
    return emptyStar;
  };
  return (
    <div className={`rating flex align-items-center ${classNames}`}>
      <FontAwesomeIcon icon={getStarIcon(1)} />
      <FontAwesomeIcon icon={getStarIcon(2)} />
      <FontAwesomeIcon icon={getStarIcon(3)} />
      <FontAwesomeIcon icon={getStarIcon(4)} />
      <FontAwesomeIcon icon={getStarIcon(5)} />
    </div>
  );
}
