import { useRef } from 'react';
import useViewport from 'hooks/useViewport';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './HubSpotChatInstructions.scss';

import MarketPlaceComponentPage from 'pages/marketplace-component-page';

export const HubSpotChatInstructions = () => {
  const { isMobile } = useViewport();
  const toast = useRef<Toast>(null);

  const hubspotScript = `<!-- Hubspot Chat -->
    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7175493.js"></script>
    `;

  function copyText(text: string) {
    navigator.clipboard.writeText(text);
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied code to clipboard',
      contentClassName: '',
    });
  }
  return (
    <>
      <Toast ref={toast} />

      <MarketPlaceComponentPage />
      <section
        className={`hubspot-chat m-auto ${
          isMobile ? 'mt-2' : 'mt-8'
        } flex flex-column border-300 border-round surface-overlay border-3 p-4 w-12 md:w-12 xl:w-8 mb-3 align-items-start`}
      >
        <h2> Add HubSpot Chat </h2>
        <h3 className="mt-3">Step 1: Copy this line of code</h3>
        <p>
          Copy the code snippet below and paste it into your website&#x27;s HTML
          head tag.
          <br />
          <strong>Where to place it: </strong> Directly before the closing
          &lt;/head&gt; HTML tag, found inside the index.html file.
        </p>
        <p>
          <i>
            If placed incorrectly, the hubspot chat may not appear on your page.
            <br />
          </i>{' '}
        </p>
        <InputTextarea
          rows={5}
          cols={30}
          value={hubspotScript}
          className="mb-2 w-full text-sm"
          style={{
            color: 'gold',
            backgroundColor: '#1c1c1c',
            minHeight: '120px',
          }}
          spellCheck="false"
        />
        <Button
          className="hubspot-chat__btn"
          label="Copy"
          icon="pi pi-copy"
          onClick={() => copyText(hubspotScript)}
        />
        <h3 className="mt-6">
          Step 2: Save your changes and view your new chat!
        </h3>
        <p>
          {' '}
          For more information or in case you&apos;re adding it to a WordPress
          website follow this link{' '}
          <a
            href="https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code"
            target="_blank"
            rel="noopener noreferrer"
          >
            hubspot resources{' '}
          </a>
        </p>
        <p>A chat icon will appear on the bottom left corner of your website</p>
        <img
          src="/images/vendor-tools/chatbox.jpg"
          alt=""
          className="mb-8"
          style={{ width: '320px' }}
        />
      </section>
    </>
  );
};
