import { ReactElement } from 'react';
import { getMarketContent } from 'utils/marketContent';
import parse from 'html-react-parser';
import { Fade } from 'react-awesome-reveal';

export default function WelcomeText(): ReactElement {
  const market = getMarketContent();
  const { welcomeText } = market.homePage;

  return (
    <>
      {welcomeText.title.length > 0 && (
        <div className="container px-2 my-4">
          <div className="mx-auto text-center w-10 md:w-10 lg:w-8 mb-6 text-xl">
            <Fade triggerOnce delay={1e3} direction="up">
              <h1
                className="capitalize mb-2"
                style={{ fontSize: '1.5em', lineHeight: '1.1em' }}
              >
                {parse(welcomeText.title)}
              </h1>
            </Fade>

            <div className="w-12 md:w-8 mx-auto">
              <Fade delay={1e3} triggerOnce direction="up">
                <h2 className="text-sm mb-2" style={{ lineHeight: '1.2rem' }}>
                  {parse(welcomeText.subtitle)}
                </h2>
              </Fade>
              <Fade delay={1e3} triggerOnce direction="up">
                <p
                  className="mb-0 text-justify"
                  style={{ lineHeight: '1.2rem' }}
                >
                  {parse(welcomeText.htmltext)}
                </p>
              </Fade>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
