export function buildCategoryPaths(
  pathParam?: string,
  categoriesParam?: string,
  root?: string,
): string[] {
  const paths: string[] = [];
  if (pathParam) {
    paths.push(pathParam);
  }
  if (categoriesParam) {
    paths.push(...categoriesParam.split(','));
  }

  if (!root) {
    return paths;
  }
  if (!paths.length) {
    paths.push(root);
  }
  return paths;
}
