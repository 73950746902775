import { Card, CardProps } from 'primereact/card';
import { CategoryTree } from 'graphql/generated/magentoApi';
import { Link } from 'react-router-dom';

type Category = Partial<CategoryTree>;

export type CategoryDetailCardProps = CardProps & {
  item: Category | undefined;
};

export default function CategoryDetailCard(props: CategoryDetailCardProps) {
  const { item, ...rest } = props;
  const title = item?.name || 'No Selection';
  const subTitle = item?.url_path || '';

  return (
    <Card {...rest} title={title} subTitle={subTitle}>
      {item && (
        <div>
          {item.name && (
            <p>
              <span className="font-bold">Name: </span>
              {item.name}
            </p>
          )}
          {item.products?.total_count !== undefined && (
            <p>
              <span className="font-bold">Products: </span>
              {item.products?.total_count}
            </p>
          )}
          {item.children_count && (
            <p>
              <span className="font-bold">Sub-categories: </span>
              {item.children_count}
            </p>
          )}
          {item.description && (
            <p>
              <span className="font-bold">Description: </span>
              {item.description}
            </p>
          )}
          {item.url_path && (
            <p>
              <span className="font-bold">Link: </span>
              <Link to={`/category/${item.url_path}`}>{item.url_path}</Link>
            </p>
          )}

          <p>{item.description}</p>
        </div>
      )}
    </Card>
  );
}
