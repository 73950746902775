import { ReactElement, useRef, useState } from 'react';
import {
  AdminInputEmail,
  AdminInputPassword,
  AdminInputText,
} from 'components/Admin';
import { useForm } from 'react-hook-form';
import { SignupInput, useUserContext } from 'UserContext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

export interface SignupFormProps {
  onComplete?: () => void;
  initialData?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    countryCode?: string;
    phone?: string;
  };
}

export default function SignupForm(props: SignupFormProps): ReactElement {
  const { onComplete, initialData } = props;
  const { login, signup } = useUserContext();
  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const messages = useRef<Messages>(null);

  const { control, handleSubmit } = useForm<SignupInput>({
    defaultValues: {
      email: initialData?.email || '',
      firstName: initialData?.firstName || '',
      lastName: initialData?.lastName || '',
      countryCode: '',
      phone: initialData?.phone || '',
      password: '',
    },
  });

  const onSubmit = async (data: SignupInput) => {
    setLoading(true);
    try {
      // * currently countryCode is set to 'US' because a country code is needed in order to save the phone number to the user obj.
      // TODO look into how to dynamically update the countryCode to match the phone input (AdminPhoneInput could do this but the style doesn't match the client mockup)
      await signup({ ...data, countryCode: 'US' });
      onComplete?.();
      setIsComplete(true);
      messages.current?.replace({
        severity: 'success',
        content: (
          <>
            Account created successfully.{' '}
            <Button
              className="ml-2 p-1"
              style={{ backgroundColor: 'black', borderColor: 'black' }}
              label="Login"
              onClick={login}
            />
          </>
        ),
        sticky: true,
        closable: false,
      });
    } catch (error: any) {
      messages.current?.replace({
        severity: 'error',
        content: error.message,
        sticky: true,
      });
    }
    setLoading(false);
  };

  return (
    <div className="p-2">
      <h4 className="text-base mb-2">Create Account</h4>
      <Messages ref={messages} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid">
          <div className="col-12 lg:col-6">
            <AdminInputEmail
              name="email"
              label="Email"
              autoComplete="email"
              control={control}
              required
              disabled={!!initialData?.email}
            />
          </div>
          <div className="col-12 lg:col-6">
            <AdminInputPassword
              name="password"
              label="Password"
              autoComplete="password"
              control={control}
              type="password"
              displayScore
              displayFeedback
              required
            />
          </div>
          <div className="col-12 lg:col-6">
            <AdminInputText
              name="firstName"
              label="First Name"
              autoComplete="given-name"
              control={control}
              required
            />
          </div>
          <div className="col-12 lg:col-6">
            <AdminInputText
              name="lastName"
              label="Last Name"
              autoComplete="family-name"
              control={control}
              required
            />
          </div>
          <div className="col-12">
            <AdminInputText
              name="phone"
              label="Mobile"
              autoComplete="tel"
              control={control}
              required
            />
          </div>
          <div className="flex justify-content-center w-full mt-2">
            <Button
              className="w-20rem p-2"
              style={{ backgroundColor: 'black', borderColor: 'black' }}
              loadingIcon="pi pi-spin pi-spinner"
              label="Submit"
              loading={loading}
              type="submit"
              disabled={isComplete}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
