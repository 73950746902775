import { useNavigate, useParams } from 'react-router-dom';

export function useStoreNames() {
  const { store } = useParams();
  return {
    storeName: store?.toLowerCase() || '',
  };
}

export function useStorePath(subPath = '') {
  const { storeName } = useStoreNames();
  if (subPath !== '' && !subPath.startsWith('/')) {
    // eslint-disable-next-line no-param-reassign
    subPath = `/${subPath}`;
  }
  return `/${storeName}${subPath}`; // backwards compatible
}

export function useStorePathGoTo(subPath = '') {
  const storePath = useStorePath(subPath);
  const navigate = useNavigate();
  return () => {
    navigate(storePath);
  };
}
