import { useEffect, useState } from 'react';

const useViewport = (): {
  width: number;
  isMobile: boolean;
  isDesktop: boolean;
  isSmallMobile: boolean;
} => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isSmallMobile = width < 576;
  const isMobile = width < 992;
  const isDesktop = width < 1200;

  return { width, isMobile, isDesktop, isSmallMobile };
};

export default useViewport;
