import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AddressCountry,
  AdminInputCountry,
  AdminInputState,
  AdminInputText,
} from 'components/Admin';

interface AddressSignupFormSectionProps {
  allowInternationalAddress: boolean;
}

const AddressFormSection = ({
  allowInternationalAddress,
}: AddressSignupFormSectionProps) => {
  const { control, setValue } = useFormContext();
  const defaultCountry = {
    code: 'US',
    iso3: 'USA',
    name: 'United States',
    flagEmoji: '🇺🇸',
    flagEmojiUnicode: 'U+1F1FA U+1F1F8',
    stateLabel: 'state',
  };
  const [selectedCountry, setSelectedCountry] = useState<
    AddressCountry | undefined
  >(allowInternationalAddress ? undefined : defaultCountry);

  useEffect(() => {
    if (!allowInternationalAddress) {
      setValue('country', 'US');
    }
  }, [allowInternationalAddress, setValue]);

  return (
    <div className="flex flex-column justify-content-center gap-3 my-6 w-full mx-auto">
      <AdminInputText
        control={control}
        name="address1"
        required
        label="Address 1"
        containerClassName="mb-4"
      />
      <AdminInputText
        control={control}
        name="address2"
        label="Address 2"
        containerClassName="mb-4"
      />
      <AdminInputText
        control={control}
        name="city"
        required
        label="City"
        containerClassName="mb-4"
      />

      <AdminInputCountry
        control={control}
        name="country"
        defaultCountryCode="US"
        allowInternationalAddress={allowInternationalAddress}
        onCountrySelect={setSelectedCountry}
        required
        label="Country"
        containerClassName="mb-4"
      />

      <AdminInputState
        control={control}
        name={selectedCountry?.stateLabel || 'state'}
        countryCode={selectedCountry?.code || ''}
        allowInternationalAddress={allowInternationalAddress}
        required
        containerClassName="mb-4"
      />

      <AdminInputText
        control={control}
        name="zipCode"
        required
        label="Zip/Postal Code"
        containerClassName="mb-4"
      />
    </div>
  );
};

export default AddressFormSection;
