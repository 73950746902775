import React from 'react';
import { useGetProductsBySkus } from 'hooks/magentoHooks';
import parse from 'html-react-parser';
import ProductCard from 'pages/ProductCard';
import { DataView } from 'primereact/dataview';
import Forms, { FormType } from 'pages/shop-home-page/forms';
import { useSearchParams } from 'react-router-dom';
import ProductFeatureComingSoon from './ProductFeatureComingSoon';
import ProductFeatureBtn, { FeatureButtonType } from './ProductFeatureBtn';

interface FeatureHeaderProps {
  title: string;
  text: string;
  image?: string;
  orderBtn: FeatureButtonType;
  divider?: {
    showBorderBottom: boolean;
    showBorderBottomSmall?: boolean;
  };
  secondarySubheader?: string;
  secondaryText?: string;
  productSkus?: string[];
  comingSoon: boolean;
  formSections: FormType[] | null;
  htmlContent: string | null;
}

export default function ProductFeatureHeader({
  title,
  text,
  image,
  orderBtn,
  divider,
  secondarySubheader = '',
  secondaryText = '',
  productSkus = [],
  comingSoon,
  formSections,
  htmlContent,
}: FeatureHeaderProps) {
  const { products } = useGetProductsBySkus({
    skus: productSkus,
  });

  const [searchParams] = useSearchParams();

  const isLocalTest = searchParams.get('env') === 'local';

  const itemTemplate = product => (
    <ProductCard product={product} isLocalTest={isLocalTest} />
  );

  return (
    <div className="grid my-2 feature-header">
      {comingSoon && image && title && (
        <ProductFeatureComingSoon image={image} title={title} />
      )}
      {!comingSoon && (
        <>
          <div
            className={`feature-header__content grid m-auto ${
              divider?.showBorderBottomSmall
                ? 'border-bottom-1 border--color border w-9'
                : ''
            }`}
          >
            {htmlContent ? (
              <>
                <div
                  className={`col-12 ${
                    image ? 'md:col-6 md:pr-6' : ''
                  } px-0 flex flex-column`}
                >
                  <h2 className="uppercase">{title}</h2>
                  {parse(htmlContent)}
                  {orderBtn?.showButton && (
                    <ProductFeatureBtn button={orderBtn} />
                  )}
                  {formSections && <Forms formSections={formSections} />}
                </div>
              </>
            ) : (
              <div
                className={`col-12 ${
                  image ? 'md:col-6 md:pr-6' : ''
                } px-0 flex flex-column`}
              >
                <h2 className="uppercase">{title}</h2>
                <p className="">{parse(text)}</p>
                {secondarySubheader && secondaryText && (
                  <>
                    <h3 className="uppercase mt-2 subtitle">
                      {secondarySubheader}
                    </h3>
                    <p>{secondaryText}</p>
                  </>
                )}
                {orderBtn?.showButton && (
                  <ProductFeatureBtn button={orderBtn} />
                )}
                {formSections && <Forms formSections={formSections} />}
              </div>
            )}
            {image && (
              <div className="col-12 md:col-6">
                <img className="w-full" src={image} alt={title} />
              </div>
            )}

            {!!products.length && (
              <div className="col-12 m-auto flex justify-content-center my-2">
                <DataView value={products} itemTemplate={itemTemplate} />
              </div>
            )}
          </div>
          {divider?.showBorderBottom && <div className="divider my-6" />}
        </>
      )}
    </div>
  );
}
