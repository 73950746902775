/* eslint-disable react-hooks/rules-of-hooks */
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import {
  DataView,
  DataViewLayoutOptions,
  DataViewLayoutType,
} from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { DigitalContentGroup, DigitalContentItem } from 'types';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Ripple } from 'primereact/ripple';
import { useGetProduct } from 'hooks/magentoHooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import buttonize from 'utils/buttonize';
import parse from 'html-react-parser';
import Spinner from 'components/Spinner';
import './DigitalContent.scss';
import { useUserContext } from 'UserContext';
import { useGetDigitalProductsForCustomerOrderQuery } from 'graphql/generated/reactQuery';
import { MenuItem } from 'primereact/menuitem';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';
import { getMarketContent } from 'utils/marketContent';

export default function DigitalContent(): ReactElement {
  const navigate = useNavigate();
  const { token, client } = useUserContext();
  const [currentItem, setCurrentItem] = useState<DigitalContentItem>();
  const { productSku, itemId } = useParams();
  const orderNumber = useQuery().get('orderNumber') || '';
  const [displayVideo, setDisplayVideo] = useState(!!itemId);
  const [referralNameValue, setReferralNameValue] = useState('');
  const [displayShareCard, setDisplayShareCard] = useState<boolean>(false);
  const [referralEmailValue, setReferralEmailValue] = useState('');
  const sku = productSku || '';
  const { meta } = getMarketContent();
  const DOMAIN = meta.protocolAndDomain
    ? meta.protocolAndDomain
    : 'https://cart.crwd.market/';
  const digitalProductQueryState = useGetDigitalProductsForCustomerOrderQuery(
    client,
    { orderNumber, productSku: sku },
  );
  const digitalProduct =
    digitalProductQueryState.data?.getDigitalProductForCustomerOrder;
  const { product } = useGetProduct({ sku });

  const productImageUrl = product?.media_gallery?.[0]?.url || undefined;

  const videoIds = digitalProduct?.groups
    ? (digitalProduct?.groups)
        .flatMap(group => group.items)
        .filter(item => item.type.includes('VIDEO'))
        .map(item => item.id)
    : [];

  // TODO: Use videoIds in new function
  console.log(videoIds);

  const items: MenuItem[] = [
    {
      label: 'All products',
      url: '/digital-content',
    },
    {
      label: digitalProduct?.name || '',
    },
  ];

  // useEffect(() => {
  //   if (videoIds.length) {
  //     useGetThumbnails(videoIds);
  //   }
  // }, [videoIds]);

  useEffect(() => {
    setDisplayVideo(!!itemId);
  }, [itemId]);

  useEffect(() => {
    const item = digitalProduct?.groups
      ? (digitalProduct?.groups)
          .flatMap(group => group.items)
          .find(item => item.id === itemId)
      : [];
    setCurrentItem(item);
  }, [itemId, digitalProduct]);

  if (!digitalProduct?.sku)
    return (
      <>
        <Spinner />
      </>
    );

  const onHide = () => {
    setDisplayVideo(false);
    navigate(`/digital-content/${productSku}?orderNumber=${orderNumber}`);
  };

  const GroupVRAIView = ({
    group: { items },
  }: {
    group: DigitalContentGroup;
  }): ReactElement => (
    <>
      {items.map(item => (
        <div key={item.name} className="mr-0 sm:mr-2">
          <h5 className="mt-4 ">{item.name}</h5>
          <a
            href={
              item.url?.includes('storefront-static')
                ? `${item.url}`
                : `${item.url}&token=${token}`
            }
            className="no-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="uppercase c-button-primary flex align-items center">
              {item.url?.includes('dmg') || item.url?.includes('pkg') ? (
                <i className="pi pi-apple mr-2" />
              ) : (
                ''
              )}
              {item.url?.includes('exe') ? (
                <i className="pi pi-microsoft mr-2" />
              ) : (
                ''
              )}
              <span>Download</span>
              <i className="pi pi-download ml-2" />
            </Button>
          </a>
        </div>
      ))}
    </>
  );

  const GroupView = ({
    group: { name, items },
  }: {
    group: DigitalContentGroup;
  }): ReactElement => {
    const [layout, setLayout] = useState<DataViewLayoutType>('grid');
    const [, setReloadImg] = useState<boolean>(false);

    const getButtons = (
      item: DigitalContentItem,
      template: string,
    ): ReactElement => {
      switch (item.type) {
        case 'PDF':
        case 'DOWNLOAD':
          return (
            <a
              href={
                item.url?.includes('storefront-static')
                  ? `${item.url}`
                  : `${item.url}&token=${token}`
              }
              className="no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="uppercase c-button-primary flex align-items center">
                {item.url?.includes('dmg') || item.url?.includes('pkg') ? (
                  <i className="pi pi-apple mr-2" />
                ) : (
                  ''
                )}
                {item.url?.includes('exe') ? (
                  <i className="pi pi-microsoft mr-2" />
                ) : (
                  ''
                )}
                <span>Download</span>
                <i className="pi pi-download ml-2" />
              </Button>
            </a>
          );
        case 'VIDEO':
          return (
            <Button
              className="uppercase c-button-primary"
              label="Video"
              onClick={() =>
                navigate(
                  `/digital-content/${productSku}/${item.id}?orderNumber=${orderNumber}`,
                )
              }
            >
              <i className="pi pi-video ml-2" />
            </Button>
          );
        case 'AUDIO':
          return (
            <>
              <a
                href={
                  item.url?.includes('storefront-static')
                    ? `${item.url}`
                    : `${item.url}&token=${token}`
                }
                className={`no-underline ${
                  template === 'grid' ? 'mr-2' : 'mr-0 md:mr-2'
                }${template === 'list' ? ' mb-2 md:mb-0' : ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="uppercase c-button-primary" label="Audio">
                  <i className="pi pi-download ml-2" />
                </Button>
              </a>
            </>
          );
        case 'VIDEO_AUDIO':
          return (
            <>
              <a
                href={
                  item.url?.includes('storefront-static')
                    ? `${item.url}`
                    : `${item.url}&token=${token}`
                }
                className={`no-underline ${
                  template === 'grid' ? 'mr-2' : 'mr-0 md:mr-2'
                }${template === 'list' ? ' mb-2 md:mb-0' : ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="uppercase c-button-primary" label="Audio">
                  <i className="pi pi-download ml-2" />
                </Button>
              </a>
              <Button
                className="uppercase c-button-primary"
                label="Video"
                onClick={() =>
                  navigate(
                    `/digital-content/${productSku}/${item.id}?orderNumber=${orderNumber}`,
                  )
                }
              >
                <i className="pi pi-video ml-2" />
              </Button>
            </>
          );
        case 'LINK':
          return (
            <>
              {!!item.url && (
                <a
                  href={item.url}
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    className="uppercase c-button-primary"
                    label={item.name}
                  >
                    <i className="pi pi-calendar ml-2" />
                  </Button>
                </a>
              )}
            </>
          );
        default:
          return <></>;
      }
    };

    const renderListItem = (item: DigitalContentItem) => (
      <div className="digital-product col-12">
        <div className="digital-content__item grid w-full">
          {item.type !== 'LINK' && (
            <>
              <div className="flex align-items-center col-2">
                <img
                  id={`img-${item.id}`}
                  alt={item.name}
                  src={productImageUrl}
                  className="max-w-full cursor-pointer"
                  {...buttonize(
                    () =>
                      (item.type === 'VIDEO' || item.type === 'VIDEO_AUDIO') &&
                      navigate(
                        `/digital-content/${productSku}/${item.id}?orderNumber=${orderNumber}`,
                      ),
                  )}
                />
              </div>
            </>
          )}
          {!!item?.meta?.htmlParse && (
            <div className="parsedHTML w-full">
              {parse(item?.meta?.htmlParse)}
            </div>
          )}
          <div className="flex align-items-center col-6">
            {item.type !== 'LINK' && <p className="m-0 ">{item.name}</p>}
            {item.description && <p className="m-0">{item.description}</p>}
          </div>
          <div
            className={`flex flex-column md:flex-row justify-content-center md:justify-content-end align-items-end md:align-items-center ${
              item.type !== 'LINK' ? 'col-4' : 'col-6'
            }`}
          >
            {getButtons(item, 'list')}
          </div>
        </div>
      </div>
    );

    const renderGridItem = (item: DigitalContentItem) => (
      <>
        <div
          className={`digital-product col-12 p-3 ${
            item.type === 'LINK' ? 'md:col-12' : 'md:col-4'
          }`}
        >
          {!!item?.meta?.htmlParse && (
            <div className="parsedHTML w-full">
              {parse(item?.meta?.htmlParse)}
            </div>
          )}
          <div className="w-full h-full flex flex-column">
            {item.type !== 'LINK' && (
              <>
                <img
                  id={`img-${item.id}`}
                  alt={item.name}
                  src={productImageUrl}
                  className="w-full max-w-full cursor-pointer"
                  style={{
                    height: '200px',
                    objectFit: 'contain',
                    borderRadius: '4px',
                  }}
                  {...buttonize(
                    () =>
                      (item.type === 'VIDEO' || item.type === 'VIDEO_AUDIO') &&
                      navigate(
                        `/digital-content/${productSku}/${item.id}?orderNumber=${orderNumber}`,
                      ),
                  )}
                />
                <p className="text-center font-bold m-0 my-2 text-sm md:text-base">
                  {item.name}
                </p>
              </>
            )}
            {item.description &&
              (item.type !== 'LINK' ? (
                <p className="m-0 mb-2 text-center">{item.description}</p>
              ) : (
                <p
                  className="m-0 mb-2 text-center flex align-items-center"
                  style={{ height: '200px' }}
                >
                  {item.description}
                </p>
              ))}
            <div className="w-full flex justify-content-center align-items-end flex-1">
              {getButtons(item, 'grid')}
            </div>
          </div>
        </div>
      </>
    );

    const itemTemplate = (item: DigitalContentItem): ReactElement => {
      if (!digitalProduct) {
        return <></>;
      }

      if (layout === 'list') return renderListItem(item);
      if (layout === 'grid') return renderGridItem(item);
      return <></>;
    };

    return (
      <Panel
        header={
          <p className="m-0" style={{ fontFamily: 'Cabin, sans-serif' }}>
            {name}
          </p>
        }
        toggleable
        headerTemplate={options => {
          const toggleIcon = options.collapsed
            ? 'pi pi-chevron-down'
            : 'pi pi-chevron-up';
          const className = `${options.className} p-jc-start w-full flex p-0 align-items-center`;
          return (
            <div className={className}>
              <div
                className="border-none bg-transparent flex flex-1 justify-content-between p-2"
                {...buttonize((e: any): void => {
                  options.onTogglerClick(e);
                  setReloadImg(prev => !prev);
                })}
              >
                <p
                  className="m-0 text-base pl-2 pt-1 flex font-bold"
                  style={{ fontFamily: 'Cabin, sans-serif' }}
                >
                  {name}
                </p>
                <button type="button" className={options.togglerClassName}>
                  <span className={toggleIcon} />
                  <Ripple />
                </button>
              </div>
              <DataViewLayoutOptions
                layout={layout}
                onChange={e => setLayout(e.value)}
                className="p-2 pl-0"
              />
            </div>
          );
        }}
      >
        <DataView
          className="digital-products-list"
          value={items}
          layout={layout}
          itemTemplate={itemTemplate}
          lazy
        />
      </Panel>
    );
  };

  return (
    <>
      <BreadCrumb
        className="digital-content__breadcrumb my-2 border-none"
        model={items}
      />
      <h1 className="text-center mt-0 text-sm md:text-base">{product?.name}</h1>
      <h1 className="text-center mt-0 mb-5 font-italic text-sm md:text-base">
        {product?.vendor_company}
      </h1>
      {productSku === '2021-12-10-ITX-CPM-vrai-004' ||
      productSku === '2021-12-10-ITX-CPM-vrai-003' ||
      productSku === '2022-07-07-ITX-CPM-vrai-008' ||
      productSku === '2022-07-07-ITX-CPM-vrai-007' ? (
        <>
          <div className="digital-content-wrappr mb-4">
            <img
              src={
                productSku === '2021-12-10-ITX-CPM-vrai-004' ||
                productSku === '2022-07-07-ITX-CPM-vrai-008'
                  ? 'https://storefront-static.crowdpoint.tech/vrai/p3plussuite--transparent.png'
                  : 'https://storefront-static.crowdpoint.tech/vrai/p3plan---purplebg.png'
              }
              alt={productSku}
              style={{ width: '280px' }}
              className="mx-auto flex"
            />
            <div className="parsedHTML">
              <h1>VRAI P3 Plus Suite</h1>
              <h2>Installation Instructions</h2>
              <ul>
                <li>
                  <p className="font-bold uppercase text-sm">Step 1</p>
                  <p>
                    Select your computer type and download VRAI Anyware. After
                    the download, you will have an application on your computer
                    called VRAI Anyware. Once downloaded and installed, click on
                    the application anytime 24/7 to reach us by chat.
                  </p>
                </li>
                <li>
                  <p className="font-bold uppercase text-sm">Step 2</p>
                  <p>
                    Step 2: Within the next 24-48 business hours after you
                    download VRAI Anyware, we will automatically send to your
                    computer a second application called VRAI Performance. This
                    new APP will appear in your applications and you can move it
                    to your doc or create a shortcut for easy access if desired.
                    Click on the VRAI Performance APP anytime to clean, find
                    duplicate files, etc to maintain your device speed and
                    performance. You may open this app to run this daily or set
                    it to automatically run.
                  </p>
                </li>
                <li>
                  <p className="font-bold uppercase text-sm">Step 3</p>
                  <p>
                    Within the same time frame we will also automatically send
                    to your computer the DeepArmor anti-virus, anti-spyware,
                    anti-malware protection.
                  </p>
                </li>
                <li>
                  <p className="font-bold uppercase text-sm">
                    Step 4 (Included with VRAI P3 Plus Suite)
                  </p>
                  <p>
                    For your perimeter protection, you will be receiving a
                    physical small device in the mail called a Fido. This is
                    your guardDog network perimeter protection. After you
                    receive your Fido in the mail, please follow the steps on
                    the enclosed card to activate it.
                  </p>
                </li>
              </ul>
              <p>
                We look forward to protecting you, maintaining your device
                performance, detecting, preventing, and mitigating threats all
                while giving you back your privacy as smart device use continues
                to soar.
              </p>
              <p>
                If you have any problems or questions for us, please find the
                VRAI Anyware APP on your computer (downloaded in step 1), and
                chat with us 24/7.{' '}
              </p>
              <h2 className="mb-2">
                Download both VRAI Anyware & Deep Armor Anti-Virus
              </h2>
              <h4 className="mb-2">
                This requires a license key that was sent to your email.
              </h4>
            </div>
            {digitalProduct.groups.map(group => (
              <div key={group.name} className="flex flex-column sm:flex-row">
                <GroupVRAIView group={group} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          {digitalProduct.groups.map(group => (
            <GroupView key={group.name} group={group} />
          ))}
        </>
      )}
      <Dialog
        header={
          <div className="flex flex-column md:flex-row md:justify-content-between">
            <span className="p-dialog-title">{currentItem?.name}</span>
            {currentItem?.meta?.isSharable && (
              <Button
                className="share-card-open p-button p-component uppercase c-button-primary mt-4 md:mt-0 mr-0 md:mr-4"
                onClick={() => setDisplayShareCard(!displayShareCard)}
                type="button"
                icon="pi pi-share-alt"
                iconPos="left"
                label="Share with a friend!"
              />
            )}
          </div>
        }
        visible={displayVideo}
        style={{ width: '80vw', height: '80vh' }}
        onHide={onHide}
        draggable={false}
        className="digital-content-video-dialog"
      >
        <div className={`share-card ${displayShareCard ? 'active' : ''}`}>
          <p className="share-card-cta mb-4">Share this video with a friend</p>
          <label htmlFor="referral-email">Friends Name</label>
          <InputText
            id="referral-Name"
            value={referralNameValue}
            onChange={e => setReferralNameValue(e.target.value)}
            className="w-12 mb-3"
            placeholder="Friends First Name"
          />
          <label htmlFor="referral-email">Friends Email</label>
          <InputText
            id="referral-email"
            value={referralEmailValue}
            onChange={e => setReferralEmailValue(e.target.value)}
            className="w-12 mb-4"
            placeholder="buddy@friend.com"
          />
          <a
            href={`mailto:${referralEmailValue}?subject=Hey ${referralNameValue} watch this video&body=${DOMAIN}/product/${sku}?referral=true%26videoID=${itemId}`}
            className="share-card-submit"
            onClick={() => setDisplayShareCard(false)}
          >
            Email A Friend
          </a>
          <button
            className="share-card-close"
            onClick={() => setDisplayShareCard(false)}
            type="button"
          >
            <i className="pi pi-times" />
            <span>Dismiss Share Feature</span>
          </button>
        </div>
        <VimeoPlayer url={`https://vimeo.com/${itemId || 0}`} />
      </Dialog>
    </>
  );
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
