import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useGetProduct } from 'hooks/magentoHooks';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import { getVendorAndMarketplaceName } from 'utils/helper';
import './index.scss';

interface WebsiteCardProps {
  header?: any;
  image?: string;
  sku: string;
  link?: string;
  description?: string;
  isButton?: boolean;
  isParam?: boolean;
  classList?: string;
  children?: ReactNode | null;
}

export default function VisitWebsiteCards({
  header,
  image,
  sku,
  link,
  classList,
  children,
  description,
  isButton = false,
  isParam = false,
}: WebsiteCardProps): ReactElement {
  const navigate = useNavigate();

  const { product } = useGetProduct({ sku });
  const { visitEdgeSite, getProductWebsiteUrl } = useCartContext();
  const [displayRedirectDialog, setDisplayRedirectDialog] =
    useState<boolean>(false);

  const [websiteUrl, setWebsiteUrl] = useState<string | undefined>();
  const timeout = useRef({});
  const hasDescription = description?.length;

  const onClickVisitWebsiteBtn = useCallback(() => {
    if (!product) return;
    const url = getProductWebsiteUrl(product);
    setWebsiteUrl(url);

    setDisplayRedirectDialog(true);
    if (url) {
      timeout.current = setTimeout(() => {
        setDisplayRedirectDialog(false);
        visitEdgeSite(url, false);
      }, 2500);
    }
  }, [getProductWebsiteUrl, product, visitEdgeSite]);

  useEffect(() => {
    if (isParam) {
      console.log(product && product.sku);
      // product && alert(product.sku);
      // websiteUrl && product && alert(websiteUrl);
      // websiteUrl && product && onClickVisitWebsiteBtn(websiteUrl);
    }
  }, [isParam, onClickVisitWebsiteBtn, product, websiteUrl]);

  function redirectURL(link) {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
      window.location.href = link;
    } else {
      navigate(link);
    }
  }

  return (
    <>
      {product && (
        <>
          {isButton ? (
            <Button
              className={classList}
              onClick={() => {
                if (link) {
                  redirectURL(link);
                }
                if (product) {
                  onClickVisitWebsiteBtn();
                }
              }}
              type="button"
              label={header}
            >
              {children && children}
            </Button>
          ) : (
            <>
              {hasDescription ? (
                <div className="visit-website-has-description my-2 col-10 md:col-6 lg:col-3 mx-4 md:px-4">
                  <img
                    src={image}
                    alt={header}
                    className="w-8 mx-auto block py-0"
                    style={{ aspectRatio: '1', objectFit: 'cover' }}
                  />
                  <p
                    className="visit-website-header text-primary text-center mb-0"
                    style={{ fontSize: '1.4rem' }}
                  >
                    {header}
                  </p>
                  <p className="visit-website-description text-center">
                    {description}
                  </p>
                  <Button
                    className="uppercase mt-auto mb-2 flex flex-column align-items-start learn-more-button text-primary surface-0 mx-auto px-4"
                    onClick={() => {
                      if (link) {
                        redirectURL(link);
                      }
                      if (product) {
                        onClickVisitWebsiteBtn();
                      }
                    }}
                    type="button"
                    label="Learn More"
                  />
                </div>
              ) : (
                <Button
                  className="uppercase my-2 flex flex-column align-items-start visit-website-btn text-primary surface-0 col-10 sm:col-8 md:col-6 lg:col-4 xl:col-3 mx-4 md:px-4"
                  onClick={() => {
                    if (link) {
                      redirectURL(link);
                    }
                    if (product) {
                      onClickVisitWebsiteBtn();
                    }
                  }}
                  type="button"
                >
                  <p className="visit-website-header">{header}</p>
                  <img
                    src={image}
                    alt={header}
                    className="w-full block py-0"
                    style={{ aspectRatio: '1', objectFit: 'cover' }}
                  />
                  <p className="py-0 font-bold visit-website-link">
                    <span>Visit Website</span>
                    <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
                  </p>
                </Button>
              )}
            </>
          )}
          <Dialog
            visible={displayRedirectDialog}
            header="Thank you for your Support!"
            onHide={() => setDisplayRedirectDialog(false)}
            onClick={() => clearTimeout(timeout.current as number)}
            onMaskClick={() => clearTimeout(timeout.current as number)}
            breakpoints={{ '1200px': '40vw', '960px': '75vw', '640px': '90vw' }}
            style={{ width: '40vw' }}
            className="visit-website-modal"
            dismissableMask
            draggable={false}
          >
            <div className="flex flex-column md:flex-row align-items-center justify-content-start mb-4">
              {image && (
                <div
                  className="background-image-square mb-4 md:mb-0 mr-0 md:mr-4 "
                  style={{ background: `url(${image})` }}
                />
              )}
              <div className="mb-4">
                <p className="py-0 font-bold mb-0 text-sm">{header}</p>
                <h3 className="py-0 font-bold mb-2">{`Redirecting to ${getVendorAndMarketplaceName(
                  product,
                )}`}</h3>
                <ProgressBar mode="indeterminate" />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
