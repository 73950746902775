import React from 'react';
import ProductFeatureSteps from 'components/product-feature-steps/ProductFeatureSteps';
import ProductFeatureHeader from 'components/product-feature-steps/ProductFeatureHeader';
import ProductFeatureBottom from 'components/product-feature-steps/ProductFeatureBottom';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import Forms from 'pages/shop-home-page/forms';
import { Head } from 'components/head/Head';
import './ProductFeatures.scss';
import { getMarketContent } from 'utils/marketContent';
import VideoHero from 'components/video-hero';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';

interface FeatureProducts {
  product: any;
}

export default function ProductFeatures({ product }: FeatureProducts) {
  const { pageHasVideoBanner } = getMarketContent();
  return (
    <>
      <ShopNavBar hasVideoNavBar={pageHasVideoBanner} />
      <Head
        title={product.videoHero.title}
        link={product.url}
        addPostfixTitle
        keywords={`${product.videoHero.title}, marketplace, decentralized ecosystem`}
      />
      <VideoHero
        cssClassNameWrapper={product.videoHero?.cssClassNameWrapper}
        cssClassNameContent={product.videoHero?.cssClassNameContent}
        title={product.videoHero.title}
        subtitle={product.videoHero.subtitle}
        ctaText={product.videoHero.ctaText}
        ctaLink={product.videoHero.ctaLink}
        tagLine={product.videoHero.tagLine}
        videoLink={product.videoHero.videoLink}
        fallbackImage={product.videoHero.fallbackImage}
        cssClassNameCTA={product.videoHero.cssClassNameCTA}
      />
      <section className="product-features">
        <ProductFeatureHeader
          comingSoon={product.topFeaturedSection.productComingSoon}
          title={product.topFeaturedSection.title}
          text={product.topFeaturedSection.text}
          image={product.topFeaturedSection.image}
          orderBtn={product.topFeaturedSection.orderButton}
          divider={product.topFeaturedSection.divider}
          secondarySubheader={
            product.topFeaturedSection.secondarySubheader
              ? product.topFeaturedSection.secondarySubheader
              : ''
          }
          secondaryText={
            product.topFeaturedSection.secondaryText
              ? product.topFeaturedSection.secondaryText
              : ''
          }
          productSkus={
            product.topFeaturedSection.productSkus
              ? product.topFeaturedSection.productSkus
              : []
          }
          formSections={
            product.topFeaturedSection.showForm
              ? product.topFeaturedSection.formSections
              : null
          }
          htmlContent={
            product.topFeaturedSection.htmlContent
              ? product.topFeaturedSection.htmlContent
              : null
          }
        />
        {!!product.steps.length && (
          <ProductFeatureSteps
            headerSection={product.stepsHeaderSection}
            steps={product.steps}
            alternateImg={product.alternateImageSteps}
            className={product.stepsClassName}
          />
        )}
        {product.bottomFeaturedSection.showSection && (
          <ProductFeatureBottom
            title={product.bottomFeaturedSection.title}
            logoDivider={product.bottomFeaturedSection.logoDivider}
            realatedProducts={product.bottomFeaturedSection.realatedProducts}
            headerSection={product.bottomFeaturedSection.headerSection}
            showBottomHeader
            video={product.bottomFeaturedSection.headerSection.video}
            htmlContent={
              product.bottomFeaturedSection.headerSection.htmlContent
                ? product.bottomFeaturedSection.headerSection.htmlContent
                : ''
            }
          />
        )}

        {!!product.bottomFeaturedSection.formSections.length && (
          <Forms formSections={product.bottomFeaturedSection.formSections} />
        )}
      </section>
      <MarketplaceFooter />
    </>
  );
}
