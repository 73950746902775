import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AuthRedirect() {
  const [searchParams] = useSearchParams();
  const returnPath = searchParams.get('returnPath') || '/';
  const navigate = useNavigate();

  useEffect(() => {
    navigate(returnPath);
  }, [returnPath, navigate]);

  return <></>;
}
