import React from 'react';

interface ComingSoonProps {
  title: string;
  image: string;
}

const ProductFeatureComingSoon = ({ title, image }: ComingSoonProps) => (
  <>
    <div className="col-12 my-5 flex justify-content-center">
      <h2 className="uppercase">{title}</h2>
    </div>
    {image && (
      <div className="col-12 flex justify-content-center">
        <img className="w-8 md:w-4" src={image} alt="" />
      </div>
    )}
  </>
);

export default ProductFeatureComingSoon;
