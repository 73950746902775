import { Head } from 'components/head/Head';
import { Link } from 'react-router-dom';
import './scss/ErrorPage.scss';

type ErrorPageProps = {
  isIMedia?: boolean;
};

export default function ErrorPage({ isIMedia = false }: ErrorPageProps) {
  return (
    <>
      <Head title="Page Not Found" noIndex link="/" addPostfixTitle />
      <section className="error--page">
        <div className="error--page--content">
          <h1>404</h1>
          <h2>Let’s get you back...</h2>
          <p>
            We’ve searched for the store page you were looking for but couldn’t
            find a thing.
            <br className="hidden md:inline-block" />{' '}
            {isIMedia
              ? 'Click the button below to explore all our exchanges.'
              : 'Try starting again by clicking the button below.'}
          </p>
          <Link
            to={isIMedia ? '/exchange-sitemap' : '/'}
            className="w-full md:w-auto border-gray-50 border-1 px-5 py-3 cursor-pointer no-underline button--white"
            style={{
              borderRadius: '165px',
              letterSpacing: '0.2em',
              background: 'white',
            }}
          >
            {isIMedia ? 'Explore exchanges' : 'Home'}
          </Link>
        </div>
      </section>
    </>
  );
}
