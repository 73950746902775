import React, { useEffect } from 'react';
import { ProductFieldsFragment } from 'graphql/generated/magentoApi';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Slider, SliderValueType } from 'primereact/slider';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import './index.scss';

interface companyVendors {
  name?: string;
}

interface FilterProps {
  setSelectedVendors: any;
  selectedVendors: companyVendors[] | null;
  setVisibleFilter: any;
  shouldShowAside: boolean;
  isMobile?: boolean;
  displayVendor?: boolean;
  products: (ProductFieldsFragment | null)[];
  priceRange: SliderValueType;
  setPriceRange: any;
  maxProductPrice: number;
}

export const ProductFilter = (props: FilterProps) => {
  const {
    selectedVendors,
    setSelectedVendors,
    setVisibleFilter,
    shouldShowAside,
    isMobile,
    products,
    priceRange,
    setPriceRange,
    maxProductPrice,
    displayVendor,
  } = props;

  // get all vendor names from productArray and format them for primereact dropdown:
  const companyVendorName = products?.map(item => ({
    name: item?.vendor_company,
  }));
  const vendorNames = companyVendorName?.map(item => item.name);
  // remove any duplicate vendors if they exist:
  const productVendors = companyVendorName?.filter(
    (item, index) => !vendorNames?.includes(item?.name, index + 1),
  );

  // to fix edge case with price range filter when user selects a category:
  useEffect(() => {
    clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilters = () => {
    setPriceRange([0, maxProductPrice]);
    setSelectedVendors(null);
  };

  useEffect(() => {
    if (!shouldShowAside) {
      clearFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAside]);

  return (
    <div className="product-filter">
      {isMobile ? (
        <>
          {!displayVendor ? (
            <Card
              className="filter-card mt-0 mb-0 border-none shadow-none"
              header={<h3 className="section_header">Filter by Vendor</h3>}
            >
              <MultiSelect
                value={selectedVendors}
                options={productVendors}
                onChange={e => setSelectedVendors(e.value)}
                optionLabel="name"
                placeholder="Filter by Vendor"
                maxSelectedLabels={1}
                filter
                className="mb-2 w-11 px-0 flex mx-auto"
              />
              <Button
                label="Reset Vendors"
                className=" bg-transparent text-primary block outline my-3 p-2 text-xs text-center max-w-max w-full flex ml-auto mr-2"
                onClick={clearFilters}
              />
            </Card>
          ) : null}
          <Button
            icon="pi pi-filter"
            label="Apply Product Filters"
            className="shop-button sb-primary block solid my-3 p-2 text-xs text-center mx-auto w-11 flex"
            onClick={() => setVisibleFilter(false)}
          />
        </>
      ) : (
        <>
          <Card
            className="filter-card hidden"
            header={<h3 className="section_header">Filter by Price</h3>}
          >
            <Slider
              className="mb-5"
              value={priceRange}
              max={maxProductPrice}
              onChange={e => {
                setPriceRange(e.value);
              }}
              range
            />
            <div className="flex justify-content-between align-items-center range-inputs">
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">$</span>
                <InputText
                  style={{ width: '4rem' }}
                  value={priceRange[0]}
                  onChange={e =>
                    setPriceRange([Number(e.target.value), priceRange[1]])
                  }
                />
              </div>
              <h5 className="mx-3">TO</h5>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">$</span>
                <InputText
                  style={{ width: '5rem' }}
                  value={priceRange[1]}
                  onChange={e =>
                    setPriceRange([priceRange[0], Number(e.target.value)])
                  }
                />
              </div>
            </div>
          </Card>

          {!displayVendor ? (
            <Card
              className="filter-card mt-3 border-none shadow-none"
              header={<h3 className="section_header">Filter by Vendor</h3>}
            >
              <MultiSelect
                value={selectedVendors}
                options={productVendors}
                onChange={e =>
                  setSelectedVendors(e.value.length ? e.value : null)
                }
                optionLabel="name"
                placeholder="Filter by Vendor"
                maxSelectedLabels={1}
                filter
                className="mb-4"
              />
              <Button
                label="Reset Vendors"
                className="bg-transparent text-primary p-button-outlined p-button-secondary m-0 clear-filter"
                onClick={clearFilters}
              />
            </Card>
          ) : null}
        </>
      )}
    </div>
  );
};
