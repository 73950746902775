import { useEffect } from 'react';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faMoon } from '@fortawesome/free-solid-svg-icons';
import { useMarketplaceDomain } from 'hooks/useMarketplace';
import { getMarketContent } from 'utils/marketContent';
import useLocalStorage from '../utils/useLocalStorage';
import './index.scss';

function updateBodyClass(isDark: boolean) {
  document.body.setAttribute('data-theme', isDark ? 'dark' : 'light');
}

export const ThemeSwitch = () => {
  const marketplaceDomain = useMarketplaceDomain();
  const market = getMarketContent();
  const marketName = market.market;
  const isMarketNameToken = marketName === 'token';
  const { defaultDarkMode } = getMarketContent();
  const [isDarkFromLocalStroage, setIsDarkInLocalStorage] =
    useLocalStorage<boolean>('isDark', defaultDarkMode as boolean);

  // * set dark mode to market place default when page first loads:

  window.onload = () => {
    setIsDarkInLocalStorage(defaultDarkMode as boolean);
  };

  // * allows dark mode to be set by MarketMaker page using params - darkMode=true or darkMode=false:
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const darkModeParam = params.get('darkMode');

  const setTheme = (val: boolean) => {
    updateBodyClass(val);
    setIsDarkInLocalStorage(val);

    if (
      darkModeParam &&
      marketplaceDomain !== 'advancedmedicine' &&
      marketName !== 'token'
    ) {
      // * If darkMode params exist on a domain that is not 'advancedmedicine', run this:
      if (darkModeParam === 'true') {
        updateBodyClass(true);
        setIsDarkInLocalStorage(true);
      }

      if (darkModeParam === 'false') {
        updateBodyClass(false);
        setIsDarkInLocalStorage(false);
      }
    }
    if (isMarketNameToken) {
      updateBodyClass(true);
      setIsDarkInLocalStorage(true);
    }
  };

  useEffect(() => {
    setTheme(isDarkFromLocalStroage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch
        checked={isDarkFromLocalStroage}
        onChange={() => setTheme(!isDarkFromLocalStroage)}
        handleDiameter={16}
        height={20}
        width={40}
        uncheckedIcon={<FontAwesomeIcon icon={faMoon} />}
        checkedIcon={<FontAwesomeIcon icon={faLightbulb} />}
        onHandleColor="#0c0931"
        offHandleColor="#ffffff"
        className={`react-switch ${isDarkFromLocalStroage ? 'dark' : 'light'}`}
      />
    </>
  );
};
