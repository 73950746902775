import { Breadcrumbs } from 'components/breadcrumbs';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import Partners from 'pages/shop-home-page/partners';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import { ReactElement, useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Fade } from 'react-awesome-reveal';
import { getMarketContent } from 'utils/marketContent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import './index.scss';
import ErrorPage from 'components/imedia/components/ErrorPage';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';

type AccordionItem = {
  header: string;
  content: string;
};

export default function ContentPage(): ReactElement {
  const { contentURL } = useParams();
  const market = getMarketContent();
  const data = market.contentPages;
  const redirects = market.redirects || {};
  const [imgs, setImgs] = useState<string[]>([]);
  const [accordionSection, setAccordionSection] = useState<AccordionItem[]>([]);
  const [title, setTitle] = useState<string>('');
  const [breadcrumbText, setBreadcrumbText] = useState<string>('');
  const [breadcrumbBGImage, setBreadcrumbBGImage] = useState<string>('');
  const [iframeOnly, setIframeOnly] = useState<boolean>(false);
  const [iframeOnlyHTML, setIframeOnlyHTML] = useState<string>('');
  const [htmlContent, setHTMLContent] = useState<string>('');
  const [conclusionText, setConclusionText] = useState<string>('');
  const [vimeo, setVimeo] = useState({
    accountId: '',
    videoId: '',
  });
  const [callToAction, setCallToAction] = useState({
    label: '',
    labelClass: '',
    labelLink: '',
  });

  const contentObject = useMemo(
    () => data.find(item => item.contentURL === contentURL),
    [data, contentURL],
  );

  useEffect(() => {
    if (contentObject) {
      setImgs(contentObject.imgs);
      setTitle(contentObject.title);
      setBreadcrumbText(contentObject.breadcrumbText);
      setBreadcrumbBGImage(contentObject.breadcrumbBGImage);
      setIframeOnly(contentObject.iframeOnly);
      setIframeOnlyHTML(contentObject.iframeOnlyHTML);
      setHTMLContent(contentObject.htmlContent);
      setVimeo(contentObject.vimeo);
      setCallToAction(contentObject.callToAction);
      setAccordionSection(contentObject.accordionSection);
      setConclusionText(contentObject.conclusionText);
    }
  }, [contentObject]);

  const redirectUrl = redirects[contentURL || ''];
  if (redirectUrl) {
    window.location.replace(redirectUrl);
    return <></>;
  }

  const redirectAll = redirects['/*'];
  if (redirectAll) {
    const redirectUrl = redirectAll.replace(':splat', contentURL || '');
    window.location.replace(redirectUrl);
    return <></>;
  }

  if (!contentObject) {
    return <ErrorPage />;
  }

  return (
    <>
      <ShopNavBar />
      {iframeOnly === true ? (
        <>{parse(iframeOnlyHTML)}</>
      ) : (
        <>
          {!!breadcrumbText && (
            <Fade>
              <Breadcrumbs label={breadcrumbText} bg={breadcrumbBGImage} />
            </Fade>
          )}
          <section id={contentURL} className="content-page">
            <div className="container px-4 py-8 md:px-0">
              <div className="grid">
                <div className="col-12 md:col-8 lg:col-6 mx-auto">
                  {!!title && <h1 className="text-center">{title}</h1>}

                  {imgs.length > 0 && (
                    <>
                      {imgs.map(value => (
                        <img
                          key={value}
                          className="block w-full my-8"
                          src={value}
                          alt=""
                        />
                      ))}
                    </>
                  )}
                  {!!htmlContent && (
                    <Fade triggerOnce direction="up">
                      <div>{parse(htmlContent)}</div>
                    </Fade>
                  )}
                  {!!callToAction?.labelLink && (
                    <Link
                      to={callToAction?.labelLink}
                      className={callToAction?.labelClass}
                    >
                      {callToAction?.label}
                    </Link>
                  )}
                  {!!vimeo?.accountId && (
                    <div className="video-wrap w-full flex justify-content-center mt-5">
                      <VimeoPlayer url={`https://vimeo.com/${vimeo.videoId}`} />
                    </div>
                  )}
                  {accordionSection?.length >= 1 && (
                    <Accordion activeIndex={0} className="my-6">
                      {accordionSection.map(accordionItem => (
                        <AccordionTab header={parse(accordionItem.header)}>
                          <p>{parse(accordionItem.content)}</p>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  )}
                  {!!conclusionText && <div>{parse(conclusionText)}</div>}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <Partners />
      <MarketplaceFooter />
    </>
  );
}
