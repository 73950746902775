interface AffiliateSignupFooterProps {
  shouldDisplayFooter: boolean;
}
export const AffiliateSignupFooter = ({
  shouldDisplayFooter,
}: AffiliateSignupFooterProps) => (
  <>
    {shouldDisplayFooter && (
      <section style={{ backgroundColor: 'var(--primary-color)' }}>
        <div
          className="signup-content text-xl px-4 py-3 text-center"
          style={{ maxWidth: '1480px' }}
        >
          <div className="mb-4">
            <h1 className="mb-3 text-3xl sm:text-4xl md:text-5xl  text-white font-montserrat font-black-900 uppercase">
              Join our affiliate program in 3 easy steps
            </h1>
            <p className="text-2xl text-white px-4 md:px-6 my-3 text-lg mb-3 font-oswald font-regular uppercase">
              The more people you help 10X their Health, the faster you can 10X
              your bank account!
              <br />
              Get set up and start today!
            </p>
          </div>
        </div>
      </section>
    )}

    <section style={{ backgroundColor: 'black' }}>
      <div className="px-4 py-4 grid m-0">
        <p className="text-white col-12 text-center my-auto mx-auto text-sm md:text-base">
          {`© Copyright ${new Date().getFullYear()} | All Rights Reserved.`}
        </p>
      </div>
    </section>
  </>
);

export default AffiliateSignupFooter;
