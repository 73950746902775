import { Link } from 'react-router-dom';
import { ReactElement } from 'react';
import { Head } from 'components/head/Head';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import './index.scss';
import { getMarketContent } from 'utils/marketContent';
import { Fade } from 'react-awesome-reveal';

export default function CollectionIndex(): ReactElement {
  const { collections } = getMarketContent();
  return (
    <>
      <Head
        link="/collections"
        title="Collections"
        description="Shop Advanced Medicine Product Collections"
        keywords="product collections, collections, blockchain marketplace, crwd market"
        largeTwitterCard
      />
      <ShopNavBar />
      <section className="my-6">
        <div className="container px-4">
          <h1 className="text-center mb-4 text-xl uppercase">
            Shop Our Collections
          </h1>

          <div className="grid">
            <Fade
              className={
                collections.length >= 6
                  ? 'col-12 md:col-8 lg:col-6 xl:col-4 mx-auto'
                  : 'col-12 md:col-8 lg:col-6 xl:col-6 mx-auto'
              }
              triggerOnce
            >
              {collections.map(obj => (
                <div key={obj.collection_name}>
                  <Link
                    to={
                      obj.collection_url.length
                        ? `/collection/${obj.collection_url}`
                        : '/collections'
                    }
                    className="collection__item"
                    style={{
                      backgroundImage: `url(${obj?.collection_image})`,
                    }}
                  >
                    <p>{obj.collection_name}</p>
                  </Link>
                </div>
              ))}
            </Fade>
          </div>
        </div>
      </section>
      <MarketplaceFooter />
    </>
  );
}
