import { ReactElement, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import './scss/ShareWidget.scss';
import {
  TwitterButton,
  FacebookButton,
  EmailShareButton,
} from './SocialMediaButtons';

export default function ShareWidget(): ReactElement {
  const toast = useRef<Toast>(null);

  const [isScrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handler = () => {
      setScrolled(isScrolled => {
        if (
          !isScrolled &&
          (document.body.scrollTop > 500 ||
            document.documentElement.scrollTop > 500)
        ) {
          return true;
        }

        if (
          isScrolled &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isScrolled;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  useEffect(() => {
    toast.current?.show({
      severity: 'info',
      className: 'share-widget',
      content: (
        <div>
          <div className="flex flex-column">
            <span className="share-widget-label">Share</span>
            <TwitterButton url={window.location.href} />
            <FacebookButton url={window.location.href} />
            <EmailShareButton url={window.location.href} />
          </div>
        </div>
      ),
      contentClassName: '',
      life: 100000,
    });
  }, []);

  return (
    <Toast
      ref={toast}
      position="bottom-left"
      className={isScrolled ? 'active' : ''}
    />
  );
}
