import React from 'react';
import { Controller } from 'react-hook-form';
import { InputTextProps, InputText } from 'primereact/inputtext';
import {
  AdminInputBaseProps,
  buildClassName,
  validateProps,
} from '../AdminForm';
import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

interface InputProps {
  isTextArea?: boolean;
  isLoading?: boolean;
  trimmed?: boolean;
}

export type AdminInputTextProps = InputTextProps &
  AdminInputBaseProps &
  InputTextareaProps &
  InputProps;

export const AdminInputText: React.FC<AdminInputTextProps> = props => {
  validateProps(props);
  const {
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    defaultValue,
    isTextArea,
    isLoading = false,
    trimmed = false,
    onBlur,
    onChange,
    rules,
    pattern,
    ...baseProps
  } = props;

  const priorityLabel = attributeType?.label ?? label ?? name;
  const description = attributeType?.description;
  const attributeTypeProps = attributeType?.props;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? ''}
      name={name}
      rules={{
        required,
        ...attributeType?.validation,
        ...rules,
      }}
      render={({ field, fieldState, formState: _formState }) => {
        const errorMessage =
          fieldState.error?.message || fieldState.error?.type;

        return (
          <div className={containerClassName}>
            <div className="p-float-label w-full p-input-icon-right">
              {!!isLoading && <i className="pi pi-spin pi-spinner" />}

              {!isTextArea ? (
                <InputText
                  {...baseProps}
                  {...attributeTypeProps}
                  className={buildClassName(baseProps.className, errorMessage)}
                  value={field.value}
                  about={description}
                  onChange={e => {
                    const value = pattern
                      ? e.target.value.replace(new RegExp(pattern, 'i'), '')
                      : e.target.value;

                    field.onChange(value);
                    onChange?.(e);
                  }}
                  onBlur={() => {
                    field.onBlur();
                    onBlur?.();
                    trimmed && field.onChange(field.value.trim());
                  }}
                  ref={field.ref}
                />
              ) : (
                <InputTextarea
                  {...baseProps}
                  {...attributeTypeProps}
                  id={name}
                  className={buildClassName(baseProps.className, errorMessage)}
                  value={field.value}
                  about={description}
                  onChange={e => {
                    const value = pattern
                      ? e.target.value.replace(new RegExp(pattern, 'i'), '')
                      : e.target.value;

                    field.onChange(value);
                    onChange?.(e);
                  }}
                  onBlur={() => {
                    field.onBlur();
                    onBlur?.();
                    trimmed && field.onChange(field.value.trim());
                  }}
                  ref={field.ref}
                />
              )}

              <label htmlFor={name} className="capitalize">
                {priorityLabel}
                {required && ' *'}
              </label>
            </div>
            <small
              id={`${name}-help`}
              className={classNames('p-d-block', { 'ml-2 block': !!helpText })}
            >
              {helpText}
            </small>
            {!!helpText && !!errorMessage && <br />}
            <small
              className={classNames('p-error p-d-block', {
                'ml-2': !!errorMessage,
              })}
            >
              {errorMessage}
            </small>
          </div>
        );
      }}
    />
  );
};
