import { ProductInterface } from 'graphql/generated/magentoApi';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useCartContext } from 'CartContext';
import './ProductCard.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface ProductCardProps {
  product: ProductInterface;
}

export default (props: ProductCardProps) => {
  const { product } = props;
  const { updateCart, loading } = useCartContext();

  if (!product?.sku) return <></>;

  const onClickAddBtn = (sku: string) => {
    updateCart({
      sku,
      quantity: 1,
      debug: 'DonateProductCard',
    });
  };

  return (
    <div className="mx-auto col-6 lg:col-3">
      <Card
        header={<></>}
        className="border-0 h-full flex flex-row md:flex-column justify-content-between mx-auto product-card-list-item mb-4 md:mb-0"
      >
        <div className="flex flex-column align-items-center justify-content-center">
          <Button
            className="uppercase font-bold text-md border-noround c-button-primary my-0 mb-2 flex flex-column"
            onClick={() => onClickAddBtn(product.sku)}
            loading={loading}
            id={`product-add-to-cart-${product.sku}`}
            style={{ minWidth: '100px' }}
          >
            <span>Donate</span>
            <span>
              {formatter.format(
                product.price_range.maximum_price.final_price.value,
              )}
            </span>
          </Button>
        </div>
      </Card>
    </div>
  );
};
