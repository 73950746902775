import { SignupInput } from 'UserContext';
import { oidcSettings } from './authConfig';

export async function loginApi(
  email: string,
  password: string,
): Promise<string> {
  const url = `${oidcSettings.authority}/trusted/login`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
      clientId: oidcSettings.client_id,
      scope: oidcSettings.scope,
    }),
  });
  const body = await response.json();
  const { id_token } = body;
  if (id_token) {
    return id_token;
  }

  throw new Error(body.error);
}

export async function signupApi(signupInput: SignupInput): Promise<void> {
  const url = `${oidcSettings.authority}/trusted/register`;
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...signupInput,
      clientId: oidcSettings.client_id,
    }),
  });
  const body = await response.json();
  if (!body.error) {
    return;
  }

  throw new Error(body.error);
}
