import React, { useCallback, useRef, useState } from 'react';
import {
  StepButton,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@mui/material';
import { Button as PrimeButton } from 'primereact/button';
import useViewport from 'hooks/useViewport';
import parse from 'html-react-parser';
import { AffiliateSignupFooter } from 'pages/signup/AffiliateSignupFooter';
import Step1TokenForm, { TokenFormFields } from './Step1TokenForm';
import Step2ContactForm, { ContactFormFields } from './Step2ContactForm';
import Step3ProductHandleForm, {
  ProductHandleFormFields,
} from './Step3ProductHandleForm';
import Step4DisclaimerForm, {
  DisclaimerFormFields,
} from './Step4DisclaimerForm';
import '../SignUpForm.scss';

const steps = [
  '<span class="font-oswald">STEP 1</span> <br /> <span class="font-montserrat">Generate Verification Code</span>',
  '<span class="font-oswald">STEP 2</span> <br /> <span class="font-montserrat">Contact Information</span>',
  '<span class="font-oswald">STEP 3</span> <br /> <span class="font-montserrat">Product Enrollment</span>',
  '<span class="font-oswald">STEP 4</span> <br /> <span class="font-montserrat">Complete Registration</span>',
];
export type FormTriggerRef<T> = {
  trigger: () => Promise<T>;
} | null;

export function AffiliateForm() {
  const step1Ref = useRef<FormTriggerRef<TokenFormFields | undefined>>(null);
  const step2Ref = useRef<FormTriggerRef<ContactFormFields | undefined>>(null);
  const step3Ref =
    useRef<FormTriggerRef<ProductHandleFormFields | undefined>>(null);
  const step4Ref =
    useRef<FormTriggerRef<DisclaimerFormFields | undefined>>(null);
  const [isFormComplete, setIsFormComplete] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const stepContentRef = useRef<HTMLDivElement>(null);

  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [error, setError] = useState<{ message: string; stepIndex: number }>();
  const [tokenData, setTokenData] = useState<TokenFormFields | undefined>();
  const [contactData, setContactData] = useState<
    ContactFormFields | undefined
  >();
  const [productHandleData, setProductHandleData] = useState<
    ProductHandleFormFields | undefined
  >();
  const [completed, setCompleted] = useState<boolean[]>(
    new Array<boolean>(steps.length),
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  const isLastStep = activeStep === steps.length - 1;
  const { isMobile } = useViewport();

  const scrollToSteps = useCallback(() => {
    const scrollY = isMobile
      ? stepContentRef.current?.offsetTop
      : (containerRef.current?.offsetTop || 0) - 70;

    if (scrollY) {
      window.scrollTo({
        top: scrollY,
        behavior: 'instant' as any,
      });
    }
  }, [isMobile]);

  const handleStepComplete = (step: number, isComplete = false) => {
    const newCompleted = [...completed];
    newCompleted[step] = isComplete;
    setCompleted(newCompleted);

    if (isComplete) {
      const newStep = step + 1;

      setActiveStep(newStep);
      scrollToSteps();
      if (newStep >= 4) {
        setRegistrationComplete(true);
        setError(undefined);
      }
    }
  };

  const handleStep = async (step: number) => {
    switch (step) {
      case 0: {
        const tokenFormData = await step1Ref.current?.trigger();
        setTokenData(tokenFormData);
        handleStepComplete(step, !!tokenFormData);
        break;
      }

      case 1: {
        const contactFormData = await step2Ref.current?.trigger();
        setContactData(contactFormData);
        handleStepComplete(step, !!contactFormData);
        break;
      }

      case 2: {
        const productHandleFormData = await step3Ref.current?.trigger();
        setProductHandleData(productHandleFormData);
        handleStepComplete(step, !!productHandleFormData);
        break;
      }

      case 3:
      default: {
        const disclaimerFormData = await step4Ref.current?.trigger();
        if (disclaimerFormData) {
          handleStepComplete(step, !!disclaimerFormData);
        }
        break;
      }
    }
  };
  const handleBack = () => {
    scrollToSteps();
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const nextStep = () => {
    handleStep(activeStep);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Step1TokenForm
            ref={step1Ref}
            data={tokenData}
            setShowNext={setShowNext}
          />
        );
      case 1:
        return (
          <Step2ContactForm
            ref={step2Ref}
            data={contactData}
            setShowNext={setShowNext}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <Step3ProductHandleForm
            ref={step3Ref}
            data={productHandleData}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case 3:
        return (
          <Step4DisclaimerForm
            ref={step4Ref}
            tokenData={tokenData}
            contactData={contactData}
            productHandleData={productHandleData}
            setError={setError}
            setLoading={setLoading}
            setIsFormComplete={setIsFormComplete}
          />
        );
      default:
        return (
          <p className="font-montserrat w-12 md:w-7 text-center mx-auto my-6">
            Thank you for applying to be a 10X Health affiliate. Our team is
            currently reviewing your application and will be in touch shortly
            with next steps.
          </p>
        );
    }
  }

  const handleStepsButton = (step: number) => {
    if (completed[step]) {
      setActiveStep(step);
    }
  };

  const isStepFailed = (step: number) => error?.stepIndex === step;

  return (
    <>
      <div
        className="provider-form my-4 px-4 md:px-2 mx-auto"
        id="provider-form-top"
      >
        <Stepper
          ref={containerRef}
          activeStep={activeStep}
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {
              completed: completed[index],
            };

            const labelProps: {
              optional?: React.ReactNode;
              error?: boolean;
            } = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  {error?.message}
                </Typography>
              );
              labelProps.error = true;
            }
            return (
              <Step
                color="black"
                key={label}
                disabled={registrationComplete}
                {...stepProps}
              >
                <StepButton
                  color="black"
                  className="uppercase"
                  onClick={() => handleStepsButton(index)}
                >
                  <StepLabel key={label} className="uppercase" {...labelProps}>
                    <div>
                      {parse(label)}
                      <br />
                      {labelProps?.optional}
                    </div>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>

        <div className="my-5" ref={stepContentRef}>
          {getStepContent(activeStep)}
        </div>

        <div className="flex align-items-center justify-content-between">
          {activeStep !== 0 && !registrationComplete && (
            <Button
              className="provider-form__basic-btn back-btn"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          {!registrationComplete && showNext && (
            <PrimeButton
              className="p-button ml-auto uppercase"
              color="primary"
              type="button"
              label={isLastStep ? 'SUBMIT' : 'NEXT'}
              loading={loading}
              disabled={isLastStep && !isFormComplete}
              onClick={() => {
                handleStep(activeStep);
              }}
            />
          )}
        </div>
      </div>
      <AffiliateSignupFooter shouldDisplayFooter={!registrationComplete} />
    </>
  );
}

export default AffiliateForm;
