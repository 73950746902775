import './index.scss';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import BlogTemplate from 'components/blog-template';
import { useNavigate } from 'react-router-dom';
import { getMarketContent } from 'utils/marketContent';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  HubspotBlogFieldsFragment,
  useHubspotBlogPostsQuery,
} from 'graphql/generated/reactQuery';
import { useClient } from 'UserContext';

export default function ShopBlogs() {
  const navigate = useNavigate();
  const client = useClient();
  const market = getMarketContent();
  const dataBlogID = market.blogIDSMB;
  const { isFetching, error, data } = useHubspotBlogPostsQuery(client, {
    tagIds: [dataBlogID],
  });
  const [hubSpotBlogs, setHubSpotBlogs] = useState<HubspotBlogFieldsFragment[]>(
    [],
  );
  const { blogSectionTitle } = market.homePage;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (dataBlogID && !isFetching) {
      if (data?.hubspotBlogPosts) {
        setHubSpotBlogs(data?.hubspotBlogPosts);
      }
      if (error) {
        console.log('Error with useHubspotBlog:', error);
      }
    }
  }, [isFetching, error, data?.hubspotBlogPosts, dataBlogID]);

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view previous item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  return (
    <section className="ShopBlogs">
      <h2 className="section_header capatalize">
        {blogSectionTitle.title && parse(blogSectionTitle.title)}
      </h2>
      <div className="grid">
        {isFetching && <ProgressSpinner />}
        {!!hubSpotBlogs.length && (
          <>
            <Slider {...settings} className="col-11 mx-auto blog-slider">
              {hubSpotBlogs?.map(blog => (
                <BlogTemplate {...blog} key={blog.id} />
              ))}
            </Slider>
            <Button
              label="View More Blogs"
              className="c-button-black ml-auto mr-3 md:mr-6 mt-3 mb-3 h-full flex"
              onClick={() => navigate('/blog-explorer')}
            />
          </>
        )}
      </div>
    </section>
  );
}
