/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  ReactElement,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMatomo } from 'matomoReact';

import {
  CartFieldsFragment,
  CartItemFieldsFragment,
  ProductFieldsFragment,
  ProductInterface,
  useAddProductsToCartMutation,
  useUpdateCartItemsMutation,
} from 'graphql/generated/magentoApi';

import { Sidebar, SidebarPositionType } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';
import RightSidebarCart from 'components/right-sidebar-cart';
import { useGetCart, useCartId } from 'hooks/magentoHooks';
import sample from 'lodash/sample';
import { ProcessCheckoutInput } from 'hooks/checkoutHooks';
import { StoreOrderGuestFieldsFragment } from 'graphql/generated/graphqlRequest';
import { productMaxQuantity } from 'utils/helper';
import skuUrls from './crwdShopUrlsAndSkus';

export interface SearchParam {
  key: string;
  value: string;
}

export interface UpdateCartParams {
  sku: string;
  quantity: number;
  addToExisting?: boolean;
  showCart?: boolean;
  showToast?: boolean;
  onlyAddOneIfNotPresent?: boolean;
  debug?: string;
}

export interface CartItem {
  product: ProductInterface;
  quantity: number;
  uid: string;
}

export interface CartContextType {
  lastCheckout?: ProcessCheckoutInput;
  lastOrders?: StoreOrderGuestFieldsFragment[];
  isEmbedded: boolean;
  hideNav: boolean;
  cartVisible: boolean;
  items: CartItemFieldsFragment[];
  loading: boolean;
  quantity: string;
  shipping: string;
  subTotal: string;
  taxes: string;
  total: string;
  affiliateCode?: string;
  edgeSiteUrl?: string;
  setLastCheckout: (lastCheckout?: ProcessCheckoutInput) => void;
  setLastOrders: (lastOrders?: StoreOrderGuestFieldsFragment[]) => void;
  reset: () => void;
  showCart: (cartPosition?: string) => void;
  updateCart: (params: UpdateCartParams) => Promise<void>;
  getProductWebsiteUrl: (
    product: ProductFieldsFragment | ProductInterface,
  ) => string | undefined;
  visitEdgeSite: (
    url: string,
    newWindow: boolean,
    params?: SearchParam[],
  ) => void;
  isMaxInCart(product: ProductFieldsFragment): boolean;
}

export interface CartProviderProps {
  children: ReactNode | ReactNode[];
}

export const CartContext = createContext<CartContextType | null>(null);

const cartQuantityStorageKey = 'cart-quantity';

export const useCartContext = () => {
  const context = useContext(CartContext);

  if (context === null) {
    throw new Error('useCartContext must be used within a CartProvider');
  }

  return context;
};

export function CartProvider(props: CartProviderProps): ReactElement {
  const { children } = props;

  const [lastCheckout, setLastCheckout] = useState<
    ProcessCheckoutInput | undefined
  >();
  const [lastOrders, setLastOrders] = useState<
    StoreOrderGuestFieldsFragment[] | undefined
  >();
  const [searchParams] = useSearchParams();
  const { pushInstruction, trackEvent, visitorIdSearchParam } = useMatomo();
  const { cartId, reset: resetCartId } = useCartId();

  const firstRender = useRef<boolean>(true);

  const { cart, cartFetching, fetchCart } = useGetCart();
  const [affiliateCode, setAffiliateCode] = useState<string | undefined>();
  const [edgeSiteUrl, setEdgeSiteUrl] = useState<string | undefined>();
  const [hideNav, setHideNav] = useState<boolean>(
    searchParams.get('hideNav') === '1',
  );
  const [isEmbedded, setIsEmbedded] = useState<boolean>(
    searchParams.get('isEmbedded') === '1',
  );
  const [cartVisible, setCartVisible] = useState(false);
  const [cartPosition, setCartPosition] = useState<
    SidebarPositionType | undefined
  >(undefined);
  const [magentoCart, setMagentoCart] = useState<
    CartFieldsFragment | undefined
  >();
  const [hasFetchedItems, setHasFetchedItems] = useState(false);
  const [quantity, setQuantityLocal] = useState(
    localStorage.getItem(cartQuantityStorageKey) || '0',
  );
  const [shipping, setShipping] = useState('free');
  const [subTotal, setSubTotal] = useState('0.00');
  const [taxes, setTaxes] = useState('0.00');
  const [total, setTotal] = useState('0.00');
  const [addProductState, addProducts] = useAddProductsToCartMutation();
  const [updateCartItemsState, updateCartItems] = useUpdateCartItemsMutation();
  const [savedUpdateCartParams, setSavedUpdateCartParams] = useState<
    UpdateCartParams | undefined
  >();
  const toast = useRef<Toast>(null);
  const loading = useMemo(
    () =>
      cartFetching ||
      addProductState.fetching ||
      updateCartItemsState.fetching ||
      !hasFetchedItems,
    [
      cartFetching,
      addProductState.fetching,
      updateCartItemsState.fetching,
      hasFetchedItems,
    ],
  );

  const setQuantity = (newQuantity: string) => {
    localStorage.setItem(cartQuantityStorageKey, newQuantity);
    window.dispatchEvent(new Event('storage'));
    setQuantityLocal(newQuantity);
  };

  const reset = useCallback(() => {
    resetCartId();
    setQuantity('0');
    setShipping('free');
    setSubTotal('0.00');
    setTotal('0.00');
    setMagentoCart(undefined);
  }, [resetCartId]);

  useEffect(() => {
    const newQuantity =
      magentoCart?.items?.reduce(
        (previous, item) => previous + item.quantity,
        0,
      ) || 0;
    setQuantity(`${newQuantity}`);

    const newSubTotal =
      magentoCart?.items?.reduce(
        (previousTotal, item) =>
          previousTotal +
          item.product.price_range.maximum_price.final_price.value *
            item.quantity,
        0,
      ) || 0;
    setSubTotal(newSubTotal.toFixed(2));

    const newTaxes = Number(
      magentoCart?.prices?.applied_taxes?.reduce(
        (prev, tax) => prev + tax?.amount.value || 0,
        0,
      ) || 0,
    );
    setTaxes(String(newTaxes.toFixed(2)));

    const newShipping = 0;

    const newTotal = newSubTotal + newTaxes + newShipping;
    setTotal(newTotal.toFixed(2));
  }, [magentoCart]);

  useEffect(() => {
    if (!hasFetchedItems && cart) {
      setMagentoCart(cart);
      setHasFetchedItems(true);
    }
  }, [cart, hasFetchedItems]);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    const afmc = searchParams.get('afmc');
    if (afmc) {
      setAffiliateCode(afmc);
    }
    const website = searchParams.get('website');
    if (website) {
      setEdgeSiteUrl(website);
    }
    const hideNav = searchParams.get('hideNav');
    if (hideNav) {
      setHideNav(hideNav === '1');
    }
    const isEmbedded = searchParams.get('isEmbedded');
    if (isEmbedded) {
      setIsEmbedded(previousValue => previousValue || isEmbedded === '1');
    }
  }, [searchParams]);

  useEffect(() => {
    if (affiliateCode) {
      console.log('tracking affiliate code', affiliateCode);
      pushInstruction('setCustomDimension', 1, affiliateCode);
    }
  }, [affiliateCode, pushInstruction]);

  useEffect(() => {
    if (cartId && firstRender.current) {
      localStorage.setItem('cartId', cartId);
      firstRender.current = false;
    }
  }, [cartId]);

  const visitEdgeSite = useCallback(
    (url: string, newWindow: boolean, params: SearchParam[] = []) => {
      const searchParams = new URLSearchParams();
      params.forEach(param => {
        searchParams.set(param.key, param.value);
      });
      if (visitorIdSearchParam) {
        const [key, value] = visitorIdSearchParam.split('=');
        searchParams.set(key, value);
      }
      const { origin } = window.location;
      if (origin.includes('localhost')) {
        // we use a dev url since http://localhost won't
        // work for the iframe on the edge site
        searchParams.set(
          'marketplace-url',
          'https://dev.advancedmedicine.market',
        );
      } else if (origin.includes('dev.')) {
        searchParams.set('marketplace-url', origin);
      }

      const query = searchParams.toString();
      const fullUrl = query ? `${url}?${query}` : url;

      if (newWindow) {
        window.open(fullUrl, '_blank');
      } else {
        window.location.assign(fullUrl);
      }
    },
    [visitorIdSearchParam],
  );

  const getProductWebsiteUrl = useCallback(
    (product: ProductFieldsFragment | ProductInterface): string | undefined => {
      const { sku, website_url } = product ?? { sku: undefined };
      if (!sku) {
        return undefined;
      }
      const urls: string[] =
        skuUrls[sku]?.map(info => {
          if (typeof info === 'string') {
            return info;
          }
          return info.domain;
        }) || [];

      const skuIndexMap: Record<string, number> = JSON.parse(
        localStorage.getItem(`skuIndexMap-${product.sku}`) || '{}',
      );

      let index = skuIndexMap[sku];

      if (typeof index === 'undefined') {
        index = urls.indexOf(sample(urls) as string);
      } else {
        index += 1;
        if (index >= urls.length) {
          index = 0;
        }
      }

      skuIndexMap[sku] = index;

      localStorage.setItem(`skuIndexMap-${sku}`, JSON.stringify(skuIndexMap));

      let url = urls[index];
      if (url && !url.startsWith('https://')) {
        url = `https://${url}`;
      }

      return url || website_url || undefined;
    },
    [],
  );

  const showCart = cartPosition => {
    setCartVisible(true);
    setCartPosition(cartPosition);
  };

  const updateCart = useCallback(
    async (params: UpdateCartParams) => {
      const {
        sku,
        quantity,
        showCart,
        showToast,
        onlyAddOneIfNotPresent,
        addToExisting,
      } = params;

      if (showCart) {
        setCartVisible(true);
      }

      if (loading) {
        setSavedUpdateCartParams(params);
        return;
      }

      let currentItem = magentoCart?.items.find(
        item => item.product.sku === sku,
      );
      const maxQuantity = productMaxQuantity(
        sku,
        (currentItem as any)?.__typename,
      );

      let computedQuantity: number = quantity;
      if (addToExisting && currentItem) {
        computedQuantity = currentItem.quantity + quantity;
      }
      if (computedQuantity > maxQuantity) {
        computedQuantity = maxQuantity;
      }

      if (currentItem && !onlyAddOneIfNotPresent) {
        const result = await updateCartItems({
          input: {
            cart_id: cartId,
            cart_items: [
              {
                cart_item_uid: currentItem.uid,
                quantity: computedQuantity,
              },
            ],
          },
        });
        if (result.data) {
          const cart = result.data?.updateCartItems.cart;
          setMagentoCart(cart);
          currentItem = cart.items.find(item => item.product.sku === sku);
        }
      } else if (!currentItem) {
        const result = await addProducts({
          cartId,
          cartItems: [{ sku, quantity: computedQuantity }],
        });
        if (result.data) {
          const cart = result.data?.addProductsToCart.cart;
          setMagentoCart(cart);
          currentItem = cart.items.find(item => item.product.sku === sku);
        }
      } else {
        computedQuantity = 0;
      }

      if (showToast) {
        // TODO:
      }

      const product = currentItem?.product;

      if (computedQuantity) {
        trackEvent({
          category: 'ecommerce',
          action: 'add_to_cart',
          name: `Product Description Action ${product?.name} - ${sku}`,
        });
        const firstFiveCategories = product?.categories?.length
          ? product?.categories?.slice(0, 5).map(category => category?.name)
          : 'No Categories Found';
        pushInstruction(
          'addEcommerceItem',
          sku,
          product?.name,
          firstFiveCategories,
          product?.price_range.maximum_price?.final_price.value,
          computedQuantity,
        );
      }
    },
    [
      addProducts,
      cartId,
      updateCartItems,
      magentoCart,
      loading,
      pushInstruction,
      trackEvent,
    ],
  );

  useEffect(() => {
    if (savedUpdateCartParams && !loading) {
      setSavedUpdateCartParams(undefined);
      updateCart(savedUpdateCartParams);
    }
  }, [savedUpdateCartParams, loading, updateCart]);

  const isMaxInCart = useCallback(
    (product: ProductFieldsFragment): boolean => {
      const maxQuantity = productMaxQuantity(product.sku, product.__typename);
      const item = magentoCart?.items.find(
        item => item.product.sku === product.sku,
      );
      if (item && item?.quantity >= maxQuantity) {
        return true;
      }
      return false;
    },
    [magentoCart],
  );

  const cartContextValue: CartContextType = {
    lastCheckout,
    lastOrders,
    isEmbedded,
    hideNav,
    cartVisible,
    items: magentoCart?.items || [],
    loading,
    quantity,
    shipping,
    subTotal,
    taxes,
    total,
    affiliateCode,
    edgeSiteUrl,
    setLastCheckout,
    setLastOrders,
    reset,
    showCart,
    updateCart,
    getProductWebsiteUrl,
    visitEdgeSite,
    isMaxInCart,
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      <Toast ref={toast} position="top-right" />
      <Sidebar
        visible={cartVisible}
        position={cartPosition || 'right'}
        onHide={() => setCartVisible(false)}
        closeOnEscape
        showCloseIcon={false}
      >
        <RightSidebarCart setVisibleRight={setCartVisible} />
      </Sidebar>
      {children}
    </CartContext.Provider>
  );
}
