import { ReactElement, useState } from 'react';
import { Fade } from 'react-awesome-reveal';

import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import VideoHero from 'components/video-hero';
import ProviderSignupHome from 'pages/signup/ProviderSignupHome';
import AffiliateSignupHome from 'pages/signup/AffiliateSignupHome';
import MemmSignupHome from 'pages/signup/MemmSignupHome';
import ProductList from 'pages/ProductList';
import { Outlet } from 'react-router-dom';
import ShopNavBar from './shop-nav-bar';
import HomeBanner from './home-banner';
import ShopBlogs from './shop-blogs';
import ProductsByTags from './products-by-tags';
import Partners from './partners';
import WebsiteCards from './website-cards';
import PromoParallax from './promo-parallax';
import Testimonials from './testimonials';
import VendorLogoSlider from './vendor-logo-slider';
import WelcomeText from './welcome-text';
import Forms from './forms';
import SmallPromoImage from './small-promo-image';
import './shop-scss/shop-index.scss';

export default function ShopHomePage(): ReactElement {
  const market = getMarketContent();
  const { pageHasVideoBanner } = getMarketContent();
  const data = market.meta;
  const { formSections, imageDivider, videoHero, visitWebsiteCards } =
    market.homePage;
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  if (market.redirects?.['/']) {
    if (
      process.env.NODE_ENV === 'development' ||
      window.location.origin.includes('dev')
    ) {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        // eslint-disable-next-line no-alert
        alert(`non-dev environments will forward to ${market.redirects['/']}`);
      }
    } else {
      window.location.replace(market.redirects['/']);
      return <></>;
    }
  }

  if (market.market === 'crwdmarket') {
    return <ProductList />;
  }
  if (market.market === 'signup10xProvider') {
    return <ProviderSignupHome />;
  }
  if (market.market === 'signup10xAffiliate') {
    return <AffiliateSignupHome />;
  }
  if (market.market === 'signup10xMemm') {
    return <MemmSignupHome />;
  }

  return (
    <>
      <Head
        title={data.title}
        link="/"
        keywords={data.title}
        description={data.title}
      />
      <ShopNavBar hasVideoNavBar={pageHasVideoBanner} />
      {pageHasVideoBanner ? (
        <VideoHero
          cssClassNameWrapper={videoHero.cssClassNameWrapper}
          cssClassNameContent={videoHero.cssClassNameContent}
          title={videoHero.title}
          subtitle={videoHero.subtitle}
          ctaText={videoHero.ctaText}
          ctaLink={videoHero.ctaLink}
          ctaSKU={videoHero.ctaSKU}
          tagLine={videoHero.tagLine}
          videoLink={videoHero.videoLink}
          fallbackImage={videoHero.fallbackImage}
          cssClassNameCTA={videoHero.cssClassNameCTA}
        />
      ) : (
        <HomeBanner />
      )}
      <SmallPromoImage />

      <WelcomeText />

      <div className="container px-2 my-4">
        <Fade triggerOnce fraction={0.1}>
          <div
            className={`grid flex-wrap mx-auto justify-content-center  ${
              visitWebsiteCards.length >= 6 ? '' : 'wide-website-card'
            } `}
          >
            {visitWebsiteCards.map(product => (
              <WebsiteCards
                key={product.sku + product.link}
                header={product.header}
                sku={product.sku}
                link={product.link || ''}
                image={product.image}
                description={product?.description}
              />
            ))}
          </div>
        </Fade>
      </div>
      <PromoParallax />

      <div className="container py-5 px-4">
        <div className="grid">
          <div className="col-12  lg:col-9">
            <ShopBlogs />
          </div>
          <div className="col-12  lg:col-3">
            <Testimonials />
          </div>
        </div>
      </div>
      <VendorLogoSlider />
      <Forms imageDivider={imageDivider} formSections={formSections} />
      <ProductsByTags />
      <Partners />
      <MarketplaceFooter />
      <Outlet />
    </>
  );
}
