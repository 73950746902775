export default {
  '2021-08-20-HSX-AMM-kp-001': [
    'https://happyhealthlink.com',
    'https://pathwaytolivingwell.com',
    'https://rescueme-products.com',
    'https://naturalhealth4all.com',
    'https://chancehealth.solutions',
  ],
  '2022-03-14-CDF-CNM-pcbd-014': [
    'https://shopsharonann.com',
    'https://naturalandhealthychoices4u.com',
    'https://peersforyou.com',
    'https://bewisebestrong.store',
    'https://mukanya-health.store',
    'https://bioorganicum.com',
  ],
  '2021-08-20-HSX-AMM-sb-001': [
    'https://healthychoicesplace.com',
    'https://flodi.store',
    'https://healthlife.ninja',
    'https://abundancehealth4u.com',
    'https://naturesway4ever.com',
    'https://marifrankblockchainstore.com',
    'https://allnaturalhealth-privacy.com',
    'https://liveberryhealthy.com',
    'https://organicos.shop',
    'https://combsnaturalsolutions.com',
    'https://bodymind.today',
    'https://harmonyhealth.shop',
    'https://1store4you.com',
    'https://healthychoicesplace.com',
    'https://tiendajim.com',
    'https://bolingohealth.com',
    'https://peerlesspicks.com',
    'https://healthbodypro.com',
    'https://thefreshlife.shop',
    'https://shop-tom.com',
    'https://elderberryimmunesupport.com',
  ],
  '2021-08-20-HSX-AMM-sb-002': [
    'https://casswil.com',
    'https://www.be3world.com',
    'https://conksite.com',
    'https://heartsmindsmarketplace.com',
    'https://thehealthyeagleparadigmllc.com',
    'https://amsolibrant.com',
    'https://lovetogethealthy.com',
    'https://eyeamwell.org',
  ],
  '2021-08-20-HSX-AMM-kp-003': [
    'https://leithbay.com',
    'https://awakenbay.com',
    'https://vitalitylifesolutions.com',
  ],
  '2022-06-29-ITX-CPM-vrai-005': [
    'https://vigilantaiprotection.com',
    'https://yumiiko.com',
    'https://cybersecuritypack.com',
    'https://idcyberprotection.com',
  ],
  '2021-12-10-ITX-CPM-vrai-003': [
    'https://nexgenprivacy.com',
    'https://cybersecuretechnologies.com',
    'https://cyberprotectionclub.com',
    'https://shieldprotectiontech.com',
    'https://nexgentitanium.com',
    'https://cybershieldsecurities.com',
  ],
  '2021-08-20-HSX-AMM-vika-003': [
    'https://ecoonline.store',
    'https://forkestore.com',
    'https://naturalbeautysource.com',
    'https://essential-care.co',
    'https://healthkaren.dk',
    'https://fromhere2better.com',
    'https://organicshub.org',
    'https://vikasessentialsorganics.com',
    'https://ozarkschoice.com',
    'https://fastedy.store',
    'https://myblockchainlegacy.com',
    'https://shine369.com',
    'https://obsinee.com',
    'https://thenaturalrelm.com',
    'https://joybynatural.com',
    'https://theblossomingwillow.com',
    'https://healthierme.shop',
    'https://aradiantglow.com',
  ],
  '2022-04-08-HSX-AMM-vika-004': [
    'https://ecoonline.store',
    'https://essential-care.co',
    'https://fromhere2better.com',
    'https://thenaturalrelm.com',
    'https://aradiantglow.com',
    'https://shine369.com',
    'https://mynaturalself.com',
  ],
  '2022-04-08-HSX-AMM-vika-005': [
    'https://ecoonline.store',
    'https://essential-care.co',
    'https://fromhere2better.com',
    'https://thenaturalrelm.com',
    'https://aradiantglow.com',
    'https://shine369.com',
    'https://mynaturalself.com',
    'https://alisonsbeautyandwellness.com',
  ],
  '2022-04-08-HSX-AMM-vika-006': ['https://chancehealth.org'],
  '2022-10-06-HSF-AMM-vika-009': ['https://mynaturalself.com'],
  '2022-10-06-HSF-AMM-vika-007': ['https://vikasessentialsorganics.com'],
  '2022-10-06-HSF-AMM-vika-008': ['https://vikasessentialsorganics.com'],
  '2021-08-20-HSX-AMM-kp-002': [
    'https://appletreenow.com',
    'https://doxagroup.com',
    'https://healthinvogue.com',
    'https://olivermaeandcompany.com',
    'https://healthyhigh.me',
  ],
  '2021-08-20-HSX-AMM-nn-001': [
    'https://supernaturalproductics.com',
    'https://healthychones.com',
    'https://nexusnutrients.com',
  ],
  '2021-08-20-HSX-AMM-nn-002': [
    'https://supernaturalproductics.com',
    'https://healthychones.com',
    'https://nexusnutrients.com',
  ],
  '2022-02-07-HSX-AMM-nn-003': [
    'https://supernaturalproductics.com',
    'https://nexusnutrients.com',
  ],
  '2022-02-07-HSX-AMM-nn-004': [
    'https://supernaturalproductics.com',
    'https://nexusnutrients.com',
  ],
  '2021-08-20-HSX-AMM-pcbd-001': [
    'https://shopsharonann.com',
    'https://belimitless.vip',
    'https://naturalandhealthychoices4u.com',
    'https://naturasbest.com',
    'https://globalcbex.com',
    'https://peersforyou.com',
    'https://bewisebestrong.store',
    'https://mukanya-health.store',
    'https://bioorganicum.com',
    'https://foreverhealthmarket.com',
  ],
  '2022-03-14-CDF-CNM-pcbd-006': ['https://healthdestiny.co'],
  '2022-03-14-CDF-CNM-pcbd-005': ['https://healthdestiny.co'],
  '2022-03-14-CDF-CNM-pcbd-008': ['https://healthdestiny.co'],
  '2022-03-14-CDF-CNM-pcbd-009': ['https://healthdestiny.co'],
  '2021-08-22-CVX-IMM-dg-001': ['https://deterioration.com'],
  '2021-08-22-CVX-IMM-dg-002': ['https://deterioration.com'],
  '2021-08-22-CVX-IMM-dg-003': ['https://deterioration.com'],
  '2021-08-22-CVX-IMM-dg-004': ['https://deterioration.com'],
  '2022-03-14-CDF-CNM-pcbd-015': ['https://backtorootshealth.com'],
  '2021-08-20-HSX-AMM-ve-001': [
    'https://myadvancedhealth.com',
    'https://advancedmedicine.market/store/vitalelements',
  ],
  '2021-08-20-HSX-AMM-ve-002': [
    'https://myadvancedhealth.com',
    'https://advancedmedicine.market/store/vitalelements',
  ],
  '2021-08-20-HSX-AMM-ve-003': [
    'https://myadvancedhealth.com',
    'https://advancedmedicine.market/store/vitalelements',
  ],
  '2021-08-20-HSX-AMM-ve-004': ['https://myadvancedhealth.com'],
  '2021-08-20-HSX-AMM-ve-005': [
    'https://myadvancedhealth.com',
    'https://anelegancetouch.com',
    'https://optimisedhealth.info',
    'https://earthhealthnow.com',
    'https://208keys.com',
    'https://thehealthrelm.com',
    'https://stekara.beauty',
    'https://highvibrationproducts.com',
  ],
  '2021-08-31-HSX-AMM-woo-001': ['https://bamboo-brush.co.uk'],
  '2021-08-20-HSX-AMM-vika-002': [
    'https://urmainstreet.com',
    'https://cosmospawellness.com',
    'https://katchbotanics.com',
    'https://mynaturalself.com',
  ],
  '2022-07-07-ITX-CPM-vrai-007': [],
  '2021-12-10-ITX-CPM-vrai-002': [
    'https://1stinthecrowd.com/',
    'https://vigilantaiprotection.com',
    'https://yumiiko.com',
    'https://cybersecuritypack.com',
    'https://idcyberprotection.com',
    'https://cybersecuretechnologies.com',
    'https://cyberprotectionclub.com',
    'https://shieldprotectiontech.com',
    'https://nexgentitanium.com',
    'https://cybershieldsecurities.com',
    'https://safeidshop.com',
    'https://protecturinfo.com',
    'https://nexgenprivacy.com',
    'https://1stinthecrowd.com',
    'https://privacylockedin.com',
    'https://cyberprivacyforyou.com',
    'https://bolingomarketplace.com',
    'https://whiteoceansecurity.com',
    'https://cyberdefendchampion.com',
    'https://evolutioncybersecurity.com',
    'https://vraisecurity.com',
    'https://cyber-safetynet.com',
  ],
  '2022-05-02-HSF-AMM-AZOH-001': [
    'https://carmshop.com',
    'https://bozennasmarketplace.com',
    'https://elistore.net',
    'https://holisticandpure.com',
  ],
  '2021-12-10-ITX-CPM-vrai-001': [
    'https://safeidshop.com',
    'https://protecturinfo.com',
    'https://nexgenprivacy.com',
    'https://1stinthecrowd.com',
    'https://privacylockedin.com',
    'https://cyberprivacyforyou.com',
    'https://bolingomarketplace.com',
    'https://whiteoceansecurity.com',
    'https://cyberdefendchampion.com',
    'https://evolutioncybersecurity.com',
    'https://vraisecurity.com',
    'https://cyber-safetynet.com',
    'https://vraiguard.com',
  ],
  '2022-07-07-ITX-CPM-vrai-006': [
    'https://vrai.tech',
    'https://bolingomarketplace.com',
    'https://cybersecuritypack.com',
    'https://cybersecuretechnologies.com',
    'https://cyberprotectionclub.com',
    'https://shieldprotectiontech.com',
    'https://nexgentitanium.com',
    'https://cybershieldsecurities.com',
  ],
  '2021-12-10-ITX-CPM-vrai-004': [
    'https://vrai.tech',
    'https://nexgenprivacy.com',
  ],
  '2022-07-07-ITX-CPM-vrai-008': [
    'https://cybersecuretechnologies.com',
    'https://cyberprotectionclub.com',
    'https://shieldprotectiontech.com',
    'https://nexgentitanium.com',
    'https://cybershieldsecurities.com',
  ],
  '2021-08-20-HSX-AMM-sb-003': [
    'https://bloomintohealth.shop',

    'https://healthnana.com',
    'https://blumiewellness.com',
    'https://natureandsimplicity.com',
    'https://2yourhealthnow.com',
    'https://haroldshealthylivingsolutions.biz',
  ],
  '2021-08-20-HSX-AMM-vika-001': ['https://alisonsbeautyandwellness.com'],
  '2021-08-20-HSX-AMM-kp-004': [
    'https://livingwellhelp.net',
    'https://nakdhealthandwellness.com',
    'https://majkearth.com',
  ],
  '2022-06-02-HSF-AMM-TWE-001': ['https://thewellnessenterpriseinc.com'],
  '2022-06-02-HSF-AMM-TWE-002': ['https://thewellnessenterpriseinc.com'],
  '2022-05-02-HSF-AMM-PFT-001': ['https://aptt3xformula.com'],
  '2022-06-06-HSF-AMM-AHA-001': ['https://ahealthyalternatives.com'],
  '2022-06-06-HSF-AMM-AHA-002': ['https://ahealthyalternatives.com'],
  '2022-04-09-HSF-AMM-MKDD-001': ['https://mkdutchdistribution.com'],
  '2022-07-14-HSF-AMM-NV-001': ['https://naturesvibrationsstore.com'],
  '2022-06-01-HSF-AMM-HBT-001': ['https://healthybreathingtoday.com'],
  '2022-06-01-HSF-AMM-HBT-002': ['https://healthybreathingtoday.com'],
  '2022-06-06-HSF-AMM-MMT-001': ['https://marmamanualtherapy.com'],
  '2022-09-09-HSF-10X-10XH-001': [
    'https://genetictestforyou.com',
    'https://yourgeneticsnow.com',
    'https://testyourgenetics.com',
  ],
  '2022-09-09-HSF-10X-10XH-002': [
    'https://lightbedme.com',
    'https://exercisewithoxygen.com',
  ],
  '2022-09-09-HSF-10X-10XH-003': ['https://exercisewithoxygen.com'],
  '2022-09-09-HSF-10X-10XH-004': [
    'https://pemftherapypro.com',
    'https://pemisystems.com',
  ],
  '2022-09-09-HSF-10X-10XH-005': [
    'https://gosuperhumannow.com',
    'https://superhumanpro.com',
  ],
  '2022-09-09-HSF-10X-10XH-006': ['https://yourivtherapies.com'],
  '2021-12-17-CDX-PDM-elisc-001': [
    'https://imedia.market/store/wildeinfluence',
  ],
  '2021-12-17-CDX-PDM-elisc-002': [
    'https://imedia.market/store/wildeinfluence',
  ],
  '2021-12-17-CDX-PDM-elisc-003': [
    'https://imedia.market/store/wildeinfluence',
  ],
  '2022-01-04-CDX-PDM-elisc-004': [
    'https://imedia.market/store/wildeinfluence',
  ],
  '2021-12-03-CVX-IMM-dntclk-001': ['https://imedia.market/store/dontclick'],
  '2021-12-03-CVX-IMM-dntclk-002': ['https://imedia.market/store/dontclick'],
  '2021-12-03-CVX-IMM-dntclk-003': ['https://imedia.market/store/dontclick'],
  '2021-12-08-CDX-PDM-libad-001': [
    'https://imedia.market/store/academyofselfknowledge',
  ],
  '2021-12-08-CDX-PDM-libad-002': [
    'https://imedia.market/store/academyofselfknowledge',
  ],
  '2021-12-08-CDX-PDM-libad-003': [
    'https://imedia.market/store/academyofselfknowledge',
  ],
  '2021-12-13-CVX-IMM-andib-001': [
    'https://imedia.market/store/voicesagainsttrafficking',
  ],
  '2021-12-13-CVX-IMM-andib-002': [
    'https://imedia.market/store/voicesagainsttrafficking',
  ],
  '2022-07-26-CVF-IMM-SP-001': ['https://sweetpeacemusic.com'],
  '2022-06-13-CVF-WEB-PFL-001': ['https://francesfletcher.com'],
  '2022-05-17-CDF-AMM-TPT-001': [
    'https://2plustopicals.com/2-plus-topicals-nourishing-skincare-lotion-nutrition-for-the-skin',
  ],
  '2022-05-17-CDF-AMM-TPT-002': [
    'https://2plustopicals.com/advanced-therapy-lotion-skin-repair',
  ],
  '2022-12-14-CDF-AMM-TPT-003': [
    'https://2plustopicals.com/2-plus-topicals-mineral-mist-hand-spray',
  ],
  '2022-12-14-CDF-AMM-TPT-004': [
    'https://2plustopicals.com/2-plus-topicals-relief',
  ],
  '2022-06-09-HSF-AMM-EOW-001': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-002': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-003': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-004': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-005': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-006': ['https://wizardoils.com'],
  '2022-06-09-HSF-AMM-EOW-007': ['https://wizardoils.com'],
  '2022-11-09-HSF-AMM-MMT-002': ['https://marmamanualtherapy.com'],
};
