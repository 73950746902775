import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useSearchParams } from 'react-router-dom';
import { AdminInputEmail, AdminInputText } from 'components/Admin';

interface SimpleFormProps {
  onSubmitSimpleForm: (data: Record<string, any>) => void;
}

export default function SimpleSignupForm(props: SimpleFormProps): ReactElement {
  const { onSubmitSimpleForm } = props;
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get('email');
  const { control, handleSubmit } = useForm<Record<string, any>>({
    defaultValues: { primaryEmail: emailParam || '' },
  });

  const onSubmit = (data: Record<string, any>) => {
    onSubmitSimpleForm(data);
  };

  return (
    <div className="mb-6 text-black">
      <h1 className="mb-5">Sign up</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminInputText
          name="firstName"
          label="First Name"
          autoComplete="given-name"
          control={control}
          containerClassName="mb-5"
        />
        <AdminInputText
          name="lastName"
          label="Last Name"
          autoComplete="family-name"
          control={control}
          containerClassName="mb-5"
        />
        <AdminInputEmail
          name="primaryEmail"
          autoComplete="email"
          control={control}
          label="email"
          containerClassName="mb-5"
          value={emailParam as string}
        />
        <Button
          label="JOIN NOW"
          className="p-button w-full font-oswald uppercase font-bold"
        />
      </form>
    </div>
  );
}
