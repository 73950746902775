/* eslint-disable react/no-children-prop */
import { Head } from 'components/head/Head';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useContent } from 'utils/useContent';
import { useMarketplace } from 'hooks/useMarketplace';
import { useRef, useEffect, ReactElement, useState } from 'react';
import Footer from 'components/imedia/components/Footer';
import GradientBanner from 'components/imedia/components/GradientBanner';
import IMediaNav from 'components/imedia/components/IMediaNav';
import MarketplaceNav from 'components/nav/MarketplaceNav';
import MyMessage from 'components/imedia/components/MyMessage';
import MyStory from 'components/imedia/components/MyStory';
import MyVision from 'components/imedia/components/MyVision';
import PackagesDetails from 'components/imedia/components/PackagesDetails';
import ReactGA from 'react-ga4';
import Reviews from 'components/imedia/components/Reviews';
import ShareWidget from 'components/imedia/components/ShareWidget';
import TagManager from 'react-gtm-module';
import TopHeader from 'components/imedia/components/TopHeader';
import VideoLanding from 'components/imedia/components/VideoLanding';
import './components/scss/App.scss';
import { useMatomo } from 'matomoReact';
import { getMarketContent } from 'utils/marketContent';

const productStores = [
  { name: 'elderpower', sku: '2021-08-20-HSX-AMM-sb-001', websiteUrl: '' },
  { name: 'vitalelements', sku: '2021-08-20-HSX-AMM-ve-005', websiteUrl: '' },
  { name: 'kepro', sku: '2021-08-20-HSX-AMM-kp-001', websiteUrl: '' },
  { name: 'woobamboo', sku: '2021-08-31-HSX-AMM-woo-001', websiteUrl: '' },
  { name: 'vikas', sku: '2021-08-20-HSX-AMM-vika-001', websiteUrl: '' },
  { name: 'purecbd', sku: '2021-08-20-HSX-AMM-pcbd-001', websiteUrl: '' },
  {
    name: 'restore',
    sku: '2021-08-20-HSX-AMM-pcbd-001',
    websiteUrl: 'https://nexusnutrients.com/',
  },
  {
    name: 'renew',
    sku: '2021-08-20-HSX-AMM-nn-002',
    websiteUrl: 'https://nexusnutrients.com/',
  },
];

export default function Store(): ReactElement {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const data = useContent();
  const { store } = useParams();
  const { trackEvent } = useMatomo();
  const marketplace = useMarketplace();
  const { styleSheet } = getMarketContent();

  const [searchParams] = useSearchParams();
  const searchEntries = Object.fromEntries(searchParams.entries());
  const exchangeAbbr = ['amm', 'imm', 'cpm'];
  const theMarketplace =
    marketplace?.subdomain !== undefined
      ? marketplace?.subdomain
      : 'not a marketplace';

  let testType: string | null = window.localStorage.getItem('abtest');

  if (!testType) {
    testType = Math.random() > 0.5 ? 'A' : 'B';
    window.localStorage.setItem('abtest', testType);
  }

  const toast = useRef<Toast>(null);

  const tagManagerArgs = {
    gtmId: data?.metadata?.googleTagManager,
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    const productStore = productStores.find(ps => ps.name === store);
    if (!data.metadata && productStore) {
      ReactGA.event({
        category: 'AMM Store Redirect to Product or Website',
        action: 'Store to Product or Website redirect',
        label: 'Store to Product or Website redirect',
      });
      trackEvent({
        category: 'AMM Store Redirect to Product or Website',
        action: 'Store to Product or Website redirect',
        name: 'Store to Product or Website redirect',
      });

      if (productStore.websiteUrl) {
        window.location.href = productStore.websiteUrl;
      } else {
        window.location.href = `/product/${productStore.sku}`;
      }
    } else if (!data.metadata) {
      window.location.href = `/`;
    }
  }, [data, store, trackEvent]);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    toast.current?.show({
      severity: 'info',
      className: 'cookies-toast',
      content: (
        <div className="flex flex-column">
          <p className="font-semibold my-0">Cookie Policy</p>
          <p className="my-0">
            We use third-party cookies to personalize your experience. Read our{' '}
            <Link
              to="/privacy-terms"
              className="no-underline text-sm lowercase font-normal letter-spacing-normal"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      ),
      contentClassName: '',
      life: 4000,
    });
  }, []);

  if (!data.metadata) {
    return <></>;
  }

  return (
    <>
      <Head
        link="https://blockchainecosystem.exchange/"
        title={String(data?.metadata?.title) || 'Blockchain Store'}
        description={
          data?.footer?.text ||
          'Our Ecosystem brings Big Data, AI, Compaction, and the Block to the next level while institutionalizing the emerging Blockchain technology. We deliver validity and real value based on finite transactions for Small and Mid Cap Companies. We provide Micro-Entrepreneurs with deep insight into a local portfolio and industry trends in real-time, so they win more often. Our use of the Blockchain helps businesses identify exposures and constraints for optimization and opportunities. Using the best of what is worked in the past, we proliferate success for your future on the Blockchain. We deliver Wall Street Capability with Main Street Practicality across many sectors'
        }
        imageCard={data?.participate?.backgroundImage}
        largeTwitterCard
        children={
          <link
            rel="stylesheet"
            href={
              styleSheet.light
                ? styleSheet.light
                : '/assets/default-light/theme.css'
            }
          />
        }
      />
      <ReactHelmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${data?.metadata?.googleAnalytics}`}
        />
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', "${data?.metadata?.googleAnalytics}");
            `}
        </script>
      </ReactHelmet>
      {exchangeAbbr.includes(theMarketplace) ? (
        <MarketplaceNav />
      ) : (
        <IMediaNav hideItems={searchEntries.referral} />
      )}
      <div id="top" className="flex flex-column relative light">
        <ShareWidget />
        {searchEntries.referral ? (
          <VideoLanding videoID={searchEntries.videoID} />
        ) : (
          <>
            <TopHeader />
            <MyStory />
            <MyVision />
            <MyMessage />
          </>
        )}
        <PackagesDetails />
        <Reviews />
        <GradientBanner />
        <Footer />
        <Toast
          ref={toast}
          position={isMobile ? 'bottom-center' : 'bottom-right'}
        />
      </div>
    </>
  );
}
