import React from 'react';
import { Carousel } from 'primereact/carousel';
import ProductLinked from '../ProductLinked';
import './ProductCarousel.scss';

export default function ProductCarousel({ products, header = '' }) {
  const itemTemplate = product => (
    <ProductLinked key={product} sku={product || ''} type="upsell" />
  );
  return (
    // <div className="product-carousel">
    <Carousel
      className="product-carousel"
      header={<h1>{header}</h1>}
      value={products}
      itemTemplate={itemTemplate}
      numVisible={3}
      numScroll={1}
    />
    // </div>
  );
}
