import { Button } from 'primereact/button';
import { OrderItem, OrderType } from 'types';
import { ReactElement, useState } from 'react';
import { useGetProduct } from 'hooks/magentoHooks';
import { useNavigate } from 'react-router-dom';
import buttonize from 'utils/buttonize';
import dateFormat from 'utils/dateFormat';
import useViewport from 'hooks/useViewport';
import OrderTracking from './OrderTracking';

function OrderImage({ orderItem }: { orderItem: OrderItem }): ReactElement {
  const { product } = useGetProduct({ sku: orderItem.sku });

  const images = product?.media_gallery?.map(image => image?.url) || [];

  return (
    <img
      alt={orderItem.name}
      src={images[0] || ''}
      style={{
        maxWidth: '120px',
        objectFit: 'contain',
        borderRadius: '4px',
        aspectRatio: '1',
      }}
      className="w-full"
    />
  );
}

function VendorName({ orderItem }: { orderItem: OrderItem }): ReactElement {
  const { product } = useGetProduct({ sku: orderItem.sku });
  return (
    <p className="my-2 font-semibold text-sm md:text-base">
      Vendor:
      <span className="font-normal ml-1 text-sm md:text-base">
        {product?.vendor_company}
      </span>
    </p>
  );
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default function Order({
  orderData,
}: {
  orderData: OrderType;
}): ReactElement {
  const { isMobile } = useViewport();
  const [showDetails, setShowDetails] = useState(!isMobile);
  const navigate = useNavigate();

  return (
    <div className="orders bg-bluegray-50 px-0 mt-1 mb-5 mx-0 w-full border-1 border-round border-blue-50">
      <div className="order__header grid mt-0 surface-ground w-full mx-0 border-round-top px-2">
        <div className="col-12 sm:col-2 flex flex flex-row sm:flex-column align-items-center sm:align-items-start">
          <p className="my-1 mr-1 sm:mr-0 inline sm:block font-semibold text-sm md:text-base">
            Order placed
          </p>
          <p className="my-1 inline sm:block flex-1 text-sm md:text-base">
            {dateFormat(orderData.updated_at)}
          </p>
          <i
            className="pi pi-angle-right sm:hidden cursor-pointer px-2"
            {...buttonize(() => setShowDetails(!showDetails))}
          />
        </div>
        {showDetails && (
          <>
            <div className="col-12 sm:col-2 flex sm:block">
              <p className="my-1 mr-1 sm:mr-0 font-semibold text-sm md:text-base">
                Total
              </p>
              <p className="my-1 text-sm md:text-base">
                {orderData.base_total_paid !== undefined
                  ? formatter.format(Number(orderData.base_total_paid)) ||
                    '$0.00'
                  : '$0.00'}
              </p>
            </div>
            <div className="col-12 sm:col-5 flex sm:block">
              <p className="my-1 mr-1 sm:mr-0 font-semibold text-sm md:text-base">
                Order Number
              </p>
              <p className="my-1 text-sm md:text-base">
                {orderData.increment_id}
              </p>
            </div>
            <div className="col-12 sm:col-3 text-left sm:text-right flex sm:block">
              <p className="my-1 mr-1 sm:mr-0 font-semibold text-sm md:text-base">
                Order status
              </p>
              <p className="my-1 capitalize text-sm md:text-base">
                {orderData.status}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="order__items border-round surface-card">
        {orderData.items.map(item => (
          <div
            key={item.item_id}
            className="grid flex-column md:flex-row md:align-items-center pt-3 pb-2 px-4 md:px-0"
          >
            <picture
              className={`col-6 md:col-2 flex md:justify-content-center md:align-items-center ${
                item.is_virtual ? 'cursor-pointer' : ''
              }`}
              {...buttonize(() =>
                item.is_virtual
                  ? navigate(
                      `/digital-content/${item.sku}?orderNumber=${orderData.increment_id}`,
                    )
                  : null,
              )}
            >
              <OrderImage orderItem={item} />
            </picture>
            <div className="flex-1 px-2">
              <p className="my-2 font-semibold text-sm md:text-base">
                {item.name}
              </p>
              <VendorName orderItem={item} />
              <p className="my-2 font-semibold text-sm md:text-base">
                {' '}
                Quantity:
                <span className="font-normal ml-1 text-sm md:text-base">
                  {item.qty_ordered}
                </span>
              </p>
              <p className="my-2 font-semibold text-sm md:text-base">
                {' '}
                Item Price:
                <span className="font-normal ml-1 text-sm md:text-base">
                  ${item.row_total || '0.00'}
                </span>
              </p>
              <p className="my-2 font-semibold text-sm md:text-base">
                Sku:
                <span className="font-normal ml-1 text-sm md:text-base">
                  {item.sku}
                </span>
              </p>
              {orderData.tracks && <OrderTracking orderData={orderData} />}
            </div>
            {item.sku === '2021-12-10-ITX-CPM-vrai-004' ||
            item.sku === '2022-07-07-ITX-CPM-vrai-008' ||
            !!item.is_virtual === true ? (
              <Button
                className="uppercase c-button-primary mr-4"
                label="View content"
                onClick={() =>
                  navigate(
                    `/digital-content/${item.sku}?orderNumber=${orderData.increment_id}`,
                  )
                }
              />
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
