import React, { SetStateAction, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import {
  ProductInterface,
  ProductFieldsFragment,
  CategoryTree,
} from 'graphql/generated/magentoApi';

interface SearchProductProps {
  categoryPaths: string[] | undefined;
  allProducts: Array<ProductFieldsFragment | null>;
  products: ProductFieldsFragment[];
  searchSuggestions: Array<ProductFieldsFragment | null>;
  setFilteredProduct: React.Dispatch<
    SetStateAction<(ProductFieldsFragment | null)[]>
  >;
  setSearchProductAuto: any;
}

export const ProductSearch = ({
  categoryPaths,
  allProducts,
  products,
  searchSuggestions,
  setSearchProductAuto,
  setFilteredProduct,
}: SearchProductProps) => {
  const productSearchTemplate = (item: ProductFieldsFragment) => (
    <div>
      {Number(item.name?.length) > 50
        ? `${item.name?.slice(0, 60).trim()}...`
        : item.name}
    </div>
  );

  const onProductChange = (e: { value: ProductInterface }) => {
    setSearchProductAuto(e.value);
  };

  const searchProduct = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredProducts: (ProductFieldsFragment | null)[] = [];
      const sanatizedSearch = event.query.replace(/[\\/:*?"<>+()?'|]/g, '');
      if (!sanatizedSearch.trim().length) {
        _filteredProducts = [...allProducts];
      } else {
        _filteredProducts = allProducts.filter(
          product =>
            Number(
              product?.name
                ?.toLowerCase()
                .search(sanatizedSearch.toLowerCase()),
            ) > -1 &&
            (product?.categories as CategoryTree[]).some(category =>
              categoryPaths?.includes(category.url_path),
            ),
        );
      }

      setFilteredProduct(_filteredProducts);
    }, 250);
  };

  useEffect(() => {
    const inputSearch = document.getElementById('search-product');
    if (inputSearch) {
      // To fix style bug on small resolutions
      inputSearch.addEventListener('keypress', () => {
        setTimeout(() => {
          const list = document.querySelector(
            '.p-autocomplete-panel.p-component.p-connected-overlay-enter-done',
          ) as HTMLElement;
          const properties = inputSearch.getBoundingClientRect();
          if (list) {
            list.style.left = `${properties.left.toString()}px`;
            list.style.maxWidth = `${properties.width.toString()}px`;
          }
        }, 1000);
      });
    }
  }, []);

  return (
    <div>
      <i
        className="pi pi-search z-5 absolute"
        style={{ top: '1.20rem', left: '1rem' }}
      />
      <AutoComplete
        id="search-product"
        value={products}
        suggestions={searchSuggestions}
        completeMethod={searchProduct}
        field="name"
        onChange={onProductChange}
        className="w-full"
        placeholder="Search a product"
        itemTemplate={productSearchTemplate}
      />
    </div>
  );
};
