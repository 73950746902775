import {
  CategoryTree as CategoryTreeType,
  CategoriesWithParentsDocument,
} from 'graphql/generated/magentoApi';
import { Panel, PanelProps } from 'primereact/panel';
import { ReactElement, useEffect, useState } from 'react';
import {
  Tree,
  TreeEventNodeParams,
  TreeSelectionParams,
} from 'primereact/tree';
import { useClient, Client } from 'urql';
import CategoryDetailCard from 'components/CategoryDetailCard';
import TreeNode from 'primereact/treenode';

async function fetchCategories(
  client: Client,
  parentUid?: string,
): Promise<Category[]> {
  const variables = parentUid ? { parentUids: [parentUid] } : undefined;
  const result = await client
    .query(CategoriesWithParentsDocument, variables)
    .toPromise();
  return result.data.categoryList;
}

function categoriesToTreeNodes(categories: Category[]): TreeNode[] {
  return categories.map(category => {
    const subCategories =
      category.children_count && category.children_count !== '0'
        ? `, sub-categories: ${category.children_count})`
        : ')';
    const label = `${category.name} (products: ${
      category.products?.total_count || 0
    }${subCategories}`;
    return {
      key: category.uid,
      label,
      data: category,
      children: categoriesToTreeNodes(category.children || []),
      leaf: category.children_count === '0',
    };
  });
}

function addNodesToTree(
  tree: TreeNode[],
  key: string,
  nodes: TreeNode[],
): TreeNode[] {
  return tree.map(node => {
    const children = node.children || [];
    const newChildren = node.key === key ? [...children, ...nodes] : children;
    return {
      ...node,
      children: addNodesToTree(newChildren, key, nodes),
    };
  });
}

function categoriesToMap(categories: Category[]): Record<string, Category> {
  return categories.reduce<Record<string, Category>>(
    (previous, category) => ({
      ...previous,
      [category.uid || '']: category,
    }),
    {},
  );
}

export type Category = Partial<CategoryTreeType>;

export default function CategoryTree({ ...props }: PanelProps): ReactElement {
  const client = useClient();
  const [categoryiesById, setCategoriesById] = useState<
    Record<string, Category>
  >({});
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Category | undefined>();

  useEffect(() => {
    setLoading(true);
    fetchCategories(client).then(categories => {
      setNodes(categoriesToTreeNodes(categories));
      setCategoriesById({
        ...categoryiesById,
        ...categoriesToMap(categories),
      });
      setLoading(false);
    });
  }, [categoryiesById, client]);

  const onSelectionChange = (event: TreeSelectionParams) => {
    const category = categoryiesById[event.value as unknown as string];
    setSelectedItem(category);
  };

  const onExpand = (event: TreeEventNodeParams) => {
    const parentId = event.node.data?.uid;
    setLoading(true);
    fetchCategories(client, parentId).then(categories => {
      const childNodes = categoriesToTreeNodes(categories);
      setNodes(addNodesToTree(nodes, parentId, childNodes));
      setCategoriesById({
        ...categoryiesById,
        ...categoriesToMap(categories),
      });
      setLoading(false);
    });
  };

  return (
    <Panel {...props} header="Categories">
      <div className="grid">
        <div className="col-6">
          <h2>Categories</h2>
          <Tree
            value={nodes}
            selectionMode="single"
            onSelectionChange={onSelectionChange}
            onExpand={onExpand}
            loading={loading}
          />
        </div>
        <div className="col-6">
          <CategoryDetailCard item={selectedItem} />
        </div>
      </div>
    </Panel>
  );
}
