import { ReactElement, useEffect, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  CategoriesWithPathsDocument,
  RootCategoriesDocument,
  ProductInterface,
  ProductFieldsFragment,
  CategoryTree,
} from 'graphql/generated/magentoApi';
import { Category } from 'pages/CategoryTree';
import { Client, useClient } from 'urql';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { Sidebar } from 'primereact/sidebar';
import {
  useGetProductsByCategoryPath,
  useGetProductsBySkus,
} from 'hooks/magentoHooks';
import { useMarketplace, useMarketplaceSiteDomain } from 'hooks/useMarketplace';
import ColorParam from 'components/ColorParam';
import Footer from 'components/footer-crwd/Footer';
import { getMarketContent } from 'utils/marketContent';
import { Head } from '../components/head/Head';
import ShopNavBar from './shop-home-page/shop-nav-bar';
import RightSidebarCart from '../components/right-sidebar-cart/RightSidebarCart';
import './ProductList.scss';
import DonateProductCard from './DonateProductCard';

async function fetchCategories(
  client: Client,
  urlPaths: string[],
): Promise<Category[]> {
  if (urlPaths.length) {
    const result = await client
      .query(CategoriesWithPathsDocument, {
        urlPaths,
      })
      .toPromise();
    return result.data.categoryList;
  }
  const result = await client
    .query(RootCategoriesDocument, undefined)
    .toPromise();
  return result.data.categoryList;
}

function buildCategoryPaths(
  pathParam?: string,
  categoriesParam?: string,
  root?: string,
): string[] {
  const paths: string[] = [];
  if (pathParam) {
    paths.push(pathParam);
  }
  if (categoriesParam) {
    paths.push(...categoriesParam.split(','));
  }

  if (!root) {
    return paths;
  }
  if (!paths.length) {
    paths.push(root);
  }
  return paths;
}

function buildSkusArray(skusParam: string | undefined): string[] {
  if (skusParam) {
    return [...skusParam.split(',')];
  }
  return [];
}

export default function DonatePCF(): ReactElement {
  const { rootCategory } = getMarketContent();
  const client = useClient();
  const navigateBase = useNavigate();
  const marketplace = useMarketplace();
  const marketplaceDomain = useMarketplaceSiteDomain();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const perPageParam = searchParams.get('per-page');
  //! temporarily set perPageParam to 300 due to a pagination bug
  const perPage = Number(perPageParam || 300);
  const categoriesParam = searchParams.get('categories') || undefined;
  const skusParam = searchParams.get('skus') || undefined;
  const { products: skuProducts } = useGetProductsBySkus({
    skus: buildSkusArray(skusParam),
  });
  const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
  const [first, setFirst] = useState<number>(perPage * (currentPage - 1));
  const [, setBreadcrumbModel] = useState<MenuItem[]>([]);
  const [, setMenuModel] = useState<MenuItem[]>([
    { label: 'Collections', items: [] },
  ]);

  const [visibleRight, setVisibleRight] = useState<boolean>(false);
  const [categoryPaths, setCategoryPaths] = useState(
    buildCategoryPaths(params['*'], categoriesParam, rootCategory),
  );
  const {
    products,
    totalProducts,
    fetching: fetchingProducts,
  } = useGetProductsByCategoryPath({
    page: currentPage,
    categoryPaths,
    perPage,
    skuProducts,
  });
  const [product, setProduct] = useState<
    Array<ProductFieldsFragment | null> | undefined
  >([]);

  const navigateToPage = (pageNumber: number) => {
    if (pageNumber > 1) {
      searchParams.set('page', `${pageNumber}`);
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  const itemTemplate = (product: ProductInterface) => (
    <DonateProductCard product={product} />
  );

  const onPageChange = ({ page, first }: DataViewPageParams) => {
    setFirst(first);
    setCurrentPage(page + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigateToPage(page + 1);
  };

  useEffect(() => {
    if (marketplace?.categories) {
      navigateBase(`/?categories=${marketplace?.categories}`);
    }
  }, [marketplace?.categories, navigateBase]);

  useEffect(() => {
    const goToFirstPage =
      document.getElementsByClassName('p-paginator-first')[0];
    if (goToFirstPage) {
      goToFirstPage.classList.remove('p-disabled');
      goToFirstPage.removeAttribute('disabled');
      goToFirstPage.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        navigateToPage(1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    setFirst(perPage * (Number(page) - 1));
    setCurrentPage(Number(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setCategoryPaths(
      buildCategoryPaths(params['*'], categoriesParam, rootCategory),
    );
  }, [categoriesParam, params, rootCategory]);

  useEffect(() => {
    const paths = marketplace?.categories
      ? categoryPaths.filter(category => category !== rootCategory)
      : [...categoryPaths];
    fetchCategories(client, paths).then(categories => {
      const firstCategory = categories[0] || {};

      const homeMenuItem = {
        label: 'Home',
        command: () => {
          const path =
            marketplace?.categories || marketplaceDomain?.categories
              ? `/category?categories=${
                  marketplace?.categories || marketplaceDomain?.categories
                }`
              : '/';
          navigateBase(path);
        },
      };

      const fetchedBreadcrumbs = (firstCategory.breadcrumbs?.map(item => {
        if (item.category_url_path === rootCategory) {
          return homeMenuItem;
        }
        return {
          label: item.category_name,
          command: () => navigateBase(`/category/${item.category_url_path}`),
        };
      }) || []) as MenuItem[];

      const menuItems: MenuItem[] = [];
      if (!rootCategory) {
        menuItems.push(homeMenuItem);
      }
      menuItems.push(...fetchedBreadcrumbs);
      if (firstCategory.url_path === rootCategory) {
        menuItems.push(homeMenuItem);
      } else if (firstCategory.url_path) {
        menuItems.push({
          label: firstCategory.name,
          command: () => navigateBase(`/category/${firstCategory.url_path}`),
        });
      }

      setBreadcrumbModel(menuItems);

      const children = categories
        .reduce<Partial<CategoryTree>[]>((previous, category) => {
          category.children?.forEach(child => {
            if (!previous.find(c => c.url_path === child.url_path)) {
              previous.push(child);
            }
          });
          return previous;
        }, [])
        .filter(item => !!item.products?.total_count)
        .map(item => ({
          label: item.name,
          icon: 'pi pi-angle-right',
          command: () => navigateBase(`/category/${item.url_path}`),
        }));
      setMenuModel([{ label: 'Shop Categories', items: [...children] }]);
    });
  }, [
    categoryPaths,
    client,
    marketplace?.categories,
    marketplaceDomain?.categories,
    navigateBase,
    rootCategory,
  ]);

  useEffect(() => {
    const inputSearch = document.getElementById('search-product');
    if (inputSearch) {
      // To fix style bug on small resolutions
      inputSearch.addEventListener('keypress', () => {
        setTimeout(() => {
          const list = document.querySelector(
            '.p-autocomplete-panel.p-component.p-connected-overlay-enter-done',
          ) as HTMLElement;
          const properties = inputSearch.getBoundingClientRect();
          if (list) {
            list.style.left = `${properties.left.toString()}px`;
            list.style.maxWidth = `${properties.width.toString()}px`;
          }
        }, 1000);
      });
    }
  }, []);

  useEffect(() => {
    let filterProducts: any = [...products].filter(
      item => item?.vendor_company === 'Prostate Cancer Foundation',
    ) as ProductFieldsFragment[];

    if (!fetchingProducts && !product?.length) {
      filterProducts = filterProducts.sort(
        (a: ProductFieldsFragment, b: ProductFieldsFragment) => {
          const aValue = a.price_range.maximum_price?.final_price?.value || 0;
          const bValue = b.price_range.maximum_price?.final_price?.value || 0;
          return aValue - bValue;
        },
      );
      setProduct(filterProducts);
    }
  }, [fetchingProducts, product?.length, products]);

  return (
    <>
      <Head
        title={
          categoryPaths[0]
            ? `${categoryPaths[0]
                .split('-')
                .join(' ')
                .replace(/\b\b\w/g, (c: string) => c.toUpperCase())
                .split('/')
                .join(', ')}`
            : 'Prostate Cancer Foundation Donation'
        }
        addPostfixTitle
        description="Prostate Cancer Foundation Donation"
        link="donate-pcf"
        keywords="prostate cancer foundation, donation, blockchain charity"
        imageCard="/images/shop/slider/PCF_AMM.jpg"
        largeTwitterCard
      />
      <ColorParam />
      <ShopNavBar />

      <Sidebar
        className="w-1/5 sm:w-auto"
        visible={visibleRight}
        position="right"
        closeOnEscape
        showCloseIcon={false}
        onHide={() => setVisibleRight(false)}
      >
        <RightSidebarCart setVisibleRight={setVisibleRight} />
      </Sidebar>

      <div
        className="categories grid"
        style={{ maxWidth: '1480px', margin: '0 auto' }}
      >
        <h1 className="text-center mx-auto mb-4" style={{ color: 'black' }}>
          Prostate Cancer <br className="block md:hidden" />
          Foundation Donation
        </h1>
        <div className="flex flex-column md:flex-row mb-3 md:mb-6">
          <img
            src="/images/shop/slider/PCF_AMM.jpg"
            alt=""
            className="w-10 md:w-6 mx-auto mb-2 md:mb-0"
            style={{ objectFit: 'contain' }}
          />

          <div className="w-10 md:w-6 mx-auto px-0 md:px-4">
            <span className="font-bold mb-1" style={{ color: '#333' }}>
              About the Prostate Cancer Foundation
            </span>
            <p>
              The Prostate Cancer Foundation (PCF) is the world’s leading
              philanthropic organization dedicated to funding life-saving
              prostate cancer research. Founded in 1993 by Mike Milken, PCF has
              been responsible for raising close to $1 billion in support of
              cutting-edge research by more than 2,200 research projects at 245
              leading cancer centers in 28 countries around the world. Since
              PCF’s inception, and through its efforts, patients around the
              world are living longer, suffering fewer complications, and
              enjoying better quality of life. PCF is committed to creating a
              global public square for prostate cancer, in service to our
              mission of ending death and suffering from the disease.
            </p>
            <p>
              <span className="font-bold">Tax Deductible: </span>
              The Prostate Cancer Foundation is a 501(c)(3) charitable
              organization. Your donation is tax deductible to the full extent
              of the law. Our tax id is #95-4418411.
            </p>
            <br />
            <a
              href="https://www.pcf.org/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#333' }}
            >
              Learn more at pcf.org
            </a>
          </div>
        </div>
        <h1
          className="text-center mx-auto mb-2 w-full block"
          style={{ color: 'black' }}
        >
          Choose Your <br className="block md:hidden" />
          Donation Amount
        </h1>
        {!product?.length ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataView
              rows={perPage}
              first={first}
              totalRecords={totalProducts}
              onPage={e => onPageChange(e)}
              value={product}
              layout="grid"
              itemTemplate={itemTemplate}
              className="max-w-full col-12 scalein p-0 mb-8"
            />
            <img
              src="/images/shop/donate/PCF_CPT_NOW.jpg"
              alt=""
              className="w-10 md:w-6 mx-auto mb-4"
              style={{ objectFit: 'contain' }}
            />
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
