import React, { useState } from 'react';
import {
  parsePhoneNumberFromString,
  CountryCode,
  getPhoneCode,
} from 'libphonenumber-js';
import { Controller } from 'react-hook-form';
import { buildClassName, validateProps } from '../AdminForm';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import countries from '../../data/countries.json';
import { classNames } from 'primereact/utils';
import { AdminInputTextProps } from './AdminInputText';

export const AdminInputPhone: React.FC<AdminInputTextProps> = props => {
  validateProps(props);
  const {
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    defaultValue,
    isTextArea,
    onBlur,
    rules,
    ...baseProps
  } = props;

  const priorityLabel = attributeType?.label ?? label ?? name;
  const description = attributeType?.description;
  const attributeTypeProps = attributeType?.props;

  const [selectedCountryCode, setSelectedCountryCode] =
    useState<CountryCode>('US');

  const validatePhoneNumber = (number?: string): boolean | string => {
    if (number && selectedCountryCode) {
      const phoneNumber = parsePhoneNumberFromString(
        number,
        selectedCountryCode,
      );

      if (phoneNumber?.country !== selectedCountryCode) {
        return 'Invalid Phone format';
      }

      return phoneNumber?.isValid() ? true : 'Invalid Phone format';
    }
    return true;
  };

  const parsePhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode,
  ): string | undefined => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode,
    );

    return parsedPhoneNumber?.formatInternational();
  };

  const onCountryCodeChange = (
    phoneNumber: string,
    onFieldChange: (newValue: string) => void,
  ) => {
    if (validatePhoneNumber(phoneNumber) !== true) {
      const number = parsePhoneNumberFromString(phoneNumber);
      const newNumber = {
        ...number,
        countryCallingCode: getPhoneCode(selectedCountryCode),
        number: parsePhoneNumberFromString(
          number?.nationalNumber as string,
          selectedCountryCode,
        )?.number,
      };
      if (newNumber) {
        onFieldChange(newNumber.number as string);
      }
    } else {
      onFieldChange(phoneNumber);
    }
  };

  const countryOptionTemplate = (option: {
    code: string;
    name: string;
    flagEmoji: any;
  }) => {
    return (
      <div className="flex align-items-center w-14rem" style={{ gap: '8px' }}>
        {option.flagEmoji}
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? ''}
      name={name}
      rules={{
        required,
        validate: validatePhoneNumber,
        ...attributeType?.validation,
      }}
      render={({ field, fieldState, formState: _formState }) => {
        const errorMessage =
          fieldState.error?.message || fieldState.error?.type;

        return (
          <div className={containerClassName}>
            <div className="p-inputgroup admin-phone-input">
              <Dropdown
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '4.25rem',
                }}
                className={classNames({ 'p-invalid': errorMessage })}
                value={selectedCountryCode}
                options={countries}
                onChange={e => {
                  setSelectedCountryCode(e.value);
                }}
                onBlur={() =>
                  field.value &&
                  onCountryCodeChange(field.value, field.onChange)
                }
                filterBy="name"
                itemTemplate={countryOptionTemplate}
                optionLabel="flagEmoji"
                optionValue="code"
                placeholder="Select a Country Code"
                resetFilterOnHide
                filter
              />
              <div className="p-float-label">
                <InputText
                  {...baseProps}
                  {...attributeTypeProps}
                  className={buildClassName(baseProps.className, errorMessage)}
                  about={description}
                  value={parsePhoneNumber(field.value, selectedCountryCode)}
                  onChange={field.onChange}
                  onBlur={() => {
                    field.onBlur();
                    onBlur?.();
                    field.onChange(field.value.trim());
                  }}
                  ref={field.ref}
                  id={name}
                />
                <label htmlFor={name} className="capitalize">
                  {priorityLabel}
                  {required && ' *'}
                </label>
              </div>
            </div>

            <small id={`${name}-help`} className="p-d-block">
              {helpText}
            </small>
            <small className="p-error p-d-block">{errorMessage}</small>
          </div>
        );
      }}
    />
  );
};
