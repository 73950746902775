import {
  faCcVisa,
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import './MarketplaceFooter.scss';
import { Link } from 'react-router-dom';
import { getMarketContent } from 'utils/marketContent';
import parse from 'html-react-parser';
import useLocalStorage from 'utils/useLocalStorage';
import { classNames } from 'primereact/utils';
import NewsletterSubscription from './NewsletterSubscription';
import SocialIcons from './SocialIcons';

export default function MarketplaceFooter(): ReactElement {
  const market = getMarketContent();
  const marketFooter = market.footer;
  const footerCol = market.footer.column;
  const marketFooterCR = market.copyright;
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);

  return (
    <>
      {marketFooter?.aboutUs && (
        <footer id="marketplace-footer">
          <div className="grid xl:px-0 pb-2 px-4 footer-about-us-section">
            <>
              <div className="col-12 md:col-5 mx-4 pr-4 about-us">
                <div className="">
                  <p className="title">{marketFooter.aboutUs.columnHeader}</p>
                  <p className="mb-1 pr-4">
                    {marketFooter.aboutUs.footerHTMLText}
                  </p>
                </div>
                {marketFooter?.aboutUs?.socialLinks && (
                  <SocialIcons
                    className="marketplace-footer__social-links "
                    socialLinks={marketFooter.aboutUs.socialLinks}
                  />
                )}
              </div>
              {marketFooter.displayNewsletter && (
                <NewsletterSubscription
                  disableForm
                  columnStyle="col-12 md:col-5 pr-4 md:col-offset-1 bout-us"
                />
              )}
            </>
          </div>
        </footer>
      )}
      <div id="bottom-footer">
        <div className="container px-4">
          <div className="footer-nav">
            <div className="grid px-2 md:px-0 mx-2 md:mx-0 pt-4">
              {footerCol.map(col => (
                <div
                  key={col.columnClasses.length * Math.random()}
                  className={classNames(col.columnClasses, {
                    'flex-order-1': marketFooter?.logoFooterStart,
                  })}
                >
                  {col.columnHeader && (
                    <p className="title">{col.columnHeader}</p>
                  )}
                  {col.footerHTMLText && (
                    <p className="mb-0">{col.footerHTMLText}</p>
                  )}
                  {col.footerNavigation && (
                    <ul>
                      {col.footerNavigation.map(linkItem => (
                        <div key={linkItem.url.length * Math.random()}>
                          <li>
                            <Link to={linkItem.url}>
                              {linkItem.label}{' '}
                              {linkItem.image && (
                                <img
                                  src={
                                    isDarkFromLocalStroage
                                      ? linkItem?.imageDark || linkItem.image
                                      : linkItem.image
                                  }
                                  alt="logoLink"
                                  width="148px"
                                  className="col-img"
                                />
                              )}
                            </Link>
                          </li>
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
              {marketFooter.logoFooterLight && (
                <div
                  className={classNames('col-12 xl:col-2', {
                    'flex-order-0': marketFooter?.logoFooterStart,
                  })}
                >
                  <div className="mt-0 mb-0 pb-0 max-w-max mx-auto">
                    <img
                      src={`${
                        isDarkFromLocalStroage
                          ? marketFooter.logoFooterLight
                          : marketFooter.logoFooterDark
                      }`}
                      alt="logo"
                      className="footer-logo"
                      style={{ maxWidth: '160px', minWidth: '120px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid align-items-center">
            <div className="col-12 md:col-8 bottom-footer-links text-center md:text-left">
              <ul>
                {marketFooterCR.secondaryLinks.map(linkItem => (
                  <li key={linkItem.url.length * Math.random()}>
                    <Link to={linkItem.url}>{linkItem.label}</Link>
                  </li>
                ))}
              </ul>
              <p className="my-0">{parse(marketFooterCR.htmlLine1)}</p>
              <p className="my-0 text-xs">
                <span className={`${marketFooterCR?.yearStyleClass} mr-2`}>
                  © {new Date().getFullYear()} {parse(marketFooterCR.htmlLine2)}
                </span>
              </p>
            </div>

            <div className="col-12 md:col-4 footer-payment-systems justify-content-center md:justify-content-end">
              <FontAwesomeIcon icon={faCcVisa} />
              <FontAwesomeIcon icon={faCcMastercard} />
              <FontAwesomeIcon icon={faCcDiscover} />
              <FontAwesomeIcon icon={faCcAmex} />
              {/* TODO: Convert declared import to class list */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
