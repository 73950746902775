import { Fade } from 'react-awesome-reveal';
import { HashLink } from 'react-router-hash-link';
import { ReactElement } from 'react';
import { useContent } from 'utils/useContent';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import './scss/MyStory.scss';
import { useMatomo } from 'matomoReact';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';

export default function MyStory(): ReactElement {
  const data = useContent();
  const { trackEvent } = useMatomo();

  return (
    <section
      id="my-story"
      className="my-story flex flex-column justify-content-center align-items-center px-4 p-m80-d100"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <div className="flex flex-column md:flex-row align-items-center md:align-items-start">
        <div className="my-story__left mr-0 md:mr-8 mt-5 md:mt-0 flex-order-2 md:flex-order-0">
          <h3 className="text-5xl font-normal mb-6 capitalize story-top-margin">
            {data.participate.plural ? 'Our Story' : 'My Story'}
          </h3>
          {data.story.text && (
            <div className="my-story__text">
              <Fade triggerOnce direction="up">
                {parse(data.story.text)}
              </Fade>
            </div>
          )}
          <Fade triggerOnce direction="up">
            <div className="flex flex-wrap flex-column md:flex-row align-items-start justify-content-start">
              <HashLink
                to="#products"
                id="MyStory-primary-action"
                className="w-full md:w-auto bg-transparent border-1 px-6 py-3 cursor-pointer no-underline text-center button--blue--filled"
                style={{
                  borderRadius: '165px',
                  letterSpacing: '0.2em',
                  color: 'var(--c-blue)',
                }}
                smooth
                onClick={() => {
                  ReactGA.event({
                    category: 'landing-page',
                    action: 'Landing Page - MyStory-primary-action',
                    label: 'Landing Page - MyStory-primary-action',
                  });
                  trackEvent({
                    category: 'landing-page',
                    action: 'Landing Page - MyStory-primary-action',
                    name: 'Landing Page - MyStory-primary-action',
                  });
                }}
              >
                SEE PRODUCTS
              </HashLink>
              <HashLink
                to="#my-vision"
                id="MyStory-secondary-action"
                className="ml-0 md:ml-3 lg:ml-6 mt-4 md:mt-0 w-full md:w-auto bg-transparent border-none px-4 py-3 cursor-pointer no-underline text-center text--link--blue"
                style={{
                  borderRadius: '165px',
                  letterSpacing: '0.2em',
                  color: 'var(--c-blue)',
                }}
                smooth
                onClick={() => {
                  ReactGA.event({
                    category: 'landing-page',
                    action: 'Landing Page - MyStory-secondary-action',
                    label: 'Landing Page - MyStory-secondary-action',
                  });
                  trackEvent({
                    category: 'landing-page',
                    action: 'Landing Page - MyStory-secondary-action',
                    name: 'Landing Page - MyStory-secondary-action',
                  });
                }}
              >
                LEARN MORE
              </HashLink>
            </div>
          </Fade>
        </div>
        {!!data.story.video && (
          <Fade triggerOnce>
            <div
              className="my-story__right mb-6 md:mb-0"
              style={{ height: '580px' }}
            >
              <VimeoPlayer
                url={`https://vimeo.com/${data ? data.story.video : 0}`}
                isVertical
              />
            </div>
          </Fade>
        )}
      </div>
    </section>
  );
}
