import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import sha256 from 'crypto-js/sha256';
import { AdminInputEmail, AdminInputText } from 'components/Admin';
import { scrollToFirstFormError } from 'utils/helper';
import { useUserContext } from 'UserContext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { FormTriggerRef } from './MemmSteps';
import '../SignUpForm.scss';

export interface Step1TokenFormProps {
  data: TokenFormFields | undefined;
  setShowNext: (show: boolean) => void;
}

export interface TokenFormFields {
  email: string;
  token: string;
  hasEmailedToken: boolean;
}

export default React.forwardRef<
  FormTriggerRef<TokenFormFields | undefined>,
  Step1TokenFormProps
>(({ data, setShowNext }, ref) => {
  const [loading, setLoading] = useState(false);
  const { control, trigger, setValue, watch, setError, formState } =
    useForm<TokenFormFields>({
      mode: 'all',
      defaultValues: data,
    });
  const { sdkClient } = useUserContext();
  const { errors } = formState;

  const values = watch();

  const messages = useRef<Messages>(null);

  useImperativeHandle(ref, () => ({
    trigger: async () => {
      const isValid = await trigger();
      if (isValid && values.token) {
        return values;
      }
      if (!values.token) {
        setError('email', {
          message: 'You must generate your token from your email',
        });
      }
      return undefined;
    },
  }));

  const handleGenerateToken = async (): Promise<string> => {
    const isValidEmail = await trigger('email');
    setValue('hasEmailedToken', false);
    scrollToFirstFormError(errors);
    if (isValidEmail) {
      const newToken = sha256(values.email).toString();
      setValue('token', newToken);
      return newToken;
    }
    return '';
  };

  const handleEmailToken = async () => {
    const token = await handleGenerateToken();
    if (token) {
      setLoading(true);
      await sdkClient
        .submitEmail({
          input: { email: values.email, submitType: '10xAffiliateTrackingId' },
        })
        .then(res => {
          if (res.submitEmail?.data) {
            setValue('hasEmailedToken', true);
            setShowNext(true);
          }
        })
        .catch(err => {
          messages.current?.show({
            severity: 'error',
            content: err.message,
            sticky: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return undefined;
  };

  useEffect(() => {
    if (values.hasEmailedToken) {
      trigger();
    }
  }, [values.hasEmailedToken, trigger]);

  return (
    <div>
      {!!messages && <Messages ref={messages} />}

      <div className="grid">
        <div className="col-12 md:col-8">
          <AdminInputEmail
            label="Email"
            name="email"
            autoComplete="email"
            helpText="Enter your email to start your registration and generate your referral verification code."
            control={control}
            required
          />
        </div>
        <div className="col-12 md:col-4">
          <Button
            className="p-button w-full font-oswald uppercase"
            type="button"
            label="Generate Verification Code"
            onClick={handleGenerateToken}
          />
        </div>
        {!!values.token && (
          <>
            <div className="col-12 md:col-8">
              <AdminInputText
                label="Remember Verification Code"
                name="token"
                helpText="Email yourself this verification code for your records."
                control={control}
                disabled
                rules={{
                  validate: value => {
                    if (!value) {
                      return 'You must generate your verification code from your email';
                    }
                    if (!values.hasEmailedToken) {
                      return 'You must email yourself your verification code';
                    }
                    return true;
                  },
                }}
              />
            </div>
            <div className="col-12 md:col-4">
              <Button
                className="p-button w-full font-oswald uppercase"
                type="button"
                label={
                  values.hasEmailedToken
                    ? 'Resend Email'
                    : 'Email Verification Code'
                }
                disabled={!values.token}
                loading={loading}
                onClick={handleEmailToken}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
});
