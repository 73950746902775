import { Link, useNavigate } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import { ReactElement, useEffect, useState, useRef } from 'react';
import { useCartContext } from 'CartContext';
import { useMarketplace } from 'hooks/useMarketplace';

import menuItems from 'components/nav/marketplaceMenuItems';
import './MarketplaceNav.scss';
import { useUserContext } from 'UserContext';

export default function MarketplaceNav(): ReactElement {
  const { quantity } = useCartContext();
  const navigate = useNavigate();
  const [model, setModel] = useState<MenuItem[]>(menuItems);
  const logoLi = useRef<HTMLLIElement>(null);
  const cartLi = useRef<HTMLLIElement>(null);
  const marketplace = useMarketplace();
  const [isShrunk, setShrunk] = useState<boolean>(false);
  const { login, logout, userId, userEmail } = useUserContext();

  useEffect(() => {
    const handler = () => {
      setShrunk(isShrunk => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 80 ||
            document.documentElement.scrollTop > 80)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  const loggedInItems = [
    {
      label: 'Orders',
      command: () => navigate('/orders'),
    },
    {
      label: 'Logout',
      command: () => logout(window.location.pathname),
    },
  ];

  const loggedOutItems = [
    {
      label: 'Login',
      command: () => login(),
    },
  ];

  useEffect(() => {
    if (userId) {
      setModel([
        ...menuItems,
        {
          label: userEmail || 'User',
          icon: 'pi pi-user',
          items: loggedInItems,
          className: 'nav-email-item',
        },
      ]);
    } else {
      setModel([...menuItems, ...loggedOutItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const hamburger = document.getElementsByClassName(
      'p-menubar-button',
    )[0] as HTMLLinkElement;
    if (hamburger) {
      hamburger.innerHTML = `<div class="hamburger-icon"></div>`;
    }
  }, []);

  return (
    <div
      className={`marketplace-nav max-w-full ${
        isShrunk ? 'py-0 md:py-2' : 'py-2 md:py-3'
      }`}
    >
      <ul
        className="flex flex-row m-0 p-0 list-none w-full"
        style={{ backgroundColor: '#1c1c1c' }}
      >
        <li
          ref={logoLi}
          className="flex align-items-center px-3 py-2"
          style={{ zIndex: 1000 }}
        >
          <a href="/">
            <img
              src={marketplace?.logo}
              alt="Interactive Medias Marketplace"
              className={`marketplace-logo ${
                isShrunk ? 'down-size' : 'full-size'
              }`}
            />
          </a>
        </li>
        <li style={{ zIndex: 999 }}>
          <Menubar className="user-menubar" model={model} />
        </li>
        <li
          ref={cartLi}
          className="flex align-items-center pt-1 px-2 cart-item"
          style={{ zIndex: 1000 }}
        >
          <div className="cart-wrapper">
            <Link to="/cart">
              <i
                className="pi pi-fw pi-shopping-cart"
                style={{ color: 'white', transform: 'scale(1.3)' }}
              />
              <span className={`${Number(quantity) === 0 ? 'hide' : ''}`}>
                {quantity}
              </span>
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
}
