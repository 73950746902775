/* eslint-disable react-hooks/exhaustive-deps */

import {
  CartFieldsFragment,
  GetProductsDocument,
  GetProductsQuery,
  ProductFieldsFragment,
  ProductsForCategoryPathsQueryVariables,
  useCreateEmptyCartMutation,
  useGetCartQuery,
  useGetProductQuery,
  useGetProductsBySkusQuery,
  useProductsForCategoryPathsQuery,
} from 'graphql/generated/magentoApi';
import { createClient } from 'urql';
import { useCallback, useEffect, useRef, useState } from 'react';
import cacheProductsData from 'fetchedProducts.json';

const magentoGraphql = `${process.env.REACT_APP_MAGENTO_URL}/graphql`;
const client = createClient({
  url: magentoGraphql,
});

export const useCartId = (): {
  cartId: string;
  fetching: boolean;
  reset: () => void;
} => {
  const [cartId, setCartId] = useState<string>(
    localStorage.getItem('cartId') || '',
  );

  const firstRender = useRef<boolean>(true);

  const [createEmptyCartState, executeCreateEmptyCartMutation] =
    useCreateEmptyCartMutation();

  const createEmptyCart = useCallback(async () => {
    const result = await executeCreateEmptyCartMutation({});
    const cartId = result.data?.createEmptyCart;
    if (cartId) {
      setCartId(cartId);
      localStorage.setItem('cartId', cartId);
    }
  }, [executeCreateEmptyCartMutation]);

  useEffect(() => {
    if (!cartId && firstRender.current) {
      createEmptyCart();
      firstRender.current = false;
    }
  }, [cartId, createEmptyCart]);

  const reset = () => {
    setCartId('');
    localStorage.removeItem('cartId');
    createEmptyCart();
  };

  return { cartId, fetching: createEmptyCartState.fetching, reset };
};

type UseCartReturn = {
  cart?: CartFieldsFragment;
  cartFetching: boolean;
  fetchCart: () => void;
};

export const useGetCart = (): UseCartReturn => {
  const { cartId, fetching: cartIdFetching, reset } = useCartId();

  const [cart, setCart] = useState<CartFieldsFragment | undefined>();

  const [cartState, fetchCart] = useGetCartQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { cart_id: cartId! },
  });

  useEffect(() => {
    const cart = cartState.data?.cart;
    if (cartId && cart) {
      if (cartId !== cart.id) {
        fetchCart();
      } else {
        setCart(cart);
      }
    } else if (!cartId) {
      setCart(undefined);
    } else if (
      cartId &&
      cartState.error?.message.includes('Could not find a cart with ID')
    ) {
      reset();
    }
  }, [cartId, cartState]);

  return {
    cart,
    cartFetching: cartState.fetching || cartIdFetching,
    fetchCart,
  };
};

interface UseGetProductsByCategoryPathParams {
  categoryPaths?: string[];
  page?: number;
  perPage?: number;
  skuProducts?: ProductFieldsFragment[];
}

interface UseGetProductsByCategoryPathReturn {
  products: ProductFieldsFragment[];
  fetching: boolean;
  totalProducts: number;
}

export const useGetProductsByCategoryPath = (
  params: UseGetProductsByCategoryPathParams,
): UseGetProductsByCategoryPathReturn => {
  const { categoryPaths, page, perPage, skuProducts } = params;
  const currentPage = page || 1;
  const pageSize = perPage || 9;
  const paths = categoryPaths || [];

  let cacheProducts: ProductFieldsFragment[] = [];
  let cacheTotal = 0;
  let initialProducts: ProductFieldsFragment[] = [];

  if (cacheProductsData.length) {
    if (paths.length) {
      initialProducts = (cacheProductsData.filter(product =>
        product.categories.some(category => paths.includes(category.url_path)),
      ) || []) as unknown as ProductFieldsFragment[];
    } else {
      initialProducts = cacheProductsData.filter(
        product => !!product.categories.length,
      ) as unknown as ProductFieldsFragment[];
    }
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    const sorted = Object.values(initialProducts).sort((a, b) =>
      (a.name || '').localeCompare(b.name || ''),
    );
    cacheTotal = initialProducts.length;
    cacheProducts = sorted.slice(start, end);
  }

  const [products, setProducts] = useState<
    Record<string, ProductFieldsFragment>
  >({});

  const [productsPage, setProductsPage] = useState<ProductFieldsFragment[]>([]);
  const [totalProducts, setTotalProducts] = useState<number>(0);

  // TODO: logic to update API pages as needed
  const [apiPage] = useState(1);
  const [apiPageSize] = useState(300);

  const variables: ProductsForCategoryPathsQueryVariables = {
    paths,
    currentPage: apiPage,
    pageSize: apiPageSize,
    getDefault: paths.length === 0,
  };
  const [productsForCategoryState] = useProductsForCategoryPathsQuery({
    variables,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fetching, data, error } = productsForCategoryState;

  useEffect(() => {
    const categories = data?.categoryList || data?.default || [];
    if (!fetching && categories.length) {
      const newProducts: Record<string, ProductFieldsFragment> = {};
      categories.forEach(category => {
        const items: any[] = category?.products?.items || [];
        items.forEach(item => {
          newProducts[item.sku] = item;
        });
      });
      setProducts(existingProducts => ({
        ...existingProducts,
        ...newProducts,
      }));
    } else {
      setTotalProducts(0);
      setProducts({});
    }
  }, [data, fetching]);

  useEffect(() => {
    setTotalProducts(Object.keys(products).length);
  }, [Object.keys(products).length]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    const sorted = Object.values(products).sort((a, b) =>
      (a.name || '').localeCompare(b.name || ''),
    );
    setProductsPage(sorted.slice(start, end));
  }, [products, currentPage, pageSize]);

  const total = fetching || !productsPage.length ? cacheTotal : totalProducts;
  // + (skuProducts?.length ? skuProducts.length : 0);

  function getProducts(): ProductFieldsFragment[] {
    let filledProducts: ProductFieldsFragment[] = [];
    const categoryProducts = (
      fetching || !productsPage.length ? cacheProducts : productsPage
    ).filter(
      product =>
        !(skuProducts || [])
          .map(skuProduct => skuProduct.sku)
          .includes(product.sku),
    );

    filledProducts = [
      ...filledProducts,
      ...(currentPage === 1 ? skuProducts || [] : []),
      ...categoryProducts,
    ];
    // const rest = total - filledProducts.length;

    return filledProducts;
  }
  return {
    fetching,
    products: getProducts(),
    totalProducts: total,
  };
};

type UseGetProductsBySkuReturn = {
  fetching: boolean;
  products: ProductFieldsFragment[];
};

type UseGetProductMapBySkuReturn = {
  fetching: boolean;
  products: Record<string, ProductFieldsFragment>;
};

export const useGetProductMapBySkus = ({
  skus,
}: {
  skus?: string[];
}): UseGetProductMapBySkuReturn => {
  const { fetching, products } = useGetProductsBySkus({ skus });

  const productMap =
    products?.reduce<Record<string, ProductFieldsFragment>>(
      (previous, current) => ({
        ...previous,
        [current.sku]: current,
      }),
      {},
    ) || {};

  return {
    fetching,
    products: productMap,
  };
};

export const useGetProductsBySkus = ({
  skus,
}: {
  skus?: string[];
}): UseGetProductsBySkuReturn => {
  const cacheProducts = cacheProductsData.filter(product =>
    skus?.includes(product.sku),
  ) as unknown as ProductFieldsFragment[];
  const [products, setProducts] =
    useState<ProductFieldsFragment[]>(cacheProducts);
  const [{ fetching, data, error }] = useGetProductsBySkusQuery({
    variables: {
      skus: skus || [],
    },
  });

  useEffect(() => {
    if (!fetching && !error && data?.products?.items) {
      setProducts(data?.products?.items as unknown as ProductFieldsFragment[]);
    }
  }, [data]);

  if (!skus) {
    return {
      fetching: false,
      products: [],
    };
  }

  return {
    fetching,
    products: skus
      .map(
        sku => products.find(item => item.sku === sku) as ProductFieldsFragment,
      )
      .filter(product => !!product?.sku),
  };
};

export type UseGetProductsReturn = {
  fetching: boolean;
  products: ProductFieldsFragment[];
};

export const useGetProducts = (): UseGetProductsReturn => {
  const cacheProducts = (cacheProductsData ||
    []) as unknown as ProductFieldsFragment[];
  const [fetching, setFetching] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductFieldsFragment[]>([]);

  const pageSize = 20; // Maximum pageSize is 300
  let currentPage = 1;
  let totalPages = 0;

  const GetProducts = useCallback(() => {
    client
      .query(GetProductsDocument, { pageSize, currentPage })
      .toPromise()
      .then(result => {
        if (result.data) {
          const response = result.data as GetProductsQuery;
          const { products } = response;
          if (products) {
            const { page_info, items } = products;
            currentPage++;
            totalPages = page_info?.total_pages || 0;
            setProducts(prev => [
              ...prev,
              ...(items as ProductFieldsFragment[]),
            ]);
            if (currentPage <= totalPages) {
              GetProducts();
            } else {
              setFetching(false);
            }
          } else {
            setFetching(false);
          }
        }
      })
      .catch(queryError => {
        setFetching(false);
        console.log(queryError);
      });
  }, []);

  useEffect(() => {
    GetProducts();
  }, [GetProducts]);

  return {
    fetching,
    products: fetching || !products.length ? cacheProducts : products,
  };
};

type UseGetProductReturn = {
  fetching: boolean;
  product: ProductFieldsFragment | undefined;
};

export const useGetProduct = ({
  sku,
}: {
  sku: string;
}): UseGetProductReturn => {
  const cacheProduct = cacheProductsData.find(
    product => product.sku === sku,
  ) as unknown as ProductFieldsFragment | undefined;
  const [product, setProduct] = useState<ProductFieldsFragment | undefined>(
    cacheProduct,
  );
  const [{ fetching, data, error }] = useGetProductQuery({
    variables: {
      sku,
    },
  });

  useEffect(() => {
    setProduct(cacheProduct);
  }, [cacheProduct, sku]);

  useEffect(() => {
    if (!error && data?.products?.items) {
      setProduct(data.products.items[0] as unknown as ProductFieldsFragment);
    }
  }, [data, error]);

  return {
    fetching,
    product,
  };
};
