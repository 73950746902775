import { Button } from 'primereact/button';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProductFieldsFragment } from 'graphql/generated/magentoApi';
import { RadioButton } from 'primereact/radiobutton';
import { ReactElement, useState, useRef, useEffect, useCallback } from 'react';
import { Toast } from 'primereact/toast';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';
import { useGetProducts } from 'hooks/magentoHooks';
import MarketPlaceComponentPage from 'pages/marketplace-component-page';
import './ButtonGenerator.scss';
import { Link, useSearchParams } from 'react-router-dom';
import {
  crwdMarketMatomoUrl,
  defaultDomains,
  ModalBaseUrlInterface,
  modalBaseUrlOptions,
  selectedModalBaseOptionTemplate,
  useLoadProductPopupJs,
} from './buttonGenerator';

export default function SkuExample(): ReactElement {
  const [affiliateValue, setAffiliateValue] = useState<string>('');
  const [buttonBorder, setButtonBorder] = useState<string>('none');
  const [buttonClass, setButtonClass] = useState<string>('');
  const [buttonColor, setButtonColor] = useState<string>('#f6b73c');
  const [buttonRadius, setButtonRadius] = useState<string>('0px');
  const [buttonTextColor, setButtonTextColor] = useState<string>('#000000');
  const [buttonWeight, setButtonWeight] = useState<string>('normal');
  const [searchParams] = useSearchParams();
  const SKUParam = searchParams.get('sku') || undefined;
  const [skuValue, setSkuValue] = useState(
    SKUParam || '2021-08-20-HSX-AMM-ve-002',
  );
  const [product, setProduct] = useState<ProductFieldsFragment | undefined>();
  const [currentModalBase, setCurrentModalBase] =
    useState<ModalBaseUrlInterface>({
      name: 'crwd market',
      url: '',
      matomoSiteId: '',
      domains: defaultDomains,
      matomoUrl: crwdMarketMatomoUrl,
    });
  const selectedButtonTypeOptions = ['Buy Now Flow', 'Add To Cart flow'];
  const [SelectedButtonType, setSelectedButtonType] = useState('Buy Now Flow');
  const isBuyNowFlow = SelectedButtonType === 'Buy Now Flow';
  const [buttonText, setButtonText] = useState<string>('Buy Now');
  const toast = useRef<Toast>(null);
  const { products } = useGetProducts();
  const cdnEmbed = useLoadProductPopupJs(
    currentModalBase.url,
    currentModalBase.matomoSiteId,
    currentModalBase.domains,
    currentModalBase.matomoUrl,
  );

  const buyNowButtonTextOptions = [
    { label: 'Buy Now', value: 'Buy Now' },
    { label: 'Order Now', value: 'Order Now' },
  ];

  const addToCartButtonTextOptions = [
    { label: 'Order Online', value: 'Order Online' },
    { label: 'Shop Now', value: 'Shop Now' },
    { label: 'Add To Cart', value: 'Add To Cart' },
  ];

  const onButtonTextChange = (e: { value: any }) => {
    setButtonText(e.value);
  };

  useEffect(() => {
    document.body.classList.add('button-generator');
  }, []);

  useEffect(() => {
    if (product && product.sku) {
      setSkuValue(product.sku);
    }
  }, [product]);

  useEffect(() => {
    const dropdown = document.getElementById('product');
    if (dropdown) {
      // To fix style bug on small resolutions
      dropdown.addEventListener('click', () => {
        setTimeout(() => {
          const list = document.querySelector(
            '.p-dropdown-panel.p-component.p-connected-overlay-enter-done',
          ) as HTMLElement;
          const properties = dropdown.getBoundingClientRect();
          if (list) {
            list.style.left = `${properties.left.toString()}px`;
            list.style.maxWidth = `${properties.width.toString()}px`;
          }
        }, 250);
      });
    }
  }, []);

  const handleProductModal = useCallback(() => {
    // eslint-disable-next-line no-undef
    isBuyNowFlow && buyNowModal?.(skuValue, affiliateValue);
    // eslint-disable-next-line no-undef
    !isBuyNowFlow && addToCartModal?.(skuValue, affiliateValue);
  }, [affiliateValue, skuValue, isBuyNowFlow]);

  function copyText(text: string) {
    navigator.clipboard.writeText(text);
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied text to clipboard',
      contentClassName: '',
    });
  }
  function copyEmbed() {
    navigator.clipboard.writeText(
      `<button onclick="${
        isBuyNowFlow ? 'buyNowModal' : 'addToCartModal'
      }('${skuValue}', '${affiliateValue}');" style="padding: 8px 16px; background-color: ${buttonColor}; color: ${buttonTextColor}; border-radius: ${buttonRadius}; ${
        buttonWeight === 'bold' ? 'font-weight: bold;' : ''
      } ${
        buttonBorder === 'none'
          ? 'border: none;'
          : `border: ${buttonBorder} solid ${buttonTextColor};`
      }" class="${buttonClass}">${buttonText}</button>`,
    );
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied embed code to clipboard',
      contentClassName: '',
    });
  }

  const onProductChange = (e: { value: ProductFieldsFragment }) => {
    setProduct(e.value);
  };

  const selectedProductTemplate = (
    option: ProductFieldsFragment,
    props: DropdownProps,
  ) => {
    const { placeholder } = props;
    if (option) {
      return (
        <div className="flex">
          <img
            alt={option.name || ''}
            src={option.image?.url || ''}
            height="40px"
            width="40px"
            style={{ objectFit: 'cover', borderRadius: '4px' }}
            className="mr-3"
          />
          <div className="white-space-normal flex align-items-center">
            {option.name}
          </div>
        </div>
      );
    }

    return <span>{placeholder}</span>;
  };

  const productOptionTemplate = (option: ProductFieldsFragment) => (
    <div className="flex">
      <img
        alt={option.name || ''}
        src={option.image?.url || ''}
        height="40px"
        width="40px"
        style={{ objectFit: 'cover', borderRadius: '4px' }}
      />
      <div className="ml-3 white-space-normal flex align-items-center">
        {`${option.name} / ${option.sku}`}
      </div>
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      modalBaseUrlOptions.map(modalBaseUrlOption => {
        if (modalBaseUrlOption.url === window.location.origin) {
          setCurrentModalBase({
            ...modalBaseUrlOption,
          });
        }
        return modalBaseUrlOption;
      });
    }, 2000);
  }, []);

  const onModalBaseUrlChange = (e: { value: ModalBaseUrlInterface }) => {
    setCurrentModalBase({ ...e.value });
  };

  const modalBaseOptionTemplate = (option: ModalBaseUrlInterface) => (
    <div className="flex flex-column">
      <div className="ml-3 white-space-normal flex align-items-center">
        {`${option.name}`}
      </div>
      <div className="ml-3 white-space-normal flex align-items-center">
        {`${option.url}`}
      </div>
    </div>
  );

  return (
    <>
      <MarketPlaceComponentPage />
      <section className="flex flex-column justify-content-center align-items-center relative w-full">
        <h2 className="mb-3 text-primary w-full text-center ">
          Buy Now Button Generator
        </h2>
        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-12 xl:w-8 mb-3">
          <section className="button-options">
            <div className="p-inputgroup flex flex-column">
              <label htmlFor="marketplace-dropdown" className="font-bold">
                Marketplace
              </label>
              <div className="w-full">
                <Dropdown
                  id="marketplace-dropdown"
                  optionLabel="name"
                  value={currentModalBase}
                  options={modalBaseUrlOptions}
                  onChange={onModalBaseUrlChange}
                  valueTemplate={selectedModalBaseOptionTemplate}
                  itemTemplate={modalBaseOptionTemplate}
                  placeholder="Select a Marketplace"
                  className="w-full mb-3 relative"
                  required
                />
              </div>

              {!SKUParam && (
                <>
                  <label htmlFor="product" className="font-bold">
                    Product
                  </label>
                  <div className="w-full">
                    <Dropdown
                      id="product"
                      value={product}
                      options={products}
                      onChange={onProductChange}
                      optionLabel="sku"
                      filter
                      showClear
                      filterBy="name"
                      placeholder="Select a Product"
                      valueTemplate={selectedProductTemplate}
                      itemTemplate={productOptionTemplate}
                      className="w-full mb-3 relative"
                    />
                  </div>
                </>
              )}

              <label htmlFor="skuCode" className="font-bold">
                Product SKU
              </label>
              <InputText
                id="skuCode"
                value={skuValue}
                onChange={e => setSkuValue(e.target.value)}
                className="w-full mb-3 disabled"
                disabled={!!(SKUParam && SKUParam)}
              />

              <label htmlFor="affiliateCode" className="font-bold">
                Affiliate Marketing Code
              </label>
              <InputText
                id="affiliateCode"
                value={affiliateValue}
                onChange={e => setAffiliateValue(e.target.value)}
                className="w-full mb-3"
                placeholder="123456"
              />

              <label
                htmlFor="buttonTextDropdown"
                className="flex align-items-center mb-1 font-bold"
              >
                <span>Button Type Selection</span>
                <Button
                  type="button"
                  label=""
                  icon="pi pi-info-circle"
                  className="bg-transparent text-primary outline ml-2"
                  tooltip={
                    isBuyNowFlow === true
                      ? 'Action: Adds item to cart and lands on checkout page'
                      : 'Action: Adds item to cart and lands on product page so a customers can keep shopping'
                  }
                />
              </label>
              <div className="w-full">
                <SelectButton
                  id="buttonTextDropdown"
                  value={SelectedButtonType}
                  options={selectedButtonTypeOptions}
                  onChange={e => setSelectedButtonType(e.value)}
                  className="initial mb-3 surface-0"
                />
                <Tooltip target=".tooltip-button" autoHide={false} />
              </div>

              <label htmlFor="button-text-dropdown" className="font-bold">
                {isBuyNowFlow
                  ? 'Buy Now Flow Text Options'
                  : 'Add To Cart Flow Text Options'}
              </label>

              <Dropdown
                value={buttonText}
                options={
                  isBuyNowFlow
                    ? buyNowButtonTextOptions
                    : addToCartButtonTextOptions
                }
                onChange={onButtonTextChange}
                optionLabel="label"
                placeholder="Button Text Options"
                id="button-text-dropdown"
                className="w-full mb-3"
              />

              <label htmlFor="buttonClass" className="font-bold">
                CSS Button Class
              </label>
              <InputText
                id="buttonClass"
                value={buttonClass}
                onChange={e => setButtonClass(e.target.value)}
                className="w-full mb-3 "
                placeholder="btn btn-primary"
              />

              <p className="m-0 font-bold">Button Radius</p>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="radius1"
                  name="radius"
                  value="0px"
                  onChange={e => setButtonRadius(e.value)}
                  checked={buttonRadius === '0px'}
                />
                <label htmlFor="radius1" className="ml-2">
                  Corner
                </label>
              </div>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="radius3"
                  name="radius"
                  value="8px"
                  onChange={e => setButtonRadius(e.value)}
                  checked={buttonRadius === '8px'}
                />
                <label htmlFor="radius3" className="ml-2">
                  Rounded
                </label>
              </div>
              <div className="p-field-radiobutton mb-3">
                <RadioButton
                  inputId="radius2"
                  name="radius"
                  value="32px"
                  onChange={e => setButtonRadius(e.value)}
                  checked={buttonRadius === '32px'}
                />
                <label htmlFor="radius2" className="ml-2">
                  Pill
                </label>
              </div>

              <p className="m-0 font-bold">Button Weight</p>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="weight3"
                  name="weight"
                  value="normal"
                  onChange={e => setButtonWeight(e.value)}
                  checked={buttonWeight === 'normal'}
                />
                <label htmlFor="weight3" className="ml-2">
                  Normal
                </label>
              </div>
              <div className="p-field-radiobutton mb-3">
                <RadioButton
                  inputId="weight2"
                  name="weight"
                  value="bold"
                  onChange={e => setButtonWeight(e.value)}
                  checked={buttonWeight === 'bold'}
                />
                <label htmlFor="radius2" className="ml-2">
                  Bold
                </label>
              </div>

              <p className="m-0 font-bold">Button Border</p>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="border3"
                  name="border"
                  value="none"
                  onChange={e => setButtonBorder(e.value)}
                  checked={buttonBorder === 'none'}
                />
                <label htmlFor="border3" className="ml-2">
                  No Border
                </label>
              </div>
              <div className="p-field-radiobutton mb-3">
                <RadioButton
                  inputId="border2"
                  name="border"
                  value="2px"
                  onChange={e => setButtonBorder(e.value)}
                  checked={buttonBorder === '2px'}
                />
                <label htmlFor="radius2" className="ml-2">
                  Border
                </label>
              </div>

              <label htmlFor="buttonColorInput" className="font-bold">
                Color Picker
              </label>
              <input
                type="color"
                id="buttonColorInput"
                name="buttonColorInput"
                value={buttonColor}
                onChange={e => setButtonColor(e.target.value)}
                className="mb-3"
              />
              <label htmlFor="buttonTextColorInput" className="font-bold">
                Text and Border Color Picker
              </label>
              <input
                type="color"
                id="buttonTextColorInput"
                name="buttonTextColorInput"
                value={buttonTextColor}
                onChange={e => setButtonTextColor(e.target.value)}
                className="mb-6"
              />
            </div>
          </section>

          <h2 className="mb-4">Buy Now Button Preview</h2>
          <Button
            label={
              isBuyNowFlow === true
                ? buyNowButtonTextOptions[0].label
                : addToCartButtonTextOptions[0].label || buttonText
            }
            onClick={() => handleProductModal()}
            style={{
              backgroundColor: `${buttonColor}`,
              color: `${buttonTextColor}`,
              borderRadius: `${buttonRadius}`,
              border:
                buttonBorder === 'none'
                  ? 'none'
                  : `${buttonBorder} solid ${buttonTextColor}`,
            }}
            className={buttonWeight === 'bold' ? 'font-bold' : ''}
          />
        </div>

        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-12 xl:w-8 mb-3">
          <div className="button-options">
            <div className="flex flex-row">
              <h2>Step 1: CDN Link</h2>
            </div>
            <p>
              Copy the code snippet below and paste it into your store&#x27;s
              HTML.
              <br />
              <br />
              <strong>Where to place it: </strong> <br />
              Directly before the closing &lt;/body&gt; HTML tag.
            </p>
            <p>
              <strong>Please note: </strong> <br />
              If you&#x27;re adding both the{' '}
              <Link to="/cart-icon-generator">cart icon</Link> and buy now
              button to your store and have already added this script tag to
              your site, skip this step. You only need this once on your
              website.
            </p>
            <InputTextarea
              rows={7}
              cols={20}
              value={cdnEmbed}
              className="mb-2 w-full text-sm"
              style={{
                color: 'gold',
                backgroundColor: '#1c1c1c',
                maxHeight: 'initial',
              }}
              spellCheck="false"
            />
            <Button
              label="Copy"
              icon="pi pi-copy"
              onClick={() => copyText(cdnEmbed)}
            />
          </div>
        </div>

        <div className="border-300 border-round surface-overlay border-3 p-4 w-12 md:w-12 xl:w-8 mb-3 ">
          <div className="button-options">
            <h2 className="mt-1 mb-3"> Step 2: Buy Now Button Code</h2>
            <p className="mt-0 mb-5">
              Copy the code snippet below and paste it into your store&#x27;s
              HTML to add your custom button. <br /> <br />
              <strong>Where to place it: </strong> <br /> Anywhere you would
              like the the button appear. Use it as many times as you like.
            </p>

            <InputTextarea
              rows={5}
              cols={30}
              value={`<button onclick="${
                isBuyNowFlow ? 'buyNowModal' : 'addToCartModal'
              }('${skuValue}', '${affiliateValue}');" style="padding: 8px 16px; background-color: ${buttonColor}; color: ${buttonTextColor}; border-radius: ${buttonRadius}; ${
                buttonWeight === 'bold' ? 'font-weight: bold;' : ''
              } ${
                buttonBorder === 'none'
                  ? 'border: none;'
                  : `border: ${buttonBorder} solid ${buttonTextColor};`
              }" class="${buttonClass}">${buttonText}</button>`}
              className="mb-2 w-full text-sm"
              style={{
                color: 'gold',
                backgroundColor: '#1c1c1c',
                maxHeight: 'initial',
              }}
              spellCheck="false"
            />
            <Button
              label="Copy Embed Code"
              icon="pi pi-copy"
              onClick={() => copyEmbed()}
            />
          </div>
        </div>
      </section>
      <Toast ref={toast} />
    </>
  );
}
