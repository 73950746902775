import { Fade } from 'react-awesome-reveal';
import { HashLink } from 'react-router-hash-link';
import { ReactElement } from 'react';
import { useContent } from 'utils/useContent';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import './scss/GradientBanner.scss';
import { useMatomo } from 'matomoReact';

export default function GradientBanner(): ReactElement {
  const data = useContent();

  const { trackEvent } = useMatomo();

  return (
    <section className="gradient blue-lightblue p-80 px-4">
      <div
        className="m-auto flex flex-column text-center align-content-center"
        style={{ maxWidth: '1200px' }}
      >
        <Fade direction="up" triggerOnce>
          {data.action.header && (
            <h4 className="text-white mt-0 mb-4">
              {parse(data?.action.header)}
            </h4>
          )}
          {data.action.info[0].text && (
            <p
              className="text-white mt-0 mb-64 w-full mx-auto"
              style={{ maxWidth: '700px' }}
            >
              {parse(data?.action.info[0].text)}
            </p>
          )}
          <HashLink
            to="#products"
            className="w-full md:w-auto bg-transparent text-white border-gray-50 border-1 px-6 py-3 cursor-pointer no-underline inline-block button--white--filled"
            style={{ borderRadius: '165px', letterSpacing: '0.2em' }}
            id="footer-action"
            onClick={() => {
              ReactGA.event({
                category: 'landing-page',
                action: 'Landing Page - footer-action',
                label: 'Landing Page - footer-action-button',
              });
              trackEvent({
                category: 'landing-page',
                action: 'Landing Page - footer-action',
                name: 'Landing Page - footer-action-button',
              });
            }}
          >
            SEE PRODUCTS
          </HashLink>
        </Fade>
      </div>
    </section>
  );
}
