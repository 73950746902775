import React from 'react';
import { useFormContext } from 'react-hook-form';
import { AdminInputSelect, AdminInputText } from 'components/Admin';

import tempStates from '../../../components/Admin/data/tempUSStates.json';

const ProviderFormSection = () => {
  const { control } = useFormContext();
  const medicalLicenses = [
    { label: 'Medical Doctor', code: 'MD' },
    { label: 'Physician Assistant', code: 'PA-C' },
    { label: 'Nurse Practitioner', code: 'BSN' },
    { label: 'Registered Nurse', code: 'RN' },
  ];

  const issuingStateOptions = tempStates.map(state => ({
    label: state.name,
    code: state.code,
  }));

  return (
    <div className="flex flex-column justify-content-center gap-3 my-6 w-full mx-auto">
      <AdminInputSelect
        control={control}
        name="medicalLicenseType"
        options={medicalLicenses}
        required
        label="Medical License"
        containerClassName="mb-4"
      />
      <AdminInputText
        control={control}
        name="medicalLicenseNumber"
        required
        label="License #"
        containerClassName="mb-4"
      />
      <AdminInputSelect
        control={control}
        name="issuingState"
        options={issuingStateOptions}
        required
        label="Issuing State"
        containerClassName="mb-4"
      />
    </div>
  );
};

export default ProviderFormSection;
