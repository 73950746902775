import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { CartItemFieldsFragment } from 'graphql/generated/magentoApi';
import { useCartContext } from 'CartContext';
import { getCartDropdownOptions } from 'utils/helper';
import { Dropdown } from 'primereact/dropdown';
import { moneyFormat } from 'utils/formatHelper';
import './CartItemList.scss';
import useViewport from 'hooks/useViewport';

type CartItemListProps = {
  showCheckoutSummary?: boolean;
};

export default function CartItemList({
  showCheckoutSummary,
}: CartItemListProps): ReactElement {
  const { items, updateCart } = useCartContext();
  const { isMobile } = useViewport();

  useEffect(() => {
    const QuantityButtons = Array.from(
      document.querySelectorAll('div.p-dropdown-trigger'),
    );
    QuantityButtons.forEach(QuantityButton => {
      QuantityButton.setAttribute('aria-label', 'Change Quantity');
    });
  });

  const itemRow = (item: CartItemFieldsFragment): ReactElement => {
    let subscriptionPlan = 'monthly';
    if (item.product.is_subscription === 1) {
      if (item.product?.name?.toLowerCase().includes('yearly')) {
        subscriptionPlan = 'yearly';
      }
    }

    const subscriptionText =
      item.product.is_subscription === 1
        ? `${item.product.name}\n Subscription`
        : `${item.product.name}`;
    const subscriptionPricing =
      item.product.is_subscription === 1
        ? `${moneyFormat.format(
            item.quantity *
              Number(item.product.price_range.maximum_price.final_price.value),
          )} / ${subscriptionPlan}`
        : `${moneyFormat.format(
            item.quantity *
              Number(item.product.price_range.maximum_price.final_price.value),
          )}`;
    if (showCheckoutSummary || isMobile) {
      return (
        <div key={item.uid} className="grid m-0 cart-summary-item w-full">
          <Link to={`/product/${item.product.sku}`} className="col-2 mx-0 px-0">
            <picture className="cart-item__image max-w-max mr-auto">
              <img
                alt={String(item.product.name) || ''}
                src={String(item.product.image?.url) || ''}
              />
            </picture>
          </Link>
          <div className="grid col-10 justify-content-between my-0 py-0 mx-0">
            <div className="grid col-12 mt-auto mb-0 justify-content-between align-items-start">
              <p
                className="col-8 m-0 p-0 text-sm"
                style={{ whiteSpace: 'pre-line' }}
              >
                {subscriptionText}
              </p>
              <p className="col-4 m-0 p-0 raleway-price-format font-bold text-right ml-auto text-sm">
                {subscriptionPricing}
              </p>
            </div>
            <div className="flex col-12 mt-0 mb-2 p-0">
              <div
                className="m-0 p-0 max-h-max max-w-max inline-flex ml-auto align-items-center justify-content-center"
                style={{ transform: 'scale(0.8)' }}
              >
                <Dropdown
                  aria-label="Adjust product quantity"
                  className="p-0 m-0 dropdown-product-quantity"
                  value={item.quantity}
                  options={getCartDropdownOptions(
                    item.product.sku,
                    item.__typename,
                  )}
                  onChange={e =>
                    updateCart({ sku: item.product.sku, quantity: e.value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div key={item.uid} className="grid m-0 px-2 checkout-item--border">
        <Link to={`/product/${item.product.sku}`} className="col-2">
          <picture className="cart-item__image">
            <img
              alt={String(item.product.name) || ''}
              src={String(item.product.image?.url) || ''}
            />
          </picture>
        </Link>
        <div className="col-4 flex align-items-center">
          <p
            className="m-0 text-800 text-sm"
            style={{ whiteSpace: 'pre-line' }}
          >
            {subscriptionText}
          </p>
        </div>
        <div className="col-2 flex justify-content-center align-items-center">
          <Dropdown
            aria-label="Adjust product quantity"
            className="p-0 m-0 dropdown-product-quantity"
            value={item.quantity}
            options={getCartDropdownOptions(item.product.sku, item.__typename)}
            onChange={e =>
              updateCart({ sku: item.product.sku, quantity: e.value })
            }
          />
        </div>
        <div className="col-2 flex justify-content-center align-items-center">
          <Button
            onClick={() => updateCart({ sku: item.product.sku, quantity: 0 })}
            className="cart-item__delete cursor-pointer surface-0 border-none"
            aria-label={`Delete Product ${item.product.name}`}
            icon="pi pi-trash"
          />
        </div>
        <div className="col-2 flex justify-content-end align-items-center">
          <span className="text-800 font-bold raleway-price-format">
            {subscriptionPricing}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full border-300 mb-0 cart-card">
        {!showCheckoutSummary && (
          <div className="border-bottom-1 border-300 text-800 font-bold cart-card--header p-0 md:p-2">
            <div className="grid hidden md:flex m-0">
              <div className="col-2">Image</div>
              <div className="col-4">Name</div>
              <div className="col-2 text-center">Quantity</div>
              <div className="col-2" />
              <div className="col-2 text-right">Total</div>
            </div>
            <div className="md:hidden font-bold px-2 py-1">Order summary</div>
          </div>
        )}
        <div className="cart-item__list p-0 w-full m-0">
          {items.map(item => itemRow(item))}
        </div>
      </div>
    </>
  );
}
