import TreeNode from 'primereact/treenode';
import { TreeTableExpandedKeysType } from 'primereact/treetable';

export function categoriesToTreeNodes(categories: any[]): TreeNode[] {
  return categories.map(category => ({
    key: category.url_path,
    label: category.name,
    data: category,
    children:
      category.children.length !== 0
        ? categoriesToTreeNodes(category.children as any)
        : [],
    leaf: !category.children.length,
  }));
}

export function createExpandedKeys(
  nodes: TreeNode[],
  isExpanded: boolean,
  keys: TreeTableExpandedKeysType = {},
): TreeTableExpandedKeysType {
  nodes.forEach(node => {
    if (node.key) {
      // eslint-disable-next-line no-param-reassign
      keys[`${node.key}`] = isExpanded;
    }
    if (node.children?.length) {
      createExpandedKeys(node.children, isExpanded, keys);
    }
  });
  return keys;
}
