import { Menu } from 'primereact/menu';
import { storesByExchange } from 'utils/content';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IMediaNav from 'components/imedia/components/IMediaNav';
import StoreList from 'components/imedia/components/StoreList';
import './scss/ExchangeSiteMap.scss';

export default function ExchangeSiteMap() {
  const [currentExchange, setCurrentExchange] = useState<string>('');
  const navigate = useNavigate();
  const exchangeArray = Object.keys(storesByExchange);

  function OnClick(store: string): void {
    setCurrentExchange(store);
  }

  const items = exchangeArray.map(item => {
    if (item === 'consumerdiscretionary') {
      return {
        label: 'Consumer Discretionary',
        icon: 'pi pi-angle-right',
        command: () => OnClick(item),
      };
    }
    return {
      label: item,
      icon: 'pi pi-angle-right',
      command: () => OnClick(item),
    };
  });

  const model = [
    {
      label: 'Explore Exchanges',
      items,
    },
    {
      label: 'Links',
      items: [
        {
          label: 'Shopping',
          icon: 'pi pi-angle-right',
          command: () => navigate('/'),
        },
      ],
    },
  ];

  return (
    <>
      <IMediaNav />
      <div className="flex justify-content-center flex-column md:flex-row mt-5 md:mt-8 mx-5">
        <Menu
          model={model}
          className="exchange-categories border-none m-auto mb-5 md:mt-0 md:mb-0 md:mr-5"
        />
        <div
          className="dataview-demo flex-1"
          style={{ minWidth: '300px', maxWidth: '1400px' }}
        >
          <StoreList currentExchange={currentExchange} />
        </div>
      </div>
    </>
  );
}
