import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
// TODO add this in if affiliateValue is needed import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Toast } from 'primereact/toast';
import MarketPlaceComponentPage from 'pages/marketplace-component-page';
import { Link } from 'react-router-dom';
import './CartIconGenerator.scss';
import {
  crwdMarketMatomoUrl,
  defaultDomains,
  ModalBaseUrlInterface,
  modalBaseUrlOptions,
  selectedModalBaseOptionTemplate,
  useLoadProductPopupJs,
} from '../buttonGenerator';

export default function CartIconGenerator() {
  const toast = useRef<Toast>(null);
  const [textColor, setTextColor] = useState('#000000');
  const [cartColor, setCartColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#9e9e9e');
  const [cartIconCode, setCartIconCode] = useState<string>('');
  const [marketplaceIsSelected, setMarketplaceIsSelected] =
    useState<boolean>(false);
  const [currentModalBase, setCurrentModalBase] =
    useState<ModalBaseUrlInterface>({
      name: 'crwd market',
      url: '',
      matomoSiteId: '',
      domains: defaultDomains,
      matomoUrl: crwdMarketMatomoUrl,
    });

  const cdnEmbed = useLoadProductPopupJs(
    currentModalBase.url,
    currentModalBase.matomoSiteId,
    currentModalBase.domains,
    currentModalBase.matomoUrl,
  );

  useEffect(() => {
    setCartIconCode(`<div class="crwd_custom-cart">
    <div class="crwd_custom-cart__wrapper">
      <iframe
        title="store"
        id="cart-quantity"
        width="25px"
        height="auto"
        src="${currentModalBase.url}/cart-quantity?color=${encodeURIComponent(
      textColor,
    )}&fontSize=14px&backgroundColor=${encodeURIComponent(
      bgColor,
    )}&textAlign=center&marginTop=-4px&marginRight=4px"
        frameBorder="0"
        style='border: none'
      ></iframe>
    </div>
    <button
      class="crwd_cart__button"
      type="button"
      onclick="showCartModal()"
    >
      <div class="crwd_svg__wrapper" style="fill: ${cartColor}"></div>
    </button>
  </div>`);
  }, [textColor, cartColor, bgColor, currentModalBase.url]);

  function copyEmbed() {
    navigator.clipboard.writeText(cartIconCode);
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied embed code to clipboard',
      contentClassName: '',
    });
  }

  const handleShowCartModal = useCallback(() => {
    // eslint-disable-next-line no-undef
    showCartModal?.('cart-modal');
  }, []);

  function copyText(text: string) {
    navigator.clipboard.writeText(text);
    toast.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Copied text to clipboard',
      contentClassName: '',
    });
  }

  const onModalBaseUrlChange = (e: { value: ModalBaseUrlInterface }) => {
    setCurrentModalBase({ ...e.value });
    setMarketplaceIsSelected(true);
  };

  useEffect(() => {
    setTimeout(() => {
      modalBaseUrlOptions.map(modalBaseUrlOption => {
        if (modalBaseUrlOption.url === window.location.origin) {
          setCurrentModalBase({ ...modalBaseUrlOption });
          setMarketplaceIsSelected(true);
        }
        return modalBaseUrlOption;
      });
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalBaseOptionTemplate = (option: ModalBaseUrlInterface) => (
    <div className="flex flex-column">
      <div className="ml-3 white-space-normal flex align-items-center">
        {`${option.name}`}
      </div>
      <div className="ml-3 white-space-normal flex align-items-center">
        {`${option.url}`}
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <MarketPlaceComponentPage />
      <section
        className="flex flex-column justify-content-center align-items-center relative w-full"
        id="cart-icon-generator"
      >
        <h1 className="mb-3 w-full text-center cart-icon__header">
          Cart Icon Generator
        </h1>
        <div className="cart-icon border-300 border-round surface-overlay border-3 p-4 w-12 md:w-12 xl:w-8 mb-3 ">
          {/* // ? is an affiliate code needed for the cart icon? 
         <label htmlFor="affiliateCode">Affiliate Marketing Code</label>
        <InputText
          id="affiliateCode"
          value={affiliateValue}
          onChange={e => setAffiliateValue(e.target.value)}
          className="w-full mb-3 cart-icon__input"
          placeholder="123456"
        /> */}
          <div className="p-inputgroup flex flex-column">
            <h2 className="mb-2">Marketplace Selection</h2>
            <div className="w-full">
              <Dropdown
                id="marketplace-dropdown"
                optionLabel="name"
                value={currentModalBase}
                options={modalBaseUrlOptions}
                onChange={onModalBaseUrlChange}
                valueTemplate={selectedModalBaseOptionTemplate}
                itemTemplate={modalBaseOptionTemplate}
                placeholder="Select a Marketplace"
                className="w-full mb-3 relative"
                required
              />
            </div>

            <h2 className="mb-2">Cart Colors Selection</h2>
            <label htmlFor="textColorInput" className="font-bold">
              Cart Quantity Text Color Picker
            </label>
            <input
              type="color"
              id="textColorInput"
              name="textColorInput"
              value={textColor}
              onChange={e => setTextColor(e.target.value)}
              className="cart-icon__color-picker"
            />

            <label htmlFor="bgColorInput" className="font-bold">
              Background Cart Quantity Color
            </label>
            <input
              type="color"
              id="bgColorInput"
              name="bgColorInput"
              value={bgColor}
              onChange={e => setBgColor(e.target.value)}
              className="cart-icon__color-picker"
            />

            <label htmlFor="cartIconColorInput" className="font-bold">
              Cart Icon Color Picker
            </label>
            <input
              type="color"
              id="cartIconColorInput"
              name="cartIconColorInput"
              value={cartColor}
              onChange={e => setCartColor(e.target.value)}
              className="cart-icon__color-picker"
            />
          </div>

          <h2 className="mb-2">Cart Icon Preview</h2>
          <div className="crwd_custom-cart">
            <div className="crwd_custom-cart__wrapper">
              <iframe
                title="store"
                id="cart-quantity"
                width="25px"
                height="auto"
                src={`${
                  currentModalBase.url
                }/cart-quantity?color=${encodeURIComponent(
                  textColor,
                )}&fontSize=14px&backgroundColor=${encodeURIComponent(
                  bgColor,
                )}&textAlign=center&marginTop=-4px&marginRight=4px`}
                frameBorder="0"
                style={{ border: 'none' }}
              />
            </div>
            <button
              className="crwd_cart__button"
              type="button"
              onClick={() => handleShowCartModal()}
            >
              <div className="crwd_svg__wrapper" style={{ fill: cartColor }}>
                <svg
                  className="crwd_custom-cart__svg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  width="256"
                  height="256"
                  viewBox="0 0 256 256"
                  xmlSpace="preserve"
                >
                  <desc>Created with Fabric.js 1.7.22</desc>
                  <defs />
                  <g transform="translate(128 128) scale(0.72 0.72)">
                    <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
                      <path
                        d="M 73.903 63.178 H 28.899 c -4.566 0 -8.377 -3.276 -9.063 -7.79 l -5.662 -37.261 c -0.087 -0.576 0.081 -1.162 0.461 -1.604 s 0.934 -0.696 1.517 -0.696 h 67.893 c 1.805 0 3.491 0.805 4.628 2.208 c 1.135 1.403 1.571 3.22 1.195 4.985 l -6.999 32.899 C 81.975 60.125 78.203 63.178 73.903 63.178 z M 18.479 19.827 l 5.313 34.96 c 0.386 2.544 2.534 4.391 5.107 4.391 h 45.004 c 2.424 0 4.549 -1.721 5.054 -4.091 l 6.999 -32.9 c 0.123 -0.58 -0.02 -1.176 -0.393 -1.636 c -0.373 -0.46 -0.926 -0.725 -1.519 -0.725 H 18.479 z"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                      />
                      <path
                        d="M 16.15 19.827 c -0.988 0 -1.848 -0.732 -1.98 -1.738 l -0.598 -4.531 c -0.279 -2.116 -2.099 -3.712 -4.233 -3.712 H 2 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 7.339 c 4.133 0 7.658 3.09 8.199 7.188 l 0.598 4.531 c 0.145 1.095 -0.626 2.1 -1.721 2.245 C 16.325 19.821 16.237 19.827 16.15 19.827 z"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                      />
                      <path
                        d="M 29.18 84.154 c -4.76 0 -8.632 -3.873 -8.632 -8.633 s 3.873 -8.632 8.632 -8.632 c 4.76 0 8.633 3.872 8.633 8.632 S 33.94 84.154 29.18 84.154 z M 29.18 70.89 c -2.554 0 -4.632 2.078 -4.632 4.632 c 0 2.555 2.078 4.633 4.632 4.633 c 2.555 0 4.633 -2.078 4.633 -4.633 C 33.813 72.968 31.734 70.89 29.18 70.89 z"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                      />
                      <path
                        d="M 71.339 84.154 c -4.76 0 -8.633 -3.873 -8.633 -8.633 s 3.873 -8.632 8.633 -8.632 s 8.632 3.872 8.632 8.632 S 76.099 84.154 71.339 84.154 z M 71.339 70.89 c -2.555 0 -4.633 2.078 -4.633 4.632 c 0 2.555 2.078 4.633 4.633 4.633 c 2.554 0 4.632 -2.078 4.632 -4.633 C 75.971 72.968 73.893 70.89 71.339 70.89 z"
                        transform=" matrix(1 0 0 1 0 0) "
                        strokeLinecap="round"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </button>
          </div>

          <h2 className="mt-4">Step 1: CDN Link</h2>
          <p>
            Copy the code snippet below and paste it into your store&#x27;s
            HTML.
            <br />
            <br />
            <strong>Where to place it: </strong>
            <br /> Directly before the closing &lt;/body&gt; HTML tag.
          </p>
          <p>
            <i>
              If placed incorrectly, the cart&#x27;s appearance &#40;css&#41;
              will not change and the cart will not function properly.
              <br />
            </i>{' '}
            <br />
            <strong>Please note: </strong>
            <br /> If you&#x27;re adding both the cart icon and{' '}
            <Link to="/button-generator">buy now button</Link> to your store and
            have already added this script tag to your site, skip this step. You
            only need this once on your website.
          </p>
          <InputTextarea
            rows={5}
            cols={30}
            value={cdnEmbed}
            className="mb-2 w-full text-sm"
            style={{
              color: 'gold',
              backgroundColor: '#1c1c1c',
              maxHeight: 'initial',
            }}
            spellCheck="false"
          />
          {!marketplaceIsSelected && (
            <p className="select-market-message">
              Select A Market Above First before copying script
            </p>
          )}
          <Button
            className="cart-icon__btn"
            label="Copy"
            icon="pi pi-copy"
            onClick={() => copyText(cdnEmbed)}
            disabled={!marketplaceIsSelected}
          />

          <h2 className="mt-8"> Step 2: Cart Icon Code</h2>
          <p className="mt-0 mb-5">
            Copy the code snippet below and paste it into your store&#x27;s HTML
            to add the cart icon you&#x27;ve customized. <br />
            <strong>Where to place it: </strong> Anywhere you would like the
            cart icon to appear. Typically, it is placed in the top navigation
            section of your store.
          </p>

          <InputTextarea
            rows={23}
            value={cartIconCode}
            className="mb-2 w-full text-sm"
            style={{
              color: 'gold',
              backgroundColor: '#1c1c1c',
              maxHeight: 'initial',
            }}
            spellCheck="false"
          />
          {!marketplaceIsSelected && (
            <p className="select-market-message">
              Select A Market Above First before copying script
            </p>
          )}
          <Button
            className="cart-icon__btn"
            label="Copy Embed Code"
            icon="pi pi-copy"
            onClick={() => copyEmbed()}
            disabled={!marketplaceIsSelected}
          />
          <p className="mt-6">Example of navigation with the cart icon</p>
          <img
            src="/images/vendor-tools/cart-icon-example.png"
            alt=""
            className="mb-6 w-full"
          />
        </div>
      </section>
    </>
  );
}
