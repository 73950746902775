import { ReactElement, useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from 'UserContext';
import { useCartContext } from 'CartContext';
import { useContent } from 'utils/useContent';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import _ from 'lodash';
import { CartItemFieldsFragment } from 'graphql/generated/magentoApi';
import Logo from 'assets/crwd/brand_market/crwdmarket_icon_w.svg';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import './scss/IMediaNav.scss';

type Props = {
  hideItems?: string;
};

export default function IMediaNav(props: Props): ReactElement {
  const { quantity, items, updateCart, showCart } = useCartContext();
  const { login, logout, userId, userEmail } = useUserContext();
  const logoLi = useRef<HTMLLIElement>(null);
  const cartLi = useRef<HTMLLIElement>(null);
  const navigate = useNavigate();
  const { hideItems } = props;
  const data = useContent();
  // const [visibleRight, setVisibleRight] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [searchParams] = useSearchParams();
  const skuToAdd = searchParams.get('add-sku') || undefined;
  const [currentCartItems, setCurrentCartItems] = useState<
    CartItemFieldsFragment[] | null
  >(null);

  const sections = document.querySelectorAll('section');
  const navLi = document.querySelectorAll('.i-nav li.p-menuitem');
  window.addEventListener('scroll', () => {
    let current: any = '';
    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      // eslint-disable-next-line no-restricted-globals
      if (scrollY >= sectionTop - 56) {
        current = section.getAttribute('id');
      }
      navLi.forEach(li => {
        li.classList.remove('active');
        if (li.classList.contains(current)) {
          li.classList.add('active');
        }
      });
    });
  });

  const menuItems = [
    {
      label: data.participate?.plural ? 'Our Story' : 'My Story',
      url: '#my-story',
      className: hideItems ? 'hidden' : 'my-story',
    },
    {
      label: data.participate?.plural ? 'Our Vision' : 'My Vision',
      url: '#my-vision',
      className: hideItems ? 'hidden' : 'my-vision',
    },
    {
      label: data.participate?.plural ? 'Our Message' : 'My Message',
      url: '#my-message',
      className: hideItems ? 'hidden' : 'my-message',
    },
    {
      label: 'Products',
      url: '#products',
      className: hideItems ? 'hidden' : 'products',
    },
    {
      label: 'Testimonials',
      url: '#testimonials',
      className: hideItems ? 'hidden' : 'testimonials',
    },
  ];
  const [model, setModel] = useState<MenuItem[]>(menuItems);

  const loggedInItems = [
    {
      label: 'Orders',
      command: () => navigate('/orders'),
    },
    {
      label: 'Logout',
      command: () => logout(window.location.pathname),
    },
  ];

  const loggedOutItems = [
    {
      label: 'Login',
      command: () => login(),
    },
  ];

  useEffect(() => {
    if (items.length) {
      if (!_.isEqual(items, currentCartItems)) setCurrentCartItems(items);
    }
  }, [items, currentCartItems]);

  useEffect(() => {
    if (userId) {
      setModel([
        ...menuItems,
        {
          label: userEmail || 'User',
          icon: 'pi pi-user',
          items: loggedInItems,
          className: 'nav-email-item',
        },
      ]);
    } else {
      setModel([...menuItems, ...loggedOutItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (skuToAdd && isFirstRender) {
      updateCart({
        sku: skuToAdd,
        quantity: 1,
        onlyAddOneIfNotPresent: true,
        showCart: true,
        debug: 'IMediaNav',
      });
    }
    setIsFirstRender(false);
  }, [skuToAdd, updateCart, isFirstRender]);

  useEffect(() => {
    if (logoLi.current && cartLi.current) {
      const list = document.getElementsByClassName(
        'p-menubar-root-list',
      )[0] as HTMLUListElement;
      if (list) {
        const measurement =
          logoLi.current.offsetWidth - cartLi.current.offsetWidth <= 200
            ? logoLi.current.offsetWidth - cartLi.current.offsetWidth
            : 200;
        list.style.paddingRight = `${measurement}px`;
      }
    }
  });

  useEffect(() => {
    const hamburger = document.getElementsByClassName(
      'p-menubar-button',
    )[0] as HTMLLinkElement;
    if (hamburger) {
      hamburger.innerHTML = `<div class="hamburger-icon"></div>`;
    }
  }, []);

  return (
    <div className="i-nav max-w-full">
      <ul
        className="flex flex-row mx-auto p-0 list-none w-full"
        style={{ maxWidth: '1480px' }}
      >
        <li
          ref={logoLi}
          className="flex align-items-center px-3 py-2"
          style={{ zIndex: 1000, backgroundColor: '#0C0C0C' }}
        >
          <a href="/" className="logoLink">
            <img src={Logo} alt="crwd market" />
          </a>
        </li>
        <li style={{ zIndex: 500, backgroundColor: '#0C0C0C' }}>
          <Menubar className="user-menubar" model={model} />
        </li>
        <li
          ref={cartLi}
          className="flex align-items-center pt-1 px-2 cart-item"
          style={{ zIndex: 1000, backgroundColor: '#0C0C0C', color: '#fffff' }}
        >
          <Button
            icon="pi pi-fw pi-shopping-cart"
            className="cart-button border-none"
            // onClick={() => setVisibleRight(!visibleRight)}
            onClick={() => showCart()}
          >
            <Badge
              className={`badge ${Number(quantity) === 0 ? 'hide' : ''}`}
              style={{
                marginLeft: '-8px',
                marginTop: '-16px',
              }}
              value={quantity}
            />
          </Button>
        </li>
      </ul>
    </div>
  );
}
