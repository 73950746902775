import TreeNode from 'primereact/treenode';
import { TreeTableExpandedKeysType } from 'primereact/treetable';

export function navItemsToTreeNodes(navItems: any[]): TreeNode[] {
  return navItems.map(item => {
    if ('items' in item) {
      return {
        key: 'url' in item ? item.url : item.label,
        label: item.label,
        data: item,
        icon: 'icon' in item ? item.icon : '',
        children:
          'items' in item.items[0]
            ? navItemsToTreeNodes(item.items[0].items as any)
            : navItemsToTreeNodes(item.items as any),
        leaf: item.items.length !== 0,
      };
    }

    return {
      key: 'url' in item ? item.url : item.label,
      label: item.label,
      icon: 'icon' in item ? item.icon : '',
      data: item,
    };
  });
}

export function createExpandedKeys(
  nodes: TreeNode[],
  isExpanded: boolean,
  keys: TreeTableExpandedKeysType = {},
): TreeTableExpandedKeysType {
  nodes.forEach(node => {
    if (node.key) {
      // eslint-disable-next-line no-param-reassign
      keys[`${node.key}`] = isExpanded;
    }
    if (node.children?.length) {
      createExpandedKeys(node.children, isExpanded, keys);
    }
  });
  return keys;
}
