import React from 'react';
import { AdminInputTextProps, AdminInputText } from './AdminInputText';

export const validateEmail = (testEmail?: string | null): boolean | string => {
  // Don't run validation if empty and not required
  if (!testEmail) {
    return true;
  }
  const result =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (result.test(String(testEmail).toLowerCase())) {
    return true;
  }
  return 'Email format is invalid';
};

export const AdminInputEmail: React.FC<AdminInputTextProps> = props => {
  const customProps: AdminInputTextProps = {
    ...props,
    autoCapitalize: props.autoCapitalize || 'none',
    rules: {
      validate: validateEmail,
      ...props.rules,
    },
  };

  return <AdminInputText {...customProps} />;
};
