import { OrderType } from 'types';

const orderTracking = (carrierCode, trackingNumber, orderId) => {
  let carrier_tracking;
  switch (carrierCode) {
    case 'dhl':
      carrier_tracking = `https://www.dhl.com/ca-en/home/tracking.html?tracking-id=${trackingNumber}`;
      break;
    case 'fedex':
      carrier_tracking = `https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=${trackingNumber}`;
      break;
    case 'ups':
      carrier_tracking = `http://www.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
      break;
    case 'usps':
      carrier_tracking = `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${trackingNumber}`;
      break;

    default:
      carrier_tracking = `mailto:support@crwd.market?subject=OrderID: ${orderId} Tracking Info`;
  }
  return carrier_tracking;
};

export default function OrderTracking({ orderData }: { orderData: OrderType }) {
  return (
    <div className="my-3">
      <i className="pi pi-map-marker mr-2" />
      <b className="bold">Tracking</b>
      {orderData.tracks.map(tracking => (
        <div key={tracking.track_number} className="mt-1">
          <p className="m-0">
            {tracking.carrier_code.toLowerCase() === 'custom' ||
            tracking.carrier_code.toLowerCase() === 'shipstation' ? (
              <>
                <span>
                  Shipped by{' '}
                  <strong> {tracking.carrier_code.toUpperCase()}</strong>:{' '}
                  {tracking.track_number}
                </span>
                <p className="mt-0 mb-1">
                  Please check your email for more tracking info.
                </p>
              </>
            ) : (
              <>
                <span className="mr-2">
                  {tracking.carrier_code.toUpperCase()}:
                </span>
                <a
                  style={{ color: 'var(--primary-dark-color)' }}
                  href={orderTracking(
                    tracking.carrier_code,
                    tracking.track_number,
                    tracking.order_id,
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tracking.track_number}
                </a>
              </>
            )}
          </p>
        </div>
      ))}
    </div>
  );
}
