import { Button } from 'primereact/button';
import { Head } from 'components/head/Head';
import { OrderType } from 'types';
import { ReactElement, useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import DigitalContent from 'components/orders/DigitalContent';
import DigitalProductList from 'components/orders/DigitalProductList';
import Nav from 'components/nav/Nav';
import Order from 'components/orders/Order';
import Spinner from 'components/Spinner';
import './OrdersAndDC.scss';
import { useUserContext } from 'UserContext';
import { useMagentoOrdersQuery } from 'graphql/generated/reactQuery';
import { getMarketContent } from 'utils/marketContent';

export default function OrdersAndDC(): ReactElement {
  const { login, userId, client } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { productSku } = useParams();
  const [activeIndex, setActiveIndex] = useState(
    location.pathname === '/orders' ? 0 : 1,
  );
  const magentoOrdersQueryState = useMagentoOrdersQuery(
    client,
    {},
    {
      enabled: !!userId,
    },
  );
  const orders = magentoOrdersQueryState.data?.magentoOrders?.orders || [];
  const { meta, displayOrderPageReturnMarket } = getMarketContent();

  const { isFetching } = magentoOrdersQueryState;

  useEffect(() => {
    if (productSku) {
      setActiveIndex(1);
    }
  }, [productSku]);

  useEffect(() => {
    if (location.pathname.includes('/digital-content') && !activeIndex) {
      setActiveIndex(1);
    }
  }, [activeIndex, location.pathname]);

  const onTabChangeHandle = (e: any) => {
    setActiveIndex(e.index);
    navigate(e.index ? '/digital-content' : '/orders');
  };

  function Digital(): ReactElement {
    if (isFetching) {
      return <Spinner />;
    }
    if (productSku) {
      return <DigitalContent />;
    }
    if (orders) {
      return <DigitalProductList orders={orders} />;
    }
    return <></>;
  }

  const digitalProducts =
    orders?.flatMap(order => {
      const items = order?.items.filter(item => item.is_virtual === 1);
      if (items) {
        return items.map(item => ({
          ...item,
          order_number: order.increment_id,
        }));
      }
      return [];
    }) || [];

  const tabReturnToMarketplace = () => (
    <a
      href={meta.protocolAndDomain}
      target="_blank"
      rel="noreferrer"
      className="p-tabview-nav-link flex align-items-center"
    >
      <i className="pi pi-home" />
      <span className="p-tabview-title">Return To Marketplace</span>
    </a>
  );

  return (
    <div className="flex flex-column w-full h-screen">
      <Head
        title={activeIndex === 0 ? 'Orders' : 'Digital Content'}
        link={activeIndex === 0 ? '/orders' : '/digital-content'}
        addPostfixTitle
      />
      <Nav />
      <div
        className="orders px-4 md:px-0 flex-grow-1 w-full"
        style={{ maxWidth: '1480px', margin: '2rem auto' }}
      >
        {userId ? (
          <TabView
            activeIndex={activeIndex}
            onTabChange={e => onTabChangeHandle(e)}
          >
            <TabPanel
              header="Orders"
              contentClassName="bg-transparent"
              leftIcon="pi pi-shopping-cart"
            >
              {isFetching ? (
                <Spinner />
              ) : (
                orders
                  ?.sort((a: any, b: any) => b.increment_id - a.increment_id)
                  ?.map((order: any) => (
                    <Order
                      key={order.increment_id}
                      orderData={order as any as OrderType}
                    />
                  )) || (
                  <div className="text-sm md:text-base">No orders found.</div>
                )
              )}
            </TabPanel>
            {!!digitalProducts.length && (
              <TabPanel
                header="Digital Content"
                leftIcon="pi pi-cloud-download"
              >
                <Digital />
              </TabPanel>
            )}
            {!!displayOrderPageReturnMarket && (
              <TabPanel
                header="Return To Marketplace"
                className="ml-auto"
                headerTemplate={tabReturnToMarketplace}
              />
            )}
          </TabView>
        ) : (
          <div
            className="flex flex-column align-items-center justify-content-center"
            style={{ height: '200px' }}
          >
            <p className="text-sm md:text-base">
              You have to login to view this page
            </p>
            <Button
              className="uppercase border-noround c-button-primary mx-auto"
              label="Login"
              onClick={login}
            />
          </div>
        )}
      </div>
    </div>
  );
}
