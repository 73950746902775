import { Link, useNavigate } from 'react-router-dom';
import { MenuItem } from 'primereact/menuitem';
import { useUserContext } from 'UserContext';
import { ReactElement, useEffect, useState } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { useCartContext } from 'CartContext';
import { useMarketplaceDomain } from 'hooks/useMarketplace';
import useLocalStorage from 'utils/useLocalStorage';
import Logo from 'assets/crwd/brand_market/crwdmarket_icon_b.svg';
import LogoLight from 'assets/crwd/brand_market/crwdmarket_icon_w.svg';
import useViewport from 'hooks/useViewport';
import { getMarketContent } from 'utils/marketContent';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import FocusLock from 'react-focus-lock';
import { ThemeSwitch } from '../../theme/index';
import './Nav.scss';

export default function Nav(): ReactElement {
  const { login, logout, userId, userEmail } = useUserContext();
  const { quantity, hideNav, showCart } = useCartContext();
  const [model, setModel] = useState<MenuItem[]>([]);
  const { market, navigation, hideSecondaryLogoNav } = getMarketContent();
  const navigate = useNavigate();
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);
  const [, setIsAMM] = useState<boolean>(true);
  const navLogo = isDarkFromLocalStroage ? LogoLight : Logo;
  const [marketPlaceLogo, setMarketPlaceLogo] = useState<any>();
  const { isMobile } = useViewport();
  const marketplaceDomain = useMarketplaceDomain();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (
      !['advancedmedicine', 'localhost'].includes(marketplaceDomain as string)
    ) {
      localStorage.setItem('isDark', 'false');
      setIsAMM(false);
    }
  }, [marketplaceDomain]);

  useEffect(() => {
    const marketPlaceLogo = isDarkFromLocalStroage
      ? navigation.logoDesktopLight
      : navigation.logoDesktopDark;
    setMarketPlaceLogo(marketPlaceLogo);
  }, [
    navigation.logoDesktopDark,
    navigation.logoDesktopLight,
    isDarkFromLocalStroage,
  ]);

  useEffect(() => {
    if (userId) {
      setModel([
        {
          label: userEmail || 'User',
          icon: 'pi pi-user',
          items: loggedInItems,
          className: 'nav-email mr-2',
        },
      ]);
    } else {
      setModel([...loggedOutItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userEmail]);

  const loggedInItems = [
    {
      label: 'Orders',
      command: () => navigate('/orders'),
    },
    {
      label: 'Logout',
      command: () => logout(window.location.pathname),
    },
  ];

  const loggedOutItems = [
    {
      label: '',
      icon: 'pi pi-user',
      command: () => login(),
    },
  ];
  if (hideNav) {
    return <></>;
  }

  return (
    <div className="nav m-0 w-screen">
      {isMobile ? (
        <div className="flex flex-row align-items-center justify-content-between w-full">
          <div className="flex flex-column md:flex-row">
            {!!marketPlaceLogo && (
              <img
                src={`${marketPlaceLogo}`}
                alt={market}
                className="crwd-nav-logo mr-4"
                style={{ maxWidth: '120px', minWidth: '120px' }}
              />
            )}
            {hideSecondaryLogoNav === false && (
              <div className="mt-0 mb-0 pb-0 max-w-max">
                <img
                  src={`${navLogo}`}
                  alt="CRWD logo"
                  className="crwd-nav-logo"
                  style={{ maxWidth: '120px', minWidth: '120px' }}
                />
              </div>
            )}
          </div>

          <div className="flex flex-row align-items-center">
            <Button
              icon="pi pi-bars max-h-max"
              onClick={() => setVisible(true)}
              className="p-button p-component border-noround p-button-outlined border-none p-button-secondary menu-bars p-button-icon-only"
            />
            <Button
              className="border-noround p-button-outlined border-none p-button-secondary mx-0 max-w-max py-2"
              onClick={() => showCart('bottom')}
            >
              <i
                className="pi pi-shopping-cart p-text-secondary p-overlay-badge pb-1"
                style={{
                  fontSize: '1.75rem',
                  transform: 'scale(0.75)',
                }}
              >
                <Badge value={quantity} />
              </i>
            </Button>
          </div>
          <Sidebar
            visible={visible}
            position="right"
            onHide={() => setVisible(false)}
            className="sidebar-h-full"
            closeOnEscape
            showCloseIcon={false}
          >
            <FocusLock className="flex flex-column h-full w-full">
              <Menu model={model} className="border-none w-full" />
              <div className="ml-2">
                <ThemeSwitch />
              </div>
              <Button
                icon="pi pi-times"
                label="close menu"
                className="uppercase border-noround flex p-button-outlined p-button-secondary ml-2 mt-auto w-full"
                onClick={() => setVisible(false)}
              />
            </FocusLock>
          </Sidebar>
        </div>
      ) : (
        <div
          className="px-3"
          style={{
            maxWidth: '1480px',
            margin: '0 auto',
            height: 'max-content',
          }}
        >
          <ul className="pr-2">
            <li className="flex flex-row align-items-center">
              {marketPlaceLogo && market !== 'crwdmarket' && (
                <span className="mb-0 pb-0">
                  <img
                    src={`${marketPlaceLogo}`}
                    alt={market}
                    className="crwd-nav-logo mr-4"
                    style={{ maxWidth: '120px', minWidth: '120px' }}
                  />
                </span>
              )}
              {market === 'crwdmarket' ? (
                <img
                  src={`${navLogo}`}
                  alt="CRWD logo"
                  className="crwd-nav-logo"
                />
              ) : (
                <>
                  {hideSecondaryLogoNav === false && (
                    <Link to="/">
                      <img
                        src={`${navLogo}`}
                        alt="CRWD logo"
                        className="crwd-nav-logo"
                      />
                    </Link>
                  )}
                </>
              )}
            </li>
            <li>
              <div className="nav--user-info">
                <TieredMenu model={model} />
                <ThemeSwitch />
                <Button
                  className="border-noround p-button-outlined border-none p-button-secondary mx-0 max-w-max pb-2 pt-2 ml-1"
                  onClick={() => showCart()}
                >
                  <i
                    className="pi pi-shopping-cart p-text-secondary p-overlay-badge pb-2"
                    style={{
                      fontSize: '1.75rem',
                      transform: 'scale(0.8)',
                    }}
                  >
                    <Badge value={quantity} />
                  </i>
                </Button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
