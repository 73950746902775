import crwdShopUrlsAndSkus from '../crwdShopUrlsAndSkus';

export const generateUrlToMatchSku = sku => {
  const urls = crwdShopUrlsAndSkus[sku];
  if (!urls) {
    return null;
  }
  const index = Math.floor(Math.random() * urls.length);
  return urls[index];
};

export const ammBlogProductSkuAndIages = {
  'ElderPower Elderberry Syrup': {
    sku: '2021-08-20-HSX-AMM-sb-001',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/e/l/elderberry.png',
  },
  'Woobamboo! Toothbrush': {
    sku: '2021-08-31-HSX-AMM-woo-001',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/w/o/woo_bamboo_toothbrush.png',
  },
  "Vika's Essentials": {
    sku: '2021-08-20-HSX-AMM-vika-003',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/s/h/shop_item_1_square.jpg',
  },
  'KePro: Harmonize Gut Bacteria & Digestion': {
    sku: '2021-08-20-HSX-AMM-kp-003',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/s/m/smoothie.png',
  },
  'Nexus Renew Wellness Formula': {
    sku: '2021-08-20-HSX-AMM-nn-002',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/n/u/nutrients2.png',
  },
  'Nexus Restore Wellness': {
    sku: '2021-08-20-HSX-AMM-nn-002',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/s/h/shop_item_7_restore_square.jpg',
  },
  'PureCBD with DMSO Pain Relief Topical': {
    sku: '2022-03-14-CDF-CNM-pcbd-025',
    link: 'https://belimitless.vip/',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/p/a/pain_relief.png',
  },
  'Vital Elements VITAluxe Hair Growth': {
    sku: '2021-08-20-HSX-AMM-ve-005',
    image:
      'https://store-api-dev.blockchainecosystem.exchange/media/catalog/product/cache/a9f5ade1cd454a2835f37e6197360fb2/h/a/hair_conditioner.png',
  },
};
