import { ReactElement, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { ProductFieldsFragment } from 'graphql/generated/magentoApi';

import { ScrollPanel } from 'primereact/scrollpanel';
import { TabView, TabPanel } from 'primereact/tabview';
import { useGetProduct } from 'hooks/magentoHooks';
import Galleria from 'components/Galleria';
import parse from 'html-react-parser';
import ProductDescription from 'components/ProductDescription';
import ProductInformation from 'components/ProductInformation';
import ProductLinked from 'components/ProductLinked';
import ProductReview from 'components/ProductReview';
import Spinner from 'components/Spinner';
import Stars from 'components/Stars';
import 'components/Product.scss';
import {
  TwitterButton,
  FacebookButton,
  EmailShareButton,
} from 'components/imedia/components/SocialMediaButtons';
import useViewport from 'hooks/useViewport';
import { ProductWithVariants } from 'types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getMarketContent } from 'utils/marketContent';
import { Fade } from 'react-awesome-reveal';
import { useLocation } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import { Head } from './head/Head';

import ProductCarousel from './product-carousel';

type ProductProps = {
  sku: string;
  isEmbedded?: boolean;
  fromIMedia?: boolean;
  classNames?: string;
};
export const filteredCategories = (root, allProductCategories) => {
  const categoryArray =
    allProductCategories?.filter(
      category =>
        category?.url_path !== `marketplace/${root}` &&
        category?.url_path?.includes(root),
    ) || [];
  if (root) {
    if (categoryArray.length) {
      return categoryArray.map(category => category?.name).join(', ');
    }
  }
  if (!root) {
    const marketplaceCategories = allProductCategories
      .filter(category => category.url_path.includes('marketplace/'))
      .map(category => category?.name)
      .join(', ');
    const shoppingCategories = allProductCategories
      .filter(category => category.url_path.includes('shopping/'))
      .map(category => category?.name)
      .join(', ');
    if (marketplaceCategories) {
      return marketplaceCategories;
    }
    if (!marketplaceCategories && shoppingCategories) {
      return shoppingCategories;
    }
  }

  return 'N/A';
};

const tabHeaderTemplate = options => (
  <button type="button" onClick={options.onClick} className={options.className}>
    {options.titleElement}
  </button>
);

export default function Product({
  sku,
  isEmbedded = false,
  fromIMedia = false,
  classNames = '',
}: ProductProps): ReactElement {
  document.addEventListener('DOMContentLoaded', () => <Fade />);
  const { rootCategory } = getMarketContent();
  const { product, fetching } = useGetProduct({ sku });
  const [showReviewForm, setShowReviewForm] = useState<boolean>(false);
  const [showFullDescription, setShowFullDescription] =
    useState<boolean>(false);
  const { isMobile } = useViewport();
  const location = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { updateCart } = useCartContext();

  useEffect(() => {
    if (isFirstLoad && location.pathname.includes('add-to-cart')) {
      setIsFirstLoad(false);
      updateCart({
        sku,
        quantity: 1,
        showCart: true,
        onlyAddOneIfNotPresent: true,
        debug: 'Product',
      });
    }
  }, [sku, location.pathname, updateCart, isFirstLoad]);

  // const [activeIndex, setActiveIndex] = useState(null);

  if (!product && fetching) {
    return (
      <Fade>
        <Spinner />
      </Fade>
    );
  }

  if (product) {
    const imageUrls: Record<string, any>[] = [];
    const videoUrls: string[] = [];
    product.media_gallery?.forEach(media => {
      if (media?.__typename === 'ProductVideo') {
        videoUrls.push(media.video_content?.video_url || '');
      } else {
        const urlAndPosition: Record<string, any> = {
          url: media?.url,
          position: media?.position,
        };
        imageUrls.push(urlAndPosition);
      }
    });
    // Product links (related or upsell)
    const upsellProducts =
      product.upsell_products?.map(item => item?.sku) || [];
    const relatedProducts =
      product.related_products?.map(item => item?.sku) || [];

    const showProductCategories = filteredCategories(
      rootCategory,
      product.categories,
    );

    return (
      <>
        <Head
          link={`/product/${product.sku}`}
          title={product.name ? product.name : 'Shop'}
          addPostfixTitle
          description={
            String(product.meta_description) ||
            'Our Ecosystem brings Big Data, AI, Compaction, and the Block to the next level while institutionalizing the emerging Blockchain technology. We deliver validity and real value based on finite transactions for Small and Mid Cap Companies. We provide Micro-Entrepreneurs with deep insight into a local portfolio and industry trends in real-time, so they win more often. Our use of the Blockchain helps businesses identify exposures and constraints for optimization and opportunities. Using the best of what is worked in the past, we proliferate success for your future on the Blockchain. We deliver Wall Street Capability with Main Street Practicality across many sectors'
          }
          imageCard={String(product.image?.url)}
          largeTwitterCard
        />
        <Fade>
          <div
            className={`product grid ${classNames} justify-content-between px-4 md:px-0`}
            style={{
              maxWidth: '1080px',
              margin: '1.5rem auto 0 auto',
              gap: '0px 0px',
            }}
          >
            <div className="col-12">
              <h1 className="font-normal mt-3 mb-2 h1-product-name">
                {product.name}
              </h1>
            </div>
            <div className="col-12 md:col-6">
              <Galleria images={imageUrls} videos={videoUrls} />
              {/* {!isMobile && Number(relatedProducts.length) > 0 && (
              <div className="px-5" style={{ marginTop: '260px' }}>
                <div className="uppercase font-medium text-lg mb-2">
                  Related products
                </div>
                <div className="grid">
                  {relatedProducts.map(productLinked => (
                    <ProductLinked
                      key={productLinked}
                      sku={productLinked || ''}
                      type="related"
                      isGrid
                    />
                  ))}
                </div>
              </div>
            )} */}
            </div>
            <div className="col-12 md:col-6 mb-6 lg:mb-0 flex-column">
              <ProductDescription
                product={product as ProductWithVariants}
                isEmbedded={isEmbedded}
                fromIMedia={fromIMedia}
              />
              {Number(upsellProducts.length) > 0 ? (
                <div className="mx-0 mb-2 relative">
                  <p className="uppercase font-medium text-lg p-bundle-header mt-0 mb-3">
                    Save more with a bundle!
                  </p>
                  <div className="grid">
                    <ProductCarousel products={upsellProducts} />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-12 mt-3 lg:mt-8">
              {isMobile ? (
                <Accordion activeIndex={0} className="mb-2">
                  <AccordionTab header="Description">
                    <div
                      className={`${
                        showFullDescription
                          ? 'toggle-description expand'
                          : 'toggle-description'
                      } text-sm mb-2`}
                    >
                      {parse(product.description?.html || '')}
                    </div>
                    <p className="mt-0 my-2 p-product-vendor">
                      <span className="font-bold">Categories: </span>
                      <span>{showProductCategories}</span>
                    </p>
                    <p className="mt-0 mb-4 p-product-vendor">
                      <span className="font-bold">Vendor: </span>
                      <span>{product.vendor_company}</span>
                    </p>

                    <Button
                      label={showFullDescription ? 'Show Less' : 'Show More'}
                      className="p-button-outlined p-button-secondary"
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    />
                  </AccordionTab>
                  <AccordionTab header="Reviews">
                    {product.reviews.items.length ? (
                      <>
                        <div className="flex mb-3">
                          <Stars
                            rating={((product.rating_summary || 0) * 5) / 100}
                            classNames="mr-2"
                          />
                          <div className="mr-2">
                            {Math.ceil(
                              ((product.rating_summary || 0) * 5) / 100,
                            ).toFixed(1)}
                          </div>
                          <span className="mr-2">|</span>
                          <div>{`${product.review_count} Reviews`}</div>
                        </div>
                        <div className="flex justify-content-start md:justify-content-end mb-3">
                          <Button
                            className={`uppercase  text-center ${
                              fromIMedia
                                ? 'write-review-btn'
                                : 'c-button-primary border-noround'
                            }`}
                            label="Write a review"
                            onClick={() => setShowReviewForm(true)}
                          />
                        </div>
                        <ScrollPanel
                          style={{ width: '100%', maxHeight: '1000px' }}
                          className="reviews-container"
                        >
                          {product.reviews.items.map((review, i) => (
                            <div
                              key={review?.created_at}
                              className={`flex flex-column ${
                                i + 1 !== product.reviews.items.length
                                  ? 'mb-3'
                                  : ''
                              }`}
                            >
                              <div className="flex mb-1">
                                <Stars
                                  rating={
                                    ((review?.average_rating || 0) * 5) / 100
                                  }
                                  classNames="mr-2"
                                />
                                <div className="mr-2">{review?.nickname}</div>
                                <div>
                                  {/* <span className="mr-2">·</span>

                                {formatDistance(
                                  new Date(
                                    review?.created_at?.replace(/ /g, 'T'),
                                  ),
                                  new Date(),
                                  {
                                    addSuffix: true,
                                  },
                                )} */}
                                </div>
                              </div>
                              <div className="font-medium text-lg mb-1">
                                {review?.summary}
                              </div>
                              <div>{review?.text}</div>
                            </div>
                          ))}
                        </ScrollPanel>
                      </>
                    ) : (
                      <>
                        <p className="text-sm mt-0 mb-3">
                          No reviews for this product yet
                        </p>
                        <div className="flex justify-content-end">
                          <Button
                            className={`uppercase text-center ${
                              fromIMedia
                                ? 'write-review-btn'
                                : 'c-button-primary border-noround'
                            }`}
                            label="Write a review"
                            onClick={() => setShowReviewForm(true)}
                          />
                        </div>
                      </>
                    )}
                  </AccordionTab>
                  <AccordionTab header="Information">
                    <ProductInformation
                      product={product as ProductFieldsFragment}
                      displayCategories={showProductCategories}
                    />
                  </AccordionTab>
                </Accordion>
              ) : (
                <TabView className="mb-2">
                  <TabPanel
                    header="Description"
                    aria-label="Product description tab"
                    aria-owns="tab"
                    headerTemplate={tabHeaderTemplate}
                  >
                    <div
                      className={`${
                        showFullDescription
                          ? 'toggle-description expand'
                          : 'toggle-description'
                      } text-sm mb-0`}
                    >
                      {parse(product.description?.html || '')}
                    </div>
                    {/* {filteredCategories.length > 0 && (
                      <p className="m-0 product-categories">
                        <span className="font-bold">Categories: </span>
                        {filteredCategories?.map(category => (
                          <React.Fragment key={category?.uid}>
                            {!displayProductVisitWebsite ? (
                              <span className="ml-1 category-link">
                                {category?.name}
                              </span>
                            ) : (
                              <Link
                                className="ml-1 category-link"
                                to={`/category/market/${category?.url_path}`}
                              >
                                {category?.name}
                              </Link>
                            )}
                          </React.Fragment>
                        ))}
                      </p>
                    )} */}
                    <p className="mt-0 my-2 p-product-vendor">
                      <span className="font-bold">Categories: </span>
                      <span>{showProductCategories}</span>
                    </p>
                    <p className="mt-0 mb-4 p-product-vendor">
                      <span className="font-bold">Vendor: </span>
                      <span>{product.vendor_company}</span>
                    </p>

                    <Button
                      label={showFullDescription ? 'Show Less' : 'Show More'}
                      className="p-button-outlined p-button-secondary"
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    />
                  </TabPanel>
                  <TabPanel
                    header="Reviews"
                    aria-label="Product reviews tab"
                    aria-owns="tab"
                    headerTemplate={tabHeaderTemplate}
                  >
                    {product.reviews.items.length ? (
                      <>
                        <div className="flex mb-3">
                          <Stars
                            rating={((product.rating_summary || 0) * 5) / 100}
                            classNames="mr-2"
                          />
                          <div className="mr-2">
                            {Math.ceil(
                              ((product.rating_summary || 0) * 5) / 100,
                            ).toFixed(1)}
                          </div>
                          <span className="mr-2">|</span>
                          <div>{`${product.review_count} Reviews`}</div>
                        </div>
                        <div className="flex justify-content-start md:justify-content-end mb-3">
                          <Button
                            className={`uppercase  text-center ${
                              fromIMedia
                                ? 'write-review-btn'
                                : 'c-button-primary border-noround'
                            }`}
                            label="Write a review"
                            onClick={() => setShowReviewForm(true)}
                          />
                        </div>
                        <ScrollPanel
                          style={{ width: '100%', maxHeight: '1000px' }}
                          className="reviews-container"
                        >
                          {product.reviews.items.map((review, i) => (
                            <div
                              key={review?.created_at}
                              className={`flex flex-column ${
                                i + 1 !== product.reviews.items.length
                                  ? 'mb-3'
                                  : ''
                              }`}
                            >
                              <div className="flex mb-1">
                                <Stars
                                  rating={
                                    ((review?.average_rating || 0) * 5) / 100
                                  }
                                  classNames="mr-2"
                                />
                                <div className="mr-2">{review?.nickname}</div>
                              </div>
                              <div className="font-medium text-lg mb-1">
                                {review?.summary}
                              </div>
                              <div>{review?.text}</div>
                            </div>
                          ))}
                        </ScrollPanel>
                      </>
                    ) : (
                      <>
                        <p className="text-sm mt-0 mb-3">
                          No reviews for this product yet
                        </p>
                        <div className="flex justify-content-end">
                          <Button
                            className={`uppercase text-center ${
                              fromIMedia
                                ? 'write-review-btn'
                                : 'c-button-primary border-noround'
                            }`}
                            label="Write a review"
                            onClick={() => setShowReviewForm(true)}
                          />
                        </div>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel
                    header="Information"
                    aria-label="Product information tab"
                    aria-owns="tab"
                    headerTemplate={tabHeaderTemplate}
                  >
                    <ProductInformation
                      product={product as ProductFieldsFragment}
                      displayCategories={showProductCategories}
                    />
                  </TabPanel>
                </TabView>
              )}
              <div className="flex flex-row product-description-social-icons mb-4">
                <TwitterButton url={window.location.href} />
                <FacebookButton url={window.location.href} />
                <EmailShareButton url={window.location.href} />
              </div>
            </div>
            {Number(relatedProducts.length) > 0 && (
              <div className="flex flex-column col-12 mb-2">
                <div className="uppercase font-medium text-lg mb-2">
                  Related products
                </div>
                <div className="grid mb-8">
                  {relatedProducts.map(productLinked => (
                    <ProductLinked
                      key={productLinked}
                      sku={productLinked || ''}
                      type="related"
                      isGrid
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* {!fromIMedia && <Footer />} */}
          <ProductReview
            showReviewForm={showReviewForm}
            fromIMedia={fromIMedia}
            productSku={product.sku || ''}
            setShowReviewForm={setShowReviewForm}
          />
        </Fade>
      </>
    );
  }
  return <></>;
}
