import { HashLink } from 'react-router-hash-link';
import { ReactElement } from 'react';
import { useContent } from 'utils/useContent';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import './scss/TopHeader.scss';
import { useMatomo } from 'matomoReact';

export default function TopHeader(): ReactElement {
  const data = useContent();
  const { trackEvent } = useMatomo();

  return (
    <section
      className={`flex text-center md:text-left top-header text-white flex flex-column justify-content-center relative ${
        data?.participate.headerAlignRight
          ? 'md:align-items-end'
          : 'md:align-items-start'
      }
      ${data?.participate.exchangeName}
      `}
      style={{
        backgroundImage: `url(${data.participate.backgroundImage})`,
      }}
    >
      <div
        className={`top-header__gradient z-2 flex flex-column justify-content-center align-items-center md:align-items-start ${data?.participate.exchangeName}`}
      >
        <h1 className="text-6xl font-normal capitalize main-heading-title">
          {parse(data?.participate.header)}
        </h1>
        <p
          className="text text-lg mt-0 md:text-left"
          style={{ marginBottom: '44px' }}
        >
          {parse(data?.participate.text)}
        </p>
        <div className="flex flex-wrap flex-column md:flex-row w-full">
          {data?.participate.button.map(
            (obj: { text: string; link: string; class?: string }) => (
              <HashLink
                key={obj.link}
                to={obj.link}
                id={
                  obj.class?.includes('cta-primary')
                    ? 'Hero-primary-action'
                    : 'Hero-secondary-action'
                }
                className={
                  obj.class?.includes('cta-primary')
                    ? `w-full md:w-auto text-white border-gray-50 border-1 px-6 py-3 cursor-pointer no-underline button--white--filled ${obj.class}`
                    : `ml-0 md:ml-6 mt-4 md:mt-0 w-full md:w-auto bg-transparent text-white border-none px-4 py-3 cursor-pointer no-underline text--link--white ${obj.class}`
                }
                smooth
                onClick={() => {
                  ReactGA.event({
                    category: `${
                      obj.class?.includes('cta-primary')
                        ? 'landing-page'
                        : 'landing-page'
                    }`,
                    action: `${
                      obj.class?.includes('cta-primary')
                        ? 'Landing Page - Hero-primary-action'
                        : 'Landing Page - Hero-secondary-action'
                    }`,
                    label: `${
                      obj.class?.includes('cta-primary')
                        ? 'Landing Page - Hero-primary-action'
                        : 'Landing Page - Hero-secondary-action'
                    }`,
                  });
                  trackEvent({
                    category: `${
                      obj.class?.includes('cta-primary')
                        ? 'landing-page'
                        : 'landing-page'
                    }`,
                    action: `${
                      obj.class?.includes('cta-primary')
                        ? 'Landing Page - Hero-primary-action'
                        : 'Landing Page - Hero-secondary-action'
                    }`,
                    name: `${
                      obj.class?.includes('cta-primary')
                        ? 'Landing Page - Hero-primary-action'
                        : 'Landing Page - Hero-secondary-action'
                    }`,
                  });
                }}
              >
                {obj.text}
              </HashLink>
            ),
          )}
        </div>
      </div>
    </section>
  );
}
