import { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function ColorParam(): ReactElement {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const searchEntries = Object.fromEntries(searchParams.entries());
    if (searchEntries.color) {
      const re = new RegExp('^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
      if (re.test(searchEntries.color)) {
        document.documentElement.style.setProperty(
          '--c-primary-color',
          `#${searchEntries.color}`,
        );
      } else {
        document.documentElement.style.setProperty(
          '--c-primary-color',
          `${searchEntries.color}`,
        );
      }
    }
  }, [searchParams]);
  return <></>;
}
