import ammLogo from 'assets/amm-logo-white.png';
import ammLogoCentered from 'assets/AMM_dark_logo_centered.png';
import immLogo from 'assets/logo-iMedia.svg';
import immLogoCentered from 'assets/IMM_dark_logo_centered.png';
import cpmLogo from 'assets/cpm-logo-white.png';
import cbexLogoCentered from 'assets/CBEX_light_logo_centered.png';

export interface MarketPlace {
  subdomain: string;
  url: string;
  domain: string;
  name: string;
  categories: string;
  logo: string;
  logoCentered: string;
}

const marketplaces: Record<string, MarketPlace> = {
  amm: {
    subdomain: 'amm',
    url: 'https://amm.healthcaresector.exchange',
    domain: 'advancedmedicine',
    name: 'Advanced Medicine Marketplace',
    categories: 'shopping/beauty,shopping/health',
    logo: ammLogo,
    logoCentered: ammLogoCentered,
  },
  imm: {
    subdomain: 'imm',
    url: 'https://imm.communicationssector.exchange',
    domain: 'communicationssector',
    name: 'Interactive Medias Marketplace',
    categories:
      'shopping/digital-content-devices/digital-content,shopping/handmade/toys-games',
    logo: immLogo,
    logoCentered: immLogoCentered,
  },
  cpm: {
    subdomain: 'cpm',
    url: 'https://cpm.infotechsector.exchange',
    domain: 'infotechsector',
    name: 'Information Technology Exchange',
    categories: 'shopping/computers/software',
    logo: cpmLogo,
    logoCentered: cbexLogoCentered,
  },
  advancedmedicine: {
    subdomain: 'www',
    url: 'http://advancedmedicine.market/',
    domain: 'advancedmedicine',
    name: 'Advanced Medicine Marketplace',
    categories: 'shopping/beauty,shopping/health',
    logo: ammLogo,
    logoCentered: ammLogoCentered,
  },
  localhost: {
    subdomain: 'localhost',
    url: 'http://localhost:3000/',
    domain: 'localhost',
    name: 'Advanced Medicine Marketplace',
    categories: 'shopping/beauty,shopping/health',
    logo: ammLogo,
    logoCentered: ammLogoCentered,
  },
};

export function useMarketplaceSubdomain(): string | undefined {
  const hrefStart = window.location.href.split('.')[0];
  let subdomain = hrefStart.slice(hrefStart.lastIndexOf('/') + 1);
  if (subdomain.endsWith('-dev')) {
    subdomain = subdomain.replace('-dev', '');
  }
  if (marketplaces[subdomain]) {
    return subdomain;
  }
  return undefined;
}

export function useIsMarketplace(): boolean {
  return useMarketplaceSubdomain() !== undefined;
}

export function useMarketplace(): MarketPlace | undefined {
  const subdomain = useMarketplaceSubdomain() || '';
  return marketplaces[subdomain];
}

export function useMarketplaceDomain(): string | undefined {
  const initialDomain = window.location.hostname;
  const splitHostname = initialDomain.split('.');
  const domain =
    splitHostname[0] === 'localhost' ? splitHostname[0] : splitHostname[1];
  if (marketplaces[domain]) {
    return marketplaces[domain].domain;
  }
  return domain;
}

export function useIsMarketplaceDomain(): boolean {
  return useMarketplaceDomain() !== undefined;
}

export function useMarketplaceSiteDomain(): MarketPlace | undefined {
  const domain = useMarketplaceDomain() || '';
  return marketplaces[domain];
}
