import React, { useState, useEffect, ReactElement } from 'react';
import parse from 'html-react-parser';
import './vimeo-player.scss';
import { Skeleton } from 'primereact/skeleton';

type Props = {
  url: string;
  isVertical?: boolean;
};

const VimeoPlayer = ({ url, isVertical }: Props): ReactElement => {
  const [vimeoHtml, setVimeoHtml] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!vimeoHtml) {
      setLoading(true);
      fetch(`https://vimeo.com/api/oembed.json?url=${url}`)
        .then(response => response.json())
        .then(json => {
          setVimeoHtml(json.html);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          console.log(e);
        });
    }
  }, [url, vimeoHtml]);

  if (loading) {
    return <Skeleton width="100%" height="100%" className="product-height" />;
  }

  if (!vimeoHtml) {
    return (
      <div>
        <p>Video Not Found</p>
      </div>
    );
  }

  return (
    <div
      className={`w-full video-iframe-container ${
        isVertical && 'vertical-video'
      }`}
    >
      {parse(vimeoHtml)}
    </div>
  );
};

export default VimeoPlayer;
