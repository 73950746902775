import React from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import './index.scss';

export type FormType = {
  instruction: { text: string; class: string };
  wrapper_class: string;
  section_class: string;
  image: {
    src: string;
    alt: string;
    title: string;
  };
  title: {
    text: string;
    class: string;
  };
  subtitle: {
    text: string;
    class: string;
  };
  formTitle: { text: string; class: string };
  formSubtitle: { text: string; class: string };
  formTextDescription: string | null;
  imageColumn: string | null;
  formInstruction: string | null;
  formStart: boolean;
  formFields: [
    {
      id: string | undefined;
      label: string | null;
      rules: string | null;
    },
    {
      id: string | undefined;
      label: string | null;
      rules: string | null;
    },
    {
      id: string | undefined;
      label: string | null;
      rules: string | null;
    },
    {
      id: string | undefined;
      label: string | null;
      rules: string | null;
    },
  ];
  submitButton: {
    label: string;
    class: string;
  };
};

export interface FormProps {
  formSections: FormType[];
  imageDivider?: {
    src: string;
    alt: string;
    title: string;
    class: string;
  };
}

const Forms = ({ formSections, imageDivider }: FormProps) =>
  formSections && formSections.length ? (
    <>
      {imageDivider && (
        <div>
          <img
            src={imageDivider.src}
            alt={imageDivider.alt}
            title={imageDivider.title}
            className={imageDivider.class}
          />
        </div>
      )}

      {formSections?.map(section => (
        <div key={JSON.stringify(section)} className={section?.wrapper_class}>
          <div
            className={`${section?.section_class} ${
              section?.formStart ? 'flex-row' : 'flex-row-reverse'
            }`}
          >
            <div
              className={classNames('w-12 py-6', {
                'lg:w-6': section?.image,
              })}
            >
              {section?.title && (
                <h1 className={section?.title.class}>{section?.title.text}</h1>
              )}

              <div
                className={classNames(
                  `flex flex-wrap lg:flex-nowrap justify-content-center ${
                    section?.subtitle ? 'flex-row p-0 md:p-4 ' : 'flex-column'
                  }`,
                )}
                style={{ gap: section?.subtitle ? '32px' : '0px' }}
              >
                {section?.subtitle && section?.instruction && (
                  <div>
                    <h1 className={section?.subtitle.class}>
                      {section?.subtitle.text}
                    </h1>

                    <span className={section?.instruction.class}>
                      {section?.instruction.text}
                    </span>
                  </div>
                )}
                {section?.formTitle && (
                  <h1 className={section?.formTitle.class}>
                    {section?.formTitle.text}
                  </h1>
                )}
                {section?.formSubtitle && (
                  <h2 className={section?.formSubtitle.class}>
                    {section?.formSubtitle.text}
                  </h2>
                )}
                <form>
                  <div className="grid py-2">
                    {section?.formFields.map(field => (
                      <div key={field.id} className="col-12 md:col-6 py-3">
                        <span className="p-float-label">
                          <InputText id={field.id} className="w-full" />
                          <label htmlFor="firstName">{field.label}</label>
                        </span>
                      </div>
                    ))}
                  </div>
                  <Button
                    className={section.submitButton?.class}
                    label={section.submitButton?.label}
                  />
                </form>
              </div>
            </div>
            {section?.image && (
              <div className="form-section-image-container">
                <img
                  src={section.image.src}
                  alt={section.image.alt}
                  title={section.image.title}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  ) : null;
export default Forms;
