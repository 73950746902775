import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { Button } from 'primereact/button';
import BlogTemplate from 'components/blog-template';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import parse from 'html-react-parser';
import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import { generateUrlToMatchSku } from 'utils/generateMarketUrls';
import { Fade } from 'react-awesome-reveal';
import buttonize from 'utils/buttonize';
import BlogAuthorDetails from 'components/blog-author-details';
import { useCartContext } from 'CartContext';
import { useHubspotBlogPostsQuery } from 'graphql/generated/reactQuery';
import { useClient } from 'UserContext';
import MarketPlaceBtnDialog from './MarketPlaceBtnDialog';
import './index.scss';

export default function ShopBlogDetails() {
  const [currentBlog, setCurrentBlog] = useState<any>();
  const { visitEdgeSite } = useCartContext();
  const client = useClient();
  const { blogID } = useParams();
  const market = getMarketContent();
  const dataBlogID = market.blogID;
  const dataBlogIDSMB = market.blogIDSMB;
  const { isFetching, data } = useHubspotBlogPostsQuery(client, {
    tagIds: [dataBlogID, dataBlogIDSMB],
  });
  const [display, setDisplay] = useState<boolean>(false);
  const [sku, setSku] = useState(null);
  const [vendorLink, setVendorLink] = useState(null);
  const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(
    sku ? generateUrlToMatchSku(sku) : null,
  );

  const onClickVisitWebsiteBtn = useCallback(
    (url: string) => {
      setDisplay(true);
      setTimeout(() => {
        setDisplay(false);
        visitEdgeSite(url, false);
      }, 3000);

      setWebsiteUrl(generateUrlToMatchSku(url));
    },
    [visitEdgeSite],
  );

  const allBlogs = useMemo(
    () => data?.hubspotBlogPosts || [],
    [data?.hubspotBlogPosts],
  );

  useEffect(() => {
    if (allBlogs) {
      const cb = allBlogs?.filter(blog => blog.id === blogID);
      const avilBlog = cb?.length > 0 ? cb[0] : undefined;
      setCurrentBlog(avilBlog);
    }
    if (currentBlog) {
      if (currentBlog.widgets.blogProductSku) {
        if (currentBlog.widgets.blogProductSku.body.value !== sku) {
          setSku(currentBlog.widgets.blogProductSku.body.value);
          setWebsiteUrl(
            generateUrlToMatchSku(
              currentBlog.widgets.blogProductSku.body.value,
            ),
          );
        }
      }
      if (currentBlog.widgets.blogVendorLink) {
        if (currentBlog.widgets.blogVendorLink.body.value !== vendorLink) {
          setVendorLink(currentBlog.widgets.blogVendorLink.body.value);
        }
      }
    }
  }, [blogID, allBlogs, currentBlog, websiteUrl, sku, vendorLink]);

  if (isFetching || !currentBlog) {
    return (
      <>
        <ShopNavBar />
        <Fade triggerOnce>
          <Spinner />
        </Fade>
      </>
    );
  }

  return (
    <>
      <MarketPlaceBtnDialog
        display={display}
        setDisplay={setDisplay}
        image={currentBlog.featuredImage}
        header={currentBlog.htmlTitle}
        vendorName={vendorLink || websiteUrl || ''}
      />
      <Head
        link={`/blog/${currentBlog.id}`}
        title={currentBlog.htmlTitle}
        description={currentBlog.metaDescription}
        keywords={`blogs, natural medicine, health blogs, wellness blogs, ${currentBlog.htmlTitle}`}
        addPostfixTitle
        imageCard={currentBlog.featuredImage}
      />
      <ShopNavBar />
      <div className="w-10 md:w-8 lg:w-6 mx-auto">
        <section
          id="shop-blog-details"
          className="flex flex-column justify-content-center align-items-center"
        >
          <div className="grid w-full">
            <div className="col-12">
              <Fade triggerOnce>
                <h1 className="blog-title mb-3">
                  {parse(currentBlog.htmlTitle)}
                </h1>
              </Fade>
            </div>
            <div className="mx-3 col-12">
              {currentBlog.featuredImage && (
                <Fade triggerOnce>
                  <img
                    src={currentBlog.featuredImage}
                    alt={currentBlog.htmlTitle}
                    className="block w-full p-0 mx-auto mb-3"
                    style={{
                      background: 'none',
                      objectFit: 'scale-down',
                      maxHeight: '450px',
                    }}
                  />
                </Fade>
              )}
              {currentBlog.postBody && (
                <Fade triggerOnce>
                  <span className="blog-content">
                    {parse(currentBlog.postBody)}
                  </span>
                </Fade>
              )}
            </div>
          </div>
          {(vendorLink || websiteUrl) && currentBlog && (
            <div
              className={`cta-item cursor-pointer max-w-max ${
                (vendorLink && 'vendorLink') || (websiteUrl && 'websiteUrl')
              }`}
              {...buttonize(() =>
                vendorLink
                  ? vendorLink && onClickVisitWebsiteBtn(vendorLink)
                  : websiteUrl &&
                    !vendorLink &&
                    onClickVisitWebsiteBtn(websiteUrl),
              )}
            >
              <div className="w-6">
                <Fade triggerOnce>
                  <img
                    src={currentBlog.featuredImage}
                    alt={currentBlog.htmlTitle}
                    className="w-full h-full p-0"
                  />
                </Fade>
              </div>
              <Fade triggerOnce>
                <div className="content">
                  <Button className="cta-readmore" label="SHOP NOW" />
                </div>
              </Fade>
            </div>
          )}
          {!!currentBlog.widgets.blogAuthorInfo && (
            <div className="col-12 mx-auto mt-6 mb-2">
              <BlogAuthorDetails
                authorDetails={
                  currentBlog.widgets.blogAuthorInfo.body.blog_author
                }
              />
            </div>
          )}
        </section>
        <div className="block2">
          <h2 className="section_header">RELATED POSTS</h2>
          <Fade triggerOnce cascade>
            <div className="grid">
              {allBlogs?.slice(0, 6).map(blog => (
                <div key={blog.id} className="col-12 md:col-6 lg:col-4 mb-3">
                  <BlogTemplate {...blog} />
                </div>
              ))}
            </div>
          </Fade>
        </div>
      </div>
      <MarketplaceFooter />
    </>
  );
}
