import React from 'react';
import { Address } from 'types';
import countries from 'utils/countries.json';
import usStates from 'utils/usStates.json';

export interface RegionCountryCodes {
  countryCode?: string;
  regionCode?: string;
}

export function lookupCountryCode(country: string): string | undefined {
  const name = country.toLowerCase();
  const foundCountry = countries.find(
    c => name === c.name.toLowerCase() || name === c.code.toLowerCase(),
  );
  return foundCountry?.code;
}

export function lookupCountryAndRegionCodes(
  country: string,
  region: string,
): RegionCountryCodes {
  const countryCode = lookupCountryCode(country);
  let regionCode: string | undefined;
  switch (countryCode) {
    case 'US':
      regionCode = regionCodeForUsState(region);
      break;
    case 'CA':
    case 'UK':
    default:
      break;
  }
  return { countryCode, regionCode };
}

function regionCodeForUsState(state: string): string | undefined {
  const name = state.toLowerCase();
  const foundState = usStates.find(
    s => name === s.name.toLowerCase() || name === s.abbreviation.toLowerCase(),
  );
  return foundState?.abbreviation;
}

export function addressLabel(address?: Address) {
  if (!address) {
    return null;
  }

  let zip = address.postalCode;
  if (address.postalCode && address.postalPlus4) {
    zip = `${address.postalCode}-${address.postalPlus4}`;
  }
  return (
    <>
      {address.street1}
      {address.street2 && (
        <>
          <br />
          {address.street2}
        </>
      )}
      <br />
      {`${address.city}, ${address.stateCode} ${zip}`}
    </>
  );
}

export function addressesAreEqualExceptLatLon(a?: Address, b?: Address) {
  return (
    a?.street1 === b?.street1 &&
    (a?.street2 || undefined) === (b?.street2 || undefined) &&
    a?.city === b?.city &&
    a?.stateCode === b?.stateCode &&
    a?.postalCode === b?.postalCode &&
    (a?.postalPlus4 || undefined) === (b?.postalPlus4 || undefined) &&
    a?.countryCode === b?.countryCode
  );
}
