import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { isEqual } from 'lodash';
import { Address } from 'types';
import { validateUsAddress } from 'utils/smartyStreetsApi';
import {
  addressesAreEqualExceptLatLon,
  addressLabel,
} from 'utils/addressTools';
import { useCartContext } from 'CartContext';
import AddressMap from './AddressMap';

export interface ValidateAddressDialogProps {
  visible: boolean;
  setIsVisible: (visible: boolean) => void;
  addressToValidate: Address | undefined;
  setApprovedAddress: (address: Address) => void;
}

export default function ValidateAddressDialog({
  visible,
  setIsVisible,
  addressToValidate,
  setApprovedAddress,
}: ValidateAddressDialogProps) {
  const { isEmbedded } = useCartContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentAddress, setCurrentAddress] = useState<Address | undefined>();
  const [validatedAddress, setValidatedAddress] = useState<
    Address | undefined
  >();

  const isUsAddress = currentAddress?.countryCode === 'US';

  const handleApprove = (address: Address) => {
    setApprovedAddress(address);
    setIsVisible(false);
  };

  const handleDismiss = () => {
    setIsVisible(false);
    setError('');
  };

  useEffect(() => {
    if (!loading && !validatedAddress && !error && currentAddress) {
      if (!isUsAddress) {
        setValidatedAddress(currentAddress);
        return;
      }
      setLoading(true);
      validateUsAddress(currentAddress)
        .then(result => {
          if (result.isValid) {
            setValidatedAddress(result.address);
          } else {
            setError('Invalid Address');
          }
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [currentAddress, loading, error, validatedAddress, isUsAddress]);

  useEffect(() => {
    if (!isEqual(currentAddress, addressToValidate)) {
      setCurrentAddress(addressToValidate);
      setValidatedAddress(undefined);
    }
    setError('');
  }, [currentAddress, addressToValidate]);

  const validAddressIsSame = addressesAreEqualExceptLatLon(
    currentAddress,
    validatedAddress,
  );

  return (
    <Dialog
      visible={visible}
      onHide={handleDismiss}
      breakpoints={{ '1200px': '70vw', '960px': '75vw', '640px': '85vw' }}
      className="address-validation"
      header="Address Validation"
      draggable={false}
    >
      <>
        {loading ? (
          <div className="flex justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            {' '}
            <div className="px-4">
              {!!error && (
                <p className="mt-0 mb-4">{`${error} The address needs to be updated.`}</p>
              )}
              {!isUsAddress ? (
                <p className="mt-0 mb-4">
                  Address validation currently supported for US addresses only.
                  <br /> <br />
                  Please hit continue without a validated US address.
                </p>
              ) : (
                <div className="grid">
                  <div
                    className={`col-12 text-center ${
                      isEmbedded ? 'sm:col-12 text-center' : 'sm:col-6'
                    } `}
                  >
                    <p className="font-bold block my-1 md:my-3">You Entered:</p>
                    <p className="my-1 md:my-3">
                      {addressLabel(addressToValidate)}
                    </p>
                  </div>
                  {!error && (
                    <div
                      className={`col-12 text-center ${
                        isEmbedded ? 'sm:col-12 text-center' : 'sm:col-6'
                      } `}
                    >
                      <p className="font-bold block my-1 md:my-3">
                        Validated Address:
                      </p>
                      <p className="my-1 md:my-3">
                        {addressLabel(validatedAddress)}
                      </p>
                    </div>
                  )}
                  {!!validatedAddress && (
                    <div className="col-12 ">
                      <AddressMap validatedAddress={validatedAddress} />
                    </div>
                  )}
                </div>
              )}
            </div>
            {!!validatedAddress && !error && (
              <div className="p-4 flex justify-content-end">
                <Button
                  label={
                    validAddressIsSame || !isUsAddress
                      ? 'Continue'
                      : 'Approve Change'
                  }
                  className="uppercase border-noround c-button-primary"
                  onClick={() => {
                    handleApprove(validatedAddress);
                  }}
                />
              </div>
            )}
          </>
        )}
      </>
    </Dialog>
  );
}
