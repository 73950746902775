import { HashLink } from 'react-router-hash-link';
import {
  leftSlideDesktop,
  leftSlideMobile,
  rightSlideDesktop,
  rightSlideMobile,
} from 'components/imedia/components/Animations';
import { Link } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { Reveal } from 'react-awesome-reveal';
import { useContent } from 'utils/useContent';
import Logo from 'assets/crwd/brand_market/crwdmarket_icon_w.svg';
import parse from 'html-react-parser';
import ScrollButton from 'components/imedia/components/ScrollButton';
import './scss/Footer.scss';
import { useUserContext } from 'UserContext';

export default function Footer(): ReactElement {
  const data = useContent();
  const { login, logout, userId } = useUserContext();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <footer className="mt-0">
      <div className="footer-section">
        <div className="footer--top py-8 px-4">
          <div className="grid flex flex-wrap justify-content-start lg:justify-content-center align-items-start w-full">
            <div className="col-12 md:col-6 lg:col-4 flex justify-content-end p-0 m-0 mr-4 lg:mr-8 mb-6 lg:mb-0">
              <Reveal
                keyframes={isMobile ? leftSlideMobile : leftSlideDesktop}
                triggerOnce
              >
                <div>
                  <img
                    src={Logo}
                    alt="crwd Market"
                    className="footer-logo block m-0 mb-4"
                  />
                  {data.footer.text && (
                    <p className="m-0">{parse(data.footer.text)}</p>
                  )}
                </div>
              </Reveal>
            </div>
            <Reveal
              keyframes={isMobile ? rightSlideMobile : rightSlideDesktop}
              triggerOnce
            >
              <div
                className="col-12 md:col-4 flex flex-row align-items-start justify-content-start"
                style={{ padding: '0.5rem 0' }}
              >
                <ul className="list-none m-0 mr-8 p-0">
                  <li className="mb-3">
                    <h5 className="text-white m-0 font-bold uppercase">
                      Navigation
                    </h5>
                  </li>
                  <li className="mb-3">
                    <HashLink
                      to="#my-story"
                      className="text-white no-underline"
                      smooth
                    >
                      My Story
                    </HashLink>
                  </li>
                  <li className="mb-3">
                    <HashLink
                      to="#my-vision"
                      className="text-white no-underline"
                      smooth
                    >
                      My Vision
                    </HashLink>
                  </li>
                  <li className="mb-3">
                    <HashLink
                      to="#my-message"
                      className="text-white no-underline"
                      smooth
                    >
                      My Message
                    </HashLink>
                  </li>
                  <li className="mb-3">
                    <HashLink
                      to="#products"
                      className="text-white no-underline"
                      smooth
                    >
                      Products
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      to="#testimonials"
                      className="text-white no-underline"
                      smooth
                    >
                      Testimonials
                    </HashLink>
                  </li>
                </ul>
                <ul className="list-none m-0 p-0">
                  <li className="mb-3">
                    <h5 className="text-white m-0 font-bold uppercase">Shop</h5>
                  </li>
                  <li className="mb-3">
                    <Link to="/cart" className="text-white no-underline">
                      Cart
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="uppercase text-white no-underline bg-transparent p-0"
                      onClick={() => (userId ? logout() : login())}
                      style={{
                        fontSize: '12px',
                        border: 'none',
                        letterSpacing: '2px',
                        fontWeight: 900,
                        cursor: 'pointer',
                      }}
                    >
                      {userId ? 'Logout' : 'Login'}
                    </button>
                  </li>
                </ul>
              </div>
            </Reveal>
          </div>
          <ScrollButton />
        </div>
        <div className="footer--bottom">
          <p>
            {data?.footer.store} ON IMM © {new Date().getFullYear()} •{' '}
            <Link to="/privacy-terms" target="_blank">
              Privacy & Terms
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
