import { ReactElement, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserManager } from 'oidc-client-ts';
import { useLocation, Routes, Route } from 'react-router-dom';
import { Provider, defaultExchanges, createClient } from 'urql';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMatomo } from 'matomoReact';
import { AuthProvider } from 'auth';
import { CartProvider } from 'CartContext';
import CategoryTree from 'pages/CategoryTree';
import ErrorPage from 'components/imedia/components/ErrorPage';
import ExchangeSiteMap from 'components/imedia/components/ExchangeSiteMap';
import Store from 'components/imedia/Store';
import OrdersAndDC from 'pages/OrdersAndDC';
import Privacy from 'components/imedia/components/Privacy';
import ProductPage from 'pages/ProductPage';
import ProductList from 'pages/ProductList';
import ShoppingCartPage from 'pages/ShoppingCartPage';
import ShoppingCartQuantity from 'pages/ShoppingCartQuantity';
import SkuExample from 'pages/SkuExample';
import ShopBlogDetails from 'pages/shop-blog-details';
import ShopHomePage from 'pages/shop-home-page';
import ShopBlogExplorer from 'pages/shop-blog-explorer';
import ScrollToTop from 'utils/ScrollToTop';
import Contactus from 'pages/contact-us';
import './fonts/Trade-Gothic/Fonts10X';
import './index.scss';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import CheckoutComplete from 'components/CheckoutComplete';
import CartIconGenerator from 'pages/ButtonGenerator/CartIconGenerator';
import DonatePCF from 'pages/DonatePCF';
import CollectionPage from 'pages/Collection';
import CollectionIndex from 'pages/Collections';
import { HubSpotChatInstructions } from 'components/hubspot-chat';
import { Head } from 'components/head/Head';
import ContentPage from 'pages/ContentPage';
import { getMarketContent } from 'utils/marketContent';
import ProductForward from 'pages/ProductForward';
import ProductFeatures from 'pages/product-features/ProductFeatures';
import Team from 'pages/Team';
import SignupEmail from 'pages/SignupEmail';
import Checkout from 'components/Checkout';
import ButtonGenerator from 'pages/ButtonGenerator/BuyNowButtonGenerator';
import EmailSignupGenerator from 'pages/ButtonGenerator/EmailSignupGenerator';
import AuthRedirect from 'pages/AuthRedirect';
import { UserProvider } from 'UserContext';
import { oidcSettings } from 'utils/authConfig';
import DynamicClass from 'utils/DynamicClass';

function onSigninCallback(_user: any): void {
  window.history.replaceState({}, document.title, window.location.pathname);
}

export const oidcUserManager = new UserManager(oidcSettings);

const magentoUrl = process.env.REACT_APP_MAGENTO_URL;
const magentoGraphql = `${magentoUrl}/graphql`;
const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
const stripePromise = loadStripe(stripePublishableKey);

export default function App(): ReactElement {
  const client = createClient({
    url: magentoGraphql,
    exchanges: [...defaultExchanges],
  });

  const location = useLocation();
  const { enableLinkTracking, trackPageView } = useMatomo();
  const { ProductFeaturesPages, meta } = getMarketContent();

  enableLinkTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    trackPageView({
      documentTitle: document.title,
      href: window.location.href,
    });
  }, [location.pathname, trackPageView]);

  return (
    <>
      <Head
        link="/"
        title={`${meta.title}`}
        keywords={`blockchain marketplace, blockchain ecosystem, ${meta.title}`}
      />
      <Elements stripe={stripePromise}>
        <AuthProvider
          userManager={oidcUserManager}
          onSigninCallback={onSigninCallback}
        >
          <UserProvider>
            <Auth0Provider
              audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
              cacheLocation="localstorage"
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
              domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
              useRefreshTokens
            >
              <Provider value={client}>
                <CartProvider>
                  <ScrollToTop />
                  <DynamicClass />
                  <Routes>
                    <Route
                      path="button-generator"
                      element={<ButtonGenerator />}
                    />
                    <Route
                      path="button-generator/*"
                      element={<ButtonGenerator />}
                    />
                    <Route
                      path="cart-icon-generator"
                      element={<CartIconGenerator />}
                    />
                    <Route
                      path="hubspot-chat"
                      element={<HubSpotChatInstructions />}
                    />
                    <Route
                      path="email-signup-generator"
                      element={<EmailSignupGenerator />}
                    />
                    <Route path="vendor-tools" element={<ButtonGenerator />} />

                    <Route
                      path="cart-quantity"
                      element={<ShoppingCartQuantity />}
                    />
                    <Route path="cart" element={<ShoppingCartPage />} />
                    <Route
                      path="cart/add-sku/:skuToAdd"
                      element={<Checkout />}
                    />
                    <Route
                      path="checkout/add-sku/:skuToAdd"
                      element={<Checkout />}
                    />
                    <Route path="category-tree" element={<CategoryTree />} />
                    <Route path="category" element={<ProductList />} />
                    <Route path="category/*" element={<ProductList />} />
                    <Route path="collection/*" element={<CollectionPage />} />
                    <Route path="collections" element={<CollectionIndex />} />
                    <Route path="donate-pcf" element={<DonatePCF />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route
                      path="checkout-complete"
                      element={<CheckoutComplete />}
                    />
                    <Route path="digital-content" element={<OrdersAndDC />}>
                      <Route path=":productSku" element={<OrdersAndDC />}>
                        <Route path=":itemId" element={<OrdersAndDC />} />
                      </Route>
                    </Route>
                    <Route
                      path="exchange-sitemap"
                      element={<ExchangeSiteMap />}
                    />
                    <Route path="sku-example" element={<SkuExample />} />
                    <Route path="orders" element={<OrdersAndDC />} />
                    <Route path="privacy-terms" element={<Privacy />} />
                    <Route path="product" element={<ProductPage />}>
                      <Route path=":sku" element={<ProductPage />} />
                      <Route
                        path=":sku/add-to-cart"
                        element={<ProductPage />}
                      />
                    </Route>
                    <Route path="blog" element={<ShopBlogDetails />}>
                      <Route path=":blogID" element={<ShopBlogDetails />} />
                    </Route>
                    {ProductFeaturesPages && (
                      <>
                        {!!ProductFeaturesPages.length &&
                          ProductFeaturesPages.map(product => (
                            <Route
                              key={product.url}
                              path={product.url}
                              element={<ProductFeatures product={product} />}
                            />
                          ))}
                      </>
                    )}

                    <Route
                      path="blog-explorer"
                      element={<ShopBlogExplorer />}
                    />

                    <Route
                      path="/product-market/:productSKU"
                      element={<ShopHomePage />}
                    >
                      <Route index element={<ProductForward />} />
                    </Route>
                    <Route path="store/:store" element={<Store />} />
                    <Route path="cta/:store" element={<Store />} />
                    {/* <Route path="faq" element={<FAQ />} /> */}
                    <Route path="contact-us" element={<Contactus />} />
                    <Route path="team" element={<Team />} />
                    <Route
                      path="/forms/signup-email"
                      element={<SignupEmail />}
                    />
                    <Route path="auth-redirect" element={<AuthRedirect />} />
                    <Route path=":contentURL" element={<ContentPage />} />
                    <Route path="" element={<ShopHomePage />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </CartProvider>
              </Provider>
            </Auth0Provider>
          </UserProvider>
        </AuthProvider>
      </Elements>
    </>
  );
}
