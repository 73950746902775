import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMatomo } from 'matomoReact';
import ReactGA from 'react-ga4';
import { useCartContext } from 'CartContext';
import { useGetProduct } from 'hooks/magentoHooks';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { getVendorAndMarketplaceName } from 'utils/helper';
import defaultImage from '../../assets/crwd/brand_market/crwdmarket-default-logo.png';

export default function ProductForward(): ReactElement {
  const { trackEvent, trackLink, pushInstruction } = useMatomo();
  const navigate = useNavigate();
  const { visitEdgeSite, getProductWebsiteUrl } = useCartContext();
  const { productSKU } = useParams();
  const { product: productBySku } = useGetProduct({
    sku: productSKU as string,
  });
  const timeout = useRef({});
  const firstRender = useRef(true);
  const [displayRedirectDialog, setDisplayRedirectDialog] =
    useState<boolean>(true);
  const [websiteUrl, setWebsiteUrl] = useState<string | undefined>();

  const onRedirectPageLand = useCallback(() => {
    if (productBySku) {
      const url = getProductWebsiteUrl(productBySku);
      setWebsiteUrl(url);
      if (!url) return;
      timeout.current = setTimeout(() => {
        setDisplayRedirectDialog(false);
        visitEdgeSite(url, false);
        ReactGA.event({
          category: 'external_link',
          action: 'Visit Website: Email Forward',
          label: `Visited ${url}`,
        });
        trackEvent({
          category: 'external_link',
          action: `Visit Website: Email Forward - ${productBySku?.name}`,
          name: `Visited ${url}`,
        });
        trackLink({ href: url, linkType: 'link' });
        pushInstruction('trackLink', url);
      }, 1500);
    }
  }, [
    getProductWebsiteUrl,
    productBySku,
    pushInstruction,
    trackEvent,
    trackLink,
    visitEdgeSite,
  ]);

  useEffect(() => {
    if (displayRedirectDialog && firstRender.current) {
      firstRender.current = false;
      onRedirectPageLand();
    }
  }, [
    firstRender,
    productSKU,
    displayRedirectDialog,
    websiteUrl,
    productBySku,
    onRedirectPageLand,
  ]);

  const closeDialog = (timeout: number, route: string) => {
    clearTimeout(timeout);
    navigate(route);
  };

  return (
    <Dialog
      visible={displayRedirectDialog}
      header="Thank you for your Support!"
      onHide={() => setDisplayRedirectDialog(false)}
      onClick={() => closeDialog(timeout.current as number, '/')}
      onMaskClick={() => closeDialog(timeout.current as number, '/')}
      breakpoints={{ '1200px': '40vw', '960px': '75vw', '640px': '90vw' }}
      style={{ width: '40vw' }}
      className="visit-website-modal"
      dismissableMask
      draggable={false}
    >
      <div className="flex flex-column md:flex-row align-items-center justify-content-start mb-4">
        {productBySku ? (
          <div
            className="background-image-square mb-4 md:mb-0 mr-0 md:mr-4 "
            style={{ background: `url(${productBySku?.image?.url})` }}
          />
        ) : (
          <div
            className="background-image-square mb-4 md:mb-0 mr-0 md:mr-4 "
            style={{ background: `url(${defaultImage})` }}
          />
        )}
        <div className="mb-4">
          <h3 className="py-0 font-bold mb-2">{`Redirecting to ${getVendorAndMarketplaceName(
            productBySku,
          )}`}</h3>
          <ProgressBar mode="indeterminate" />
        </div>
      </div>
    </Dialog>
  );
}
