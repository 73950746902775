import { ReactElement, useEffect, useState } from 'react';
import { getMarketContent } from 'utils/marketContent';
import useViewport from 'hooks/useViewport';
import useLocalStorage from 'utils/useLocalStorage';

export default function SmallPromoImage(): ReactElement {
  const { isMobile } = useViewport();

  const market = getMarketContent();
  const smallPromo = market.homePage?.smallPromoImage;
  const [promoImageMobile, setPromoImageMobile] = useState<any>();
  const [promoImageDesktop, setPromoImageDesktop] = useState<any>();
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);

  useEffect(() => {
    const promoImageMobile = isDarkFromLocalStroage
      ? smallPromo?.desktopImageDark
      : smallPromo?.desktopImageLight;
    setPromoImageDesktop(promoImageMobile);

    const promoImageDesktop = isDarkFromLocalStroage
      ? smallPromo?.mobileImageDark
      : smallPromo?.mobileImageLight;
    setPromoImageMobile(promoImageDesktop);
  }, [
    isDarkFromLocalStroage,
    smallPromo?.desktopImageDark,
    smallPromo?.desktopImageLight,
    smallPromo?.mobileImageLight,
    smallPromo?.mobileImageDark,
  ]);

  return (
    <>
      {smallPromo && (
        <section
          id="small-promo-image"
          className={`mx-auto w-full block mb-4 ${smallPromo?.cssClassName}`}
        >
          <a href={smallPromo.link}>
            <img
              src={isMobile ? promoImageMobile : promoImageDesktop}
              alt=""
              className="mx-auto w-full block"
            />
          </a>
        </section>
      )}
    </>
  );
}
