import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { AdminInputEmail, AdminInputPassword } from 'components/Admin';
import { useForm } from 'react-hook-form';
import { useUserContext } from 'UserContext';
import { Button } from 'primereact/button';
import { Messages, MessagesMessageType } from 'primereact/messages';
import ForgotPassword from 'components/forms/ForgotPasswordForm';

export interface LoginFormProps {
  onLoginOrDismiss?: () => void;
}

interface LoginFormFields {
  email: string;
  password: string;
}

export default function LoginForm(props: LoginFormProps): ReactElement {
  const { onLoginOrDismiss } = props;
  const { token } = useUserContext();
  const { loginWithCredentials } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const messages = useRef<Messages>(null);

  const { control, handleSubmit } = useForm<LoginFormFields>({
    defaultValues: { email: '', password: '' },
  });

  const handleHideForgotPassword = (message?: MessagesMessageType) => {
    setShowForgotPassword(false);
    if (message) {
      setTimeout(() => {
        messages.current?.show(message);
      }, 100);
    }
  };

  const onSubmit = async (data: LoginFormFields) => {
    const { email, password } = data;
    setLoading(true);
    try {
      await loginWithCredentials(email, password);
    } catch (error: any) {
      messages.current?.replace({
        severity: 'error',
        content: error.message,
        sticky: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      setLoading(false);
      onLoginOrDismiss?.();
    }
  }, [token, onLoginOrDismiss]);

  return (
    <div className="login-account-form">
      {!showForgotPassword ? (
        <div className="p-2x">
          <h4 className="text-base mb-2">Account Login</h4>
          <Messages ref={messages} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid">
              <div className="col-12 lg:col-6">
                <AdminInputEmail
                  name="email"
                  label="Email"
                  autoComplete="email"
                  control={control}
                  required
                />
              </div>
              <div className="col-12 lg:col-6">
                <span className="p-input-icon-right w-full">
                  <AdminInputPassword
                    name="password"
                    label="Password"
                    autoComplete="password"
                    displayScore={false}
                    displayFeedback={false}
                    control={control}
                    required
                  />
                </span>
              </div>
              <div className="flex w-full justify-content-end">
                <Button
                  type="button"
                  className="text-gray-600 font-medium no-underline hover:underline p-2 bg-transparent border-none"
                  onClick={() => setShowForgotPassword(true)}
                >
                  <span style={{ fontSize: '.7rem' }}>Forgot Password?</span>
                </Button>
              </div>
              <div className="flex justify-content-center w-full mt-2">
                <Button
                  className="w-20rem p-2"
                  style={{ backgroundColor: 'black', borderColor: 'black' }}
                  loadingIcon="pi pi-spin pi-spinner"
                  label="Login"
                  loading={loading}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <ForgotPassword
          email=""
          hideForgotPassword={handleHideForgotPassword}
        />
      )}
    </div>
  );
}
