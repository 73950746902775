import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from 'CartContext';
import TreeNode from 'primereact/treenode';
import { TieredMenu } from 'primereact/tieredmenu';
import { getMarketContent } from 'utils/marketContent';
import {
  TreeTable,
  TreeTableExpandedKeysType,
  TreeTableSelectionKeysType,
} from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { navItemsToTreeNodes } from './navItemsUtils';
import './NavTreeTable.scss';

interface NavTreeTableProps {
  isVisible: Dispatch<SetStateAction<boolean>>;
  userAuth?: any;
}

const NavTreeTable: React.FC<NavTreeTableProps> = ({ isVisible, userAuth }) => {
  const { showCart } = useCartContext();
  const { navigation } = getMarketContent();
  const navigate = useNavigate();
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [selectedNodekey, setSelectedNodekey] =
    useState<TreeTableSelectionKeysType | null>(null);
  const [expandedKeys, setExpandedKeys] = useState<TreeTableExpandedKeysType>(
    {},
  );

  const navItemUrl = selectedNode => {
    const isUrl = selectedNode.toString().includes('/');
    const shouldShowCart = selectedNode.toString().toLowerCase() === 'cart';
    if (isUrl) {
      navigate(selectedNode.toString());
      isVisible(false);
    }
    if (shouldShowCart && !isUrl) {
      showCart('bottom');
    }
  };

  useEffect(() => {
    if (navigation.navigationItems.length) {
      const treeNodes = navItemsToTreeNodes(navigation.navigationItems);
      if (treeNodes) {
        setNodes(treeNodes);
      }
    }
  }, [userAuth, navigation.navigationItems]);

  return (
    <div className="nav-tree-table">
      {nodes.length ? (
        <>
          <div className="nav-tree-table__container">
            <TreeTable
              value={nodes}
              expandedKeys={expandedKeys}
              onToggle={e => setExpandedKeys(e.value)}
              selectionMode="single"
              selectionKeys={selectedNodekey}
              onSelectionChange={e => {
                setSelectedNodekey(e.value);
                navItemUrl(e.value);
              }}
            >
              <Column field="label" header="" expander />
            </TreeTable>
          </div>
        </>
      ) : (
        <Skeleton height="250px" borderRadius="4px" />
      )}
      <TieredMenu model={userAuth} className="user-info mb-2 pl-5" />
    </div>
  );
};

export default NavTreeTable;
