import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import geneImage from 'assets/10x/10X_Health_gene.png';
import '../SignUpForm.scss';
import { AdminInputEmail, AdminInputText } from 'components/Admin';
import { useForm } from 'react-hook-form';
import { scrollToFirstFormError } from 'utils/helper';
import { useSearchParams } from 'react-router-dom';
import { GraphQLClient } from 'graphql-request';
import { FormTriggerRef } from './AffiliateSteps';

const product = {
  id: 'b3e3c112-70a1-4ba5-9728-d6f15cad0859',
  sku: '2022-09-09-HSF-10X-10XH-001',
  name: '10X Health Genetics Test',
  image: geneImage,
  label: 'Genetic Test Kit (20% Commission per sale)',
};

export interface HandleFormFields {
  handle: string;
  referrerEmail: string;
}

export interface ProductHandleFormFields extends HandleFormFields {
  sku: string;
  handle: string;
  productId: string;
}

export interface Step3ProductHandleFormProps {
  data: ProductHandleFormFields | undefined;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export default React.forwardRef<
  FormTriggerRef<ProductHandleFormFields | undefined>,
  Step3ProductHandleFormProps
>(({ data, loading, setLoading }, ref) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!data);
  const [error, setError] = useState<string>('');
  const [isHandleAvailable, setIsHandleAvailable] = useState<boolean>(true);

  const {
    control,
    trigger,
    watch,
    clearErrors,
    formState: { errors, isValid },
    setError: setFormError,
  } = useForm<HandleFormFields>({
    defaultValues: { handle: data?.handle || '' },
  });

  const values = watch();

  useImperativeHandle(ref, () => ({
    trigger: async () => {
      setError('');

      values.handle && (await validateHandle(values.handle));
      const isValid = await trigger();
      if (isChecked && isValid && !errors.handle?.message) {
        return {
          sku: product.sku,
          productId: product.id,
          handle: values.handle,
          referrerEmail: values.referrerEmail,
        };
      }
      if (!isChecked) {
        setError('You must select a product');
      }
      scrollToFirstFormError(errors);

      return undefined;
    },
  }));

  const toggleCheck = () => {
    setIsChecked(!isChecked);
  };

  const [searchParams] = useSearchParams();
  const referrerEmailParam = searchParams.get('email-referrer') || '';

  const validateHandle = async (handle: string): Promise<void> => {
    setLoading(true);
    try {
      const graphqlClient = new GraphQLClient(
        'https://crwd-wallet.hasura.app/v1/graphql',
      );
      const query = `query isHandleAvailable($handle:String!){
      isHandleAvailable(handle:$handle) {
        isAvailable
      }
    }`;

      const result = await graphqlClient.rawRequest(query, {
        handle,
      });
      if (!result.data.isHandleAvailable.isAvailable) {
        setFormError('handle', {
          type: 'custom',
          message: 'Handle is already taken',
        });
        setIsHandleAvailable(false);
      } else {
        setIsHandleAvailable(true);
      }
    } catch (error: any) {
      setError(`Something went wrong, ${error?.message}`);
    } finally {
      setLoading(false);
    }
  };

  const clearHandleError = () => {
    if (!errors.handle?.message) return;
    clearErrors('handle');
  };

  useEffect(() => {
    if (!isValid) {
      scrollToFirstFormError(errors);
    }
  }, [errors, isValid]);

  return (
    <div>
      <div className="mt-6 mb-4">
        <h1 className="mb-0 text-2xl text-black font-montserrat uppercase">
          Create a HANDLE
        </h1>
        <p className="font-montserrat text-base mb-2">
          This is the last step for signing up as an affiliate! This is where
          you create a HANDLE.
        </p>
        <p className="font-montserrat text-base mb-2">
          What is a HANDLE? It is a username that you choose. In this case, your
          handle username will be displayed in your affiliate link. Handles have
          many benefits for websites and users such as anonymity or easy
          searching. Most importantly, your handle connects to your affiliate
          tracking ID which analyzes and tracks all of your transactions.
        </p>
        <form>
          <div className="grid">
            <div className="col-12">
              <AdminInputText
                required
                label="Handle"
                name="handle"
                pattern="[^0-9a-zA-Z-_]"
                autoComplete="nickname"
                onChange={() => clearHandleError()}
                onBlur={() => values.handle && validateHandle(values.handle)}
                control={control}
                isLoading={loading}
                rules={{
                  validate: value => {
                    const noSpaceValidation = /^\S{3,}$/;

                    if (!isHandleAvailable) {
                      return 'Handle is already taken';
                    }
                    if (!noSpaceValidation.test(value)) {
                      return 'Must be at least 3 characters with no spaces';
                    }
                    return true;
                  },
                }}
              />
            </div>
            <div className="mt-4 px-2">
              <h1 className="mb-0 text-2xl text-black font-montserrat uppercase">
                Referral Email:
              </h1>
              <p className="font-montserrat text-base mb-1">
                If someone referred you to become an affiliate, submit their
                email here:
              </p>
            </div>
            <div className="col-12">
              <AdminInputEmail
                label="Referrer Email (If Applicable)"
                name="referrerEmail"
                defaultValue={referrerEmailParam || ''}
                control={control}
                disabled={referrerEmailParam.length > 1}
              />
            </div>
          </div>
        </form>
      </div>
      <h1 className="mb-0 text-2xl text-black font-montserrat uppercase">
        Select a product
      </h1>
      <p className="font-montserrat text-base mb-2">
        Select the product you are signing up to be an affiliate for:
      </p>
      <Button
        className={`p-0 px-2 m-0 w-full grid toggle-card-button ${
          isChecked ? 'selected' : ''
        } ${!!error && 'error-border'}`}
        onClick={toggleCheck}
      >
        <div className="col-3">
          <img src={product.image} alt={product.name} width="75px" />
        </div>
        <div className="col-6">
          <p className="text-900">{product.label}</p>
        </div>

        <div className="col-3">
          <Checkbox
            checked={isChecked}
            onClick={toggleCheck}
            style={{ transform: 'scale(1.5)' }}
          />
        </div>
      </Button>
      {!!error && <small className="p-error p-d-block">{error}</small>}
    </div>
  );
});
