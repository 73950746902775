import { ReactElement, useEffect, lazy, Suspense } from 'react';
import { useCartContext } from 'CartContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckoutSummary from 'components/CheckoutSummary';
import useLocalStorage from 'utils/useLocalStorage';
import { Head } from './head/Head';
import Spinner from './Spinner';
import './Checkout.scss';

const CheckoutForm = lazy(() => import('./forms/CheckoutForm'));

export default function Checkout(): ReactElement {
  const { items, loading, lastOrders } = useCartContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get('isEmbedded') === '1';
  const [, setIsDarkFromLocalStorage] = useLocalStorage<boolean>(
    'isDark',
    localStorage.getItem('isDark') === 'true',
  );

  useEffect(() => {
    if (isEmbedded) {
      setIsDarkFromLocalStorage(false);
    }
  }, [isEmbedded, setIsDarkFromLocalStorage]);

  useEffect(() => {
    if (!items.length && !loading) {
      navigate('/cart');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastOrders) {
      navigate(
        `/checkout-complete?orderNumber=${lastOrders
          .map(order => order.id)
          .join(',')}`,
      );
    }
  }, [lastOrders, navigate]);

  return (
    <>
      <Head
        title="Checkout"
        link="/checkout"
        addPostfixTitle
        keywords="checkout, new account, returning customer"
      />

      <div
        className={`grid flex justify-content-center mx-auto mb-4 ${
          isEmbedded ? 'mt-3' : 'mt-0 pl-2'
        }`}
        style={{ maxWidth: '1480px' }}
        id="checkout-page"
      >
        <Suspense fallback={<Spinner />}>
          <div className="checkout col-12 md:col-8 flex-order-1 md:flex-order-0">
            {loading ? (
              <Spinner />
            ) : (
              <>
                {items.length ? (
                  <CheckoutForm />
                ) : (
                  <div
                    className="w-full text-center font-medium"
                    style={{ minHeight: '40vh' }}
                  >
                    <h1>Empty cart</h1>
                    <p>Please, add your first item.</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="col-12 mt-3 md:col-4 md:p-0">
            <CheckoutSummary />
          </div>
        </Suspense>
      </div>
    </>
  );
}
