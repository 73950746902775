import { useEffect, useState, useRef, ReactElement } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import useViewport from 'hooks/useViewport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import './Galleria.scss';
import VimeoPlayer from './vimeo-player/vimeo-player';

type Props = {
  images: Record<string, any>[];
  videos?: string[];
};

const GalleriaCustom = ({ images, videos }: Props): ReactElement => {
  const finalImages = images
    .sort((a, b) => a.position - b.position)
    .map(image => image.url);

  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const slider1 = useRef<Slider>(null);
  const slider2 = useRef<Slider>(null);
  const { isMobile } = useViewport();

  useEffect(() => {
    if (slider1.current && slider2.current) {
      setNav1(slider1.current);
      setNav2(slider2.current);
    }
  }, []);

  useEffect(() => {
    if (slider1.current) {
      slider1.current?.slickGoTo(0, false);
    }
    if (slider2.current) {
      slider2.current?.slickGoTo(0, false);
    }
  }, [slider1, slider2]);

  function getProductAltTest(productSrc: string) {
    if (!productSrc) return '';
    const splitSrc = productSrc
      // eslint-disable-next-line no-useless-escape
      .replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, ' ')
      .split(' ');

    const lastWordOfUrl = splitSrc[splitSrc.length - 2];
    return lastWordOfUrl;
  }

  const PrevArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view previous item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  };

  const NextArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  };

  const settings = {
    accessibility: true,
    infinite: true,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: false,
    lazyLoad: 'anticipated' as LazyLoadTypes,
    slidesToScroll: 1,
    // eslint-disable-next-line no-nested-ternary
    slidesToShow: finalImages.length > 2 ? 3 : finalImages.length > 1 ? 2 : 1,
    arrows: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    initialSlide: 0,
    afterChange: () => {
      videos &&
        document.querySelectorAll('iframe').forEach(v => {
          // eslint-disable-next-line no-param-reassign, no-self-assign
          v.src = v.src;
        });
      document.querySelectorAll('video').forEach(v => {
        v.pause();
      });
    },
  };

  return (
    <div className="w-full relative">
      {!!isMobile && (
        <Slider className="product-slick-slider" {...settings}>
          {finalImages.map(image => (
            <div className="slick-main-image" key={image}>
              <img
                key={image}
                src={image}
                alt={`Selected ${getProductAltTest(image)} product in gallery`}
              />
            </div>
          ))}
          {videos?.map((videoUrl, i) => (
            <div
              className={`vimeo-player-container flex flex-column ${
                i + 1 === videos.length ? 'mt-2 mb-4' : 'my-2'
              }`}
              key={videoUrl}
            >
              <VimeoPlayer url={videoUrl} />
            </div>
          ))}
        </Slider>
      )}
      {!isMobile && (
        <>
          <Slider
            className="product-slick-slider"
            ref={slider1}
            asNavFor={nav2}
            {...(isMobile ? settings : {})}
          >
            {finalImages.map(image => (
              <div className="slick-main-image" key={image}>
                <img
                  key={image}
                  src={image}
                  alt={`Selected ${getProductAltTest(
                    image,
                  )} product in gallery`}
                />
              </div>
            ))}
            {videos?.map((videoUrl, i) => (
              <div
                className={`vimeo-player-container flex flex-column ${
                  i + 1 === videos.length ? 'mt-2 mb-4' : 'my-2'
                }`}
                key={videoUrl}
              >
                <VimeoPlayer url={videoUrl} />
              </div>
            ))}
          </Slider>

          <div className="mb-8 mx-auto thumbnails-slider">
            <Slider
              asNavFor={nav1}
              ref={slider2}
              swipeToSlide
              focusOnSelect
              {...settings}
              className="slick-dots slick-thumb"
            >
              {finalImages.map(image => (
                <div
                  className="slick-thumb-link flex flex-row justify-content-center"
                  key={image}
                >
                  <div className="image-thumbnails">
                    <img
                      src={image}
                      alt={`Selected ${getProductAltTest(
                        image,
                      )} product in gallery`}
                      height={72}
                      width={72}
                    />
                  </div>
                </div>
              ))}
              {videos?.map(videoUrl => (
                <div
                  className="slick-thumb-link flex flex-row justify-content-center video-thumbnails"
                  key={videoUrl}
                >
                  <div
                    className="slick-thumb-link flex align-items-center justify-content-center border-500 border-1 p-0 m-0"
                    key={videoUrl}
                    style={{ height: '64px', width: '64px' }}
                  >
                    <i className="pi pi-play" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
};

export default GalleriaCustom;
