import { ReactElement } from 'react';
import { getMarketContent } from 'utils/marketContent';
import './index.scss';
import VisitWebsiteCards from '../website-cards';

const TagItems = ({ tagName, products }) => (
  <div className="product-widget-list">
    <h2 className="section_header">{tagName}</h2>
    {products.map(product => (
      <div key={Math.random() * Math.random()}>
        {product.sku && (
          <VisitWebsiteCards
            link=""
            sku={product.sku}
            isButton
            classList="product-widget"
            key={product.index}
          >
            <img src={product.thumbnail} alt="thumbnail" />
            <div className="content">
              <p className="product-title">{product.name}</p>
              <span className="price">
                {product.priceHigh && (
                  <del>
                    <span>{product.priceHigh}</span>
                  </del>
                )}
                <ins>
                  <span className="amount">{product.price}</span>
                </ins>
              </span>
            </div>
          </VisitWebsiteCards>
        )}
      </div>
    ))}
  </div>
);

export default function ProductsByTags(): ReactElement {
  const market = getMarketContent();
  const { productsByTags } = market.homePage;

  return (
    <>
      {!!productsByTags && (
        <section id="productsByTags">
          <div className="container px-4">
            <div className="grid">
              {productsByTags.map(obj => (
                <div key={obj.index} className="col-12 md:col-6 lg:col-3">
                  <TagItems {...obj} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
