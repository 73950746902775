import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputTextProps, InputText, OverlayPanel } from 'primereact';
import { InputTextareaProps } from 'primereact/inputtextarea';
import {
  AdminInputBaseProps,
  buildClassName,
  validateProps,
} from '../AdminForm';
import { zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import { ShouldShowPasswordFeedback } from 'components/forms/PasswordValidationFeedback';
import useViewport from 'hooks/useViewport';
import { PasswordFeedbackAndScore } from '../../../forms/PasswordValidationFeedback';

export type AdminInputPasswordProps = InputTextProps &
  AdminInputBaseProps &
  InputTextareaProps &
  ShouldShowPasswordFeedback;

export const AdminInputPassword: React.FC<AdminInputPasswordProps> = props => {
  validateProps(props);
  const {
    displayScore = false,
    displayFeedback = false,
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    defaultValue,
    onBlur,
    ...baseProps
  } = props;
  const { isSmallMobile } = useViewport();
  const priorityLabel = attributeType?.label ?? label ?? name;
  const description = attributeType?.description;
  const attributeTypeProps = attributeType?.props;
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnCommonPackage.dictionary,
    },
  };
  zxcvbnOptions.setOptions(options);
  const op = useRef<OverlayPanel>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue ?? ''}
      name={name}
      rules={{
        required,
        ...attributeType?.validation,
      }}
      render={({ field, fieldState, formState: _formState }) => {
        const errorMessage =
          fieldState.error?.message || fieldState.error?.type;
        return (
          <div className={containerClassName}>
            <div className="p-float-label p-input-icon-right w-full">
              <i
                className={`pi ${
                  showPassword ? 'pi pi-eye-slash icon' : 'pi pi-eye icon'
                }`}
                onClick={onShowPassword}
              />

              <InputText
                {...baseProps}
                type={showPassword ? 'text' : 'password'}
                {...attributeTypeProps}
                className={buildClassName(baseProps.className, errorMessage)}
                value={field.value}
                about={description}
                onChange={e => {
                  field.onChange(e);
                  op.current?.show(e, e.target);
                }}
                onBlur={() => {
                  field.onBlur();
                  onBlur && onBlur();
                }}
                ref={field.ref}
              />
              <label htmlFor={name} className="capitalize">
                {priorityLabel}
                {required && ' *'}
              </label>
            </div>
            <small id={`${name}-help`} className="p-d-block">
              {helpText}
            </small>
            <small className="p-error p-d-block">{errorMessage}</small>

            {field.value !== '' && displayFeedback && displayScore && (
              <OverlayPanel
                ref={op}
                style={{
                  width: isSmallMobile ? '100%' : '450px',
                  zIndex: 1000,
                }}
              >
                <PasswordFeedbackAndScore
                  inputValue={field.value}
                  displayFeedback={displayFeedback}
                  displayScore={displayScore}
                />
              </OverlayPanel>
            )}
          </div>
        );
      }}
    />
  );
};
