import { ReactElement, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { useUserContext } from 'UserContext';
import { useCartContext } from 'CartContext';
import './index.scss';
import FocusLock from 'react-focus-lock';
import { Sidebar } from 'primereact/sidebar';
import { TieredMenu } from 'primereact/tieredmenu';
import { MegaMenu } from 'primereact/megamenu';
import { Badge } from 'primereact/badge';
import useViewport from 'hooks/useViewport';
import { ThemeSwitch } from 'theme';
import useLocalStorage from 'utils/useLocalStorage';
import { Button } from 'primereact/button';
import { getMarketContent } from 'utils/marketContent';
import NavTreeTable from './nav-tree-table/NavTreeTable';
import '../../Collections/index.scss';

interface ShopNavBarProps {
  hasVideoNavBar?: boolean;
  displaySimpleNav?: boolean;
}

export default function ShopNavBar(props: ShopNavBarProps): ReactElement {
  const { hasVideoNavBar, displaySimpleNav } = props;
  const [marketPlaceLogo, setMarketPlaceLogo] = useState<any>();
  const [marketPlaceLogoMobile, setMarketPlaceLogoMobile] = useState<any>();
  const { quantity, hideNav, showCart } = useCartContext();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { login, logout, userId, userEmail } = useUserContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isDarkFromLocalStorage] = useLocalStorage<boolean>(
    'isDark',
    localStorage.getItem('isDark') === 'true',
  );
  const { isMobile } = useViewport();
  const [visible, setVisible] = useState<boolean>(false);
  const market = getMarketContent();
  const marketName = market.market;
  const marketNavigation = market.navigation;
  const marketCollections = market.collections;
  const hasSecondaryLogo = marketNavigation.logoSecondaryLight;
  const isEdgeSite = searchParams.get('isEmbedded') === '1';
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 350 ? setIsScrolled(true) : setIsScrolled(false);
  };

  useEffect(() => {
    const marketPlaceLogoMobile = isDarkFromLocalStorage
      ? marketNavigation.logoMobileLight
      : marketNavigation.logoMobileDark;
    setMarketPlaceLogoMobile(marketPlaceLogoMobile);

    const marketPlaceLogo = isDarkFromLocalStorage
      ? marketNavigation.logoDesktopLight
      : marketNavigation.logoDesktopDark;
    setMarketPlaceLogo(marketPlaceLogo);
  }, [
    marketNavigation.logoDesktopDark,
    marketNavigation.logoDesktopLight,
    marketNavigation.logoMobileDark,
    marketNavigation.logoMobileLight,
    isDarkFromLocalStorage,
    isEdgeSite,
    userEmail,
  ]);

  const loggedInItems = [
    {
      label: userEmail || 'User',
      icon: 'pi pi-user',
      className: 'nav-email-item',
      items: [
        {
          label: 'Orders',
          command: () => navigate('/orders'),
        },
        {
          label: 'Logout',
          command: () => logout(window.location.pathname),
        },
      ],
    },
  ];

  const loggedOutItems = [
    {
      label: '',
      icon: 'pi pi-user',
      command: () => login(),
      className: 'w-full max-w-max',
    },
  ];

  const DesktopNavitems = marketNavigation.navigationItems?.map(item => ({
    label: item.label,
    ...(item.className && {
      className: splitLocation[1] === item.className ? 'active-route' : '',
    }),
    ...(item.url && {
      command: () => navigate(item.url),
    }),
    ...(item.items &&
      (item.label !== 'Collections'
        ? {
            items: [
              item.items?.map(subItem => ({
                label: subItem.label,
                ...(subItem.items && {
                  items: subItem.items?.map(innerItem => ({
                    label: innerItem.label,
                    command: () => {
                      innerItem.showCart !== undefined
                        ? showCart()
                        : navigate(innerItem.url);
                    },
                  })),
                }),
              })),
            ],
          }
        : {
            items: item.items?.map(portFolioItem => [
              {
                label: portFolioItem.label,
                items: portFolioItem.items?.map((item, itemIndex) =>
                  itemIndex === 0
                    ? {
                        label: item.label,
                        template: () => (
                          <Link
                            to={item.url}
                            className="p-megamenu-submenu-header--custom-link"
                          >
                            <h1 className="p-megamenu-submenu-header--custom-link">
                              {item.labelHeader}
                            </h1>
                          </Link>
                        ),
                      }
                    : {
                        label: item.label,
                        command: () => navigate(item.url),
                      },
                ),
              },
            ]),
          })),
  }));

  if (hideNav) {
    return <></>;
  }

  if (displaySimpleNav === true || isEdgeSite) {
    return <></>;
  }

  return (
    <>
      <nav
        className={`store-nav-bar-wrapper ${
          hasVideoNavBar && 'videoNavOverlay'
        } ${hasVideoNavBar && isScrolled ? 'solid-bg' : ''}`}
        style={{ top: !hasVideoNavBar && isScrolled ? '0' : '-225px' }}
      >
        <div className="StoreNavBar w-full">
          <div className="container">
            {isMobile ? (
              <>
                <div className="store-nav-mobile-menu py-4 px-2 flex justify-content-between mr-0 md:mr-2">
                  <div className="flex flex-column sm:flex-direction-row justify-content-between align-items-center">
                    <Link
                      to="/"
                      className="mb-0 pb-0 pl-0 sm:pl-3 flex"
                      style={{ maxWidth: '150px' }}
                    >
                      <img
                        src={
                          hasVideoNavBar && !isScrolled
                            ? marketNavigation.logoMobileLight
                            : marketPlaceLogo
                        }
                        alt={marketName}
                        className="crwd-logo-mobile"
                      />
                    </Link>
                  </div>
                  {hasSecondaryLogo && (
                    <div className="flex sm:col-6 md:col-3 relative z-4 align-items-center">
                      <img
                        src={`${
                          isDarkFromLocalStorage
                            ? marketNavigation.logoSecondaryLight
                            : marketNavigation.logoSecondaryDark
                        }`}
                        alt="CRWD logo"
                        className="crwd-nav-logo ml-auto"
                        style={{ maxWidth: '120px', minWidth: '120px' }}
                      />
                    </div>
                  )}
                  {/* <SearchMenu /> */}
                </div>

                <div className="nav-mobile-bottom fixed z-3 w-full flex justify-content-between align-items-center px-4 py-0">
                  <Link to="/" className="mb-0 pb-0 text-primary">
                    <i
                      className="pi pi-fw pi-home"
                      style={{ fontSize: '1.5em' }}
                    />
                  </Link>

                  {/* <div style={{ marginBottom: '0.5rem' }}>
                    <ThemeSwitch />
                  </div> */}

                  <div className="mt-1 relative">
                    <Button
                      className="border-noround p-button-outlined border-none p-button-secondary mx-0 max-w-max py-3"
                      onClick={() => showCart()}
                    >
                      <i
                        className="pi pi-shopping-cart text-primary p-overlay-badge"
                        style={{
                          fontSize: '1.5rem',
                          transform: 'scale(0.75)',
                        }}
                      >
                        <Badge value={quantity} />
                      </i>
                    </Button>
                    {/* <div className="cart-wrapper">
                      <Button
                        icon="pi pi-shopping-cart"
                        className="border-noround p-button-outlined border-none p-button-secondary menu-bars"
                        onClick={() => setCartVisible(true)}
                      />
                      <span
                        className={`nav-cart-quantity ${
                          items.length === 0 ? 'cart-zero' : ''
                        }`}
                      >
                        {quantity}
                      </span>
                    </div> */}
                  </div>

                  <Button
                    icon="pi pi-bars"
                    className="border-noround p-button-outlined border-none p-button-secondary menu-bars text-primary"
                    onClick={() => setVisible(true)}
                  />
                </div>

                <Sidebar
                  visible={visible}
                  position="bottom"
                  onHide={() => setVisible(false)}
                  closeOnEscape
                  showCloseIcon={false}
                >
                  <FocusLock>
                    <div className="logo-wrapper col-12 pl-3 flex flex-row space-between align-items-center mb-2">
                      <Link to="/" className="max-h-max">
                        <img
                          src={marketPlaceLogoMobile}
                          alt={marketName}
                          style={{ maxWidth: '140px', minWidth: '140px' }}
                          className="menu-mobile-logo"
                        />
                      </Link>
                      <div
                        className="ml-auto"
                        style={{ marginBottom: '0.5rem' }}
                      >
                        <ThemeSwitch />
                      </div>
                    </div>
                    <div className="w-full">
                      <NavTreeTable
                        isVisible={setVisible}
                        userAuth={userId ? loggedInItems : loggedOutItems}
                      />
                    </div>
                    {marketCollections.length > 1 && (
                      <div className="px-0 mx-0">
                        <h1 className="text-center mb-4 text-lg md:text-xl">
                          Shop Our Collections
                        </h1>
                        <div className="grid flex-wrap">
                          {marketCollections.map(obj => (
                            <div
                              key={obj.collection_name}
                              className="col-10 md:col-5 mx-auto"
                            >
                              <Link
                                to={
                                  obj.collection_url.length
                                    ? `/collection/${obj.collection_url}`
                                    : '/collections'
                                }
                                className="collection__item mobile-menu px-0 mx-0"
                                style={{
                                  backgroundImage: `url(${obj?.collection_image})`,
                                }}
                                onClick={() => setVisible(false)}
                              >
                                <p className="p-0 m-0 text-center">
                                  {obj.collection_name}
                                </p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <Button
                      icon="pi pi-times"
                      label="close menu"
                      className="uppercase border-noround border-none surface-0 p-button-primary ml-2 mt-auto text-sm bg-transparent mx-auto text-500 text-center"
                      onClick={() => setVisible(false)}
                    />
                  </FocusLock>
                </Sidebar>
              </>
            ) : (
              <>
                <div className="store-nav-middle flex">
                  <div
                    className="grid py-2 w-full m-0"
                    style={{ alignItems: 'center' }}
                  >
                    {/* <SearchMenu /> */}
                    {hasSecondaryLogo && (
                      <div className="sm:flex sm:col-6 md:col-3 relative z-4">
                        <img
                          src={`${
                            isDarkFromLocalStorage
                              ? marketNavigation.logoSecondaryLight
                              : marketNavigation.logoSecondaryDark
                          }`}
                          alt="CRWD logo"
                          className="crwd-nav-logo ml-auto"
                          style={{ maxWidth: '160px', minWidth: '120px' }}
                        />
                      </div>
                    )}
                    <div
                      className={
                        hasSecondaryLogo
                          ? 'logo-wrapper col-6'
                          : 'logo-wrapper mr-auto max-w-max'
                      }
                    >
                      <Link to="/">
                        <img
                          src={
                            hasVideoNavBar && !isScrolled
                              ? marketNavigation.logoMobileLight
                              : marketPlaceLogo
                          }
                          alt={marketName}
                        />
                      </Link>
                    </div>
                    {(hasVideoNavBar || !hasSecondaryLogo) && (
                      <MegaMenu
                        className="store-nav-lower-mega hidden lg:flex text-white"
                        model={DesktopNavitems}
                      />
                    )}
                    <div
                      className={
                        hasSecondaryLogo
                          ? `col-3 relative flex flex-row offset-nav-items `
                          : `col-3 relative flex flex-row ml-auto max-w-max $`
                      }
                    >
                      <div className="hidden lg:flex mr-1">
                        <ul className="wrapper my-0 ml-auto">
                          <TieredMenu
                            model={userId ? loggedInItems : loggedOutItems}
                            className="user-info-menu ml-auto flex-column"
                          />
                        </ul>
                        <div className={`my-1 mr-1 py-1  `}>
                          <ThemeSwitch />
                        </div>
                        <Button
                          className={`border-noround p-button-outlined border-none p-button-secondary mx-0 max-w-max pb-2 pt-2 `}
                          onClick={() => showCart()}
                        >
                          <i
                            className="pi pi-shopping-cart text-primary p-overlay-badge"
                            style={{
                              fontSize: '1.75rem',
                              transform: 'scale(0.75)',
                            }}
                          >
                            <Badge value={quantity} />
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!hasVideoNavBar && hasSecondaryLogo && (
          <MegaMenu
            model={DesktopNavitems}
            className="store-nav-lower-mega hidden lg:flex"
          />
        )}
      </nav>
    </>
  );
}
