import { ReactElement, useEffect, useRef, useState } from 'react';
import { ConfigurableVariant } from 'graphql/generated/magentoApi';
import { getCartDropdownOptions } from 'utils/helper';
import useViewport from 'hooks/useViewport';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useCartContext } from 'CartContext';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import ReactGA from 'react-ga4';
import Stars from 'components/Stars';
import './ProductDescription.scss';
import { ProductWithVariants } from 'types';
import { getMarketContent } from 'utils/marketContent';
import { useMatomo } from 'matomoReact';
import skuUrls from '../crwdShopUrlsAndSkus';

type Props = {
  product: ProductWithVariants;
  isEmbedded?: boolean;
  fromIMedia?: boolean;
};

export default function ProductDescription({
  product,
  isEmbedded = false,
  fromIMedia = false,
}: Props): ReactElement {
  const {
    __typename,
    name,
    short_description,
    rating_summary,
    price_range,
    is_subscription,
  } = product;
  const marketContent = getMarketContent();
  const { isMobile } = useViewport();
  const displayProductVisitWebsite =
    marketContent.displayProductVisitWebsite && !isEmbedded && !fromIMedia;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const [finalPrice, setFinalPrice] = useState(
    price_range.maximum_price?.final_price.value
      ? formatter.format(price_range.maximum_price?.final_price.value)
      : price_range.maximum_price?.final_price.value,
  );

  let subscriptionPlan = 'monthly';
  if (is_subscription === 1) {
    if (name?.toLowerCase().includes('yearly')) {
      subscriptionPlan = 'yearly';
    }
  }
  const pricingText =
    is_subscription === 1
      ? `${finalPrice} / ${subscriptionPlan}`
      : `${finalPrice}`;

  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const {
    total,
    updateCart,
    showCart,
    getProductWebsiteUrl,
    visitEdgeSite,
    isMaxInCart,
  } = useCartContext();

  const defaultIndex = JSON.parse(
    localStorage.getItem(`skuIndexMap-${product.sku}`) || '{}',
  );
  const defaultWebsiteUrl =
    skuUrls[product.sku as string]?.[defaultIndex[product.sku as string]];

  const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(
    defaultWebsiteUrl,
  );
  const [variant] = useState<ConfigurableVariant>();
  const [variants, setVariants] = useState<ConfigurableVariant[]>([]);
  const { trackEvent, pushInstruction } = useMatomo();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (product.variants?.length) {
      setVariants(product.variants);
    }
  }, [product.variants, pushInstruction, total]);

  useEffect(() => {
    setWebsiteUrl(() => getProductWebsiteUrl(product));
  }, [product, getProductWebsiteUrl]);

  useEffect(() => {
    if (variant) {
      setFinalPrice(
        formatter.format(
          variant.product.price_range.minimum_price.regular_price.value,
        ),
      );
    } else {
      setFinalPrice(
        price_range.maximum_price?.final_price.value
          ? formatter.format(price_range.maximum_price?.final_price.value)
          : price_range.maximum_price?.final_price.value,
      );
    }
  }, [variant, price_range, formatter]);

  const onClickAddBtn = () => {
    // * since there isn't a consistent isMobile screen width. This is based off of isMobile in Nav.tsx & shop-nav-bar so it displays consistent behavior based on the nav bar.
    if (isMobile) {
      showCart('bottom');
    } else showCart();

    if (!isMaxInCart(product)) {
      updateCart({
        sku: product.sku,
        quantity,
        addToExisting: true,
        debug: 'ProductDescription',
      });
    }
  };

  const onClickViewCartBtn = () => {
    showCart();
    ReactGA.event({
      category: 'ecommerce',
      action: 'View Cart',
      label: `View Cart ${name} - ${product.sku}`,
    });
    trackEvent({
      category: 'ecommerce',
      action: 'Visit Cart',
      name: `View Cart ${name} - ${product.sku}`,
    });
  };

  const onClickVisitWebsiteBtn = (url: string) => {
    visitEdgeSite(url, true);

    ReactGA.event({
      category: 'external_link',
      action: 'Visit Website - Product Description',
      label: `Visited ${url}`,
    });
    trackEvent({
      category: 'external_link',
      action: 'Visit Website - Product Description',
      name: `Visited ${url}`,
    });
    setWebsiteUrl(() => getProductWebsiteUrl(product));
  };

  return (
    <div className="product-description__container relative">
      {!!rating_summary && (
        <Stars rating={(rating_summary * 5) / 100} classNames="p-0 mb-2" />
      )}
      <p className="font-medium mt-0 mb-2 text-2xl p-pricing-text p-0 raleway-price-format">
        {`${pricingText === '0' ? 'Free' : `${pricingText}`}`}
      </p>
      <div className="grid flex flex-column sm:flex-row align-items-center pb-2 mt-4">
        <div className="col-12 md:col-4">
          <Dropdown
            className="w-full btn--height text-center"
            aria-label="Adjust product quantity"
            value={quantity}
            options={getCartDropdownOptions(
              product.sku,
              product.__typename,
              false,
            )}
            onChange={e => setQuantity(e.value)}
            disabled={
              !name?.toLowerCase().includes('donation') &&
              __typename === 'VirtualProduct'
            }
          />
        </div>
        {/* {!!variants.length && (
          <Dropdown
            className="w-8 sm:w-6 mr-3 mt-3 xl:mt-0"
            value={variant}
            options={product.variants}
            onChange={e => setVariant(e.value as ConfigurableVariant)}
            optionLabel="variant"
            placeholder="Select a license type"
            valueTemplate={selectedVariantTemplate}
            itemTemplate={variantOptionTemplate}
          />
        )} */}
        <div className="col-12 md:col-8">
          <Button
            className={`uppercase text-sm font-bold w-full py-3 btn--height ${
              fromIMedia
                ? 'p-button-rounded add-to-cart-button'
                : 'c-button-primary'
            }`}
            label={isMaxInCart(product) ? 'View in Cart' : 'Add to Cart'}
            onClick={() => onClickAddBtn()}
            type="button"
            id="product-description-add-to-cart"
            disabled={variants.length ? !variant : false}
          />
        </div>
      </div>
      {displayProductVisitWebsite && (
        <div className="flex flex-column xl:flex-row align-items-start pb-2 mt-0">
          <>
            {websiteUrl && (
              <Button
                className="uppercase xl:mt-0 xl:ml-3 c-button-primary mt-3 text-sm font-bold visit-website-btn btn--height"
                label="Visit Website"
                onClick={() => onClickVisitWebsiteBtn(websiteUrl)}
                type="button"
              />
            )}
          </>
        </div>
      )}
      {isEmbedded ? (
        <div className="flex flex-column xl:flex-row align-items-start pb-2 mt-0">
          <>
            {websiteUrl && (
              <Button
                className="uppercase xl:mt-0 xl:ml-3 c-button-primary mt-3 text-sm font-bold visit-website-btn"
                label="Checkout"
                onClick={() => navigate('/checkout')}
                type="button"
              />
            )}
          </>
        </div>
      ) : null}
      <div className="flex flex-column xl:flex-row align-items-start ml-0 pb-2 mt-0 w-full">
        {fromIMedia && (
          <Button
            className="uppercase view-cart-button ml-0 my-2 md:mt-0 text-center w-full"
            label="View cart"
            onClick={() => onClickViewCartBtn()}
            id="product-description-view-cart"
            icon="pi pi-shopping-cart"
            iconPos="left"
          />
        )}
      </div>
      {/* <p className="mt-0 mb-2 product-categories">
        <span className="font-bold">Categories: </span>
        {filteredCategories?.map(category => (
          <React.Fragment key={category?.name}>
            {isMarketNameCrwd ? (
              <span className="ml-1 category-link">{category?.name}</span>
            ) : (
              <Link
                className="ml-1 category-link"
                to={`/category/${category?.url_path}`}
              >
                {category?.name}
              </Link>
            )}
          </React.Fragment>
        ))}
      </p> */}
      <div className="text-sm p_m-0 product-short-description">
        {short_description?.html && parse(short_description.html)}
      </div>
      {is_subscription === 1 && (
        <p className="mt-0 mb-3 raleway-price-format">
          <span className="font-bold">Subscription: </span>
          {`${finalPrice} / ${subscriptionPlan}`}
        </p>
      )}
      <Toast
        ref={toast}
        position="center"
        className="product-description__toast"
      />
    </div>
  );
}
