import { useEffect, useState } from 'react';
import BlogTemplate from 'components/blog-template';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { useSearchParams } from 'react-router-dom';
import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import { Fade } from 'react-awesome-reveal';
import TreeNode from 'primereact/treenode';
import { Breadcrumbs } from 'components/breadcrumbs';
import Spinner from 'components/Spinner';
import { useHubspotBlogPostsQuery } from 'graphql/generated/reactQuery';
import { useClient } from 'UserContext';
import SmallBusinessSlider from './SmallBusinessSlider';
import './index.scss';

export default function ShopBlogExplorer() {
  const [selectedNodeKey] = useState(undefined);
  const [blogFilterOptions, setBlogFilterOptions] = useState<TreeNode[]>([]);
  const [filterBlogs, setFilterBlogs] = useState<any | undefined>(undefined);
  const [, setTotalRecords] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const perPageParam = searchParams.get('per-page');
  const perPage = Number(perPageParam || 9);
  const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
  const [first, setFirst] = useState<number>(perPage * (currentPage - 1));
  const [hasNodeKeyChanged, setHasNodeKeyChanged] = useState<boolean>(false);

  const navigateToPage = (pageNumber: number) => {
    if (pageNumber > 1) {
      searchParams.set('page', `${pageNumber}`);
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };
  const market = getMarketContent();
  const { blogPage } = getMarketContent();
  const marketMetadata = market.meta;
  const dataBlogID = market.blogID;
  const client = useClient();
  const { isFetching, data } = useHubspotBlogPostsQuery(client, {
    tagIds: [dataBlogID],
  });
  const blogPosts = data?.hubspotBlogPosts;

  // const handleNodeChange = value => {
  //   if (
  //     selectedNodeKey &&
  //     JSON.stringify(selectedNodeKey) !== JSON.stringify(value)
  //   ) {
  //     setHasNodeKeyChanged(true);
  //     return true;
  //   }
  //   return false;
  // };

  useEffect(() => {
    let filterOptions;
    if (blogPosts && !isFetching) {
      let blogCreatedAt = blogPosts
        .filter(blog => blog.currentState.toUpperCase() === 'PUBLISHED')
        .map(blog => blog.created.substring(0, 10));
      blogCreatedAt = blogPosts
        .filter(
          (blog, index) =>
            !blogCreatedAt.includes(blog?.created.substring(0, 10), index + 1),
        )
        .map(blog => blog.created.substring(0, 10));

      if (!blogFilterOptions.length) {
        filterOptions = [
          {
            key: 'date_parent_node',
            label: 'Date',
            children: blogCreatedAt.map(date => ({
              key: date,
              label: date,
            })),
          },
        ];
        setBlogFilterOptions(filterOptions);
      }
    }
  }, [blogFilterOptions, blogPosts, isFetching]);

  useEffect(() => {
    if (blogPosts && filterBlogs === undefined) {
      setFilterBlogs(blogPosts);
    }
    if (selectedNodeKey && hasNodeKeyChanged && blogPosts) {
      setHasNodeKeyChanged(false);
      const treeSelectOptionKeys: string[] = Object.keys(selectedNodeKey);
      let temp = [...blogPosts];

      if (!isFetching) {
        temp = treeSelectOptionKeys
          .map(value =>
            blogPosts.filter(blog =>
              blog.created.substring(0, 10).includes(value),
            ),
          )
          .filter(blog => blog.length !== 0)
          .flat();
        if (temp.length === 0) {
          temp = [...blogPosts];
        }
      }
      setFilterBlogs(temp);
    }
  }, [isFetching, blogPosts, filterBlogs, selectedNodeKey, hasNodeKeyChanged]);

  useEffect(() => {
    const goToFirstPage =
      document.getElementsByClassName('p-paginator-first')[0];
    if (goToFirstPage) {
      goToFirstPage.classList.remove('p-disabled');
      goToFirstPage.removeAttribute('disabled');
      goToFirstPage.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        navigateToPage(1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogPosts]);

  const onPageChange = ({ page, first }: DataViewPageParams) => {
    setFirst(first);
    setCurrentPage(page + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigateToPage(page + 1);
  };

  useEffect(() => {
    if (blogPosts) {
      setTotalRecords(blogPosts?.length);
    }
  }, [blogPosts]);

  const renderGridItem = item => (
    <div className="col-12 md:col-6 lg:col-4 mb-1 inline-block blog-item-col">
      <BlogTemplate {...item} />
    </div>
  );

  // eslint-disable-next-line consistent-return
  const ItemTemplate = (blog, layout) => {
    if (layout === 'grid') {
      return renderGridItem(blog);
    }
  };

  return (
    <>
      <Head
        link="/blog-explorer"
        title="Blogs"
        description={`${marketMetadata.title} Blogs`}
        keywords={`${marketMetadata.title} Blogs`}
        addPostfixTitle
      />
      <ShopNavBar />
      <Breadcrumbs label={blogPage?.title} bg={blogPage?.breadcrumbBGImage} />
      <section id="shop-blog-explorer">
        {isFetching && <Spinner />}
        <SmallBusinessSlider />

        {!!blogPosts?.length && (
          <>
            <div className="grid m-0 px-4 lg:px-2 xl:px-0">
              <div className="col-12">
                <div className="px-4">
                  <h3 className="header uppercase mb-4 mx-auto text-center">
                    {`${marketMetadata.title} Blogs`}
                  </h3>
                </div>
                <Fade triggerOnce cascade duration={2000}>
                  <DataView
                    value={blogPosts}
                    layout="grid"
                    itemTemplate={ItemTemplate}
                    onPage={e => onPageChange(e)}
                    first={first}
                    paginator
                    rows={perPage}
                    pageLinkSize={4}
                    className="grid mx-auto"
                  />
                </Fade>
              </div>
            </div>
          </>
        )}
      </section>
      <MarketplaceFooter />
    </>
  );
}
