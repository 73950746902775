import {
  ProductFieldsFragment,
  ProductInterface,
} from 'graphql/generated/magentoApi';
import { FieldErrors, FieldError } from 'react-hook-form';
import { getMarketContent } from './marketContent';

export interface CartDropdownOption {
  label: string;
  value: number;
}

export default function scrollWithOffset(el: HTMLElement) {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -128;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

export const geneticTestSkus = ['2022-09-09-HSF-10X-10XH-001'];
const defaultMaxQuantity = 20;

export const productMaxQuantity = (
  productSku: string | undefined,
  productType: string | undefined,
): number => {
  if (productSku && geneticTestSkus.includes(productSku)) {
    return 1;
  }
  if (productType === 'VirtualProduct' || productType === 'VirtualCartItem') {
    return 1;
  }
  return defaultMaxQuantity;
};

export const getCartDropdownOptions = (
  productSku: string | undefined,
  productType: string | undefined,
  includeDelete = true,
): CartDropdownOption[] => {
  const maxQuantity = productMaxQuantity(productSku, productType);

  if (includeDelete) {
    const cartDropdownOptions: CartDropdownOption[] = [
      { label: '0 (Delete)', value: 0 },
    ];
    for (let index = 1; index <= maxQuantity; index++) {
      cartDropdownOptions[index] = { label: `Qty ${index}`, value: index };
    }
    return cartDropdownOptions;
  }
  const cartDropdownOptions: CartDropdownOption[] = [];
  for (let index = 0; index < maxQuantity; index++) {
    cartDropdownOptions[index] = {
      label: `Qty ${index + 1}`,
      value: index + 1,
    };
  }
  return cartDropdownOptions;
};

export const isQtyDropDownLimited = (
  productSku: string,
  productType: string | undefined,
) => {
  const isVirtual =
    productType === 'VirtualProduct' || productType === 'VirtualCartItem';
  const isGeneTest = geneticTestSkus.find(sku => sku === productSku);

  if (isVirtual || isGeneTest) {
    return true;
  }
  return false;
};

export function getVendorAndMarketplaceName(
  product?: ProductFieldsFragment | ProductInterface,
): string {
  const { meta } = getMarketContent();
  if (product) {
    return product.vendor_company === meta.title
      ? product.vendor_company
      : `${product.vendor_company} ${meta.title}`;
  }
  return `independent ${meta.title}`;
}

export function sortProductByName(x, y) {
  return x.name.localeCompare(y.name, 'en', { ignorePunctuation: true });
}

export function checkPriceFormat(price: string | number | undefined | null) {
  let updatePriceFormat;
  if (price) {
    const stringPrice = price.toString();

    if (stringPrice.includes('.')) {
      if (stringPrice.slice(stringPrice.indexOf('.') + 1).includes('.')) {
        updatePriceFormat = `${stringPrice.slice(
          0,
          stringPrice.indexOf('.'),
        )}.00`;
      }
      if (
        stringPrice.slice(stringPrice.indexOf('.') + 1).length === 1 &&
        !stringPrice.slice(stringPrice.indexOf('.') + 1).includes('.')
      ) {
        updatePriceFormat = `${stringPrice}0`;
      }
      if (stringPrice.slice(stringPrice.indexOf('.') + 1).length === 0) {
        updatePriceFormat = `${stringPrice}00`;
      }

      if (
        stringPrice.slice(stringPrice.indexOf('.') + 1).length > 1 &&
        !stringPrice.slice(stringPrice.indexOf('.') + 1).includes('.')
      ) {
        updatePriceFormat = stringPrice;
      }
    }
    if (!stringPrice.includes('.')) {
      updatePriceFormat = `${stringPrice}.00`;
    }
  }
  if (!price) {
    updatePriceFormat = `Price error: ${price}`;
  }

  return `$${updatePriceFormat}`;
}

// *function that loops through to return any product with the collectionURL word found in a tag:
type ProductCollectionArrayProps = {
  products: ProductFieldsFragment[];
  urlCollectionName: string;
};

export const productCollectionArray = ({
  products,
  urlCollectionName,
}: ProductCollectionArrayProps) => {
  const productArray: ProductFieldsFragment[] = [];

  for (let i = 0; i < products.length; i++) {
    if (products[i] && products[i]?.categories) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (let z = 0; z < products[i].categories?.length || 0; z++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const productTag: string | null = products[i]?.categories[z].name
          .toLowerCase()
          .replace(/\s/g, '')
          .replace(/-/g, '')
          .replace(/&/g, '')
          .replace(/,/g, '');

        //* check each product tag to see if it's equal to the collection/collectionURL:
        if (
          String(productTag) ===
          String(
            urlCollectionName
              .replace(/\s/g, '')
              .replace(/-/g, '')
              .replace(/&/g, ''),
          )
        ) {
          productArray.push(products[i]);
        }
      }
    }
  }
  const removeDuplicateProducts = [...new Set(productArray)];

  return removeDuplicateProducts;
};

export function scrollToFirstFormError(errors: FieldErrors) {
  const errorFieldNames: string[] = Object.keys(errors);

  if (errorFieldNames.length > 0) {
    const element = errors?.[errorFieldNames[0]] as FieldError;
    if (element?.ref?.focus) {
      element.ref.focus();
    }
  }
}
