import { useEffect, useState } from 'react';
import './index.scss';
import BlogTemplate from 'components/blog-template';
import { getMarketContent } from 'utils/marketContent';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useClient } from 'UserContext';
import { useHubspotBlogPostsQuery } from 'graphql/generated/reactQuery';

export default function ShopBlogExplorer() {
  const [, setTotalRecords] = useState(0);

  const market = getMarketContent();
  const dataBlogIDSMB = market.blogIDSMB;
  const client = useClient();
  const { data } = useHubspotBlogPostsQuery(client, {
    tagIds: [dataBlogIDSMB],
  });
  const blogPosts = data?.hubspotBlogPosts;

  useEffect(() => {
    if (blogPosts) {
      setTotalRecords(blogPosts.length);
    }
  }, [blogPosts]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view previous item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  return (
    <>
      {!!blogPosts?.length && (
        <>
          <div className="px-4">
            <h3 className="header uppercase mb-4 mx-auto text-center">
              Featured Small Business Blogs
            </h3>
          </div>
          <Slider {...settings} className="col-11 mx-auto mb-8 blog-slider">
            {blogPosts?.map(blog => (
              <BlogTemplate {...blog} key={blog.id} />
            ))}
          </Slider>
        </>
      )}
    </>
  );
}
