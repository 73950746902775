import { ReactElement, useRef } from 'react';
import { getMarketContent } from 'utils/marketContent';
import parse from 'html-react-parser';
import { Button as PrimeButton } from 'primereact/button';
import { FormProvider, useForm } from 'react-hook-form';
import { AdminInputEmail } from 'components/Admin';
import { Toast } from 'primereact/toast';
import { useMatomo } from 'matomoReact';
import './index.scss';

export default function SignupEmail(): ReactElement {
  const { signupEmail } = getMarketContent();
  const toast = useRef<Toast>(null);
  const { pushInstruction } = useMatomo();

  const formMethods = useForm<Record<string, any>>({
    mode: 'all',
    defaultValues: {
      primaryEmail: '',
    },
  });
  const { control, handleSubmit, reset } = formMethods;

  const onSubmit = (data: Record<string, unknown>) => {
    pushInstruction(
      'trackEvent',
      'Contact',
      'Email Signup Submit Landing Page',
      data.primaryEmail,
    );
    toast?.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: ` E-mail submitted`,
    });
    window.location.replace(
      `${signupEmail?.redirectOnSubmitLink}?email=${data.primaryEmail}`,
    );
    reset();
  };

  const handleIncompleteSection = () => {
    toast?.current?.show({
      severity: 'info',
      summary: 'Incomplete Section',
      detail: `Please enter a valid e-mail address`,
    });
  };

  return (
    <div>
      {signupEmail?.header.length > 1 && (
        <section className="my-6 w-12 mx-auto signup-page">
          <div className="container px-4">
            {signupEmail?.header && (
              <h1 className="text-center mb-4 large-text">
                {parse(signupEmail?.header)}
              </h1>
            )}
            {signupEmail?.dividerImage && (
              <img
                src={signupEmail?.dividerImage}
                alt=""
                className="block w-full mb-4"
              />
            )}
            {signupEmail?.description && (
              <div className="content mb-4">
                <p className="parsed-text text-justify">
                  {parse(signupEmail?.description)}
                </p>
              </div>
            )}
            {signupEmail?.featuredImage && (
              <img
                src={signupEmail?.featuredImage}
                alt=""
                className="block w-full mb-4"
              />
            )}
            <FormProvider {...formMethods}>
              <form
                className="provider-form mx-auto mt-6 w-full"
                onSubmit={handleSubmit(onSubmit, handleIncompleteSection)}
                id="email-signup-join-page"
              >
                <Toast ref={toast} />
                <div className="grid justify-content-center">
                  <div className="col-10 mx-auto md:col-6 md:mr-0">
                    <AdminInputEmail
                      control={control}
                      name="primaryEmail"
                      required
                      label="Primary E-mail"
                      containerClassName="mb-2"
                      id="email-signup-join-form-input"
                    />
                  </div>
                  <div className="col-4 md:col-2 mx-auto md:ml-0 md:mr-auto">
                    <PrimeButton
                      className="text-center w-full"
                      label="Submit"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </section>
      )}
    </div>
  );
}
