import App from 'App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import 'i18n';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MatomoProvider } from 'matomoReact';

const matomoAnalyticsUrl = process.env.REACT_APP_MATOMO_ANALYTICS_URL as string;
const matomoSiteId = parseInt(
  process.env.REACT_APP_MATOMO_SITE_ID as string,
  10,
);
const matomoConfig = {
  urlBase: matomoAnalyticsUrl,
  siteId: matomoSiteId,
  trackerUrl: `${matomoAnalyticsUrl}/matomo.php`,
  srcUrl: `${matomoAnalyticsUrl}/matomo.js`, // TODO: test including this locally or using the cdn: cdn.matomo.cloud/crwdmarket.matomo.cloud/matomo.js
  linkTracking: false, // see https://www.npmjs.com/package/matomoReact#spa-link-tracking
  configurations: {
    enableCrossDomainLinking: true,
  },
};

const helmetContext: any = {};

const container = document.getElementById('root')!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <MatomoProvider config={matomoConfig}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </MatomoProvider>
  </React.StrictMode>,
);

const sendSizeMessage = () => {
  const message = {
    source: 'cbex-storefront',
    payload: {
      type: 'window-size',
      path: window.location.pathname,
      payload: {
        height: document.body.scrollHeight,
        width: document.body.scrollWidth,
      },
    },
  };
  window.top?.postMessage(message, '*');
};

if (root) {
  new MutationObserver(sendSizeMessage).observe(container, {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
