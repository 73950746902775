import { ReactElement, useState, useEffect, useMemo } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import useViewport from 'hooks/useViewport';
import { ProductInterface } from 'graphql/generated/magentoApi';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import ProductList from 'components/collection-product-list/CollectionProductList';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import { MenuItem } from 'primereact/menuitem';
import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import './index.scss';

export default function CollectionPage(): ReactElement {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [searchProductAuto] = useState<ProductInterface | null>();
  const hideCategories = searchParams.get('hide-categories') === 'true';
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [menuModel, setMenuModel] = useState<MenuItem[]>([
    { label: 'Collections', items: [] },
  ]);
  const { isMobile } = useViewport();
  const [bgImg, setBgImg] = useState<string[]>([]);
  const market = getMarketContent();
  const marketCollections = market.collections;

  const collectionPage = useMemo(
    () =>
      marketCollections.find(
        collectionList => collectionList.collection_url === params['*'],
      ),
    [marketCollections, params],
  );

  const shouldShowAside =
    !hideCategories &&
    !searchProductAuto?.sku &&
    (!!menuModel[0].items || loadingCategories);

  useEffect(() => {
    const temp: string[] = [];
    collectionPage?.collection_vendors.forEach(vendor =>
      import(
        `../../assets/vendor-logos/${String(
          vendor.vendor_company.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''),
        )}.png`
      )
        .then(marketCollections => {
          temp.push(marketCollections.default);
          if (temp.length === collectionPage?.collection_vendors.length) {
            setBgImg(temp);
          }
        })

        .catch(error => {
          console.log(`The error is: ${error}`);
        }),
    );
  }, [collectionPage]);

  return (
    <>
      <Head
        title={String(collectionPage?.collection_name)}
        addPostfixTitle
        description={collectionPage?.meta.Description}
        link={String(collectionPage?.meta.OGurl)}
        keywords="blockchain market, product collection, collections"
        imageCard={collectionPage?.collection_image}
        largeTwitterCard
      />
      <ShopNavBar />
      <section
        id={collectionPage?.collection_name}
        className="collection__container mb-6 mx-0"
      >
        <div
          className="collection__content"
          style={{
            backgroundImage: `url(${collectionPage?.collection_image})`,
          }}
        >
          <div className="collection__content__text">
            <Link to="/collections" className="text-xs">
              Collections
            </Link>
            <h1 className="collection__header">
              {collectionPage?.collection_name}
            </h1>
            <p>{collectionPage?.collection_description}</p>
          </div>
        </div>
      </section>

      <section className="mb-8 px-4">
        <ProductList
          collectionURL={collectionPage?.collection_url}
          shouldShowAside={shouldShowAside}
          setLoading={setLoadingCategories}
          isMobile={isMobile}
          menuModel={menuModel}
          setMenuModel={setMenuModel}
          collectionCategorylist={collectionPage?.collection_category_list}
        />
      </section>

      {!!collectionPage?.collection_vendors && (
        <section className="collection__vendors-container mb-6 px-4 mx-auto w-full md:w-8 lg:w-6">
          <h2 className="text-center mx-auto block w-full">
            Meet Our{' '}
            {collectionPage?.collection_vendor_title ||
              collectionPage?.collection_name}{' '}
            Vendors
          </h2>
          <div className="flex flex-wrap align-items-center justify-content-center mx-auto w-full">
            {collectionPage?.collection_vendors.map(product => (
              <div key={product.vendor_company}>
                {bgImg.length ? (
                  <Link
                    className="collection__vendors"
                    style={{
                      backgroundImage: `url(${bgImg
                        .filter(logo =>
                          logo.includes(
                            product.vendor_company
                              .toLowerCase()
                              .replace(/[^a-zA-Z0-9]/g, ''),
                          ),
                        )
                        .toString()})`,
                    }}
                    to={`/category?vendor=${product.vendor_company}`}
                  />
                ) : (
                  <Link
                    className="collection__vendors"
                    to={`/category?vendor=${product.vendor_company}`}
                  >
                    <h4 className="collection__text uppercase">
                      {product.vendor_company}
                    </h4>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </section>
      )}

      <MarketplaceFooter />
    </>
  );
}
