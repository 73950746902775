export default [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Ecosystem',
    items: [
      {
        label: 'About',
        url: '/ecosystem/about.html',
      },
      {
        label: 'GICS',
        url: '/ecosystem/gics.html',
      },
      {
        label: 'Our Clients',
        url: '/ecosystem/our-clients.html',
      },
      {
        label: 'Our Members',
        url: '/ecosystem/our-members.html',
      },
      {
        label: 'Trading',
        url: '/ecosystem/trading.html',
      },
    ],
  },
  {
    label: 'Voices',
    items: [
      {
        label: 'Our Blogs',
        url: '/voices/our-blogs.html',
      },
      {
        label: 'The Crowd',
        url: '/voices/the-crowd.html',
      },
    ],
  },
  {
    label: 'Marketplace',
    items: [
      {
        label: 'Marketplace Products',
        url: '/store',
      },
      {
        label: 'Cart',
        url: '/store/cart',
      },
      {
        label: 'Checkout',
        url: '/store/checkout',
      },
    ],
  },
  {
    label: 'Interact',
    items: [
      {
        label: 'Contact Us',
        url: '/interact/contact-us.html',
      },
      {
        label: 'Why Crowdpoint',
        url: '/interact/why-crowdpoint.html',
      },
      {
        label: 'Earn',
        url: '/interact/earn.html',
      },
      {
        label: 'Join Our Cause',
        url: '/interact/join-our-cause.html',
      },
    ],
  },
];
