import React, { useEffect, useState } from 'react';
import { getMarketContent } from 'utils/marketContent';
import createInstance from './instance';
import MatomoContext from './MatomoContext';
import { InstanceParams } from './types';

export interface MatomoProviderProps {
  children?: React.ReactNode;
  config: InstanceParams;
}

function getAffiliateCode(): string {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('afmc')) {
    return searchParams.get('afmc') || '';
  }
  const segments = window.location.pathname.split('/');
  return segments.pop() || segments.pop() || '';
}

// TODO: use marketplace config
function resolveSiteId(config: InstanceParams, affiliateCode: string): number {
  const searchParams = new URLSearchParams(window.location.search);
  const matomoSiteId = searchParams.get('matomoSiteId');
  if (matomoSiteId) {
    try {
      return parseInt(matomoSiteId, 10);
    } catch {} // eslint-disable-line no-empty
  }

  const affiliateSearch = affiliateCode.trim().toLowerCase();
  const marketplace = getMarketContent();
  const affiliateSiteId = marketplace.matomoSiteIdMappings?.[affiliateSearch];
  if (affiliateSiteId) {
    return affiliateSiteId;
  }
  return config.siteId;
}

const MatomoProvider: React.FC<MatomoProviderProps> = ({
  children,
  config,
}) => {
  const affiliateCode = getAffiliateCode();
  const siteId = resolveSiteId(config, affiliateCode);
  const matomoConfig = { ...config, siteId };
  const tracker = createInstance(matomoConfig);
  const [visitorIdSearchParam, setVisitorIdSearchParam] = useState('');

  useEffect(() => {
    if (!visitorIdSearchParam) {
      window._paq.push([
        // eslint-disable-next-line func-names
        function (this: any) {
          // eslint-disable-next-line react/no-this-in-sfc
          const visitorId = this.getCrossDomainLinkingUrlParameter();
          console.log('visitorIdSearchParam', visitorId);
          setVisitorIdSearchParam(visitorId);
        } as any,
      ]);
    }
  }, [visitorIdSearchParam]);

  const value = {
    tracker,
    visitorIdSearchParam,
  };

  return (
    <MatomoContext.Provider value={value}>{children}</MatomoContext.Provider>
  );
};

export default MatomoProvider;
