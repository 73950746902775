import React, { ReactElement } from 'react';
import useViewport from 'hooks/useViewport';
import './index.scss';
import parse from 'html-react-parser';
import VisitWebsiteCards from 'pages/shop-home-page/website-cards';

interface videoHeroProps {
  cssClassNameWrapper?: string;
  cssClassNameContent?: string;
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaLink?: string;
  ctaSKU?: string;
  tagLine?: string;
  videoLink?: string;
  fallbackImage?: string;
  cssClassNameCTA?: string;
}

export default function VideoHero(props: videoHeroProps): ReactElement {
  const {
    cssClassNameWrapper,
    cssClassNameContent,
    title,
    subtitle,
    ctaText,
    ctaLink,
    ctaSKU,
    tagLine,
    videoLink,
    fallbackImage,
    cssClassNameCTA,
  } = props;

  const { isMobile } = useViewport();

  return (
    <section
      className={`video-wrapper ${cssClassNameWrapper && cssClassNameWrapper}`}
    >
      <div
        className={`video-overlay-content ${
          cssClassNameContent && cssClassNameContent
        }`}
      >
        {title && (
          <h1 className="text-white mb-4 title-text uppercase">{title}</h1>
        )}
        {subtitle && <h2>{subtitle}</h2>}
        {(!!ctaSKU || !!ctaLink) && (
          <VisitWebsiteCards
            sku={ctaSKU as string}
            header={parse(ctaText as string)}
            link={ctaLink}
            isButton
            classList={`shop-button solid sb-primary border-none py-2 px-4 ${
              cssClassNameCTA && cssClassNameCTA
            }`}
          />
        )}
      </div>
      {tagLine && (
        <div className="tagline-wrapper">
          <p className="py-0">{tagLine}</p>
        </div>
      )}
      {/* TODO: Fix fallbackImage for all */}
      {videoLink && !isMobile ? (
        <div className="main">
          <div className="overlay" />
          <video src={videoLink} autoPlay loop muted />
        </div>
      ) : (
        <section
          className="background-image-only"
          style={{ backgroundImage: `url(${fallbackImage})` }}
        />
      )}
    </section>
  );
}
