import React from 'react';
import parse from 'html-react-parser';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';
import ProductFeatureBtn, { FeatureButtonType } from './ProductFeatureBtn';

export type StepsType = {
  title: string;
  text: string;
  duration: string;
  image: string;
  displayImageLeft?: boolean;
  stepsBtn: FeatureButtonType;
  htmlContent?: string | '';
  headerSection: headerType;
  styles?: {
    imageCol?: string;
    imageWidth?: string;
    borderBottom?: string;
    textContentCol?: string;
  };
  video?: {
    showVideo: boolean;
    header: string;
    accountId: string;
    videoId: string;
  };
};

type headerType = {
  header: string;
  text: string;
  duration: string;
  image: string;
  headerStyles?: { header?: string; subheader?: string };
  displayImageLeft?: boolean;
  stepsHeaderBtn: FeatureButtonType;
  htmlContent?: string | '';
};

interface StepsProps {
  headerSection?: headerType;
  alternateImg: boolean;
  steps: StepsType[];
  className: string;
}

function alternateImagePosition(value: number, alternateImg: boolean) {
  if (alternateImg) {
    if (value % 2 === 0) {
      return '';
    }
    return 'flex-row-reverse';
  }

  return 'flex-row-reverse';
}

export default function ProductFeatureSteps({
  headerSection,
  steps,
  alternateImg = false,
  className = '',
}: StepsProps) {
  return (
    <div className={`p-3 steps ${className}`}>
      {headerSection && (
        <section className="steps__header grid ">
          <h2
            className={`${
              headerSection?.headerStyles?.header
                ? headerSection?.headerStyles.header
                : 'col-12 md:col-8 uppercase p-0'
            }`}
          >
            {headerSection?.header}
          </h2>
          <p
            className={`${
              headerSection?.headerStyles?.subheader
                ? headerSection?.headerStyles.subheader
                : 'col-12 md:col-8 p-0 mb-3'
            }`}
          >
            {headerSection?.text}
          </p>
          {headerSection?.image && (
            <div className="w-full md:w-6 m-auto">
              <img
                className="w-full"
                src={headerSection.image}
                alt={headerSection.header}
                style={{ maxHeight: '239px', width: 'auto' }}
              />
            </div>
          )}
          {headerSection?.stepsHeaderBtn?.showButton && (
            <div className="col-12 mb-5 p-0">
              <ProductFeatureBtn button={headerSection?.stepsHeaderBtn} />
            </div>
          )}
        </section>
      )}
      {steps.map((section, index) => (
        <div
          key={section.title}
          className={`steps__section ${
            section.styles?.borderBottom
              ? `border-bottom-${section.styles?.borderBottom}`
              : 'border-bottom-1'
          } border--color border `}
        >
          <div
            className={`grid py-3 md:py-6 ${
              section.displayImageLeft
                ? 'flex-row'
                : alternateImagePosition(index, alternateImg)
            }`}
          >
            <div
              className={`${
                section.styles?.imageCol
                  ? `${section.styles?.imageCol}`
                  : 'md:col-6'
              } col-12`}
            >
              <img
                className={`${
                  section.styles?.imageWidth
                    ? section.styles.imageWidth
                    : ' w-full'
                }`}
                src={section.image}
                alt=""
              />
            </div>
            {section.htmlContent ? (
              <div
                className={`${
                  section.styles?.textContentCol
                    ? `${section.styles?.textContentCol}`
                    : 'md:col-6'
                } col-12 px-4 pt-3`}
              >
                <h3 className="uppercase steps__title">{section.title}</h3>
                {parse(section.htmlContent)}
                {section.stepsBtn.showButton && (
                  <ProductFeatureBtn button={section.stepsBtn} />
                )}
              </div>
            ) : (
              <div
                className={`${
                  section.styles?.textContentCol
                    ? `${section.styles?.textContentCol}`
                    : 'md:col-6'
                } col-12 px-4 pt-3`}
              >
                {section.title && (
                  <h3 className="uppsercase steps__title">{section.title}</h3>
                )}
                {section.text && (
                  <p className="steps__text mt-0 pt-1">{section.text}</p>
                )}
                {section.stepsBtn.showButton && (
                  <ProductFeatureBtn button={section.stepsBtn} />
                )}
              </div>
            )}
            {section.video?.videoId && section.video.showVideo && (
              <div className="col-12">
                <h2 className="col-12 text-center uppercase">
                  {section.video.header}
                </h2>
                <div className="video-wrap mt-5">
                  <h2 className="col-12 px-4">{section.video.header}</h2>
                  <VimeoPlayer
                    url={`https://vimeo.com/${section.video?.videoId}`}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
