import { keyframes } from '@emotion/react';

export const leftSlideDesktop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50px, 0px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const leftSlideMobile = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const rightSlideDesktop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(50px, 0px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const rightSlideMobile = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0px, 50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
