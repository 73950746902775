import React from 'react';
import { zxcvbn } from '@zxcvbn-ts/core';
import { FeedbackType } from '@zxcvbn-ts/core/dist/types';
import { DataTable, Column } from 'primereact';

// Docs: https://zxcvbn-ts.github.io/zxcvbn

export type ShouldShowPasswordFeedback = {
  displayScore?: boolean;
  displayFeedback?: boolean;
};

const passwordBarColors = {
  0: { color: '#9B2C2C', width: '20%' },
  1: { color: '#D44949', width: '40%' },
  2: { color: '#DCA02D', width: '60%' },
  3: { color: '#387F95', width: '80%' },
  4: { color: '#48AE65', width: '100%' },
};

export const PasswordScoreBar = ({
  indicatorColor,
  score,
}: {
  indicatorColor: number;
  score: number;
}) => {
  const normalizedScore = Math.round((score / 4) * 100);

  return (
    <div
      className="indicator flex justify-content-center w-full"
      key={indicatorColor}
      style={{
        backgroundColor: passwordBarColors[score].color,
        width: passwordBarColors[score].width,
      }}
    >
      <small className="text-white mx-auto px-3">{normalizedScore}/100</small>
    </div>
  );
};

export const PasswordSuggestions = ({ passwordFeedback }) => {
  const { warning, suggestions }: FeedbackType = passwordFeedback;
  let passwordSuggestionsAndWarning;

  if (suggestions.length) {
    passwordSuggestionsAndWarning = suggestions.map((suggestion, index) => {
      let newFeedBackObj = {
        warning: '',
        suggestions: suggestion,
      };
      if (index === 0 && warning) {
        newFeedBackObj = {
          ...newFeedBackObj,
          warning,
        };
      }
      return newFeedBackObj;
    });
  }

  if (passwordSuggestionsAndWarning) {
    return (
      <DataTable
        value={passwordSuggestionsAndWarning}
        responsiveLayout="scroll"
      >
        <Column field="warning" header="warning" />
        <Column field="suggestions" header="suggestions" />
      </DataTable>
    );
  }
  return <></>;
};

export const PasswordFeedbackAndScore = ({
  inputValue,
  displayFeedback,
  displayScore,
}) => {
  const feedback = zxcvbn(inputValue) ? zxcvbn(inputValue).feedback : undefined;
  return (
    <>
      {displayScore && (
        <PasswordScoreBar
          indicatorColor={zxcvbn(inputValue).score || -1}
          score={zxcvbn(inputValue).score || 0}
        />
      )}
      {feedback && displayFeedback && (
        <PasswordSuggestions passwordFeedback={feedback} />
      )}
    </>
  );
};
