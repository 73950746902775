import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductInterface } from 'graphql/generated/magentoApi';
import ReactGA from 'react-ga4';
import { useMatomo } from 'matomoReact';
import { Button } from 'primereact/button';

interface BuyNowButtonProps {
  product: ProductInterface & { __typename: string };
  label: string;
  loading?: boolean;
  btnStyle?: string;
}

export const BuyNowCheckoutButton = ({
  product,
  label,
  loading,
  btnStyle,
}: BuyNowButtonProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useMatomo();

  const onClickAddCartWebsiteBtn = useCallback(
    (sku: string) => {
      navigate(`/checkout/add-sku/${sku}`);

      ReactGA.event({
        category: 'buy_now',
        action: 'Buy Now - Product Card',
        label: `Add product to cart and checkout`,
      });
      trackEvent({
        category: 'buy_now',
        action: 'Buy Now - Product Card',
        name: `Add product to cart and checkout`,
      });
    },
    [navigate, trackEvent],
  );

  return (
    <Button
      className={
        btnStyle ||
        `uppercase font-bold text-xs border-noround c-button-primary my-0 mb-4`
      }
      label={label || 'Buy Now'}
      onClick={() => onClickAddCartWebsiteBtn(product.sku)}
      loading={loading}
      id={`product-add-to-cart-${product.sku}`}
    />
  );
};

export default BuyNowCheckoutButton;
