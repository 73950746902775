import { ReactElement } from 'react';
import { Head } from 'components/head/Head';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import { getMarketContent } from 'utils/marketContent';
import VideoHero from 'components/video-hero';
import { Fade } from 'react-awesome-reveal';

export default function Team(): ReactElement {
  const { teamPage } = getMarketContent();
  return (
    <>
      {teamPage && (
        <>
          <Head
            link="/team"
            title="Team"
            addPostfixTitle
            description="Our Team"
            keywords="ONE TEAM, ONE GOAL"
            largeTwitterCard
          />
          <ShopNavBar hasVideoNavBar />
          <VideoHero
            cssClassNameWrapper={teamPage.videoHero?.cssClassNameWrapper}
            cssClassNameContent={teamPage.videoHero?.cssClassNameContent}
            title={teamPage.videoHero.title}
            subtitle={teamPage.videoHero.subtitle}
            ctaText={teamPage.videoHero.ctaText}
            ctaLink={teamPage.videoHero.ctaLink}
            tagLine={teamPage.videoHero.tagLine}
            videoLink={teamPage.videoHero.videoLink}
            fallbackImage={teamPage.videoHero.fallbackImage}
            cssClassNameCTA={teamPage.videoHero.cssClassNameCTA}
          />
          <section className="my-6">
            <div className="container px-4 py-8 md:px-0">
              <div className="col-12 md:col-8 md:col-offset-2">
                <h1 className="text-center mb-4 text-xl uppercase">
                  {teamPage.title}
                </h1>

                <div className="grid justify-content-between">
                  {teamPage.teamMembers.map(obj => (
                    <Fade
                      key={obj.name}
                      className="col-10 md:col-5 mx-auto sm:mr-auto"
                    >
                      <div>
                        <img src={obj.image} alt="" className="w-full" />
                        <h4>{obj.name}</h4>
                        <h5 className="brand-10x">{obj.role}</h5>
                        <p>{obj.bio}</p>
                      </div>
                    </Fade>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <MarketplaceFooter />
        </>
      )}
    </>
  );
}
