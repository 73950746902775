import {
  faFacebookSquare,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './scss/SocialMediaButtons.scss';

export function TwitterButton({ url }) {
  return (
    <a
      href={`https://twitter.com/intent/tweet?text=Checkout this website!&url=${url}`}
      target="_blank"
      rel="noreferrer"
      aria-label="Share on Twitter"
    >
      <FontAwesomeIcon
        icon={faTwitterSquare}
        className="font-awesome-facebook"
      />
    </a>
  );
}

export function FacebookButton({ url }) {
  return (
    <a
      href={`http://www.facebook.com/sharer/sharer.php?u=${url}`}
      target="_blank"
      rel="noreferrer"
      aria-label="Share on Facebook"
    >
      <FontAwesomeIcon
        icon={faFacebookSquare}
        className="font-awesome-facebook"
      />
    </a>
  );
}

export function EmailShareButton({ url }) {
  return (
    <a
      href={`mailto:?&subject=I wanted you to see this site!&body=Checkout this website ${url}`}
      target="_blank"
      rel="noreferrer"
      aria-label="Share via Email"
    >
      <FontAwesomeIcon
        icon={faEnvelopeSquare}
        className="font-awesome-facebook"
      />
    </a>
  );
}
