import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { ReactElement, useEffect, useState } from 'react';
import {
  CreateProductReviewInput,
  ProductReviewRatingMetadata,
  useAddProductReviewMutation,
  useGetRatingsMetadataQuery,
} from 'graphql/generated/magentoApi';
import './ProductReview.scss';

type ProductReviewProps = {
  showReviewForm: boolean;
  fromIMedia?: boolean;
  productSku: string;
  setShowReviewForm: (state: boolean) => void;
};

type FieldValuesType = {
  nickname: string;
  ratings: number[];
  sku: string;
  summary: string;
  text: string;
};

export default function ProductReview({
  showReviewForm,
  fromIMedia = false,
  productSku,
  setShowReviewForm,
}: ProductReviewProps): ReactElement {
  const [defaultValues] = useState<FieldValuesType>({
    nickname: '',
    ratings: [],
    sku: productSku,
    summary: '',
    text: '',
  });
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues,
  });
  const { errors } = formState;

  const [{ fetching: fetchingMetadata, data: metadata, error: errorMetadata }] =
    useGetRatingsMetadataQuery();
  const ratingItems = metadata?.productReviewRatingsMetadata
    .items as unknown as ProductReviewRatingMetadata[];

  const [{ fetching, data, error }, addProductReview] =
    useAddProductReviewMutation();

  useEffect(() => {
    if (!fetching && !error && data?.createProductReview.review) {
      setShowReviewForm(false);
      reset();
    }
  }, [fetching, data, error, setShowReviewForm, reset]);

  const getFormErrorMessage = (name: string, ratingName?: string) => {
    if (name === 'ratings' && ratingName) {
      return (
        <>
          {errors.ratings?.map?.(ratingError =>
            ratingError?.message?.includes(ratingName) ? (
              <small key={ratingError.message} className="p-error w-full">
                {ratingError.message}
              </small>
            ) : null,
          )}
        </>
      );
    }
    return (
      errors[name] && (
        <small className="p-error w-full">{errors[name].message}</small>
      )
    );
  };

  const getRatingInputs = () => {
    if (!ratingItems || !ratingItems.length) {
      return null;
    }
    return (
      <>
        {ratingItems.map((rating, i) => {
          const requiredMsg = `${rating?.name} is required.`;
          return rating ? (
            <div key={rating.id} className="flex align-items-center mb-3">
              <label htmlFor={`ratings.${i}`} style={{ width: '5.5rem' }}>
                {rating.name}
              </label>
              <div className="flex flex-column">
                <Controller
                  name={`ratings.${i}`}
                  control={control}
                  rules={{ required: requiredMsg }}
                  render={({ field, fieldState }) => (
                    <Rating
                      {...field}
                      id={field.name}
                      value={field.value || 0}
                      cancel={false}
                      className={classNames({
                        'p-invalid': fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage('ratings', rating.name)}
              </div>
            </div>
          ) : null;
        })}
      </>
    );
  };

  const onSubmit = (data: FieldValuesType) => {
    const { nickname, sku, summary, text, ratings } = data;
    const review: CreateProductReviewInput = {
      nickname,
      sku,
      summary,
      text,
      ratings: ratingItems.map((feature, i) => ({
        id: feature.id,
        value_id:
          ratingItems[i].values.find(
            value => value.value === ratings[i].toString(),
          )?.value_id || '',
      })),
    };
    addProductReview({ review });
  };

  if (fetchingMetadata || errorMetadata) {
    return <></>;
  }

  return (
    <Dialog
      header="Write a review"
      visible={showReviewForm}
      onHide={() => setShowReviewForm(false)}
      breakpoints={{ '1200px': '40vw', '960px': '75vw', '640px': '90vw' }}
      style={{ width: '40vw' }}
      className="product-review-modal"
      dismissableMask
      draggable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-column mb-3">
          <label
            htmlFor="nickname"
            style={{ width: '5.5rem' }}
            className="mb-1"
          >
            Nickname
          </label>
          <div className="flex flex-column w-full">
            <Controller
              name="nickname"
              control={control}
              rules={{ required: 'Nickname is required.' }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  id={field.name}
                  value={field.value || ''}
                  className={classNames({ 'p-invalid': fieldState.invalid })}
                  placeholder="Username or nickname"
                  style={{ minWidth: '40%' }}
                />
              )}
            />
            {getFormErrorMessage('nickname')}
          </div>
        </div>
        {getRatingInputs()}
        <div className="flex flex-column w-full mb-3">
          <label htmlFor="summary" style={{ width: '5.5rem' }} className="mb-1">
            Summary
          </label>
          <div className="flex flex-column flex-1 w-full">
            <Controller
              name="summary"
              control={control}
              rules={{ required: 'Summary is required.' }}
              render={({ field, fieldState }) => (
                <InputText
                  {...field}
                  id={field.name}
                  value={field.value || ''}
                  className={classNames('flex-1', {
                    'p-invalid': fieldState.invalid,
                  })}
                  placeholder="Short description"
                />
              )}
            />
            {getFormErrorMessage('summary')}
          </div>
        </div>
        <div className="flex flex-column w-full mb-5">
          <label htmlFor="text" style={{ width: '5.5rem' }} className="mb-1">
            Description
          </label>
          <div className="flex-column w-full">
            <Controller
              name="text"
              control={control}
              rules={{ required: 'Description is required.' }}
              render={({ field, fieldState }) => (
                <InputTextarea
                  {...field}
                  id={field.name}
                  value={field.value || ''}
                  className={classNames('flex-1 w-full', {
                    'p-invalid': fieldState.invalid,
                  })}
                  placeholder="Tell us more"
                  rows={5}
                  cols={30}
                  autoResize
                />
              )}
            />
            {getFormErrorMessage('text')}
          </div>
        </div>
        <div className="flex justify-content-end">
          <Button
            className={`uppercase text-center m-0 ${
              fromIMedia
                ? 'write-review-btn'
                : 'c-button-primary border-noround'
            }`}
            label="Send"
            type="submit"
          />
        </div>
      </form>
    </Dialog>
  );
}
