import axios from 'axios';
import { Address } from 'types';

const smartyLicence = process.env.REACT_APP_SMARTY_LICENSE as string;
const smartyAuthToken = process.env.REACT_APP_SMARTY_AUTH_TOKEN as string;
const baseUrl = 'https://us-street.api.smartystreets.com/street-address';

const smartyLocalhostToken = '105107379775910059';

export interface SmartyAddressInput {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zipcode: string;
  match: 'enhanced';
}

export interface SmartyAddressResponse {
  input_index: number;
  candidate_index: number;
  delivery_line_1: string;
  delivery_line_2?: string;
  last_line: string;
  delivery_point_barcode: string;
  components: {
    primary_number: string;
    street_name: string;
    street_suffix: string;
    city_name: string;
    default_city_name: string;
    state_abbreviation: string;
    zipcode: string;
    plus4_code: string;
    delivery_point: string;
    delivery_point_check_digit: string;
  };
  metadata: {
    record_type: string;
    zip_type: string;
    county_fips: string;
    county_name: string;
    carrier_route: string;
    congressional_district: string;
    rdi: string;
    elot_sequence: string;
    elot_sort: string;
    latitude: number;
    longitude: number;
    precision: string;
    time_zone: string;
    utc_offset: number;
    dst?: boolean;
  };
  analysis: {
    dpv_match_code: string;
    dpv_footnotes: string;
    dpv_cmra: string;
    dpv_vacant: string;
    dpv_no_stat: string;
    active: string;
  };
}

export interface AddressValidationResult {
  isValid: boolean;
  address?: Address;
}

export async function validateUsAddress(
  address: Address,
): Promise<AddressValidationResult> {
  const searchParams = new URLSearchParams();
  if (window.location.origin.startsWith('http://localhost')) {
    searchParams.set('key', smartyLocalhostToken);
  } else {
    searchParams.set('key', smartyAuthToken);
  }
  searchParams.set('license', smartyLicence);
  searchParams.set('candidates', '1');
  searchParams.set('street', address.street1);
  if (address.street2) {
    searchParams.set('street2', address.street2);
  }
  searchParams.set('city', address.city);
  searchParams.set('state', address.stateCode);
  searchParams.set('zipcode', address.postalCode);

  const responseAddresses: SmartyAddressResponse[] = await axios({
    url: `${baseUrl}?${searchParams.toString()}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then(response => response.data);

  if (responseAddresses.length === 0) {
    return {
      isValid: false,
    };
  }
  const response = responseAddresses[0];

  return {
    isValid: true,
    address: {
      street1: response.delivery_line_1,
      street2: response.delivery_line_2,
      city: response.components?.city_name,
      stateCode: response.components?.state_abbreviation,
      postalCode: response.components.zipcode,
      postalPlus4: response.components.plus4_code,
      countryCode: address.countryCode,
      latitude: response.metadata?.latitude,
      longitude: response.metadata?.longitude,
    },
  };
}
