import { Carousel } from 'primereact/carousel';
import { ReactElement, useEffect } from 'react';
import { getMarketContent } from 'utils/marketContent';
import './index.scss';
// import data from './data.json';

export default function Testimonials(): ReactElement {
  const market = getMarketContent();
  const marketTestimonials = market.homePage.testimonials;
  const TestimonialBox = ({ author, address, text }) => (
    <div className="testimonial-card">
      <p className="testimonial-text">{text}</p>
      <p className="author">
        <span>{author}</span>
        <span>{address}</span>
      </p>
    </div>
  );

  useEffect(() => {
    const Indicators = Array.from(document.querySelectorAll('button.p-link'));
    Indicators.forEach(indicator => {
      indicator.setAttribute('aria-label', 'slide-dot');
    });
  });

  return (
    <section id="shop-testimonials">
      <h2 className="section_header">HAPPY CLIENTS</h2>
      <Carousel
        value={marketTestimonials}
        itemTemplate={TestimonialBox}
        numVisible={1}
        numScroll={1}
        circular
        autoplayInterval={7000}
      />
    </section>
  );
}
