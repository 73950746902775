import React, { useState, useEffect, useCallback } from 'react';
import TreeNode from 'primereact/treenode';
import { fetchCategories } from 'utils/fetchCategories';
import { useClient } from 'urql';
import { getMarketContent } from 'utils/marketContent';
import { NavLink as Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import useViewport from 'hooks/useViewport';
import { Chip } from 'primereact/chip';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  TreeTable,
  TreeTableExpandedKeysType,
  TreeTableSelectionKeysType,
} from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { categoriesToTreeNodes } from './categoryUtils';

interface CatalogCategoryFilterProps {
  setCategoryPaths: React.Dispatch<React.SetStateAction<string[]>>;
}

const CatalogCategoryFilter: React.FC<CatalogCategoryFilterProps> = props => {
  const { setCategoryPaths } = props;

  const client = useClient();
  const { rootCategory: marketRoot } = getMarketContent();
  const rootCategory = marketRoot ? `marketplace/${marketRoot}` : `shopping`;
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [selectedNodekey, setSelectedNodekey] =
    useState<TreeTableSelectionKeysType | null>(null);
  const [expandedKeys, setExpandedKeys] = useState<TreeTableExpandedKeysType>(
    {},
  );

  const { isMobile } = useViewport();

  const getCategories = useCallback(async () => {
    const response = await fetchCategories(client, [rootCategory]);

    if (response) {
      const treeNodes = categoriesToTreeNodes(response);
      if (treeNodes[0].children) {
        setNodes(treeNodes[0].children);
      } else {
        setNodes(treeNodes);
      }
    }
    return null;
  }, [client, rootCategory]);

  const tabHeaderTemplate = options => (
    <button
      type="button"
      onClick={options.onClick}
      className={options.className}
    >
      <Chip className="bg-primary text-xs" label={options.titleElement} />
    </button>
  );

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <div>
      {nodes.length ? (
        <>
          {isMobile ? (
            <Card
              className="filter-card mt-0 mb-0 border-none shadow-none"
              header={<h3 className="section_header">Collections</h3>}
            >
              <TabView
                onTabChange={e =>
                  setCategoryPaths([nodes[e.index].key as string])
                }
              >
                {nodes.map(node => (
                  <TabPanel
                    key={node.key}
                    header={node.label}
                    headerTemplate={tabHeaderTemplate}
                    headerClassName="flex align-items-center"
                  >
                    <span style={{ fontFamily: '"Raleway", sans-serif' }}>
                      CATEGORIES
                    </span>
                    <div
                      className="flex flex-row flex-wrap align-items-center justify-content-start mt-2"
                      style={{ gap: '16px' }}
                    >
                      {node.children?.map(child => (
                        <Link
                          key={child.key}
                          className="store-nav-lower-mega no-underline capitalize text-sm"
                          to={child.key as string}
                          onClick={() =>
                            setCategoryPaths([child.key as string])
                          }
                        >
                          <Chip
                            style={{ color: 'unset' }}
                            label={child.label}
                          />
                        </Link>
                      ))}
                    </div>
                  </TabPanel>
                ))}
              </TabView>
              <Button
                onClick={() => setCategoryPaths([rootCategory])}
                label="Reset Collection Filter"
                className="bg-transparent text-primary block outline my-3 p-2 text-xs text-center max-w-max w-full flex ml-auto mr-2"
              />
            </Card>
          ) : (
            <div className="category-tree">
              <TreeTable
                value={nodes}
                expandedKeys={expandedKeys}
                onToggle={e => setExpandedKeys(e.value)}
                selectionMode="single"
                selectionKeys={selectedNodekey}
                onSelectionChange={e => {
                  setSelectedNodekey(e.value);
                  setCategoryPaths([
                    e.value ? e.value.toString() : rootCategory,
                  ]);
                }}
              >
                <Column field="name" header="COLLECTIONS" expander />
              </TreeTable>
            </div>
          )}
        </>
      ) : (
        <Skeleton height="250px" borderRadius="4px" />
      )}
    </div>
  );
};

export default CatalogCategoryFilter;
