import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Fade } from 'react-awesome-reveal';
import { ReactElement, useState } from 'react';
import { useCartContext } from 'CartContext';
import { useContent } from 'utils/useContent';
import { useGetProductsBySkus } from 'hooks/magentoHooks';
import buttonize from 'utils/buttonize';
import parse from 'html-react-parser';
import Product from 'components/Product';
import ReactGA from 'react-ga4';
import Logo from 'assets/crwd/brand_market/crwdmarket_icon_b.svg';
import { useMatomo } from 'matomoReact';
import './scss/PackagesDetails.scss';

type FooterPropsType = {
  sku: string | null;
  name: string | null;
  is_subscription: number | null;
  price: number | null;
};

export default function PackagesDetails(): ReactElement {
  const { product, participate } = useContent();
  const [displayBasic, setDisplayBasic] = useState<boolean>(false);
  const [dialogPackage, setDialogPackage] = useState('');
  const [, setPackageName] = useState('');
  const { products } = useGetProductsBySkus({
    skus: product.skus,
  });

  const { updateCart } = useCartContext();

  const { trackEvent } = useMatomo();

  const maxWrapperWidth = Number(product.skus.length) > 3 ? '1000px' : '1218px';
  const maxCardWidth = Number(product.skus.length) > 3 ? '480px' : '386px';

  function Packages() {
    const onClick = (sku?: string | null, header?: string | null) => {
      setDisplayBasic(!displayBasic);
      setDialogPackage(sku || '');
      setPackageName(header || '');
      ReactGA.event({
        category: 'ecommerce',
        action: 'product_view',
        label: `Product View ${sku}`,
      });
      trackEvent({
        category: 'ecommerce',
        action: 'product_view',
        name: `Product View ${sku}`,
      });
    };

    const footer = ({ sku, name, price, is_subscription }: FooterPropsType) => {
      let subscriptionPlan = 'monthly';
      if (is_subscription === 1) {
        if (name?.toLowerCase().includes('yearly')) {
          subscriptionPlan = 'yearly';
        }
      }

      return (
        <>
          <p className="p-0 m-0 text-lg font-bold price my-4 text-center flex flex-column mt-auto">
            <span>{`${price === null ? 'Free' : `$${price}`}`}</span>
            <span className="product-subscription">
              {is_subscription ? `${subscriptionPlan} ` : <span>one-time</span>}
            </span>
          </p>
          <div className="flex justify-content-center w-full mb-4">
            <button
              type="button"
              className="p-0 m-0 view-product-button"
              onClick={() => onClick(sku, name)}
              id="product-card-view-action"
            >
              View Product
            </button>
          </div>
          <div className="flex justify-content-center">
            <Button
              label="ADD TO CART"
              className="w-full p-button-rounded add-to-cart-button p-3"
              onClick={() =>
                updateCart({
                  sku: sku || '',
                  quantity: 1,
                  showToast: true,
                  debug: 'PackagesDetails',
                })
              }
              id={`product-card-add-to-cart-action-${sku}`}
            />
          </div>
        </>
      );
    };

    return (
      <div className="grid mobile-package-cards m-auto justify-content-center">
        {products.map(
          (
            {
              sku,
              name,
              short_description,
              image,
              is_subscription,
              price_range,
              vendor_company,
            },
            index,
          ) => (
            <div
              className="relative column"
              style={{ width: maxCardWidth }}
              key={sku || `product-${index}`}
              {...buttonize(() => onClick(sku, name))}
              id={`product-card-${index}`}
            >
              <Card
                header={
                  <img
                    alt={name || ''}
                    src={image?.url || ''}
                    className={`${participate.exchangeName}`}
                  />
                }
                footer={footer({
                  sku,
                  name,
                  price: price_range.maximum_price?.final_price.value || null,
                  is_subscription,
                })}
                className="product-card flex flex-column justify-content-space-between"
                style={{
                  height: '100%',
                }}
              >
                <div className="w-full pt-0 mb-2">
                  <p className="font-italic mt-0 mb-0 text-small">
                    {vendor_company}
                  </p>
                  <p className="p-0 m-0 text-lg font-bold product-name mx-auto">
                    {name}
                  </p>
                </div>
                <div className="product-description">
                  {parse(`${short_description?.html}`)}
                </div>
              </Card>
            </div>
          ),
        )}
        <Dialog
          visible={displayBasic}
          breakpoints={{ '960px': '90vw', '640px': '90vw' }}
          style={{ height: '80vh', width: '92vw' }}
          position="center"
          header={
            <div className="mt-0 mb-0 pb-0 max-w-max">
              <img
                src={`${Logo}`}
                alt="CRWD logo"
                className="crwd-nav-logo"
                style={{ maxWidth: '120px', minWidth: '120px' }}
              />
            </div>
          }
          dismissableMask
          draggable={false}
          onHide={onClick}
          className="package-details-modal"
        >
          <Product sku={dialogPackage} fromIMedia />
        </Dialog>
      </div>
    );
  }

  return (
    <>
      <section
        id="products"
        className="section-margin p-m80-d100"
        style={{ maxWidth: maxWrapperWidth, paddingBottom: '100px' }}
      >
        <div className="px-4">
          <h3 className="flex justify-content-center text-5xl capitalize font-normal text-center mb-6">
            {product.packagesTitle}
          </h3>
        </div>
        <div className="flex justify-content-center">
          <Fade triggerOnce>{Packages()}</Fade>
        </div>
      </section>
    </>
  );
}
