import './scss/Footer.scss';

export default function ScrollButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <button
      type="button"
      onClick={scrollToTop}
      className="footer--scroll cursor-pointer"
      aria-label="Scroll To Top"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18.5" cy="18.5" r="18" stroke="#5C5555" />
        <path
          d="M13.5 21.0612L18.602 14.9387L23.5 21.0612"
          stroke="#8886FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
