import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useClient } from 'urql';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { getMarketContent } from 'utils/marketContent';
import { buildCategoryPaths } from '../../utils/buildCategoryPaths';
import { fetchCategories } from '../../utils/fetchCategories';

interface CategoryBreadCrumbProps {
  setLoadingCategories: React.Dispatch<React.SetStateAction<boolean>>;
  componentStyle?: string;
  setCategoryPaths: React.Dispatch<React.SetStateAction<string[]>>;
  categoryPaths: string[];
  showHome?: boolean;
}

export const CategoriesBreadCrumb = ({
  setLoadingCategories,
  setCategoryPaths,
  categoryPaths,
  componentStyle = 'w-full p-breadcrumb-chevron--hidden p-breadcrumb-category mb-4',
  showHome = false,
}: CategoryBreadCrumbProps) => {
  const client = useClient();
  const navigateBase = useNavigate();
  const params = useParams();
  const urlParams: string | undefined = params['*'];
  const [searchParams] = useSearchParams();
  const vendorParam = searchParams.get('vendor') || undefined;
  const categoriesParam = searchParams.get('categories') || undefined;
  const [breadcrumbModel, setBreadcrumbModel] = useState<MenuItem[]>([]);
  const { rootCategory } = getMarketContent();

  const getCategoryPaths = useCallback(async () => {
    const paths = categoryPaths?.filter(category =>
      category !== rootCategory ? rootCategory : 'shopping',
    );

    await fetchCategories(client, paths).then(categories => {
      let tempCategory = [...categories];

      if (urlParams) {
        if (tempCategory.length > 1) {
          tempCategory = categories.filter(
            category =>
              category?.url_path?.toLowerCase() === urlParams.toLowerCase(),
          );
        }
      }
      const firstCategory = categories[0] || {};
      const homeMenuItem: MenuItem = {
        label: 'Catalog',
        command: () => {
          let path: string;
          if (window.location.pathname.includes('collection')) {
            path = `/collections`;
          } else {
            path = rootCategory
              ? `category/marketplace/${rootCategory}`
              : '/category';
          }
          navigateBase(path);
          setCategoryPaths([
            rootCategory.length !== 0 ? rootCategory : 'shopping',
          ]);
        },
      };

      const fetchedBreadcrumbs = (firstCategory.breadcrumbs?.map(item => {
        if (item.category_url_path === rootCategory) {
          return homeMenuItem;
        }
        return {
          label: item.category_name,
          command: () => navigateBase(`${item.category_url_path}`),
        };
      }) || []) as MenuItem[];

      const menuItems: MenuItem[] = [];

      if (vendorParam) {
        menuItems.push({
          label: 'Catalog',
          command: () => {
            navigateBase(`/collections`);
            setCategoryPaths([
              rootCategory.length !== 0 ? rootCategory : 'shopping',
            ]);
          },
        });
        const vendorCategories = categories.map(item => ({
          label: item.name,
          command: () => {
            navigateBase(
              `/category/marketplace/${rootCategory}/${item.url_path}`,
            );
            window.location.reload();
          },
        }));

        menuItems.concat(vendorCategories);
      } else {
        if (!rootCategory) {
          menuItems.push(homeMenuItem);
        }

        menuItems.push(...fetchedBreadcrumbs);

        if (firstCategory.url_path === `marketplace/${rootCategory}`) {
          menuItems.push({ items: [homeMenuItem] });
        }

        if (firstCategory.url_path) {
          menuItems.push({
            label: firstCategory.name,
            command: () => navigateBase(`${firstCategory.url_path}`),
          });
        }
        // const children = tempCategory
        //   .reduce<Partial<CategoryTree>[]>((previous, category) => {
        //     category.children?.forEach(child => {
        //       if (!previous.find(c => c.url_path === child.url_path)) {
        //         previous.push(child);
        //       }
        //     });
        //     return previous;
        //   }, [])
        //   .filter(item => !!item.products?.total_count)
        //   .map(item => ({
        //     label: item.name,
        //     icon: 'pi pi-angle-right',
        //     command: () =>
        //       navigateBase(
        //         `${
        //           rootCategory ? item.url_path : `/category/${item.url_path}`
        //         }`,
        //       ),
        //   }));
      }

      // TODO: Make Dymanic
      const removeNav = [
        'marketplace',
        'communications sector',
        'infotech sector',
        'advanced medicine',
        '10x health',
      ];
      setBreadcrumbModel([
        {
          label: 'Catalog',
          command: () => {
            navigateBase(
              rootCategory ? `/category/marketplace/${rootCategory}` : '/',
            );
            setCategoryPaths([
              rootCategory.length !== 0 ? rootCategory : 'shopping',
            ]);
          },
        },
        ...menuItems.filter(
          item => !removeNav.includes(item.label?.toLowerCase() as string),
        ),
      ]);
      setLoadingCategories(false);
    });
  }, [
    categoryPaths,
    client,
    navigateBase,
    rootCategory,
    setCategoryPaths,
    setLoadingCategories,
    urlParams,
    vendorParam,
  ]);

  useEffect(() => {
    if (!categoryPaths?.includes(urlParams as string) && !vendorParam) {
      setCategoryPaths(
        buildCategoryPaths(
          urlParams,
          categoriesParam,
          rootCategory || 'shopping',
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, categoriesParam, rootCategory, vendorParam]);

  useEffect(() => {
    // setLoadingCategories(true);

    getCategoryPaths();
  }, [categoryPaths, getCategoryPaths]);

  const parentBreadCrumb = { icon: 'pi pi-chevron-left' };

  const parentCategory = breadcrumbModel
    .slice(-2)
    .filter((item, index) => index === 0);

  useEffect(() => {
    // * Tracking breadcrumbModel updates
  }, [breadcrumbModel]);

  return (
    <div
      className={
        parentCategory[0]?.label?.toLowerCase() === 'catalog' && showHome
          ? 'hidden'
          : 'contents'
      }
    >
      <BreadCrumb
        className={componentStyle}
        home={showHome ? parentBreadCrumb : undefined}
        model={showHome ? parentCategory : breadcrumbModel}
      />
    </div>
  );
};
