import { ReactElement, useEffect, useRef, useState } from 'react';
import { ProductSearch } from 'components/amm-product-search/ProductSearch';
// ? left the CategoriesBreadCrumb here in case we want to add it/refactor it to only be nav BreadCrumbs since we're using CategoryTreeTable instead of menu was being setState in the CategoriesBreadCrumb
// import { CategoriesBreadCrumb } from 'components/categories-breadcrumb/CategoriesBreadCrumb';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { SliderValueType } from 'primereact/slider';
import {
  ProductInterface,
  ProductFieldsFragment,
} from 'graphql/generated/magentoApi';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { Toast } from 'primereact/toast';
import { MenuItem } from 'primereact/menuitem';
import { Head } from 'components/head/Head';
import { useSearchParams } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import {
  useGetProducts,
  useGetProductsByCategoryPath,
  useGetProductsBySkus,
} from 'hooks/magentoHooks';
import _ from 'lodash';
import { CategoriesBreadCrumb } from 'components/categories-breadcrumb/CategoriesBreadCrumb';
import Footer from 'components/footer-crwd/Footer';
import Product from 'components/Product';
import useViewport from 'hooks/useViewport';
import { getMarketContent } from 'utils/marketContent';
import FocusLock from 'react-focus-lock';
import { ProductFilter } from 'components/product-filter/ProductFilter';
import CatalogCategoryFilter from 'components/catalog-category-filter/CatalogCategoryFilter';
import ShopNavBar from './shop-home-page/shop-nav-bar';
import ProductCard from './ProductCard';
import './ProductList.scss';

function buildSkusArray(skusParam: string | undefined): string[] {
  if (skusParam) {
    return [...skusParam.split(',')];
  }
  return [];
}

interface companyVendors {
  name?: string;
}

export default function ProductList(): ReactElement {
  const { rootCategory } = getMarketContent();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const perPageParam = searchParams.get('per-page');
  //! temporarily set perPageParam to 300 due to a pagination bug
  const perPage = Number(perPageParam || 300);
  const hideCategories = searchParams.get('hide-categories') === 'true';
  const vendorParam = searchParams.get('vendor') || undefined;
  console.log(vendorParam);
  const skusParam = searchParams.get('skus') || undefined;
  const isEmbedded = searchParams.get('isEmbedded') === '1';

  const isLocalTest = searchParams.get('env') === 'local';

  const { products: skuProducts } = useGetProductsBySkus({
    skus: buildSkusArray(skusParam),
  });
  const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
  const [first, setFirst] = useState<number>(perPage * (currentPage - 1));
  const [menuModel] = useState<MenuItem[]>([
    { label: 'Collections', items: [] },
  ]);
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const [categoryPaths, setCategoryPaths] = useState<string[]>([
    rootCategory ? `marketplace/${rootCategory}` : `shopping`,
  ]);
  const toast = useRef<Toast>(null);

  const [selectedVendors, setSelectedVendors] =
    useState<Array<companyVendors> | null>(null);

  const [searchProductAuto, setSearchProductAuto] =
    useState<ProductInterface | null>();
  const [filteredProduct, setFilteredProduct] = useState<
    (ProductFieldsFragment | null)[]
  >([]);
  const { products: allProducts } = useGetProducts();
  const { isMobile } = useViewport();

  const {
    products: categoryProducts,
    totalProducts,
    fetching: fetchingProducts,
  } = useGetProductsByCategoryPath({
    page: currentPage,
    categoryPaths,
    perPage,
    skuProducts,
  });

  const [products, setProducts] = useState<Array<ProductFieldsFragment>>();

  useEffect(() => {
    if (categoryProducts.length) {
      setProducts(categoryProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(categoryProducts)]);

  const filteredProducts: ProductFieldsFragment[] = products?.filter(
    item => item !== null,
  ) as ProductFieldsFragment[];
  // * get maximum price/highest priced product to use as a max value for slider:
  const maxProductPrice =
    filteredProducts?.sort(
      (a, b) =>
        (b.price_range.maximum_price?.final_price.value || 0) -
        (a.price_range.maximum_price?.final_price.value || 0),
    )[0]?.price_range.maximum_price?.final_price.value || 0;

  const [priceRange, setPriceRange] = useState<SliderValueType>([0, 0]);

  useEffect(() => {
    if (maxProductPrice) {
      setPriceRange([0, maxProductPrice]);
    }
  }, [maxProductPrice]);

  const navigateToPage = (pageNumber: number) => {
    if (pageNumber > 1) {
      searchParams.set('page', `${pageNumber}`);
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const goToFirstPage =
      document.getElementsByClassName('p-paginator-first')[0];
    if (goToFirstPage) {
      goToFirstPage.classList.remove('p-disabled');
      goToFirstPage.removeAttribute('disabled');
      goToFirstPage.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        navigateToPage(1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryProducts]);

  useEffect(() => {
    if (!!vendorParam && products) {
      const vendorCategories = products?.[0]?.categories
        ?.filter(item => item?.url_path?.toLowerCase().includes(rootCategory))
        .filter(item => item?.url_path !== rootCategory)
        .map(category => category?.url_path?.toString());
      setCategoryPaths(vendorCategories as string[]);
    }
  }, [vendorParam, products, rootCategory]);

  useEffect(() => {
    setFirst(perPage * (Number(page) - 1));
    setCurrentPage(Number(page));
  }, [page, perPage]);

  const itemTemplate = (product: ProductInterface & { __typename: string }) => (
    <ProductCard product={product} isLocalTest={isLocalTest} />
  );

  const onPageChange = ({ page, first }: DataViewPageParams) => {
    setFirst(first);
    setCurrentPage(page + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    navigateToPage(page + 1);
  };

  useEffect(() => {
    if (products && priceRange[1]) {
      let filterProducts: Array<ProductFieldsFragment> = products.filter(
        item => item !== null,
      ) as ProductFieldsFragment[];

      if (!fetchingProducts) {
        filterProducts = filterProducts.filter(
          item =>
            (item?.price_range.minimum_price?.final_price?.value || 0) >=
              priceRange?.[0] &&
            (item?.price_range.maximum_price?.final_price?.value || 0) <=
              priceRange?.[1],
        );

        if (selectedVendors) {
          filterProducts = filterProducts.filter(item =>
            selectedVendors.some(
              vendorItem => item?.vendor_company === vendorItem.name,
            ),
          );
        }

        if (!_.isEqual(filterProducts, products)) {
          setProducts(filterProducts);
        }
      }
    }
  }, [
    categoryProducts,
    fetchingProducts,
    priceRange,
    products,
    selectedVendors,
  ]);

  // to fix edge case with price range filter when user selects a category:
  useEffect(() => {
    vendorParam ? setSelectedVendors([{ name: vendorParam }]) : clearFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryPaths, vendorParam]);

  const clearFilters = () => {
    setPriceRange([0, maxProductPrice]);
    setSelectedVendors(null);
  };

  const shouldShowAside =
    !hideCategories &&
    !searchProductAuto?.sku &&
    (!!menuModel[0].items || loadingCategories);

  useEffect(() => {
    if (!shouldShowAside) {
      clearFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowAside]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleFilter(false);
    }
  }, [isMobile]);

  if (!categoryPaths) {
    return <></>;
  }

  return (
    <>
      <Head title="Shop" link="/category" addPostfixTitle />
      <Toast position="top-right" ref={toast} />

      {!isEmbedded && <ShopNavBar />}

      <div
        className="categories grid mb-8 px-4"
        style={{ maxWidth: '1480px', margin: '0 auto' }}
      >
        {!hideCategories && (
          <div className="flex justify-content-start md:align-items-center w-full mb-2 md:mb-3 flex-column md:flex-row px-4 md:px-2 lg:px-0">
            {!isMobile && (
              <CategoriesBreadCrumb
                componentStyle="category-page__breadcrumb border-none col-12 md:col-6 p-2 my-3 md:mb-0 w-full ml-1"
                setLoadingCategories={setLoadingCategories}
                categoryPaths={categoryPaths}
                setCategoryPaths={setCategoryPaths}
              />
            )}
            <div className="relative col-12 md:col-6 hidden">
              <ProductSearch
                categoryPaths={categoryPaths}
                allProducts={allProducts}
                products={products as ProductFieldsFragment[]}
                searchSuggestions={filteredProduct}
                setFilteredProduct={setFilteredProduct}
                setSearchProductAuto={setSearchProductAuto}
              />
              {searchProductAuto?.sku && (
                <button
                  type="button"
                  onClick={() => setProducts([])}
                  className="bg-transparent border-transparent flex align-items-center cursor-pointer absolute p-2 border-none"
                  style={{
                    top: '0.5rem',
                    right: '0.5rem',
                    height: 'calc(100% - 1rem)',
                  }}
                >
                  <i className="pi pi-times" />
                </button>
              )}
            </div>
          </div>
        )}
        {shouldShowAside && (
          <>
            {isMobile ? (
              <div className="mb-4 px-3 md:px-0">
                <Sidebar
                  visible={visibleFilter}
                  onHide={() => {
                    setVisibleFilter(false);
                  }}
                  position="bottom"
                  closeOnEscape
                  showCloseIcon={false}
                >
                  <FocusLock>
                    <aside className="flex flex-column" style={{ gap: '16px' }}>
                      {!loadingCategories && categoryPaths.length ? (
                        <>
                          <h3 className="uppercase mb-5">Product Filters</h3>
                          <CatalogCategoryFilter
                            setCategoryPaths={setCategoryPaths}
                          />
                        </>
                      ) : (
                        <ProgressSpinner
                          strokeWidth="5"
                          animationDuration=".5s"
                          style={{
                            width: '50px',
                            height: '50px',
                            margin: ' 0 25%',
                          }}
                        />
                      )}
                      <ProductFilter
                        setVisibleFilter={setVisibleFilter}
                        setSelectedVendors={setSelectedVendors}
                        selectedVendors={selectedVendors}
                        shouldShowAside={shouldShowAside}
                        isMobile={isMobile}
                        products={categoryProducts}
                        priceRange={priceRange}
                        setPriceRange={setPriceRange}
                        maxProductPrice={maxProductPrice}
                        displayVendor
                      />
                    </aside>
                  </FocusLock>
                </Sidebar>
                <Button
                  icon="pi pi-filter"
                  label="Filter Products"
                  className="hidden"
                  // TODO: Fix Mobile filter
                  // className="shop-button sb-primary solid ml-2 my-0 py-2 px-2 text-xs flex align-items-center flex-row"
                  onClick={() => setVisibleFilter(true)}
                />
              </div>
            ) : (
              <aside className="col-12 lg:col-3 px-3 lg:px-2">
                {categoryPaths.length ? (
                  <div className="flex flex-column" style={{ gap: '16px' }}>
                    <CatalogCategoryFilter
                      setCategoryPaths={setCategoryPaths}
                    />

                    <ProductFilter
                      setVisibleFilter={visibleFilter}
                      setSelectedVendors={setSelectedVendors}
                      selectedVendors={selectedVendors}
                      shouldShowAside={shouldShowAside}
                      isMobile={isMobile}
                      products={categoryProducts}
                      priceRange={priceRange}
                      setPriceRange={setPriceRange}
                      maxProductPrice={maxProductPrice}
                      displayVendor
                    />
                  </div>
                ) : (
                  <ProgressSpinner
                    strokeWidth="5"
                    animationDuration=".5s"
                    style={{ width: '50px', height: '50px', margin: ' 0 25%' }}
                  />
                )}
              </aside>
            )}
          </>
        )}
        {searchProductAuto?.sku ? (
          <Product sku={searchProductAuto?.sku} classNames="col-12" />
        ) : (
          <>
            {!loadingCategories && categoryPaths.length ? (
              <>
                <DataView
                  paginator
                  rows={perPage}
                  first={first}
                  totalRecords={totalProducts}
                  onPage={e => onPageChange(e)}
                  value={products}
                  layout="grid"
                  itemTemplate={itemTemplate}
                  className={`max-w-full col-12  scalein p-0 ${
                    shouldShowAside ? 'lg:col-9' : ''
                  }`}
                />
              </>
            ) : (
              <div className="w-full flex align-items-center justify-content-center">
                <ProgressSpinner
                  strokeWidth="5"
                  animationDuration=".5s"
                  style={{
                    width: '50px',
                    height: '50px',
                    margin: ' 0 25%',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      {!isEmbedded && <Footer />}
    </>
  );
}
