import { ReactElement } from 'react';
import { Image } from 'primereact/image';

import { Head } from 'components/head/Head';
import { getMarketContent } from 'utils/marketContent';
import MemmSteps from './forms/memmSteps/MemmSteps';
import './signupHome.scss';
import MemmSectionForm from './forms/MemmSectionForm';

export default function MemmSignupHome(): ReactElement {
  const { meta } = getMarketContent();

  return (
    <>
      <Head
        title={meta.title}
        link="/"
        keywords={meta.mainKeyWords}
        description={meta.title}
      />

      <div className="signup-hero-dark relative">
        <div
          className="bg-cover bg-center absolute w-full h-full"
          style={{
            zIndex: -100,
            objectFit: 'fill',
            backgroundSize: 'auto 100%',
            backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/instafunnels-91427.appspot.com/o/images%2FOogMDkJ3xj?alt=media&token=ed56635d-af28-4cda-9379-8bb6717e6b55")`,
          }}
        />
        <div className="pt-5 flex bg-cover wide-width-container px-3 flex-wrap align-items-center">
          <Image
            className="flex logo-image h-full ml-2 md:ml-3"
            src="/images/10xhealth/10xhealth-light.png"
          />
          <div className="flex-grow-1" />
          <a
            className="no-underline text-white text-base md:text-xl font-bold font-oswald mx-auto flex align-items-center font-regular px-2 md:px-3"
            href="#about"
          >
            About
          </a>
          <a
            className="no-underline text-white text-base md:text-xl font-bold font-oswald mx-auto flex align-items-center font-regular px-1 md:px-3"
            href="#join"
          >
            Join
          </a>
        </div>
        <div className="signup-hero-content w-full px-3">
          <div className="w-12 md:w-7 md:pl-3">
            <p className="text-white font-bold mb-2 text-lg md:text-xl font-oswald font-regular uppercase">
              CREATE AN INCOME. MAKE AN IMPACT ON PEOPLE &apos;S HEALTH.
            </p>
            <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-montserrat uppercase font-black-900">
              Earn | Refer to 10
              <span className="text-primary font-montserrat font-black-900">
                X
              </span>{' '}
              Health
            </h1>
            <p className="text-white font-size-1-1  md:w-9 font-montserrat font-regular">
              Our referral program offers an easy way to generate additional
              income by referring your friends, family and customers to 10X
              Health. For the first time ever, earn a commission for helping
              achieve optimal health and well-being with our revolutionary 10X
              Health products and services.
            </p>
            {/* TODO: Restore form */}
            {/* <Button
              className="font-montserrat text-4xl"
              label="JOIN US"
              style={{ backgroundColor: 'var(--10x-brand)' }}
              // onClick={showForm}
            /> */}
            <a
              href="#join"
              className="p-button p-component font-montserrat text-4xl"
              style={{
                backgroundColor: 'var(--10x-brand)',
                textDecoration: 'none',
              }}
            >
              Join Us
            </a>
          </div>
        </div>
      </div>
      <MemmSectionForm />
      <section className="bg-gray-100">
        <div className="signup-content text-xl px-4 pt-6 pb-0 mb-0 -mb-8 wide-width-container">
          <div className="mb-6 grid">
            <h1 className="mb-4 text-3xl sm:text-5xl md:text-6xl text-black font-montserrat uppercase font-black-900 text-center mx-auto max-w-max">
              Why Join Our REFERRAL Program?
            </h1>
            <div className="col-12 lg:col-6">
              <h1 className="mb-4 text-4xl md:text-6xl font-oswald font-regular text-center lg:text-left">
                ONE TEAM.{' '}
                <span className="text-primary font-oswald font-regular">
                  ONE GOAL.
                </span>
              </h1>

              <p
                className="w-12 mx-auto lg:ml-0 lg:mr-auto font-montserrat"
                style={{ marginBottom: '20px' }}
              >
                The 10X Health Team is made up of a group of remarkable humans
                that share one single goal and vision…to redefine the health of
                millions through 10X Health, leading with our genetic testing.
              </p>
              <p
                className="w-12 mx-auto lg:ml-0 lg:mr-auto font-montserrat"
                style={{ marginBottom: '20px' }}
              >
                We are looking for people like you who have a passionate
                interest in sharing cutting-edge health & wellness products and
                services.
              </p>
              <p
                className="w-12 mx-auto lg:ml-0 lg:mr-auto font-montserrat"
                style={{ marginBottom: '20px' }}
              >
                We will teach, guide and support you in all the aspects of being
                a successful referral marketer, giving you the freedom and
                flexibility to earn extra income from home or on-the-go.
              </p>
              <p className="mb-0 pb-0 w-12 mx-auto lg:ml-0 lg:mr-auto mb-4 lg:mb-0 font-montserrat">
                It’s time to start - just three easy steps!{' '}
                <b>JOIN OUR TEAM TODAY!</b>
                <span className="font-montserrat font-regular" />
              </p>
            </div>
            <div className="col-12 lg:col-6 my-auto">
              <img
                src="/images/10xhealth/images/genetic-testing-box.jpg"
                alt="Genetic Testing"
                className="block w-12 md:col-8 lg:w-10 mx-auto"
              />
            </div>
          </div>
        </div>
        <img
          src="/images/10xaffiliate/grant-quote.png"
          alt="Grant Cardone"
          className="block w-12 p-0 m-0  mt-7 sm:-mt-8 md:mt-0"
        />
        <div id="join" />
      </section>

      <section className="pt-6">
        <h1 className="mb-4 text-4xl text-black font-oswald font-regular uppercase text-center mx-auto max-w-max">
          Join 10
          <span className="text-primary font-oswald font-regular uppercase">
            X
          </span>{' '}
          Health - Referral Program
        </h1>
        <MemmSteps />
      </section>
    </>
  );
}
