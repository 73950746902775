import { ReactElement } from 'react';
import { HashLink } from 'react-router-hash-link';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { useContent } from 'utils/useContent';
import './scss/VideoLanding.scss';
import VimeoPlayer from 'components/vimeo-player/vimeo-player';

type Props = {
  videoID?: string;
};

export default function MessageVideos(props: Props): ReactElement {
  const data = useContent();
  const { videoID } = props;
  const { search } = useLocation();

  return (
    <div className="video-page react-media-player__wrapper">
      <h1>Exclusive Preview</h1>
      <VimeoPlayer url={`https://vimeo.com/${videoID}`} />

      {data.action.info[0].text && (
        <p
          className="text-white mt-0 mb-64 w-full mx-auto font-bold text-center"
          style={{ maxWidth: '700px' }}
        >
          {parse(data?.participate.text)}
        </p>
      )}
      <div className="flex flex-wrap flex-column md:flex-row w-full justify-content-center">
        {data?.participate.button.map(
          (obj: { text: string; link: string; class?: string }) => (
            <HashLink
              key={obj.link}
              to={search + obj.link}
              className={
                obj.class?.includes('cta-primary')
                  ? `w-full md:w-auto text-white border-gray-50 border-1 px-6 py-3 cursor-pointer no-underline button--white--filled text-center ${obj.class}`
                  : `hidden ${obj.class}`
              }
              smooth
            >
              {obj.text}
            </HashLink>
          ),
        )}
      </div>
    </div>
  );
}
