import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MarketplaceFooter from 'components/footer-marketplace/MarketplaceFooter';
import Spinner from 'components/Spinner';
import HubspotForm from 'react-hubspot-form';
import ShopNavBar from 'pages/shop-home-page/shop-nav-bar';
import './index.scss';
import { Breadcrumbs } from 'components/breadcrumbs';
import { getMarketContent } from 'utils/marketContent';
import { Head } from 'components/head/Head';

const Contactus = () => {
  const { contentPages } = getMarketContent();
  const marketContentIndex = contentPages.findIndex(
    page => page.contentURL.toLowerCase() === 'contact-us',
  );
  const contactUsContent = contentPages[marketContentIndex];

  return (
    <>
      <Head
        title="Contact"
        link="/contact-us"
        addPostfixTitle
        keywords="contact us, get in touch, find out more, learn more"
      />
      <ShopNavBar />
      <Breadcrumbs
        label="We want to hear from you"
        bg="/images/BLOCKCHAIN_ICE.jpg"
      />
      <section id="contact-us">
        <div className="container px-4 md:px-0">
          <div className="grid">
            <div className="col-12 md:col-8 md:col-offset-2 pt-5 pb-2 header-section text-center">
              <h2>{contactUsContent.title}</h2>
              <p>
                {contactUsContent.htmlContent.content}
                <br className="block md:hidden" />
                {contactUsContent.htmlContent.link.showLink && (
                  <>
                    <a
                      className="contact-us__link-join-our-cause"
                      href={contactUsContent.htmlContent.link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textTransform: 'capitalize',
                        letterSpacing: '1px',
                      }}
                    >
                      {contactUsContent.htmlContent.link.linkLabel}
                    </a>
                  </>
                )}
                .
              </p>
            </div>
          </div>
          <div className="grid pb-5">
            <div className="col-12 md:col-offset-1 md:col-10">
              <HubspotForm
                portalId={contactUsContent.hubspotForm.portalId}
                formId={contactUsContent.hubspotForm.formId}
                cssClass={contactUsContent.hubspotForm.cssClass}
                loading={<Spinner />}
              />
              <div className="grid py-5">
                {!!contactUsContent.callToAction.length && (
                  <>
                    {contactUsContent.callToAction.map(item => {
                      const fontAwesomeIcons = {
                        faEnvelope,
                        faMapMarkerAlt,
                        faPhone,
                      };
                      const icon = item.fontAwesomeIcon.replace(/\(/g, '');
                      return (
                        <div key={item.label} className="col-12 md:6 lg:col-4">
                          <div className="contact-item">
                            <FontAwesomeIcon icon={fontAwesomeIcons[icon]} />
                            <div className="title">{item.label}</div>
                            {item.isEmail ? (
                              <a
                                href={`mailto:${item.labelContent}`}
                                className="text contact-us__mailto"
                              >
                                {item.labelContent}
                              </a>
                            ) : (
                              <div className="text">{item.labelContent}</div>
                            )}
                            {item.moreInfo && item.isPhone && (
                              <a
                                href={`tel:${item.moreInfo}`}
                                className="text contact-us__mailto"
                              >
                                {item.moreInfo}
                              </a>
                            )}
                            {item.moreInfo && !item.isPhone && (
                              <div className="text">{item.moreInfo}</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <MarketplaceFooter />
    </>
  );
};

export default Contactus;
