import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import './index.scss';

const BlogTemplate = ({
  id,
  featuredImage,
  // publishDate,
  htmlTitle,
  metaDescription,
}) => (
  <div className="blog-item">
    <Link
      className="blog-img-wrapper"
      to={`/blog/${id}`}
      style={{ background: `url(${featuredImage})` }}
    >
      {/* <div className="blog-date">
        <span>{format(new Date(publishDate), 'dd')}</span>
        <span>{format(new Date(publishDate), 'MMM')}</span>
      </div> */}
    </Link>
    <div className="content">
      <Link to={`/blog/${id}`} className="blog-title">
        {htmlTitle}
      </Link>
      <p className="blog-text">{metaDescription}</p>
      <Link to={`/blog/${id}`} className="blog-readmore">
        Read Blog
        <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
      </Link>
    </div>
  </div>
);

export default BlogTemplate;
