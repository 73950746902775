import { ReactElement, useEffect, useState } from 'react';
import { Button } from 'primereact/button';

import { Head } from 'components/head/Head';
import { useCartContext } from 'CartContext';
import { useNavigate, useParams } from 'react-router-dom';
import buttonize from 'utils/buttonize';
import CartItemList from 'components/CartItemList';
import Spinner from 'components/Spinner';

import { useTranslation } from 'react-i18next';
import { moneyFormat } from 'utils/formatHelper';
import ShopNavBar from './shop-home-page/shop-nav-bar';
import './ShoppingCartPage.scss';

export default function ShoppingCartPage(): ReactElement {
  const { items, loading, shipping, subTotal, total, taxes, updateCart } =
    useCartContext();
  const navigate = useNavigate();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { skuToAdd } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add('bg-white');
    document.body.style.backgroundColor = 'white';
    return () => {
      document.body.style.backgroundColor = '';
      document.body.classList.remove('bg-white');
    };
  }, []);

  useEffect(() => {
    if (skuToAdd && isFirstRender) {
      updateCart({
        sku: skuToAdd,
        quantity: 1,
        onlyAddOneIfNotPresent: true,
        debug: 'ShoppingCartPage',
      });
    }
    setIsFirstRender(false);
  }, [skuToAdd, updateCart, isFirstRender]);

  if (loading)
    return (
      <>
        <ShopNavBar displaySimpleNav />
        <Spinner />
      </>
    );

  return (
    <>
      <ShopNavBar displaySimpleNav />
      <Head
        title="Cart"
        link="/cart"
        addPostfixTitle
        keywords="cart, checkout, blockchain shopping cart, blockchain market cart, blockchain checkout"
      />
      <div
        className="grid cart align-items-center p-4 mb-8"
        style={{ maxWidth: '1480px', margin: '0 auto' }}
      >
        <p
          {...buttonize(() => navigate(-1))}
          className="text-primary no-underline cursor-pointer uppercase mt-0 mb-2 text-xs"
          style={{
            fontFamily: 'Raleway, sans-serif',
            fontWeight: 600,
            letterSpacing: '2px',
          }}
        >
          {t('go_back')}
        </p>
        {items.length ? (
          <>
            <CartItemList />
            <div className="flex flex-column col-12 align-items-end mb-4">
              <p className="text-700 font-light m-0">
                {t('subtotal')}:{' '}
                <span className="text-900 raleway-price-format">
                  {moneyFormat.format(Number(subTotal))}
                </span>
              </p>
              <p className="text-700 font-light m-0">
                {t('shipping')}: <span className="text-900">{shipping}</span>
              </p>
              <p className="text-700 font-light m-0">
                {t('taxes')}:{' '}
                <span className="text-900 raleway-price-format">
                  {taxes !== '0.00'
                    ? `${moneyFormat.format(Number(taxes))}`
                    : 'Calculated at checkout'}
                </span>
              </p>
              <p className="text-700 font-light mt-2 mb-3 text-2xl raleway-price-format">
                {t('total')}:{' '}
                <span className="text-900">
                  {moneyFormat.format(Number(total))}
                </span>
              </p>
              <Button
                className="uppercase border-noround c-button-primary"
                label="Checkout"
                onClick={() => {
                  navigate('/checkout');
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="w-full text-center font-medium"
              style={{ minHeight: '40vh' }}
            >
              <h1>Empty cart</h1>
              <p>Please, add your first item.</p>
            </div>
          </>
        )}
      </div>
    </>
  );
}
