import { useCallback, useContext, useState } from 'react';
import MatomoContext from './MatomoContext';
import {
  TrackEventParams,
  TrackLinkParams,
  TrackPageViewParams,
  TrackSiteSearchParams,
} from './types';
import useOutboundClickListener from './utils/useOutboundClickListener';

function useMatomo() {
  const context = useContext(MatomoContext);
  const instance = context?.tracker || null;
  const visitorIdSearchParam = context?.visitorIdSearchParam;
  const [linkTrackingEnabled, setLinkTrackingEnabled] = useState(false);

  useOutboundClickListener(linkTrackingEnabled, instance);

  const trackPageView = useCallback(
    (params?: TrackPageViewParams) => instance?.trackPageView(params),
    [instance],
  );

  const trackEvent = useCallback(
    (params: TrackEventParams) => instance?.trackEvent(params),
    [instance],
  );

  const trackEvents = useCallback(() => instance?.trackEvents(), [instance]);

  const trackSiteSearch = useCallback(
    (params: TrackSiteSearchParams) => instance?.trackSiteSearch(params),
    [instance],
  );

  const trackLink = useCallback(
    (params: TrackLinkParams) => instance?.trackLink(params),
    [instance],
  );

  const enableLinkTracking = useCallback(() => {
    if (!linkTrackingEnabled) {
      setLinkTrackingEnabled(true);
    }
  }, [linkTrackingEnabled]);

  const pushInstruction = useCallback(
    (name: string, ...args: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      instance?.pushInstruction(name, ...args);
    },
    [instance],
  );

  return {
    trackEvent,
    trackEvents,
    trackPageView,
    trackSiteSearch,
    trackLink,
    enableLinkTracking,
    pushInstruction,
    visitorIdSearchParam,
  };
}

export default useMatomo;
