import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AdminInputEmail,
  AdminInputPhone,
  AdminInputText,
} from 'components/Admin';

const UserFormDetailsSection = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-column justify-content-center gap-3 my-6 w-full mx-auto">
      <AdminInputText
        control={control}
        error={errors}
        name="firstName"
        required
        label="First Name"
        containerClassName="mb-4"
      />
      <AdminInputText
        control={control}
        name="middleName"
        label="Middle Name"
        containerClassName="mb-4"
      />
      <AdminInputText
        control={control}
        name="lastName"
        required
        label="Last Name"
        containerClassName="mb-4"
      />
      <AdminInputPhone
        control={control}
        name="mobilePhone"
        required
        label="Mobile #"
        containerClassName="mb-4 bg-white"
      />
      <AdminInputEmail
        control={control}
        name="primaryEmail"
        required
        label="Primary E-mail"
        containerClassName="mb-4"
      />
    </div>
  );
};

export default UserFormDetailsSection;
