import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { scrollToFirstFormError } from 'utils/helper';
import { Button } from 'primereact/button';
import {
  AdminInputText,
  AdminInputCountry,
  AdminInputState,
  AddressCountry,
  AdminInputPhone,
} from 'components/Admin';
import ValidateAddressDialog from 'components/forms/ValidateAddressDialog';
import { Address } from 'types';
import { FormTriggerRef } from './MemmSteps';
import '../SignUpForm.scss';

export interface ContactFormFields {
  personFirstName: string;
  personMiddleName: string;
  personLastName: string;
  personFamilyName: string;
  personAddress1: string;
  personAddress2: string;
  personCountryCode: string;
  personProvinceState: string;
  personCity: string;
  personRegionCounty: string;
  personPostal: string;
  personPostal4: string;
  personPrimaryMobile: string;
  personSocialLink: string;
  personFollowingSize: string;
}

export interface Step1TokenFormProps {
  data: ContactFormFields | undefined;
  setShowNext: (showNext: boolean) => void;
  nextStep: () => void;
}

export default React.forwardRef<
  FormTriggerRef<ContactFormFields | undefined>,
  Step1TokenFormProps
>(({ data, setShowNext, nextStep }, ref) => {
  const [selectedCountry, setSelectedCountry] = useState<
    AddressCountry | undefined
  >();
  const [validateAddressIsVisible, setValidateAddressIsVisible] =
    useState(false);
  const [addressIsValidated, setAddressIsValidated] = useState(false);
  const [addressToValidate, setAddressToValidate] = useState<
    Address | undefined
  >();

  const {
    control,
    trigger,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<ContactFormFields>({
    mode: 'all',
    defaultValues: data,
    shouldFocusError: true,
  });

  const values = watch();

  useImperativeHandle(ref, () => ({
    trigger: async () => {
      const isValid = await trigger();
      if (isValid) {
        return values;
      }
      scrollToFirstFormError(errors);
      return undefined;
    },
  }));

  const getAddressFromValues = useCallback(
    (): Address => ({
      street1: values.personAddress1,
      street2: values.personAddress2,
      city: values.personCity,
      stateCode: values.personProvinceState,
      countryCode: values.personCountryCode,
      postalCode: values.personPostal,
      postalPlus4: values.personPostal4,
    }),
    [values],
  );

  const handleValidateAddress = async () => {
    const formIsValid = await trigger();
    if (formIsValid) {
      setAddressToValidate(getAddressFromValues());
      setValidateAddressIsVisible(true);
    } else {
      scrollToFirstFormError(errors);
    }
  };

  const handleApprovedAddress = async (address: Address) => {
    setValue('personAddress1', address.street1);
    setValue('personAddress2', address.street2 || '');
    setValue('personCity', address.city);
    setValue('personProvinceState', address.stateCode);
    setValue('personCountryCode', address.countryCode);
    setValue('personPostal', address.postalCode);
    setValue('personPostal4', address.postalPlus4 || '');
    setAddressIsValidated(true);
  };

  useEffect(() => {
    setShowNext(false);
  }, [setShowNext]);

  useEffect(() => {
    if (addressIsValidated) {
      nextStep();
      setShowNext(true);
    }
  }, [addressIsValidated, nextStep, setShowNext]);

  useEffect(() => {
    if (!isValid) {
      scrollToFirstFormError(errors);
    }
  }, [errors, isValid]);

  return (
    <div>
      <ValidateAddressDialog
        visible={validateAddressIsVisible}
        setIsVisible={setValidateAddressIsVisible}
        addressToValidate={addressToValidate}
        setApprovedAddress={handleApprovedAddress}
      />
      <form>
        <h1 className="mb-2 text-2xl text-black font-montserrat uppercase">
          Contact Information
        </h1>
        <div className="grid">
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="First Name"
              name="personFirstName"
              autoComplete="given-name"
              control={control}
              errors={errors}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Middle Name"
              name="personMiddleName"
              autoComplete="additional-name"
              control={control}
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Last Name"
              name="personLastName"
              autoComplete="family-name"
              control={control}
              errors={errors}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Family Name"
              name="personFamilyName"
              control={control}
            />
          </div>
          <div className="col-12 my-2" />
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Social Link"
              name="personSocialLink"
              control={control}
              icon="pi pi-cart"
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Following Size"
              name="personFollowingSize"
              control={control}
            />
          </div>
          <div className="col-12 my-2" />
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Address 1"
              name="personAddress1"
              autoComplete="address-line1"
              control={control}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Address 2"
              name="personAddress2"
              autoComplete="address-line2"
              control={control}
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputCountry
              label="Country"
              name="personCountryCode"
              control={control}
              onCountrySelect={setSelectedCountry}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputState
              label="Province / State"
              name="personProvinceState"
              control={control}
              countryCode={selectedCountry?.code || 'US'}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="City"
              name="personCity"
              autoComplete="address-level2"
              control={control}
              required
            />
          </div>
          <div className="col-12 sm:col-6">
            <AdminInputText
              label="Region / County"
              name="personRegionCounty"
              control={control}
            />
          </div>
          <div className="col-12 sm:col-6 md:col-4">
            <AdminInputText
              label="Postal"
              name="personPostal"
              autoComplete="postal-code"
              control={control}
              required
            />
          </div>
          <div className="col-12 sm:col-6 md:col-4">
            <AdminInputText
              label="Postal 4"
              name="personPostal4"
              control={control}
            />
          </div>
          <div className="col-12 sm:col-6 md:col-4">
            <AdminInputPhone
              label="Primary Mobile"
              name="personPrimaryMobile"
              autoComplete="tel"
              control={control}
              required
            />
          </div>
          <div className="col-12 flex justify-content-end">
            <Button
              className="w-16rem"
              label="VALIDATE ADDRESS"
              onClick={handleValidateAddress}
              type="button"
            />
          </div>
        </div>
      </form>
    </div>
  );
});
