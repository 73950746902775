import { ReactElement, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useViewport from 'hooks/useViewport';
import { TabMenu, Menu, Button } from 'primereact';

export default function MarketPlaceComponentPage(): ReactElement {
  const navigate = useNavigate();
  const { isMobile } = useViewport();

  const menu = useRef<any>(null);

  const currentParams = window.location.pathname;

  const menuItems = [
    {
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      command: () => {
        document.body.classList.remove('button-generator');
        navigate('/');
      },
    },
    {
      label: 'Buy Now Button',
      icon: 'pi pi-fw pi-credit-card',
      pathname: '/button-generator',
      command: () => {
        navigate('/button-generator');
      },
    },
    {
      label: 'Cart Icon',
      icon: 'pi pi-fw pi-shopping-cart',
      pathname: '/cart-icon-generator',
      command: () => {
        navigate('/cart-icon-generator');
      },
    },
    {
      label: 'HubSpot Chat',
      icon: 'pi pi-fw pi-comments',
      pathname: '/hubspot-chat',
      command: () => {
        navigate('/hubspot-chat');
      },
    },
    {
      label: 'Email Signup',
      icon: 'pi pi-fw pi-envelope',
      pathname: '/email-signup-generator',
      command: () => {
        navigate('/email-signup-generator');
      },
    },
  ];

  const [activeIndex, setActiveIndex] = useState(
    menuItems.findIndex(item => item.pathname === currentParams),
  );
  useEffect(() => {
    if (currentParams === '/vendor-tools' || activeIndex === -1) {
      setActiveIndex(1);
    }
  }, [activeIndex, currentParams]);

  return (
    <>
      {isMobile ? (
        <div className="w-full flex justify-content-end">
          <Menu model={menuItems} popup ref={menu} id="popup_menu" />
          <Button
            className="p-button-outlined "
            label=""
            icon="pi pi-bars"
            onClick={event => menu?.current?.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          />
        </div>
      ) : (
        <TabMenu
          className="flex justify-content-center mb-2"
          model={menuItems}
          activeIndex={activeIndex}
          onTabChange={e => setActiveIndex(e.index)}
        />
      )}
    </>
  );
}
