import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useClient } from 'urql';
import { CategoryTree } from 'graphql/generated/magentoApi';
import { useMarketplace } from 'hooks/useMarketplace';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { getMarketContent } from 'utils/marketContent';
import { fetchCategories } from '../../utils/fetchCategories';

interface ProductBreadcrumbTreeProps {
  setLoadingCategories: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuModel: React.Dispatch<React.SetStateAction<MenuItem[]>>;
  componentStyle?: string;
  categoryPaths: string[];
  showHome?: boolean;
  collectionURL?: string;
}

export function ProductBreadcrumbTree({
  setLoadingCategories,
  setMenuModel,
  categoryPaths,
  componentStyle = 'w-full p-breadcrumb-chevron--hidden p-breadcrumb-category mb-4',
  showHome = false,
  collectionURL,
}: ProductBreadcrumbTreeProps) {
  const client = useClient();
  const navigateBase = useNavigate();
  const params = useParams();
  const urlParams: string | undefined = params['*'];
  const [searchParams] = useSearchParams();
  const vendorParam = searchParams.get('vendor') || undefined;
  const collectionParam = searchParams.get('collections');
  const marketplace = useMarketplace();
  const [breadcrumbModel, setBreadcrumbModel] = useState<MenuItem[]>([]);
  const { rootCategory } = getMarketContent();

  useEffect(() => {
    setLoadingCategories(true);
    const paths: any = marketplace?.categories
      ? categoryPaths?.filter(category => category !== rootCategory)
      : [...categoryPaths];

    fetchCategories(client, paths).then(categories => {
      let tempCategory = [...categories];

      if (collectionParam) {
        if (tempCategory.length > 1) {
          tempCategory = categories.filter(
            category =>
              category?.url_path?.toLowerCase() ===
              collectionParam.toLowerCase(),
          );
        }
      }

      const firstCategory = categories[0] || {};
      const homeMenuItem: MenuItem = {
        label: 'Collections',
        command: () => {
          let path: string;

          if (window.location.pathname.includes('collection')) {
            path = `/collections`;
          } else {
            path = '/';
          }
          navigateBase(path);
        },
      };

      const fetchedBreadcrumbs = (firstCategory.breadcrumbs?.map(item => {
        if (item.category_url_path === rootCategory) {
          return homeMenuItem;
        }
        return {
          label: item.category_name,
          command: () =>
            navigateBase(`/collection?collections=${item.category_url_path}`),
        };
      }) || []) as MenuItem[];

      const menuItems: MenuItem[] = [];

      if (vendorParam) {
        menuItems.push({
          label: 'Collections',
          command: () => {
            navigateBase(`/collections`);
          },
        });
        const vendorCategories = categories.map(item => ({
          label: item.name,
          command: () => {
            navigateBase(`/category/${item.url_path}`);
            window.location.reload();
          },
        }));
        setMenuModel([
          {
            label: 'Shop Collections',
            items: [...vendorCategories],
          },
        ]);
        menuItems.concat(vendorCategories);
      } else {
        if (!rootCategory) {
          menuItems.push(homeMenuItem);
        }

        menuItems.push(...fetchedBreadcrumbs);

        if (firstCategory.url_path === rootCategory) {
          menuItems.push({ items: [homeMenuItem] });
        }

        if (firstCategory.url_path) {
          menuItems.push({
            label: firstCategory.name,
            command: () =>
              navigateBase(
                `/collection/${collectionURL}?collections=${firstCategory.url_path}`,
              ),
          });
        }

        const children = tempCategory
          .reduce<Partial<CategoryTree>[]>((previous, category) => {
            category.children?.forEach(child => {
              if (!previous.find(c => c.url_path === child.url_path)) {
                previous.push(child);
              }
            });
            return previous;
          }, [])
          .filter(item => !!item.products?.total_count)
          .map(item => ({
            label: item.name,
            icon: 'pi pi-angle-right',
            command: () =>
              navigateBase(
                `/collection/${collectionURL}?collections=${item.url_path}`,
              ),
          }));

        setMenuModel([
          {
            label: 'Collections',
            command: () => {
              navigateBase(`/collections`);
            },
            items:
              [...children].length > 0
                ? [...children]
                : [
                    {
                      label: `${'Back to collections'}`,
                      icon: 'pi pi-check',
                      command: () => navigateBase('/collections'),
                    },
                  ],
          },
          {
            label: 'Back to collections',
            className: 'mt-4',
            command: () => navigateBase('/collections'),
          },
        ]);
      }

      setBreadcrumbModel(menuItems.filter(item => item.label !== 'Shopping'));
      setLoadingCategories(false);
    });
  }, [
    categoryPaths,
    client,
    collectionURL,
    marketplace?.categories,
    navigateBase,
    rootCategory,
    setLoadingCategories,
    setMenuModel,
    urlParams,
    collectionParam,
    vendorParam,
  ]);

  const parentBreadCrumb = { icon: 'pi pi-chevron-left' };

  const parentCategory = breadcrumbModel
    .slice(-2)
    .filter((item, index) => index === 0);

  useEffect(() => {
    // * Tracking breadcrumbModel updates.
  }, [breadcrumbModel]);

  return (
    <div className="contents">
      <BreadCrumb
        className={componentStyle}
        home={showHome ? parentBreadCrumb : undefined}
        model={showHome ? parentCategory : breadcrumbModel}
      />
    </div>
  );
}
