import { Client } from 'urql';
import {
  CategoriesWithPathsDocument,
  RootCategoriesDocument,
} from 'graphql/generated/magentoApi';
import { Category } from 'pages/CategoryTree';

// fetch categories based on paths:
export async function fetchCategories(
  client: Client,
  urlPaths: string[],
): Promise<Category[]> {
  if (urlPaths.length) {
    const result = await client
      .query(CategoriesWithPathsDocument, {
        urlPaths,
      })
      .toPromise();

    return result.data.categoryList;
  }
  const result = await client
    .query(RootCategoriesDocument, undefined)
    .toPromise();

  return result.data.categoryList;
}
