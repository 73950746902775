import { Head } from 'components/head/Head';
import { Link } from 'react-router-dom';
import { ReactElement, useRef } from 'react';
import Footer from 'components/footer-crwd/Footer';
import './scss/IMediaNav.scss';
import { getMarketContent } from 'utils/marketContent';

export default function Privacy(): ReactElement {
  const logoLi = useRef<HTMLLIElement>(null);
  const { privacy, navigation } = getMarketContent();
  return (
    <>
      <Head
        title="Privacy Terms"
        addPostfixTitle
        largeTwitterCard
        link="/privacy-terms"
      />
      <div className="i-nav max-w-full">
        <ul className="flex flex-row m-0 p-0 list-none w-full">
          <li
            ref={logoLi}
            className="flex align-items-center px-3 py-2"
            style={{ zIndex: 1000, backgroundColor: '#0C0C0C' }}
          >
            <Link to="/">
              <img
                src={navigation.logoDesktopLight}
                alt=""
                width={220}
                height={60}
                style={{ objectFit: 'contain' }}
              />
            </Link>
          </li>
        </ul>
      </div>
      <section className="col px-4 md:px-12">
        {privacy.map(copy => {
          let content;
          if (typeof copy === 'string') {
            content = <p>{copy}</p>;
          }
          if (typeof copy === 'object') {
            if (copy.cssClass && copy.text) {
              content = <p className={copy.cssClass}>{copy.text}</p>;
            }
            if (copy.ul) {
              content = copy.ul.map(item => (
                <ul>
                  <li>{item}</li>
                </ul>
              ));
            }
            if (copy.href) {
              content = (
                <a
                  className={
                    copy.cssClass ? copy.cssClass : 'p-link text-primary'
                  }
                  href={copy.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {copy.hrefLabel}
                </a>
              );
            }
          }
          return content;
        })}
      </section>
      <Footer />
    </>
  );
}
