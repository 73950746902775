import { ReactElement, useEffect, useState } from 'react';
import './Spinner.scss';
import { getMarketContent } from 'utils/marketContent';
import useLocalStorage from 'utils/useLocalStorage';
import { ProgressBar } from 'primereact/progressbar';

export default function Spinner({
  className = '',
}: {
  className?: string;
}): ReactElement {
  const { market, navigation } = getMarketContent();
  const data = navigation;
  const marketName = market;
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);
  const [marketPlaceLogo, setMarketPlaceLogo] = useState<any>();

  useEffect(() => {
    const marketPlaceLogo = isDarkFromLocalStroage
      ? data.logoDesktopLight
      : data.logoDesktopDark;
    setMarketPlaceLogo(marketPlaceLogo);
  }, [data.logoDesktopDark, data.logoDesktopLight, isDarkFromLocalStroage]);
  return (
    <div className={className ? `loader ${className}` : 'loader'}>
      <div className="loader--content">
        {marketPlaceLogo && (
          <img
            src={`${marketPlaceLogo}`}
            alt={marketName}
            className="crwd-nav-logo m-4"
            style={{ maxWidth: '200px', minWidth: '120px' }}
          />
        )}
        <ProgressBar mode="indeterminate" className="w-10 mx-auto" />
      </div>
    </div>
  );
}
