import { ReactElement } from 'react';
import Slider from 'react-slick';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import { getMarketContent } from 'utils/marketContent';
import VisitWebsiteCards from '../website-cards';

const market = getMarketContent();
const marketSlider = market.homePage.heroSlider;

const HeroItem = ({
  header,
  subheader,
  img,
  link,
  linkText,
  contentAlign,
  buttonStyle,
  textColor,
  tint,
  overlayLogo,
  mobileBGAlign,
  skuForMarketplace,
}) => (
  <div
    className={`Hero-item-wrapper ${contentAlign && contentAlign} ${
      tint && tint
    } ${mobileBGAlign && mobileBGAlign}`}
    style={{ backgroundImage: `url(${img && img})` }}
  >
    <div className={`Hero-item-content ${textColor && textColor}`}>
      {overlayLogo ? (
        <>
          <img
            src={overlayLogo}
            alt={header && header}
            className="overlay-logo"
          />
          <p className="hero-subheader">{subheader && subheader}</p>
        </>
      ) : (
        <>
          <h2 className="hero-header">{header && parse(header)}</h2>
          <p className="hero-subheader">{subheader && subheader}</p>
        </>
      )}
      {(!!skuForMarketplace || !!link) && (
        <VisitWebsiteCards
          sku={skuForMarketplace}
          header={parse(linkText)}
          link={link}
          isButton
          classList={`shop-button hero ${buttonStyle && buttonStyle} ${
            textColor && textColor
          }`}
        />
      )}
    </div>
  </div>
);

export default function HomeBanner(): ReactElement {
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        role="button"
        aria-label="Press enter to view next item"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 7000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    autoplay: false,
    adaptiveHeight: false,
    fade: true,
    easing: 'in',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <section id="HeroCarousel" aria-hidden="true">
      <Slider {...settings}>
        {marketSlider.map(item => (
          <HeroItem {...item} key={item.link} />
        ))}
      </Slider>
    </section>
  );
}
