import { useState, ReactElement } from 'react';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faPinterest,
  faLinkedinIn,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import './SocialIcons.scss';

interface SocialIconsProps {
  socialLinks: Record<string, string | URL | undefined>;
  className?: string;
  buttonStyle?: string;
}

export default function SocialIcons({
  socialLinks,
  className = 'footer__social-links',
  buttonStyle = 'p-button-rounded p-button-secondary',
}: SocialIconsProps): ReactElement {
  const [iconList] = useState({
    tikTok: faTiktok,
    facebook: faFacebookF,
    instagram: faInstagram,
    linkedin: faLinkedinIn,
    pinterest: faPinterest,
    twitter: faTwitter,
    youtube: faYoutube,
  });

  return (
    <div className={className}>
      {Object.keys(socialLinks).map(socialKey => (
        <div key={socialKey && Math.random() * Math.random()}>
          {socialLinks[socialKey] && (
            <Button
              aria-label={socialKey}
              className={buttonStyle}
              onClick={() => window.open(socialLinks[socialKey], '_blank')}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon={iconList[socialKey] ? iconList[socialKey] : null}
                />
              }
            />
          )}
        </div>
      ))}
    </div>
  );
}
