/* eslint-disable react/require-default-props */
import { memo, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useMatomo } from 'matomoReact';
import parse from 'html-react-parser';
import { ProductInterface } from 'graphql/generated/magentoApi';
import { useCartContext } from 'CartContext';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import Stars from 'components/Stars';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import buttonize from 'utils/buttonize';
import { getMarketContent } from 'utils/marketContent';
import BuyNowCheckoutButton from 'components/buttons/BuyNowCheckoutButton';
import './ProductCard.scss';
import { getVendorAndMarketplaceName } from 'utils/helper';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface ProductCardProps {
  product: ProductInterface & { __typename: string };
  isLocalTest?: boolean;
}

export default memo((props: ProductCardProps) => {
  const { product, isLocalTest } = props;

  const { getProductWebsiteUrl, visitEdgeSite, updateCart, isEmbedded } =
    useCartContext();
  const {
    redirectVisitWebsiteBtn,
    redirectAddToCartVendorSite,
    redirectProductDetails,
    market,
  } = getMarketContent();
  const [displayRedirectDialog, setDisplayRedirectDialog] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const redirectToCheckout = isEmbedded || market === 'crwdmarket';
  const timeout = useRef({});
  const { trackEvent } = useMatomo();

  const url = useMemo(
    () => getProductWebsiteUrl(product),
    [getProductWebsiteUrl, product],
  );

  const navigateToEdgeSite = (url: string) => {
    setDisplayRedirectDialog(true);
    timeout.current = setTimeout(() => {
      setDisplayRedirectDialog(false);
      visitEdgeSite(url, false);
    }, 1250);

    ReactGA.event({
      category: 'external_link',
      action: 'Visit Website - Product Card',
      label: `Visited ${url}`,
    });
    trackEvent({
      category: 'external_link',
      action: 'Visit Website - Product Card',
      name: `Visited ${url}`,
    });
  };

  const onClickProductDetails = () => {
    const url = getProductWebsiteUrl(product);
    if (!redirectProductDetails || !url || isLocalTest) {
      navigate(`/product/${product.sku}`);
    } else {
      navigateToEdgeSite(url);
    }
  };

  const onClickVisitWebsiteBtn = () => {
    const url = getProductWebsiteUrl(product);
    if (!redirectVisitWebsiteBtn || !url || isLocalTest) {
      navigate(`/product/${product.sku}`);
    } else {
      navigateToEdgeSite(url);
    }
  };

  const onClickAddCartWebsiteBtn = () => {
    if (!redirectAddToCartVendorSite || !url) {
      updateCart({
        sku: product.sku,
        quantity: 1,
        showCart: true,
      });
    } else {
      setDisplayRedirectDialog(true);
      timeout.current = setTimeout(() => {
        setDisplayRedirectDialog(false);
        visitEdgeSite(url, false, [{ key: 'add-sku', value: product.sku }]);
      }, 1250);

      ReactGA.event({
        category: 'external_link',
        action: 'Visit Website - Product Card',
        label: `Visited ${url}`,
      });
      trackEvent({
        category: 'external_link',
        action: 'Visit Website Add To Cart - Product Card',
        name: `Visited ${url}`,
      });
    }
  };

  if (!product?.sku) return <></>;

  return (
    <div className="col-12 md:col-5 lg:col-5 xl:col-4 product-card-col">
      <Card
        header={
          <>
            <Button
              onClick={() => onClickProductDetails()}
              style={{ background: `url(${product.image.url})` }}
              className="product-card-image block border-none m-0 border-noround"
              aria-label={product.name}
              id={`product-image-view-website-${product.sku}`}
            />
          </>
        }
        className="h-full flex flex-column justify-content-between mx-auto product-card-list-item mb-4 md:mb-0 align-items-center md:align-items-stretch p-2 md:p-0 w-11 md:w-12 mx-auto md:mx-0 thin-border-card"
      >
        <div className="content-box" style={{ minHeight: '90px' }}>
          <div className="product-details-hover">
            {parse(product.short_description.html)}
          </div>
          <div className="mb-1 md:mb-2 product-details-hide">
            <Button
              className="product-link m-0 mb-1"
              onClick={() => onClickProductDetails()}
              id={`product-view-website-${product.sku}`}
            >
              <p className="uppercase m-0 font-bold line-height-2">
                {product.name}
              </p>
            </Button>

            <>
              <p
                className="capetilize mt-0 mb-1 text-xs line-height-0 cursor-pointer"
                {...buttonize(() => onClickProductDetails())}
              >
                {product.vendor_company}
              </p>
            </>

            <div className="flex justify-content-between">
              <span className="ml-1 font-semibold text-lg raleway-price-format">
                {product.price_range.maximum_price.final_price.value
                  ? `${formatter.format(
                      product.price_range.maximum_price.final_price.value,
                    )}`
                  : 'Free'}
              </span>
              {!!product.rating_summary && (
                <Stars rating={(product.rating_summary * 5) / 100} />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-column align-items-start">
          {redirectToCheckout ? (
            <BuyNowCheckoutButton
              btnStyle="uppercase font-bold text-xs border-noround c-button-primary my-0 mb-4"
              product={product}
              label="Buy Now"
            />
          ) : (
            <Button
              className="uppercase font-bold text-xs border-noround c-button-primary my-0 mb-4"
              label="ADD TO CART"
              onClick={() => onClickAddCartWebsiteBtn()}
              id={`product-add-to-cart-${product.sku}`}
            />
          )}
          <Button
            className="uppercase text-xs m-0 text-left md:mt-2 ml-0 border-noround learn-more-button pl-0"
            onClick={() => onClickVisitWebsiteBtn()}
            id={`product-view-website-${product.sku}`}
          >
            <span>Visit Website</span>
            <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
          </Button>
        </div>
      </Card>

      <Dialog
        visible={displayRedirectDialog}
        header="Thank you for your Support!"
        onHide={() => setDisplayRedirectDialog(false)}
        onClick={() => clearTimeout(timeout.current as number)}
        onMaskClick={() => clearTimeout(timeout.current as number)}
        breakpoints={{ '1200px': '40vw', '960px': '75vw', '640px': '90vw' }}
        style={{ width: '40vw' }}
        className="visit-website-modal"
        dismissableMask
        draggable={false}
      >
        <div className="flex flex-column md:flex-row align-items-center justify-content-start mb-4">
          {product.image.url && (
            <div
              className="background-image-square mb-4 md:mb-0 mr-0 md:mr-4 "
              style={{ background: `url(${product.image.url})` }}
            />
          )}
          <div className="mb-4">
            <p className="py-0 font-bold mb-0 text-sm">{product.name}</p>
            <h3 className="py-0 font-bold mb-2">{`Redirecting to ${getVendorAndMarketplaceName(
              product,
            )}`}</h3>
            <ProgressBar mode="indeterminate" />
          </div>
        </div>
      </Dialog>
    </div>
  );
});
