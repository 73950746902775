import Slider from 'react-slick';
import { ReactElement, useState, useEffect } from 'react';
import useLocalStorage from 'utils/useLocalStorage';
import { getMarketContent } from 'utils/marketContent';
import './index.scss';

const Partner = ({ img }) => (
  <div className="partner">
    <img src={img} alt="partner" height="100%" width="100%" />
  </div>
);

export default function Partners(): ReactElement {
  const market = getMarketContent();
  const data = market.homePage?.partnerLogos;
  const [slides, setSlides] = useState(data);
  const [isDarkFromLocalStroage] = useLocalStorage<boolean>('isDark', true);

  useEffect(() => {
    const partnerIcon = isDarkFromLocalStroage
      ? 'partner_logo_dark.png'
      : 'partner_logo_light.png';
    const slideData = data?.map(item => ({
      ...item,
      img: `/images/${partnerIcon}`,
    }));
    setSlides(slideData);
  }, [data, isDarkFromLocalStroage]);

  const settings = {
    arrow: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {slides && (
        <section id="partners">
          <div className="container px-6 md:px-0 py-4">
            <Slider {...settings}>
              {slides.map(item => (
                <Partner img={item.img} key={`${item.img}${Math.random()}`} />
              ))}
            </Slider>
          </div>
        </section>
      )}
    </>
  );
}
