import { PackageDetails } from 'types';
import academyofselfknowledge from 'utils/content/academyofselfknowledge.json';
import deterioration from 'utils/content/deterioration.json';
import dontclick from 'utils/content/dontclick.json';
import drbuttar from 'utils/content/drbuttar.json';
import drleemerritt from 'utils/content/drleemerritt.json';
import drnorthrup from 'utils/content/drnorthrup.json';
import eileenmckusick from 'utils/content/eileenmckusick.json';
import leilacentner from 'utils/content/leilacentner.json';
import loislaynee from 'utils/content/loislaynee.json';
import thebrehms from 'utils/content/thebrehms.json';
import rsb from 'utils/content/rsb.json';
import sayerji from 'utils/content/sayerji.json';
import tap from 'utils/content/tap.json';
import theduttons from 'utils/content/theduttons.json';
import vitalelements from 'utils/content/vitalelements.json';
import voicesagainsttrafficking from 'utils/content/voicesagainsttrafficking.json';
import wildeinfluence from 'utils/content/wildeinfluence.json';

interface Store {
  exchange: string;
  name: string;
  store: PackageDetails;
}

const healthcare: Record<string, PackageDetails> = {
  drbuttar,
  drleemerritt,
  drnorthrup,
  eileenmckusick,
  leilacentner,
  loislaynee,
  rsb,
  sayerji,
  vitalelements,
  tap,
};
// const hsx: Record<string, PackageDetails> = healthcare;

const consumerdiscretionary: Record<string, PackageDetails> = {
  deterioration,
};
// const cdx: Record<string, PackageDetails> = consumerdiscretionary;

const communications: Record<string, PackageDetails> = {
  theduttons,
  dontclick,
  academyofselfknowledge,
  voicesagainsttrafficking,
  thebrehms,
  wildeinfluence,
};
// const cvx: Record<string, PackageDetails> = communications;

// Exchanges with currenlty no stores:
// Energy | ESX
// Materials | MSX
// Industrials | ISX
// Consumer Staples | CSX
// Financials | FSX
// Information Technology | ITX
// Utilities | USX
// Real Estate | REX
// Blockchain Ecosystem | BEX

export const storesByExchange: Record<
  string,
  Record<string, PackageDetails>
> = {
  healthcare,
  // hsx,
  consumerdiscretionary,
  // cdx,
  communications,
  // cvx,
};

export function getStoreArray() {
  const stores: Store[] = [];
  const exchanges: string[] = Object.keys(storesByExchange);
  exchanges.forEach(exchange => {
    const storeNames: string[] = Object.keys(
      storesByExchange[exchange],
    ) as string[];
    storeNames.forEach(name => {
      stores.push({
        exchange,
        name,
        store: storesByExchange[exchange][name],
      });
    });
  });
  return stores;
}

export function getRandomStoreNames() {
  const stores = getStoreArray();
  const min = 0;
  const max = stores.length;
  const rand = Math.floor(min + Math.random() * (max - min));
  const store = rand < max ? stores[rand] : stores[0];
  return {
    storeName: store.name,
  };
}

export default function getStoreContent(
  storeName: string,
  exchange?: string,
): PackageDetails {
  let storeContent: PackageDetails | undefined;
  if (exchange && storesByExchange[exchange]) {
    storeContent = storesByExchange[exchange][storeName] || undefined;
  } else if (!exchange) {
    storeContent = getStoreArray().find(
      (store: { name: string }) => store.name === storeName,
    )?.store;
  }

  if (!storeContent) {
    return {} as PackageDetails;
  }

  return storeContent;
}
