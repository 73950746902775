import { Carousel } from 'primereact/carousel';
import { ReactElement } from 'react';
import { Reveal, Fade } from 'react-awesome-reveal';
import { rightSlideMobile } from 'components/imedia/components/Animations';
import { useContent } from 'utils/useContent';
import './scss/Reviews.scss';

export default function Reviews(): ReactElement {
  const data = useContent();

  interface Review {
    name: string;
    position?: string;
    content: string;
  }
  const reviewItems = data.reviews;
  const itemTemplate = (review: Review) => (
    <div className="flex justify-content-center align-items-center flex-column review--wrapper">
      <p className="text-2xl font-bold review--name">{review.name}</p>
      <p className="text-1xl review--ocupation">{review.position}</p>
      <p className="text-center my-0 text-color-light-gray w-11">
        {review.content}
      </p>
    </div>
  );

  return (
    <section
      id="testimonials"
      className="p-m80-d100 px-3"
      style={{
        backgroundImage:
          'url(https://storefront-static.crowdpoint.tech/shared/testimonial-background.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Fade direction="up" triggerOnce>
        <h3 className="text-5xl font-normal mb-6 capitalize mt-0 text-center text-white review-title-text">
          What people are saying...
        </h3>
        {/* {data.reviewSection.reviewSubtitle && (
          <p className="text-xl mt-0 mb-5 text-center text-white review-subtitle-text">
            {parse(data.reviewSection.reviewSubtitle)}
          </p>
        )} */}
      </Fade>
      <div className="flex justify-content-center">
        <Reveal keyframes={rightSlideMobile} triggerOnce>
          <Carousel
            value={reviewItems}
            itemTemplate={itemTemplate}
            circular
            numVisible={1}
            numScroll={1}
            autoplayInterval={5000}
            className="review-carousel"
            style={{
              maxWidth: '40rem',
              padding: '1em 0 1.5em 0',
              background: '#F1F1F1',
              borderRadius: '20px',
              margin: '0 auto',
            }}
          />
        </Reveal>
      </div>
    </section>
  );
}
