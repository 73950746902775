import React, { useEffect, useRef, useState } from 'react';
import { OidcClient, OidcClientSettings, SigninRequest } from 'oidc-client-ts';

import { AuthContext, AuthContextProps } from './AuthContext';

/**
 * @public
 */
export const useAuth = (): AuthContextProps => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error(
      'AuthProvider context is undefined, please verify you are calling useAuth() as child of a <AuthProvider> component.',
    );
  }

  return context;
};

export function useSigninRequest(oidcSettings: OidcClientSettings): {
  signinRequest: SigninRequest | undefined;
  error: any;
  isExecuting: boolean;
  refresh: () => void;
} {
  const [isExecuting, setIsExecuting] = useState(true);
  const [result, setResult] = useState<SigninRequest | undefined>();
  const [error, setError] = useState<any>();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      setIsExecuting(true);
      const oidcClient = new OidcClient(oidcSettings);
      oidcClient
        .createSigninRequest({
          request_type: 'si:r',
          ...oidcSettings,
        })
        .then(setResult)
        .catch(setError)
        .finally(() => {
          setIsExecuting(false);
          setShouldRefresh(false);
        });
    }
    return () => {
      hasRun.current = true;
    };
  }, [oidcSettings, shouldRefresh]);

  const refresh = () => {
    hasRun.current = false;
    setShouldRefresh(true);
  };

  return { signinRequest: result, error, isExecuting, refresh };
}
